import { JtClickHouse } from '@portal/clickhouse';
import { guardUnspecified } from '@portal/utils/util-guards';
import { isSameRegion, Regions } from '@smh/projects/regions';
import { Action } from 'vuex-simple';

import { PAGE_NAME } from '@jtnews/shared/data';

import {
  AnalyticsService,
  analyticsService,
  GAParams as GAParamsAlias,
  PageData as PageDataAlias,
  ReachGoalParams,
  ReachGoalType,
  AnalyticsDataMapper,
  analyticsDataMapper
} from '../services/analytics';

import { RootModule } from './store';

type ReachGoal = {
  name: string;
  params?: ValueParams;
};

type NewReachGoal = {
  blockType: string;
  fieldType: string;
  goalName: string;
  valueName: ValueParams;
  productName?: string;
  willDeprecate?: boolean;
};

type NewFormatReachGoal = {
  goalName: string;
  blockType: string;
  actionType: string;
  prop1?: string;
  prop2?: ValueParams;
};

type ClickHouseReachGoalType<T extends ReachGoalType> = {
  type: T;
  params: T extends 'reachgoal'
    ? ReachGoal
    : T extends 'new_reachgoal'
    ? NewReachGoal
    : never;
};

// eslint-disable-next-line
interface GoalValueParams {
  [prop: string]: Record<string, GoalValueParams> | string;
}

type ValueParams = string | number | string[] | Record<string, string | GoalValueParams>;

export class AnalyticsModule {
  public analyticsService: AnalyticsService = analyticsService;

  // eslint-disable-next-line
  private _analyticsDataMapper: AnalyticsDataMapper;

  // eslint-disable-next-line
  private _clickHouseService: JtClickHouse;

  // eslint-disable-next-line
  constructor(private _root: RootModule) {}

  @Action()
  public sendReachGoal(goalData: ReachGoal): void {
    const { name, params } = goalData;
    this.analyticsService.sendReachGoal(this._root.regionId, name, params);

    const clickHouseParams = this._getClickHouseReachgoalsParams({
      params: goalData,
      type: 'reachgoal'
    });
    this._analyticsDataMapper.sendClickhouseReachGoal(clickHouseParams);
  }

  @Action()
  public sendNewReachGoal(params: NewReachGoal): void {
    const {
      blockType,
      fieldType,
      valueName,
      goalName,
      productName = 'Продукт',
      willDeprecate = false
    } = params;

    if (willDeprecate && isSameRegion(this._root.regionId, Regions.Sochi)) {
      return;
    }

    this.analyticsService.sendNewReachGoal(
      this._root.regionId,
      this._root.pageType,
      blockType,
      fieldType,
      valueName,
      goalName,
      productName
    );

    const clickHouseParams = this._getClickHouseReachgoalsParams({
      params,
      type: 'new_reachgoal'
    });
    this._analyticsDataMapper.sendClickhouseReachGoal(clickHouseParams);
  }

  @Action()
  public sendNewFormatReachGoal({
    goalName,
    blockType,
    actionType,
    prop1,
    prop2
  }: NewFormatReachGoal): void {
    if (!isSameRegion(this._root.regionId, Regions.Sochi)) {
      return;
    }

    this.analyticsService.sendNewFormatReachGoal(
      this._root.regionId,
      goalName,
      this._root.pageType,
      blockType,
      actionType,
      prop1,
      prop2
    );

    this._clickHouseService.sendEvent(
      this._getNewFormatClickHouseParams(goalName, blockType, actionType, prop1, prop2)
    );
  }

  @Action()
  public sendPageAnalyticsData(data: PageData): void {
    this.analyticsService.sendPageAnalyticsData(
      this._root.regionId,
      data,
      this._root.serverDate
    );
  }

  @Action()
  public sendPerformanceMetrics(metrics: Record<string, string>): void {
    Object.keys(metrics).forEach(key => {
      const params = this._getPerformanceParams(key, metrics[key]);

      const clickHouseParams = this._getClickHouseReachgoalsParams({
        params,
        type: 'reachgoal'
      });

      this._analyticsDataMapper.sendClickhouseReachGoal(clickHouseParams);
    });
  }

  init(domain: string, envType: string): void {
    this._analyticsDataMapper = analyticsDataMapper;

    this._clickHouseService = new JtClickHouse(domain + envType);
  }

  // eslint-disable-next-line
  private _getPerformanceParams(key: string, value: string): ReachGoal {
    const metricsName = 'Мониторинг';
    const metricsType = 'Загрузка страниц';
    const params = {
      params: {
        [metricsName]: {
          [PAGE_NAME[this._root.pageType]]: {
            [metricsType]: {
              [key]: value
            }
          }
        }
      },
      name: 'measureTech'
    };

    return params;
  }

  // eslint-disable-next-line
  private _getClickHouseReachgoalsParams(
    data: ClickHouseReachGoalType<'new_reachgoal'> | ClickHouseReachGoalType<'reachgoal'>
  ): ReachGoalParams {
    let defaultParams: ReachGoalParams = {
      regionId: this._root.regionId,
      experiments: this._root.experimentNames,
      pageType: this._root.pageType,
      currentUrl: window.location.href,
      referrerUrl: document.referrer,
      valueName: data.type === 'reachgoal' ? data.params.params : data.params.valueName,
      goalName: data.type === 'reachgoal' ? data.params.name : data.params.goalName
    };

    if (data.type === 'new_reachgoal') {
      const { params } = data;
      defaultParams = {
        ...defaultParams,
        blockType: params.blockType,
        fieldType: params.fieldType,
        productName: params.productName || 'Продукт'
      };
    }

    return defaultParams;
  }

  // eslint-disable-next-line
  private _getNewFormatClickHouseParams(
    eventName: string,
    blockType: string,
    actionType: string,
    prop1?: string,
    prop2?: string
  ) {
    const params: string[] = [PAGE_NAME[this._root.pageType], blockType, actionType];

    if (guardUnspecified(prop1)) {
      params.push(prop1);
    }

    if (guardUnspecified(prop2)) {
      params.push(prop2);
    }

    return {
      url: window.location.href,
      referrer: document.referrer,
      eventName,
      experiments: this._root.experimentNames,
      params
    };
  }
}

export type GAParams = GAParamsAlias;
export type PageData = PageDataAlias;
