/* eslint-disable @typescript-eslint/naming-convention */
declare global {
  interface Window {
    hsmCreativeManagerReady: Promise<ICreativeManager>;
    __ADS_CONFIG__: {
      layout: Layout;
      adunits: Creative[];
    };
  }
}

type CreateSlotParams = {
  container: HTMLElement;
  adunit: string;
  params?: CustomSlotParams;
};

type Targeting = {
  key: string;
  value: string | string[];
};

type SlotSizes = {
  x: number;
  y: number;
};

type SlotEvents =
  | 'render'
  | 'stub'
  | 'error'
  | 'request'
  | 'beforeDisplay'
  | 'display'
  | 'beforeRefresh'
  | 'refresh'
  | 'beforeDestroy'
  | 'closed'
  | 'destroy';

type Layout = 'STANDART' | 'INTERNATIONAL';

export type CustomSlotParams = {
  type?: 'fullscreen' | 'floorAd';
  priority?: number;
  fullscreenBannerBlacklist?: string[];
};
/**
 * TODO дополнить
 */
export enum AdUnit {
  Inread = 'inread',
  Inread2 = 'inread-2',
  Inline = 'inline',
  Inline1 = 'inline-1',
  Inline2 = 'inline-2',
  Inline3 = 'inline-3',
  Inline4 = 'inline-4',
  Inline5 = 'inline-5',
  HbMid1 = 'hb-mid-1',

  VbRight1 = 'vb-right-1',
  VbRight2 = 'vb-right-2',
  VbRight3 = 'vb-right-3',
  VbRight4 = 'vb-right-4',
  VbRight5 = 'vb-right-5',

  StickyRsya = 'sticky-rsya',
  Sticky = 'sticky',
  Fullscreen = 'fullscreen',
  FullscreenMain = 'fullscreen-main',
  Flyroll = 'flyroll',
  InImageButton = 'in-image-button'
}

export enum SlotStatus {
  CREATED,
  READY,
  IN_PROGRESS,
  DESTROYED,
  STUB,
  ERROR
}

export interface ISlot {
  readonly $el: HTMLElement;
  readonly config: Creative;
  readonly bannerSize: SlotSizes;
  isDestroyed: boolean;
  status: SlotStatus;
  display(): Promise<unknown>;
  refresh(): Promise<unknown>;
  destroy(): Promise<unknown>;
  setTargeting(targeting: Targeting): void;
  on(eventName: SlotEvents, callback: () => void): void;
  off(eventName: SlotEvents, callback?: () => void): void;
}

export type Creative = {
  adserver: string;
  placement: string;
  layout: Layout;
  isMobile: boolean;
  placeholderEnabled: boolean;
  placeholderConfig: {
    height: number;
    backgroundEnabled: boolean;
    bordersEnabled: boolean;
    advMarkEnabled: boolean;
  };
  refreshEnabled: boolean;
  refreshConfig: {
    refreshInterval: number;
    viewportTime: number;
  };
  adfoxConfig: {
    p1: string;
    p2: string;
  };
};

export interface IStickyRsyaManager {
  setzIndex(zIndex: number): void;
  setBottomOffset(offset: number): void;
  setLeftOffset(offset: number): void;
  setMaxHeight(height: number): void;
}

export interface ICreativeManager {
  ready: Promise<void>;
  geoReady: Promise<void>;
  stickyRsya: IStickyRsyaManager;
  configManager: {
    layout: Layout;
    findAdunit(placement: string): Creative;
  };
  createSlot(params: CreateSlotParams): Promise<ISlot>;
  destroySlot(slot: ISlot): void;
  setTargeting(...targetings: Targeting[]): void;
  findSlots(adunit: string): ISlot[];
}
