var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({class:[
    _vm.$style['responsive-list'],
    _vm.isPictureRedesign ? _vm.$style['isPictureRedesign'] : '',
    _vm.types.desktop ? _vm.$style['_desktop'] : '',
    _vm.types.laptop ? _vm.$style['_laptop'] : '',
    _vm.types.tablet ? _vm.$style['_tablet'] : '',
    _vm.types.mobile ? _vm.$style['_mobile'] : ''
  ]},_vm.$listeners),[(!_vm.isMobile)?_c('text-list-with-dropdown',{class:[_vm.$style['list-dropdown'], _vm.$style['themes']],attrs:{"type":_vm.type,"items":_vm.items},on:{"clickedSublistBtn":function($event){return _vm.$emit('showMoreLinks')},"clickedLink":function($event){return _vm.$emit('sendNewReachGoal', $event.path)}}}):_c('mobile-text-slider',{attrs:{"type":_vm.type,"is-picture-redesign":_vm.isPictureRedesign,"items":_vm.items},on:{"linkClicked":function($event){return _vm.$emit('sendNewReachGoal', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }