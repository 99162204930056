// export type ParsedStyle = {
//   raw: string,
//   content?: string
// };

// export type ParsedScript = {
//   src?: string
// } & ParsedStyle;

export const getScriptsFromRawHtml = (rawHtml /* : string */) /* : ParsedScript[] */ => {
  if (typeof rawHtml !== 'string') return [];

  const rawHtmlWithoutLineBreaks = removeLineBreaks(rawHtml);

  const scriptRegEx = /(<script\b[^>]*>.*?<\/script>)/gm;
  const rawScriptsArray /* : string[] | null */ =
    rawHtmlWithoutLineBreaks.match(scriptRegEx) || null;

  if (rawScriptsArray === null) {
    return [];
  }

  const srcRegEx = /src="(.*?)"/gm;
  const contentRegEx = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;

  const scriptsElements /* : ParsedScript[] */ = rawScriptsArray.map((
    rawScript /* : string */
  ) => {
    let regexResult /* : any */ = srcRegEx.exec(rawScript);

    const res = {
      src: '',
      content: '',
      raw: rawScript
    };

    if (regexResult !== null && regexResult[1]) {
      return { ...res, src: regexResult[1] };
    }

    regexResult = contentRegEx.exec(rawScript);

    if (regexResult !== null && regexResult[1]) {
      return { ...res, content: regexResult[1] };
    }

    return res;
  });

  return scriptsElements;
};

export const getStylesFromRawHtml = (rawHtml /* : string */) /* : ParsedStyle[] */ => {
  if (typeof rawHtml !== 'string') return [];

  const rawHtmlWithoutLineBreaks = removeLineBreaks(rawHtml);

  const styleRegEx = /(<style\b[^>]*>.*?<\/style>)/gm;
  const rawStylesArray /* : string[] | null */ =
    rawHtmlWithoutLineBreaks.match(styleRegEx) || null;

  if (rawStylesArray === null) {
    return [];
  }

  const contentRegEx = /<style\b[^>]*>([\s\S]*?)<\/style>/gm;

  const stylesElements /* : ParsedStyle[] */ = rawStylesArray.map((
    rawScript /* : string */
  ) => {
    const res = {
      content: '',
      raw: rawScript
    };

    const regexResult /* : any */ = contentRegEx.exec(rawScript);

    if (regexResult !== null && regexResult[1]) {
      return { ...res, content: regexResult[1] };
    }

    return res;
  });

  return stylesElements;
};

export const tagId = (str /* : string */) /* : string | null */ => {
  const idRegEx = /\sid=("|')([^"]*?)("|')/gi;
  const res = idRegEx.exec(str);

  if (res === null) return null;

  return res[2];
};

export const getDivs = (rawHtml /* : string */) /* : string[] | null */ => {
  if (typeof rawHtml !== 'string') return null;

  const divRegEx = /<div\b[^>]*>.*?<\/div>/gm;
  return removeLineBreaks(rawHtml).match(divRegEx) || null;
};

export const getStyles = (rawHtml /* : string */) /* : string[] | null */ => {
  if (typeof rawHtml !== 'string') return null;

  const styleRegEx = /<style\b[^>]*>.*?<\/style>/gm;
  return removeLineBreaks(rawHtml).match(styleRegEx) || null;
};

export const getScripts = (rawHtml /* : string */) /* : string[] | null */ => {
  if (typeof rawHtml !== 'string') return null;

  const scriptRegEx = /<script\b[^>]*>.*?<\/script>/gm;
  return removeLineBreaks(rawHtml).match(scriptRegEx) || null;
};

export const removeStyles = (rawHtml /* : string */) /* : string */ => {
  if (typeof rawHtml !== 'string') return '';

  const styleRegEx = /<style\b[^>]*>.*?<\/style>/gm;
  return removeLineBreaks(rawHtml).replace(styleRegEx, '');
};

export const removeScripts = (rawHtml /* : string */) /* : string */ => {
  if (typeof rawHtml !== 'string') return '';

  const scriptRegEx = /<script\b[^>]*>.*?<\/script>/gm;
  return removeLineBreaks(rawHtml).replace(scriptRegEx, '');
};

export const removeLineBreaks = (str /* : string */) /* : string */ =>
  str.replace(/\r?\n/g, '');
