/* eslint-disable @typescript-eslint/naming-convention */
import { BehaviorSubject } from 'rxjs';

export type AlertsState = {
  value: 'close' | 'open';
  changedBy: string;
  name?: string;
};

export class AlertsService {
  static DEFAULT_PARAMS_STATE: AlertsState = {
    value: 'close',
    changedBy: 'default',
    name: 'alerts'
  };

  static DEFAULT_PARAMS_CLOSING_ALLOWED = true;
  static DEFAULT_PARAMS_READING_STATUS = true;

  private readonly _state: BehaviorSubject<AlertsState> = new BehaviorSubject(
    AlertsService.DEFAULT_PARAMS_STATE
  );

  // нам нужно запрещать закрывать выпадающее окошко с уведомлениями, если открыто окно связи с редакцией.
  private readonly _сlosingAllowed: BehaviorSubject<boolean> = new BehaviorSubject(
    AlertsService.DEFAULT_PARAMS_CLOSING_ALLOWED
  );

  // задаем статус (прочитанные/непрочитанные) уведомлений
  private readonly _readingStatus: BehaviorSubject<boolean> = new BehaviorSubject(
    AlertsService.DEFAULT_PARAMS_READING_STATUS
  );

  private readonly _name = 'alerts';

  public get state(): BehaviorSubject<AlertsState> {
    return this._state;
  }
  public get сlosingAllowed(): BehaviorSubject<boolean> {
    return this._сlosingAllowed;
  }
  public get readingStatus(): BehaviorSubject<boolean> {
    return this._readingStatus;
  }

  public changeState(state: AlertsState): void {
    const name = this._name;
    this._state.next({ ...state, name });
  }

  public changeСlosingAllowedState(state: boolean): void {
    this._сlosingAllowed.next(state);
  }

  public changeReadingStatus(state: boolean): void {
    this._readingStatus.next(state);
  }
}

export const alertsService = new AlertsService();
