import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@jtnews/shared';

import UserBarSettingsBtn from './user-bar-settings-btn.component';
import styles from './user-bar.styles.scss?module';

type AvatarSources = {
  srcset: string;
  sizes?: string;
  type?: string;
  media?: string;
}[];

interface Events {
  onBookmarksClicked: Event;
  onLogoutClicked: Event;
  onProfileClicked: Event;
  onSettingsClicked: Event;
}

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'UserBarProfile',
    props: {
      name: {
        type: String,
        default: ''
      },
      email: {
        type: String,
        default: ''
      },
      link: {
        type: String,
        default: ''
      },
      avatarData: {
        type: Object as () => { src: string; sources: AvatarSources },
        default() {
          return {
            src: '',
            sources: []
          };
        }
      },
      sex: {
        type: String,
        default: ''
      },
      newUserNoticesCount: {
        type: Number,
        default: 0
      }
    },
    computed: {
      bookmarkUrl(): string {
        return `${this.link}?tab=bookmarks`;
      }
    },
    methods: {
      logout(event: Event) {
        this.$emit('logoutClicked', event);
      },
      openProfile(event: Event) {
        this.$emit('profileClicked', event);
      },
      openSettings(event: Event) {
        this.$emit('settingsClicked', event);
      },
      openBookmarks(event: Event) {
        this.$emit('bookmarksClicked', event);
      }
    },
    render() {
      return (
        <ul class={[styles.sublist]}>
          <li class={styles.sublistItem}>
            <UserBarSettingsBtn
              name={this.name}
              email={this.email}
              avatarData={this.avatarData}
              sex={this.sex}
              link={this.link}
              newUserNoticesCount={this.newUserNoticesCount}
              onProfileClicked={(event: Event) => this.openProfile(event)}
              onSettingsClicked={(event: Event) => this.openSettings(event)}
            />
          </li>
          <li class={styles.sublistItem}>
            <a
              href={this.bookmarkUrl}
              onClick={(event: Event) => this.openBookmarks(event)}
            >
              Закладки
            </a>
          </li>
          <li class={styles.sublistItem}>
            <button onClick={(event: Event) => this.logout(event)}>Выйти</button>
          </li>
        </ul>
      );
    }
  });
