import type { ApiResponseWithBlockData, BaseRecord } from '../base/api-response';
import type { BaseConfig } from '../base/base-http-client';
import { BaseHttpClient } from '../base/base-http-client';
import { catchError } from '../base/catch-error';
import type { ClientBlockResponse } from '../base/client-response';
import type { BasePageParams } from '../page-params';

export class NetworkContentHttpClient extends BaseHttpClient {
  private readonly _networkContentBlockUrl = '/public/jtnews/services/network_content/';

  constructor(config: BaseConfig) {
    super(config);
  }

  @catchError
  public async getNetworkContentBlock(
    params: BasePageParams,
  ): Promise<ClientBlockResponse<BaseRecord[]>> {
    const { regionId, headers } = params;
    const host = this.getHost(regionId);
    const url = `${host}${this.v}${this._networkContentBlockUrl}`;

    const response = await this.httpClient.get<ApiResponseWithBlockData<BaseRecord[]>>(
      url,
      {
        headers,
        withCredentials: true,
        params: {
          regionId,
        },
      },
    );

    return {
      data: response.data,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: response.headers,
      responseTime: response.data.responseTime,
    };
  }
}
