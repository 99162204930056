import { RouteConfig } from 'vue-router';

import { RUBRICS_REGEX_IDS } from '@jtnews/shared/data';

type RubrucModuleType = typeof import('./pages/rubric.vue');

export const RUBRIC_ROUTING: RouteConfig[] = [
  // страница рубрики
  {
    path: `/text/:rubricId${RUBRICS_REGEX_IDS}`,
    component: (): Promise<RubrucModuleType> =>
      import(/* webpackChunkName: "rubric-page" */ './pages/rubric.vue')
  }
];
