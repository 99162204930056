<template>
  <div :class="$style.widgetCurrency">
    <jtn-ui-informer-mini
      v-if="data"
      :type="link ? 'link' : 'block'"
      :href="link"
      :title="link"
      target="_blank"
      data-test="widget-currency"
      @click="sendCurrencyCTR($event)"
    >
      <div slot="text" :class="$style.list">
        <template v-for="item of data">
          <div :key="item.name" :class="$style.item">
            <span> {{ item.name }}&nbsp;{{ formatValue(item.value, 2) }} </span>
            <span :class="$style.icon">
              <svg
                  fill="currentColor"
                  :class="item.delta < 0 ? $style.rotate : ''"
                  :width="8"
                  :height="8">
                <use xlink:href="jtn-critical-trend"></use>
              </svg>
            </span>
          </div>
        </template>
      </div>
    </jtn-ui-informer-mini>
  </div>
</template>

<script>
import { JtnUiInformerMini } from '@jtnews/jtn-ui';

import { StoreMixin } from '../../mixins';

export default {
  name: 'CurrencyWidget',
  components: { JtnUiInformerMini },
  mixins: [StoreMixin],
  computed: {
    currencyWidgetBlock() {
      return this.store.commonModule.commonCurrencyBlock;
    },
    link() {
      const block = this.currencyWidgetBlock;
      return block.data && block.data.link ? block.data.link : '';
    },
    data() {
      const block = this.currencyWidgetBlock;
      return block.data && block.data.currency ? block.data.currency : null;
    }
  },
  methods: {
    formatValue(value, exp) {
      return parseFloat(value.toString()).toFixed(exp);
    },
    sendCurrencyCTR(event) {
      this.$emit('sendNewReachGoal');
    },
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.widgetCurrency {
  display: flex;
  align-items: center;

  @include on-min-resize($laptop) {
    height: $header-height;
  }
}

.list {
  display: flex;
  flex-direction: column;

  @include on-min-resize($tablet) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  @include on-min-resize($laptop) {
    flex-direction: column;
  }
}

.item {
  display: flex;
  align-items: center;

  white-space: nowrap;

  & + & {
    margin-top: 3px;
  }

  .icon {
    margin-left: 6px;
    color: $color-success;
  }

  .rotate {
    transform: rotate(180deg);
    color: $color-error;
  }

  @include on-res($mobile) {
    justify-content: space-between;
  }
  @include on-min-resize($tablet) {
    & + & {
      margin-top: 0;
      margin-left: 16px;
    }
  }
  @include on-min-resize($laptop) {
    & + & {
      margin: 0;
    }
  }
}
</style>
