import * as tsx from 'vue-tsx-support';

import { JtnUiBtnIcon } from '@jtnews/jtn-ui';
import { injectStylesMixin } from '@jtnews/shared';

import styles from './sp-widget.styles.scss?module';

interface Events {
  onClickCloseBtn: void;
}

export default tsx
  .componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'SpWidget',
    props: {
      width: {
        default: 600
      },
      height: {
        default: 493
      }
    },
    render() {
      return (
        <div class={styles['sp-widget']}>
          <iframe
            src="https://www.nn.ru/jtnews/fedsp/"
            width={this.width}
            height={this.height}
            frameBorder="0"
            title="sp-widget"
          />
          <JtnUiBtnIcon
            class={styles['close-btn']}
            btn-type="button-other-transparent"
            type="button"
            size={40}
            onClick={() => {
              this.$emit('clickCloseBtn');
            }}
          >
            <svg style={{ color: '#37404d' }} width={16} height={16}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-close"></use>
            </svg>
          </JtnUiBtnIcon>
        </div>
      );
    }
  });
