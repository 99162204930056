import { clickOutside } from '@portal/utils/util-click-outside';
import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { Regions } from '@smh/projects/regions';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiBtnIcon, JtnUiBadge, JtnUiAvatar, JtnUiImage } from '@jtnews/jtn-ui';
import { injectStylesMixin, tsStoreMixin } from '@jtnews/shared';
import type { UserInfo } from '@jtnews/shared/news';

import type { UserMenuState } from '../../../core/services/user-menu.service';
import { AuthParams } from '../../containers/modals/auth-modal/auth-modal.container';

import UserBarDropdown from './user-bar-dropdown.component';
import styles from './user-bar.styles.scss?module';

// eslint-disable-next-line @typescript-eslint/naming-convention
const AuthAndRegisterModal = () =>
  import(/* webpackChunkName: "auth-and-register-modal" */ '@jtnews/layout/modals').then(
    m => m.AuthAndRegisterModal
  );

type StickyHeaderState = {
  sticky: boolean;
  stickyHeaderHeight: number;
};

type ToggleEvent = {
  isShowed: boolean;
  event: Event;
};

type ReachGoalValue = string | Record<string, string>;

type AlertsState = {
  value: 'close' | 'open';
  changedBy: string;
  name?: string;
};

type ImageSourceVM = {
  srcset: string;
  sizes?: string;
  type?: string;
  media?: string;
};

type ForumProfileData = null | {
  id: number;
  login: string;
  email: string;
  link: string;
  avatarData: {
    src: string;
    sources: ImageSourceVM[];
  };
  sex: string;
  menu: ForumMenuItem[];
};

type ForumMenuItem = {
  id: string;
  name: string;
  link: string;
  count: number;
  desktop?: boolean;
};

type ComponentData = {
  destroy$: Subject<void>;
  width: number;
  showDropdown: boolean;
  blockType: string;
  currentPath: string;
  triedUnsubscribeFromNotices: boolean;
  triedLogout: boolean;
  alertsOpened: boolean;
  isAuthModalOpen: boolean;
  isAuthModalRendered: boolean;
  authParams: AuthParams | null;
};

interface IEvents {
  onToggleSearch: ToggleEvent;
  onSubmitSearch: string;
}

export default componentFactoryOf<IEvents>()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'UserBar',
    props: {
      isMobile: {
        type: Boolean,
        default: false
      },
      isSmallMobile: {
        type: Boolean,
        default: false
      },
      newUserNoticesCount: {
        type: Number,
        default: 0
      },
      isShowBadge: {
        type: Boolean,
        default: false
      },
      hasUserForums: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        destroy$: new Subject(),
        width: 0,
        showDropdown: false,
        blockType: '',
        currentPath: '',
        triedUnsubscribeFromNotices: false,
        triedLogout: false,
        alertsOpened: false,
        isAuthModalOpen: false,
        isAuthModalRendered: false,
        authParams: null
      };
    },
    computed: {
      userInfo(): null | UserInfo {
        return this.store.commonModule.userInfo;
      },
      isAuthorized(): boolean {
        return guardUnspecified(this.userInfo);
      },
      regionId(): Regions {
        return this.store.regionId;
      },
      profileId(): string {
        return guardUnspecified(this.userInfo) ? String(this.userInfo.userId) : '';
      },
      profileLink(): string {
        if (!guardUnspecified(this.userInfo)) {
          return '';
        }

        return this.userInfo.hasProfile
          ? this.userInfo.link
          : `${this.userInfo.link}${this.userInfo.userId}`;
      },
      isPushNotificationsEnabled(): boolean {
        return this.store.pushModule.isPushNotificationsEnabled;
      },
      isAnswerPushNotificationsEnabled() {
        return (
          this.store.pushModule.savedUserBrowserNotificationSettings
            ?.isAnswersPushEnabled ?? false
        );
      },
      canUnsubscribeFromNotices(): boolean {
        return (
          this.isPushNotificationsEnabled &&
          this.isAnswerPushNotificationsEnabled &&
          this.triedLogout &&
          !this.triedUnsubscribeFromNotices
        );
      },
      showNoticesBadge(): boolean {
        return !this.showDropdown && this.isSmallMobile && this.isShowBadge;
      },
      forumProfile(): ForumProfileData {
        return this.store.commonModule?.forumProfileData;
      }
    },
    beforeMount() {
      if (this.$route.hash === '#auth') {
        this.openAuthModal({});
      }

      this.store.commonModule.headerServiceState$
        .pipe(takeUntil(this.destroy$))
        .subscribe((value: StickyHeaderState) => {
          this.blockType = value.sticky ? 'Залипающее Меню' : 'Меню';
          if (value.sticky && value.stickyHeaderHeight === 0) {
            this.closeUserBar();
          }
        });

      this.store.commonModule.alertsState$
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: AlertsState) => {
          this.alertsOpened = state.value === 'open';
        });

      this.store.commonModule.userMenuState$
        .pipe(
          takeUntil(this.destroy$),
          filter(({ value }: UserMenuState) => value === 'close')
        )
        .subscribe(() => {
          this.showDropdown = false;
        });
    },
    mounted() {
      if (guardUnspecified(this.$refs.userBar)) {
        clickOutside(this.$refs.userBar as HTMLElement, () => this.closeUserBar(), {
          isKeepAlive: true,
          isCapture: true
        });
      }
    },
    beforeDestroy() {
      this.destroy$.next();
      this.destroy$.unsubscribe();
    },
    methods: {
      openAuthModal(params: AuthParams) {
        this.authParams = params;
        this.isAuthModalOpen = true;
        this.isAuthModalRendered = true;
      },
      updateAlertsState(value: AlertsState) {
        this.store.commonModule.updateAlertsState(value);
      },
      changeAlertsState() {
        const value = this.alertsOpened ? 'close' : 'open';

        if (value === 'open') {
          this.showDropdown = false;
          this.sendNewReachGoal('Меню', 'Пользователь', 'Колокольчик', 'clickMenu');
          this.sendNewFormatReachGoal('Колокольчик');
        }

        this.updateAlertsState({
          value,
          changedBy: 'header-toolbar'
        });
      },
      closeUserBar() {
        this.showDropdown = false;
      },
      toggleDropdown() {
        if (!this.isAuthorized && !this.hasUserForums) {
          this.clickLogin({ typeProfile: 'news' });
          return;
        }

        if (!this.showDropdown) {
          this.store.commonModule.updateAlertsState({
            value: 'close',
            changedBy: 'user-menu'
          });
          this.store.commonModule.emitSearchBarState('close');

          this.sendNewReachGoal(this.blockType, 'Пользователь', 'Открыл', 'clickMenu');
          this.sendNewFormatReachGoal('Открыл');
        }

        this.showDropdown = !this.showDropdown;
      },
      clickUserMenu(label: string) {
        this.sendNewReachGoal(this.blockType, 'Пользователь', label, 'clickMenu');
        this.sendNewFormatReachGoal(label);
      },
      clickUserSettings() {
        this.sendNewFormatReachGoal('Настройки');
      },
      async logout(): Promise<void> {
        this.sendNewReachGoal(this.blockType, 'Пользователь', 'Выход', 'clickMenu');
        this.sendNewFormatReachGoal('Выход');

        try {
          this.triedLogout = true;

          if (this.canUnsubscribeFromNotices && guardEmptyString(this.profileId)) {
            await this.unsubscribeFromNotices(this.profileId);
          }

          await this.store.commonModule.logout();

          window.location.reload();
        } catch (error) {
          console.error(error);
        }
      },
      async unsubscribeFromNotices(profileId: string) {
        this.triedUnsubscribeFromNotices = true;

        await this.store.pushModule.unsubscribeUserFromPushNotifications({
          profileId,
          notificationTypes: ['COMMENTS_NEW_ANSWER'],
          isNeedUpdateData: false
        });
      },
      clickLogin(params: AuthParams) {
        this.openAuthModal(params);
        this.sendNewReachGoal('Меню', 'Пользователь', 'Вход', 'clickMenu');
        this.sendNewFormatReachGoal('Вход');
      },
      forumLogoutClicked() {
        this.sendNewReachGoal('Меню', 'Пользователь', 'Выход из Форума', 'clickMenu');
        this.sendNewFormatReachGoal('Выход из Форума');
        void this.store.commonModule.logoutFromForumNN();
      },
      forumProfileClicked() {
        this.sendNewReachGoal('Меню', 'Пользователь', 'Профиль на Форуме', 'clickMenu');
        this.sendNewFormatReachGoal('Профиль на Форуме');
      },
      forumMenuClicked(id: string) {
        let value = '';

        if (id === 'new_answers') {
          value = 'Вам отвечают на форуме';
        }
        if (id === 'new_private_messages') {
          value = 'Приватные сообщения';
        }
        if (id === 'sp') {
          value = 'Мои покупки';
        }
        if (id === 'new_topics') {
          value = 'Слежу за темами';
        }
        if (id === 'new_blog_events') {
          value = 'Подписка на блоги';
        }

        this.sendNewReachGoal(
          'Меню',
          'Пользователь',
          { [value]: 'Раскрытое меню' },
          'clickMenu'
        );

        this.sendNewFormatReachGoal(value);
      },
      sendNewFormatReachGoal(place?: string) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Меню',
          goalName: 'ClickMenuProfile',
          actionType: 'Клик',
          prop1: 'Профиль',
          prop2: place
        });
      },
      sendNewReachGoal(
        blockType: string,
        fieldType: string,
        valueName: ReachGoalValue,
        goalName: string
      ) {
        this.store.analyticsModule.sendNewReachGoal({
          blockType,
          fieldType,
          valueName,
          goalName,
          willDeprecate: true
        });
      }
    },
    render() {
      const DURATION_PARAMS = { enter: 500, leave: 100 };

      return (
        <div ref="userBar" class={styles.userBar}>
          <div class={styles.userBarBtn}>
            <JtnUiBtnIcon
              size={44}
              btnType="button-other-transparent"
              data-test="user-bar-btn"
              nativeOnClick={() => {
                this.toggleDropdown();
              }}
            >
              {this.showDropdown ? (
                <svg class={styles.disableIcon} width={20} height={20}>
                  <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-close"></use>
                </svg>
              ) : this.isAuthorized ? (
                <div class={[styles.userAvatarWrap, styles.disableIcon]}>
                  <JtnUiAvatar
                    class={styles.disableIcon}
                    photo={this.userInfo.avatar}
                    sex={this.userInfo.sex}
                    size={24}
                  >
                    {guardEmptyString(this.userInfo.avatarData.src) && (
                      <JtnUiImage
                        slot="photo"
                        src={this.userInfo.avatarData.src}
                        sources={this.userInfo.avatarData.sources}
                        loading="eager"
                        alt="Фото пользователя"
                      />
                    )}
                  </JtnUiAvatar>
                </div>
              ) : (
                <svg class={styles.disableIcon} width={28} height={28}>
                  <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-user"></use>
                </svg>
              )}
            </JtnUiBtnIcon>
            {this.showNoticesBadge && (
              <JtnUiBadge
                class={[
                  styles.noticesBadge,
                  this.newUserNoticesCount ? styles._withCount : ''
                ]}
                content={this.newUserNoticesCount}
                size={this.newUserNoticesCount > 0 ? 'big' : 'small'}
                data-test="notifications-bt-badge"
              />
            )}
          </div>
          <transition name="fade" mode="out-in" duration={DURATION_PARAMS}>
            {this.showDropdown && (
              <UserBarDropdown
                userInfo={this.userInfo}
                forumProfile={this.forumProfile}
                profileLink={this.profileLink}
                isMobile={this.isMobile}
                isSmallMobile={this.isSmallMobile}
                newUserNoticesCount={this.newUserNoticesCount}
                isShowBadge={this.isShowBadge}
                hasUserForums={this.hasUserForums}
                onLogoutClicked={() => void this.logout()}
                onAlertsClicked={() => this.changeAlertsState()}
                onProfileClicked={() => this.clickUserMenu('Профиль')}
                onSettingsClicked={() => this.clickUserSettings()}
                onBookmarksClicked={() => this.clickUserMenu('Закладки')}
                onLoginClicked={params => this.clickLogin(params)}
                onForumLogoutClicked={() => this.forumLogoutClicked()}
                onForumProfileClicked={() => this.forumProfileClicked()}
                onForumMenuClicked={(event: string) => this.forumMenuClicked(event)}
              />
            )}
          </transition>
          {this.isAuthModalRendered && (
            <AuthAndRegisterModal
              vModel={this.isAuthModalOpen}
              params={this.authParams}
            />
          )}
        </div>
      );
    }
  });
