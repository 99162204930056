import { Getter } from 'vuex-simple';

import { getBlockWithParams } from '../../shared/utils/store/getBlockWithParams';

import { RootModule } from './store';

export class HeaderModule {
  private _configName = 'header';

  constructor(private root: RootModule) {}

  @Getter()
  public get headerServices(): any {
    return getBlockWithParams(
      this.root.config,
      this._configName,
      this.root.blocks,
      'services'
    );
  }
  @Getter()
  public get headerRubrics(): any {
    return getBlockWithParams(
      this.root.config,
      this._configName,
      this.root.blocks,
      'rubrics'
    );
  }
  @Getter()
  public get headerThemes(): any {
    return getBlockWithParams(
      this.root.config,
      this._configName,
      this.root.blocks,
      'themes'
    );
  }
  @Getter()
  public get headerSettings(): any {
    return getBlockWithParams(
      this.root.config,
      this._configName,
      this.root.blocks,
      'settings'
    );
  }
}
