import { RecordImageInfo, RecordImage } from '../record-image';

export interface RecordAudioInfo {
  author: string;
  description: string;
  previewImage: RecordImageInfo | null;
  url: string;
}

export class RecordAudio implements RecordAudioInfo {
  public readonly author: string;

  public readonly description: string;

  public readonly previewImage: RecordImage | null = null;

  public readonly url: string;

  constructor(info: RecordAudioInfo) {
    this.author = info.author;
    this.description = info.description;
    if (info.previewImage) {
      this.previewImage = new RecordImage(info.previewImage);
    }
    this.url = info.url;
  }
}
