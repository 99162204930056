import { BehaviorSubject } from 'rxjs';

type StickyHeaderState = {
  sticky: boolean;
  stickyHeaderHeight: number;
};

const initialState: StickyHeaderState = {
  sticky: false,
  stickyHeaderHeight: 0
};

export class HeaderService {
  // eslint-disable-next-line
  private _state: BehaviorSubject<StickyHeaderState> = new BehaviorSubject(initialState);

  // eslint-disable-next-line
  get state() {
    return this._state.asObservable();
  }

  // eslint-disable-next-line
  public changeState(state: StickyHeaderState) {
    this._state.next(state);
  }
}

export const headerService = new HeaderService();
