export type RecordFileType = 'doc' | 'docx' | 'txt' | 'pdf' | 'xls';

export interface RecordFileInfo {
  description: string;
  url: string;
  type: RecordFileType;
}

export class RecordFile implements RecordFileInfo {
  public readonly description: string;

  public readonly url: string;

  public readonly type: RecordFileType;

  constructor(info: RecordFileInfo) {
    this.description = info.description;
    this.url = info.url;
    this.type = info.type;
  }
}
