import {
  TABLET_MIN_WIDTH,
  LAPTOP_MIN_WIDTH,
  LAPTOP_DESKTOP_MIN_WIDTH,
  DESKTOP_MIN_WIDTH,
} from './constants';

export const BREAKPOINTS = {
  tablet: TABLET_MIN_WIDTH,
  laptop: LAPTOP_MIN_WIDTH,
  ['laptop-desktop']: LAPTOP_DESKTOP_MIN_WIDTH,
  desktop: DESKTOP_MIN_WIDTH,
};
