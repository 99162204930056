import { Regions } from '@smh/projects/regions';
import { AxiosRequestConfig } from 'axios';

import { BaseConfig, BaseHttpClient, catchError } from '../base';

import { BookmarkDTO, BookmarkApiResponseDTO, BaseUserResponseDTO } from './user-dtos';

type AddBookmarkParams = {
  regionId: number;
  profileId: number;
  recordId: number;
  type: string;
};

type DeleteBookmarkParams = {
  regionId: number;
  profileId: number;
  bookmarkId: string;
};

type SaveRecordInHistoryParams = {
  regionId: Regions;
  profileId: number;
  type: string;
  recordId: number;
};

export class UserHttpClient extends BaseHttpClient {
  private readonly _usersUrl = '/public/users/';

  constructor(config: BaseConfig) {
    super(config);
  }

  @catchError
  async confirmNotifyEmail(
    regionId: Regions,
    profileId: number,
    data: FormData,
  ): Promise<BaseUserResponseDTO> {
    const host = this.getHost(regionId);
    const url = `${host}${this.v}${this._usersUrl}${profileId}/profile/email/`;

    const options: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        accept: 'vnd.news.v1.jtnews+json',
      },
      params: {
        regionId,
      },
    };

    const response = await this.httpClient.post<BaseUserResponseDTO>(url, data, options);

    return response.data;
  }

  @catchError
  async saveRecordInBrowsingHistory({
    regionId,
    profileId,
    type,
    recordId,
  }: SaveRecordInHistoryParams): Promise<BaseUserResponseDTO> {
    const host = this.getHost(regionId);
    const url = `${host}${this.v}${this._usersUrl}${profileId}/browsing/`;

    const options: AxiosRequestConfig = {
      withCredentials: true,
      params: {
        regionId,
      },
    };

    const response = await this.httpClient.post<BaseUserResponseDTO>(
      url,
      { type, recordId },
      options,
    );

    return response.data;
  }

  @catchError
  public async addBookmark(params: AddBookmarkParams): Promise<BookmarkDTO> {
    const { regionId, profileId, recordId, type } = params;
    const host = this.getHost(regionId);
    const url = `${host}${this.v}${this._usersUrl}${profileId}/bookmarks/`;
    const options: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        accept: 'vnd.news.v1.jtnews+json',
      },
    };

    const response = await this.httpClient.post<BookmarkApiResponseDTO>(
      url,
      { recordId, type, regionId },
      options,
    );

    return response.data.data;
  }

  @catchError
  public async deleteBookmark(params: DeleteBookmarkParams): Promise<void> {
    const { regionId, profileId, bookmarkId } = params;
    const host = this.getHost(regionId);
    const url = `${host}${this.v}${this._usersUrl}${profileId}/bookmarks/${bookmarkId}`;
    const options: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        accept: 'vnd.news.v1.jtnews+json',
      },
      params: {
        regionId,
      },
    };

    await this.httpClient.delete<void>(url, options);
  }
}
