import { guardUnspecified } from '@portal/utils/util-guards';

import { CaptchaInterface } from './captcha.service';

export class GReCaptchaService implements CaptchaInterface {
  constructor(private _publicKey: string) {}

  public execute(action = ''): PromiseLike<string> {
    return new Promise((res, rej) => {
      if (!guardUnspecified(window.grecaptcha)) {
        return rej();
      }

      window.grecaptcha?.ready(() => {
        window.grecaptcha?.execute(this._publicKey, { action }).then(res, rej);
      });
    });
  }
}
