import NoSSR from 'vue-no-ssr';
import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@jtnews/shared';

import { tsStoreMixin } from '../../../mixins';
import { AdvCreative } from '../adv-creative';

import styles from './adv-mobile-sticky.styles.scss?module';

type AdvData = {
  data: string;
  path: string;
  settings: {
    place: string;
    placeId: number;
    sticky: boolean;
    subPlace: string;
    types: {
      desktop: boolean;
      laptop: boolean;
      mobile: boolean;
      tablet: boolean;
    };
  };
  type: string;
} | null;

type ComponentData = {
  isClosed: boolean;
  isCloseBtnVisible: boolean;
  hasAdvElement: boolean;
  isUnallowedReferrer: boolean;
};

const CLOSE_DELAY_TIME = 4000;

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'AdvMobileSticky',
    props: {
      adv: {
        type: Object as () => AdvData,
        default: null
      },
      isBottomIntend: {
        type: Boolean,
        default: false
      },
      isHidden: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        isClosed: false,
        isCloseBtnVisible: false,
        hasAdvElement: false,
        isUnallowedReferrer: true
      };
    },
    computed: {
      hasAdv(): boolean {
        return this.hasAdvElement || this.hasMobileBrandingCreative;
      },
      hasMobileBrandingCreative(): boolean {
        return Boolean(this.store.advModule.mobileBrandingCreatives?.stickyHtml);
      },
      isHiddenSticky() {
        return this.isHidden || this.isUnallowedReferrer;
      }
    },
    watch: {
      isHidden(value) {
        if (!value) {
          this.showCloseBtn();
        }
      }
    },
    mounted() {
      this.isUnallowedReferrer = this.checkUnallowedAdvReferrer();

      if (!this.isHidden) {
        this.showCloseBtn();
      }
    },
    methods: {
      showCloseBtn() {
        setTimeout(() => {
          this.isCloseBtnVisible = true;
        }, CLOSE_DELAY_TIME);
      },
      checkUnallowedAdvReferrer() {
        const unallowedAdvReferrers = [
          'iframe-toloka.com',
          'raterhub.com',
          'iframe-tasks.yandex',
          'iframe-yang.yandex',
          'yandex-team.ru'
        ];
        const referrer =
          new URLSearchParams(window.location.search).get('_testReferrer') ||
          document.referrer;

        return unallowedAdvReferrers.some(domain => referrer.includes(domain));
      },
      onDisplay() {
        this.hasAdvElement = true;
      }
    },
    render() {
      return (
        <div
          class={[
            styles.adv,
            {
              [styles.bottomIntend]: this.isBottomIntend,
              [styles.hide]: this.isHiddenSticky,
              [styles.closed]: this.isClosed,
              [styles.hideAdv]: !this.hasAdv,
              [styles.mobileBrandingSticky]: this.hasMobileBrandingCreative
            }
          ]}
        >
          <div
            class={[
              styles.advWrapper,
              {
                [styles.mobileBrandingSticky]: this.hasMobileBrandingCreative
              }
            ]}
          >
            <NoSSR>
              <AdvCreative
                adv={this.adv}
                clientRender={true}
                onDisplay={this.onDisplay}
              />
            </NoSSR>
            <div class={[styles.closeBtn, this.isCloseBtnVisible ? '' : styles.hidden]}>
              <button
                class={styles.closeBtnIcon}
                onClick={() => {
                  this.isClosed = true;
                }}
              >
                <svg width="24" height="24">
                  <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-close"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>
      );
    }
  });
