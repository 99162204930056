// eslint-disable @typescript-eslint/naming-convention
import { BehaviorSubject } from 'rxjs';

export type UserMenuState = {
  value: 'close' | 'open';
  changedBy?: string;
  name?: string;
};

export class UserMenuService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static DEFAULT_PARAMS_STATE: UserMenuState = {
    value: 'close',
    name: 'userMenu'
  };

  // eslint-disable-next-line
  private _state: BehaviorSubject<UserMenuState> = new BehaviorSubject(
    UserMenuService.DEFAULT_PARAMS_STATE
  );

  // eslint-disable-next-line
  private _name = 'userMenu';

  public get state(): BehaviorSubject<UserMenuState> {
    return this._state;
  }

  public changeState(state: UserMenuState): void {
    const name = this._name;
    this._state.next({ ...state, name });
  }
}

export const userMenuService = new UserMenuService();
