import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';
import { JtnUiBadge } from '../_common/jtn-ui-badge';

import styles from './jtn-ui-default-btn.styles.scss?module';

/**
 * @deprecated использовать JtnUiBtn
 */

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiDefaultBtn',
  props: {
    text: {
      type: String,
      default: ''
    },
    counter: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    btnType: {
      type: String,
      default: 'button-blue'
    },
    badge: {
      type: Number,
      default: undefined
    },
    isRedesign: {
      type: Boolean,
      default: false
    }
  },
  render() {
    const COMPONENT = this.url === '' ? 'button' : 'a';
    const attributes = {
      attrs: this.$attrs,
      on: this.$listeners
    };

    if (this.url !== '') {
      attributes.attrs = {
        ...this.$attrs,
        href: this.url
      };
    }

    return (
      <COMPONENT
        class={[
          styles.button,
          this.url ? styles.link : '',
          styles[this.btnType],
          this.counter ? styles['with-counter'] : '',
          this.isRedesign ? styles.redesign : ''
        ]}
        {...attributes}
      >
        {this.text ? <span domPropsInnerHTML={this.text} /> : this.$slots.default}
        {this.counter && <span domPropsInnerHTML={this.counter} />}
        {this.badge !== undefined && (
          <JtnUiBadge class={styles.badge} content={this.badge} size="button" />
        )}
      </COMPONENT>
    );
  }
});
