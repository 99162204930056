export const loadSmallImage = el => {
  const smallImage = el.querySelector('.small-img');

  if (smallImage) {
    smallImage.addEventListener('load', () => {
      setTimeout(() => smallImage.classList.add('loaded'));
    });
    smallImage.addEventListener('error', e => console.error(e));
    smallImage.src = smallImage.dataset.src;
  }
};

const createObserver = (el, handleIntersect) => {
  const options = {
    // circumstances under which the observer's callback is invoked
    root: null, // defaults to the browser viewport if not specified or if null
    rootMargin: '300px',
    threshold: 0 // the degree of intersection between the target element and its root (0 - 1)
    // threshold of 1.0 means that when 100% of the target is visible within
    // the element specified by the root option, the callback is invoked
  };

  // Whether you're using the viewport or some other element as the root,the API works the same way,
  // executing a callback function you provide whenever the visibility of the target element changes
  // so that it crosses desired amounts of intersection with the root

  const observer = new IntersectionObserver(handleIntersect, options);
  // eslint-disable-next-line
  el.lazyLoadImageObserver = observer;

  el.lazyLoadImageObserver.observe(el); // target element to watch
};

// TODO: разобраться, почему метод вызывается еще дважды для каждого изображения перед вызовом updated и computedUpdate
export const loadPicture = el => {
  if (el.classList.contains('loaded')) {
    return;
  }

  loadSmallImage(el);
  // @TODO: вынести имена классов в константы
  const pictureEl = el.querySelector('.picture-wrap');
  const sourceEl = pictureEl.querySelectorAll('source');
  const imageEl = pictureEl.querySelector('img');

  if (sourceEl && sourceEl.length > 0) {
    for (let i = 0; i < sourceEl.length - 1; i++) {
      sourceEl[i].srcset = sourceEl[i].dataset.srcset;
    }
  }

  if (imageEl) {
    imageEl.addEventListener('load', () => {
      setTimeout(() => el.classList.add('loaded'), 100);
    });
    imageEl.addEventListener('error', e => console.error(e));
    imageEl.src = imageEl.dataset.src;
    imageEl.srcset = imageEl.dataset.srcset;
  }
};

const initLoading = el => {
  loadPicture(el);
};

const handleIntersect = el => (entries, observer) => {
  entries.forEach(entry => {
    if (!entry.isIntersecting) {
      return;
    }
    initLoading(el);
    observer.disconnect();
  });
};

export const loadBackground = el => {
  const imageEl = new Image();
  imageEl.src = el.dataset.url;
  imageEl.addEventListener('load', () => {
    setTimeout(() => {
      // eslint-disable-next-line
      el.style.backgroundImage = `url(${el.dataset.url})`;
    }, 100);
  });
  imageEl.addEventListener('error', e => console.error(e));
};

export const cleanerImage = el => {
  const smallImage = el.querySelector('.small-img');
  smallImage.classList.remove('loaded');
  el.classList.remove('loaded');
};

export const LazyLoadImage = {
  inserted: (el, binding) => {
    const { disable } = binding.value;

    if (disable === true) {
      return;
    }

    if (!window.IntersectionObserver) {
      initLoading(el);
    } else {
      createObserver(el, handleIntersect(el));
    }
  },
  updated: el => {
    el.lazyLoadImageObserver.disconnect();
    cleanerImage(el);
  },
  // TODO: разобраться, почему метод loadPicture вызывается для большего количества изображений, чем нужно
  componentUpdated: (el, binding) => {
    const { disable } = binding.value;

    if (disable === true) {
      cleanerImage(el);
      createObserver(el, handleIntersect(el));
      return;
    }

    el.lazyLoadImageObserver.observe(el);

    loadPicture(el);
  },
  unbind: el => {
    if (el.lazyLoadImageObserver) {
      el.lazyLoadImageObserver.disconnect();
    }
  }
};

export default LazyLoadImage;
