import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-informer-mini.styles.scss?module';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiInformerMini',
  props: {
    type: {
      type: String as () => 'block' | 'link',
      default: 'block'
    },
    hasIcon: {
      type: Boolean,
      default: false
    }
  },
  render() {
    const COMPONENT = this.type === 'link' ? 'a' : 'div';
    const attributes = {
      attrs: this.$attrs,
      on: this.$listeners
    };

    return (
      <COMPONENT class={[styles.headerInformer, styles[this.type]]} {...attributes}>
        {this.hasIcon ? <div class={styles.icon}>{this.$slots.icon}</div> : null}
        <div class={styles.text}>{this.$slots.text}</div>
      </COMPONENT>
    );
  }
});
