import {
  guardEmptyArray,
  guardEmptyString,
  guardUnspecified
} from '@portal/utils/util-guards';

import { Image } from '@jtnews/shared/news';
import { BaseApiResponsePageResult } from '@jtnews/shared/newsapi/base';

import { generateImageUrl, getImageSources } from '../../shared/utils';
import { NewsDataDTO, NewsSlideDTO, NewsSliderData, NewsSliderImage } from '../domain';

const NEWS_SLIDER_FIELD = 'news_slider';

const IMAGE_SIZE = 200;

export class NewsSliderMapper {
  public static newsSliderToDO(
    pageDto: BaseApiResponsePageResult
  ): NewsSliderData | null {
    const config = pageDto.config.common[NEWS_SLIDER_FIELD];

    if (!guardUnspecified(config)) {
      return null;
    }

    const { path } = config;
    const blockData = pageDto.data[path].data as NewsDataDTO;

    let data = blockData.map(item => ({
      id: item.id,
      title: item.title,
      path: NewsSliderMapper._getUrl(item.slides),
      image: NewsSliderMapper._getImage(item.image)
    }));

    data = data.filter(
      item => guardUnspecified(item.image) && guardEmptyString(item.path)
    );

    if (!guardEmptyArray(data)) {
      return null;
    }

    return {
      data
    };
  }

  private static _getUrl(slides: NewsSlideDTO[]): string {
    const news = slides.find(slide => guardUnspecified(slide.reference));

    if (guardUnspecified(news) && guardUnspecified(news.reference)) {
      return news.reference.url;
    }

    return '';
  }

  private static _getImage(image: Image | null): NewsSliderImage | null {
    if (!guardUnspecified(image)) {
      return null;
    }

    const src = generateImageUrl(image, true, IMAGE_SIZE, IMAGE_SIZE) as string;

    const sources = getImageSources(src);

    return {
      src,
      sources
    };
  }
}
