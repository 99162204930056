import Vue from 'vue';
import Router from 'vue-router';

import { CORE_ROUTING } from '../core';
import { ACTION_RESULT_ROUTING } from '../features/action-result';
import { ARCHIVE_ROUTING, ARCHIVE_LONGREAD_ROUTING } from '../features/archive';
import { AUTHORS_ROUTING } from '../features/authors';
import { COMMENTS_ROUTING } from '../features/comments';
import { HOROSCOPE_ROUTING } from '../features/horoscope';
import { MAIN_ROUTING } from '../features/main';
import { PHOTO_AND_VIDEOS_ROUTING } from '../features/photos-and-videos';
import { PROFILE_ROUTING } from '../features/profile';
import { RECORD_ROUTING } from '../features/record';
import { RUBRIC_ROUTING } from '../features/rubric';
import { SEARCH_ROUTING } from '../features/search';
import { SUBSCRIPTION_ROUTING } from '../features/subscription';

Vue.use(Router);

const routes = [
  ...MAIN_ROUTING,
  ...COMMENTS_ROUTING,
  ...PHOTO_AND_VIDEOS_ROUTING,
  ...SUBSCRIPTION_ROUTING,
  ...ACTION_RESULT_ROUTING,
  ...PROFILE_ROUTING,
  ...SEARCH_ROUTING,
  ...ARCHIVE_LONGREAD_ROUTING,
  ...RUBRIC_ROUTING,
  ...ARCHIVE_ROUTING,
  ...RECORD_ROUTING,
  ...AUTHORS_ROUTING,
  ...HOROSCOPE_ROUTING,
  ...CORE_ROUTING
];

export const createRouter = (): Router =>
  new Router({
    mode: 'history',
    routes
  });
