import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';
import { JtnUiBtn } from '../jtn-ui-btn';

import styles from './jtn-ui-informer-dropdown.styles.scss?module';

type BtnData = {
  href: string;
  title: string;
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiInformerDropdown',
  props: {
    title: {
      type: String,
      default: ''
    },
    btn: {
      type: Object as () => BtnData,
      default() {
        return {
          href: '',
          title: ''
        };
      }
    },
    iconType: {
      type: String,
      default: ''
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  render() {
    const COMPONENT = this.clickable ? 'a' : 'div';
    const listeners = {
      on: this.$listeners
    };
    let attributes = {
      attrs: this.$attrs
    };

    if (this.clickable) {
      attributes = {
        attrs: {
          ...this.$attrs,
          href: this.btn.href,
          target: '_blank'
        },
        ...listeners
      };
    }

    return (
      <COMPONENT class={styles.informerDropdown} {...attributes}>
        <div class={styles.titleWrap}>
          <h3 class={styles.title}>{this.title}</h3>
        </div>
        <div class={styles.contentWrap}>{this.$slots.content}</div>

        {this.clickable && <JtnUiBtn>{this.btn.title}</JtnUiBtn>}
      </COMPONENT>
    );
  }
});
