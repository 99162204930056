import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiBtnIcon } from '@jtnews/jtn-ui';
import { injectStylesMixin, tsStoreMixin } from '@jtnews/shared';

import styles from './search-bar.styles.scss?module';
import SearchInput from './search-input.component';

type ToggleEvent = {
  isShowed: boolean;
  event: Event;
};

interface Events {
  onToggleSearch: ToggleEvent;
  onSubmitSearch: string;
}

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'SearchBar',
    data() {
      return {
        showedSearchInput: false
      };
    },
    methods: {
      closeSearchBar() {
        if (this.showedSearchInput) {
          this.showedSearchInput = false;
        }
      },
      toggleSearchInput(event: Event) {
        this.showedSearchInput = !this.showedSearchInput;
        const eventData = {
          isShowed: this.showedSearchInput,
          event
        };

        this.$emit('toggleSearch', eventData);
      }
    },
    render() {
      return (
        <div class={styles['search-bar']}>
          <div class={styles['search-bar-btn']}>
            <JtnUiBtnIcon
              size={44}
              btn-type="button-other-transparent"
              data-test="search-bar-btn"
              nativeOnClick={(event: Event) => this.toggleSearchInput(event)}
            >
              <svg width={22} height={22}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-search"></use>
              </svg>
            </JtnUiBtnIcon>
          </div>

          {this.showedSearchInput && (
            <SearchInput
              onSubmitSearch={(query: string) => {
                this.$emit('submitSearch', query);
              }}
              onResetSearch={(event: Event) => this.toggleSearchInput(event)}
            />
          )}
        </div>
      );
    }
  });
