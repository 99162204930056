import { guardEmptyArray, guardUnspecified } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { LazyHydrate, injectStylesMixin, tsStoreMixin } from '@jtnews/shared';

import { tagId } from '../../../utils/html.utils';

import styles from './adv.styles.scss?module';

interface IEvents {
  onViewAdv: string;
  onClickAdv: string;
}

type AdvData = {
  data: string;
  path: string;
  settings: {
    place: string;
    placeId: number;
    sticky: boolean;
    subPlace: string;
    types: {
      desktop: boolean;
      laptop: boolean;
      mobile: boolean;
      tablet: boolean;
    };
  };
  type: string;
} | null;

const ADV_DATA_ATTR = '1';
const RELAP_REG = /\sdata-adv-relap=("|')([^\1]*?)\1/gi;
const SMI2_REG = /\sdata-adv-smi-two=("|')([^\1]*?)\1/gi;
const SPARROW_REG = /\sdata-adv-sparrow=("|')([^\1]*?)\1/gi;

export default componentFactoryOf<IEvents>()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'Adv',
    props: {
      adv: {
        type: Object as () => AdvData,
        required: true as const
      },
      index: {
        type: Number,
        default: undefined
      }
    },
    computed: {
      isMobile(): boolean {
        return this.store.deviceInfo.isMobile;
      },
      isAllowedDesktop(): boolean {
        return this.adv?.settings?.types?.desktop || false;
      },
      isAllowedLaptop(): boolean {
        return this.adv?.settings?.types?.laptop || false;
      },
      isAllowedTablet(): boolean {
        return this.adv?.settings?.types?.tablet || false;
      },
      isAllowedMobile(): boolean {
        return this.adv?.settings?.types?.mobile || false;
      },
      placeId(): number {
        return this.adv?.settings?.placeId || 0;
      },
      advType(): string {
        return this.adv?.type || '';
      },
      isFullscreenAdv(): boolean {
        return this.advType === 'fullscreen';
      },
      isHeaderAdv(): boolean {
        return this.advType === 'header_advert';
      },
      isBrandingPage(): boolean {
        const { aTemplateBlock } = this.store.advModule;

        return (
          typeof aTemplateBlock?.data?.adfox_branding === 'string' &&
          !this.isAdblock &&
          !this.isMobile
        );
      },
      isRightColumnPlace(): boolean {
        return this.adv?.settings?.place === 'right';
      },
      isLeftColumnPlace(): boolean {
        return this.adv?.settings?.place === 'left';
      },
      isCentralColumnPlace(): boolean {
        return this.adv?.settings?.place === 'central';
      },
      isWindowPlace(): boolean {
        return this.adv?.settings?.subPlace === 'window';
      },
      isSideSticky(): boolean {
        if (this.adv?.settings?.sticky === undefined) {
          return false;
        }
        return (
          this.adv?.settings?.sticky &&
          (this.isRightColumnPlace || this.isLeftColumnPlace)
        );
      },
      isRender(): boolean {
        if (this.isBrandingPage && !this.isAllowedLaptop) {
          return false;
        }
        if (this.isBrandingPage && this.isHeaderAdv) {
          return false;
        }
        return true;
      },
      isTopRight(): boolean {
        return this.advType === 'top_right_advert';
      },
      isAdblock(): boolean {
        return this.store.isAdblock;
      },
      isWindowBlock(): boolean {
        return this.advType === 'window_advert';
      },
      advData(): string {
        return this.adv?.data || '';
      },
      advHtml(): string {
        let advHtml = '';

        if (this.index) {
          const oldContainerId = tagId(this.advData);

          if (oldContainerId) {
            const regex = new RegExp(oldContainerId, 'g');
            const newContainerId = oldContainerId + '_' + String(this.index);
            advHtml = this.advData.replace(regex, newContainerId) || '';
            return advHtml;
          }
        }

        return this.advData;
      }
    },
    mounted() {
      if (this.$root._isMounted) {
        this.injectAdvScript();
      }
      if (this.needToEmitAdv(RELAP_REG)) {
        this.addEmitInAdvBlock('relap');
      }
      if (this.needToEmitAdv(SMI2_REG)) {
        this.addEmitInAdvBlock('smi2');
      }
      if (this.needToEmitAdv(SPARROW_REG)) {
        this.addEmitInAdvBlock('sparrow');
      }
    },
    methods: {
      isRelevantAdv(dataRegEx: RegExp, dataAtr: string): boolean {
        const res = this.advData === '' ? null : dataRegEx.exec(this.advData);
        if (res === null) {
          return false;
        }
        return res[2] === dataAtr;
      },
      needToEmitAdv(dataRegEx: RegExp): boolean {
        return this.isRelevantAdv(dataRegEx, ADV_DATA_ATTR);
      },
      addEmitInAdvBlock(type: string): void {
        setTimeout(() => {
          const wrapper: HTMLElement | null = document.querySelector(
            `div[data-cms-place="${this.placeId}"]`
          );
          if (!guardUnspecified(wrapper)) {
            return;
          }
          this.advEmitClick(wrapper, type);
          this.visibilityObs(wrapper, type);
        }, 500);
      },
      advEmitClick(element: HTMLElement, type: string): void {
        element.addEventListener('click', () => {
          this.$emit('clickAdv', type);
        });
      },
      visibilityObs(element: HTMLElement, type: string): void {
        const observer = new IntersectionObserver(
          (entries, observer) => {
            if (guardEmptyArray(entries)) {
              entries.forEach(entry => {
                if (entry.isIntersecting) {
                  this.$emit('viewAdv', type);
                  observer.disconnect();
                }
              });
            }
          },
          {
            threshold: 0.5
          }
        );
        observer.observe(element);
      },
      injectAdvScript(): void {
        const parsedAdv = this.store.advModule.advService.processAdvContent(this.advHtml);
        void this.store.commonModule.externalScriptsService.injectScriptToHead(
          parsedAdv.script
        );
      }
    },
    render() {
      if (this.isRender) {
        return (
          <LazyHydrate ssrOnly>
            <div
              class={{
                [styles.adv]: true,
                [styles.desktop]: this.isAllowedDesktop,
                [styles.laptop]: this.isAllowedLaptop,
                [styles.tablet]: this.isAllowedTablet,
                [styles.mobile]: this.isAllowedMobile,
                [styles.fullscreen]: this.isFullscreenAdv,
                [styles.top]: this.isHeaderAdv,
                [styles.topRight]: this.isTopRight,
                [styles.windowBlock]: this.isWindowBlock,
                [styles.windowPlace]: this.isWindowPlace,
                [styles.leftColumnPlace]: this.isLeftColumnPlace,
                [styles.rightColumnPlace]: this.isRightColumnPlace,
                [styles.centralColumnPlace]: this.isCentralColumnPlace,
                [styles.sideSticky]: this.isSideSticky,
                [styles.isBranding]: this.isBrandingPage,
                [styles.isAdblock]: this.isAdblock
              }}
              data-cms-place={this.placeId}
              domPropsInnerHTML={this.advHtml}
            />
          </LazyHydrate>
        );
      }

      return (
        <div class={styles.notRender} data-cms-place={this.adv?.settings?.placeId} />
      );
    }
  });
