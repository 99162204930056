/* eslint-disable */
import { BehaviorSubject } from 'rxjs';


export type NavigateMethod = 'scroll' | 'click';

export type CurrentPoint = {
  navigatedBy: NavigateMethod;
  id: number;
};

const DEFAULT_PARAMS: CurrentPoint = {
  navigatedBy: 'scroll',
  id: 0
};

export class RecordNavigation {
  _currentPointId: BehaviorSubject<CurrentPoint> = new BehaviorSubject(DEFAULT_PARAMS);
  _recordHeight: BehaviorSubject<number> = new BehaviorSubject(0);

  get currentPointId() {
    return this._currentPointId;
  }

  get recordHeight() {
    return this._recordHeight;
  }

  setCurrentPointId(params: CurrentPoint) {
    this.currentPointId.next(params);
  }

  setRecordHeight(height: number) {
    this.recordHeight.next(height);
  }
}

export const recordNavigation = new RecordNavigation();
