import { componentFactory } from 'vue-tsx-support';
import { getLinkTarget } from '@smh/utils/url';

import type { AdvLogoInThemes } from '@jtnews/shared/data';
import styles from './widget-adv-logo-in-themes.styles.scss?module';

export default componentFactory
  .create({
    props: {
      advData: {
        type: Object as () => AdvLogoInThemes,
        required: true as const
      }
    },
    computed: {
      target(): '_blank' | '_self' {
        return getLinkTarget(this.advData.link);
      },
    },
    render() {
      return (
        <li
          class={[
            styles.advWidget,
            this.advData.advMarker ? styles.advMarker : ''
          ]}
        >
          <a href={this.advData.link} class={styles.advLink} target={this.target}>
            <img
              src={this.advData.imageSrc}
              height="27"
              alt={this.advData.altText}
            />
          </a>
        </li>
      );
    }
  });
