export const WEBP_POSTFIX = '.webp-portal';

export type ImageSource = {
  srcset: string;
  type?: string;
  media?: string;
};

export const getImageSources = (src: string, media = ''): ImageSource[] => {
  const defaultSource: ImageSource = {
    srcset: src
  };

  if (media !== '') {
    defaultSource.media = media;
  }

  return [
    {
      ...defaultSource,
      srcset: getWebPSrc(src),
      type: 'image/webp'
    },
    { ...defaultSource }
  ];
};

export const getWebPSrc = (src: string): string => `${src}${WEBP_POSTFIX}`;
