import { getFirstConsistentlyInteractive } from 'tti-polyfill';

type ObserverEntry = PerformanceEntry & {
  processingStart?: number;
  renderTime?: number;
  loadTime?: number;
};

type MetricsData = Record<string, string>;

enum MetricsEntryType {
  FCP = 'paint',
  LCP = 'largest-contentful-paint',
  FID = 'first-input',
  TTI = 'longtask'
}

const FCP_METRIC_NAME = 'first-contentful-paint';

export class PerformanceMetricsService {
  private _metricsData: MetricsData = {};
  private _wp = window.performance;

  private get _isPerformanceSupported(): boolean {
    return this._wp !== undefined && this._wp.getEntriesByType.length > 0;
  }

  public get performanceMetrics(): MetricsData {
    return this._metricsData;
  }

  public collectPerformanceMetrics(): void {
    if (this._isPerformanceSupported) {
      const performanceObserver = new PerformanceObserver(entryList => {
        for (const entry of entryList.getEntries()) {
          const observerEntry = entry as ObserverEntry;

          if (observerEntry.name === FCP_METRIC_NAME) {
            this._addMetrics('FCP', observerEntry.startTime);
          }

          if (observerEntry.entryType === MetricsEntryType.LCP) {
            this._addMetrics(
              'LCP',
              observerEntry.renderTime || observerEntry.loadTime || 0
            );
          }

          if (observerEntry.entryType === MetricsEntryType.FID) {
            const { startTime, processingStart = 0 } = observerEntry;
            const time = processingStart - startTime;
            this._addMetrics('FID', time);
          }
        }
      });

      void getFirstConsistentlyInteractive().then(tti => {
        this._addMetrics('TTI', tti || 0);
      });

      performanceObserver.observe({ type: MetricsEntryType.FCP, buffered: true });
      performanceObserver.observe({ type: MetricsEntryType.LCP, buffered: true });
      performanceObserver.observe({ type: MetricsEntryType.FID, buffered: true });
      performanceObserver.observe({ type: MetricsEntryType.TTI, buffered: true });
    }
  }

  private _addMetrics(name: string, value: number) {
    this._metricsData[name] = Math.round(value).toString();
  }
}
