import { RecordAudio, RecordAudioInfo } from '../record-audio';
import { RecordCustomWidgetInfo, RecordCustomWidget } from '../record-custom-widget';
import { RecordFile, RecordFileInfo } from '../record-file';
import { RecordGallery, RecordGalleryInfo } from '../record-gallery';
import { RecordIframe } from '../record-iframe';
import { RecordImage, RecordImageInfo } from '../record-image';
import { RecordLogotypeInfo, RecordLogotype } from '../record-logotype';
import { RecordTextWidget, RecordTextWidgetInfo } from '../record-text-widget';
import { RecordVideo, RecordVideoInfo } from '../record-video';

export type WidgetType =
  | 'adv'
  | 'images'
  | 'text'
  | 'test'
  | 'text-result'
  | 'poll'
  | 'doublePhoto'
  | 'gallery'
  | 'append'
  | 'wquote'
  | 'logotype'
  | 'adv'
  | 'customWidget'
  | 'iframe'
  | 'logotype'
  | 'yesnotest'
  | 'videos'
  | 'authorOpinion'
  | 'documents'
  | 'audios';

export type WidgetMedia = {
  class: 'center' | 'longread-style' | null;
  position?: string;
  images?: string;
  content?: string;
  src?: string;
  link?: string;
} | null;

export interface RecordWidgetInfo {
  type: WidgetType;
  media: WidgetMedia;
  value: WidgetValueType | null;
}

export type WidgetValueType =
  | RecordImageInfo
  | RecordTextWidgetInfo
  | RecordGalleryInfo
  | RecordImageInfo[]
  | RecordCustomWidgetInfo
  | RecordLogotypeInfo
  | RecordVideoInfo
  | RecordAudioInfo
  | RecordFileInfo;

export class RecordWidget implements RecordWidgetInfo {
  public readonly type: WidgetType;

  public readonly media: WidgetMedia;

  public readonly value:
    | RecordImage
    | RecordTextWidget
    | RecordGallery
    | RecordCustomWidget
    | RecordIframe
    | RecordLogotype
    | RecordVideo
    | RecordAudio
    | RecordFile
    | null = null;

  constructor(widgetInfo: RecordWidgetInfo) {
    this.type = widgetInfo.type;
    this.media = widgetInfo.media;

    const { value } = widgetInfo;

    if (this.type === 'images') {
      this.value = new RecordImage(<RecordImageInfo>value);
    }

    if (this.type === 'text' || this.type === 'authorOpinion') {
      this.value = new RecordTextWidget(<RecordTextWidgetInfo>value);
    }

    if (this.type === 'gallery') {
      this.value = new RecordGallery({ images: <RecordImageInfo[]>value });
    }

    if (this.type === 'customWidget') {
      const info = <RecordCustomWidgetInfo>value;
      const content = this.media && this.media.content ? this.media.content : '';
      this.value = new RecordCustomWidget({
        src: info ? info.src : '',
        script: info ? info.script : '',
        content
      });
    }

    if (this.type === 'iframe') {
      const src = this.media && this.media.src ? this.media.src : '';
      this.value = new RecordIframe({ src });
    }

    if (this.type === 'logotype') {
      const link = this.media && this.media.link ? this.media.link : '';
      this.value = new RecordLogotype({
        image: <RecordImageInfo>value,
        link
      });
    }

    if (this.type === 'videos') {
      this.value = new RecordVideo(<RecordVideoInfo>value);
    }

    if (this.type === 'audios') {
      this.value = new RecordAudio(<RecordAudioInfo>value);
    }

    if (this.type === 'append') {
      const text = this.media && this.media.content ? this.media.content : '';
      this.value = new RecordTextWidget({ text });
    }

    if (this.type === 'documents') {
      this.value = new RecordFile(<RecordFileInfo>value);
    }
  }
}
