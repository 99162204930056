export enum ImageLoading {
  Lazy = 'lazy',
  Eager = 'eager',
}

export enum ImageDecoding {
  Auto = 'auto',
  Sync = 'sync',
  Async = 'async',
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
export enum ImageAspectRatio {
  None = 0,
  Square = 1,
  Classic = 3 / 2,
  Wide = 16 / 9,
  VerticalClassic = 2 / 3,
  VerticalWide = 9 / 16,
}
/* eslint-enable @typescript-eslint/no-magic-numbers */

export type ImageSourceVM = {
  srcset: string;
  sizes?: string;
  type?: string;
  media?: string;
};

export type ImageVM = {
  src: string;
  sources: ImageSourceVM[];
  hasCommercialLabel: boolean;
  loading?: ImageLoading;
  decoding?: ImageDecoding;
  aspectRatio?: ImageAspectRatio;
};

export type ResponsiveImageDataVM = {
  data: ImageVM & {
    /**
     * @deprecated
     */
    img: ImageVM;
  };
  preload: string;
};
