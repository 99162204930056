import { guardEmptyString } from '@portal/utils/util-guards';
import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-super-news.styles.scss?module';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiSuperNews',
  props: {
    type: {
      type: String as () => 'urgent' | 'topic',
      default: 'urgent'
    },
    text: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  computed: {
    tag(): 'a' | 'div' {
      return this.link !== '' ? 'a' : 'div';
    },
    label(): string {
      return this.type === 'topic' ? 'Тема дня' : 'Срочно';
    }
  },
  render() {
    const COMPONENT = this.tag;
    const attributes = {
      attrs: this.$attrs,
      on: this.$listeners
    };

    if (guardEmptyString(this.link)) {
      attributes.attrs = {
        ...this.$attrs,
        href: this.link
      };
    }

    return (
      <COMPONENT class={[styles.superNews, styles[this.type]]} {...attributes}>
        <div class={styles.content}>
          <span class={styles.label}>{this.label}</span>
          <span class={styles.text}>{this.text}</span>
          <div class={styles.icon}>
            {guardEmptyString(this.link) && (
              <svg
                style={{ color: this.type === 'topic' ? '#e54545' : '#fff' }}
                width={23}
                height={12}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-direction"></use>
              </svg>
            )}
          </div>
        </div>
      </COMPONENT>
    );
  }
});
