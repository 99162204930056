<template>
  <div ref="notifications" :class="$style['notifications']">
    <div v-show="!isSmallMobile" :class="$style['notifications-btn-group']">
      <jtn-ui-btn-icon
        v-if="!alertsOpened"
        :class="$style['notifications-btn']"
        :size="44"
        btn-type="button-other-transparent"
        title="Уведомления"
        data-test="notifications-btn-open"
        @click="changeAlertsState()"
      >
        <svg :width="24" :height="24">
          <use xlink:href="jtn-critical-alert"></use>
        </svg>
      </jtn-ui-btn-icon>

      <jtn-ui-btn-icon
        v-if="alertsOpened"
        :size="44"
        btn-type="button-other-transparent"
        data-test="notifications-btn-close"
        @click="changeAlertsState()"
      >
        <svg :width="20" :height="20">
          <use xlink:href="jtn-critical-close"></use>
        </svg>
      </jtn-ui-btn-icon>

      <jtn-ui-badge
        v-show="isShowBadge && !alertsOpened"
        :content="newUserNoticesCount"
        :class="[
          $style['notifications-label'],
          newUserNoticesCount > 0 ? $style['with-count'] : ''
        ]"
        :size="newUserNoticesCount > 0 ? 'big' : 'small'"
        data-test="notifications-bt-badge"
      />
    </div>

    <transition name="fade" mode="out-in" :duration="{ enter: 500, leave: 100 }">
      <notifications-dropdown
        v-if="alertsOpened"
        :is-mobile="isMobile"
        :has-push-subscribe-btn="hasSubscribeToPushBtn"
        :alerts="alerts"
        :alert-state="alertState"
        :allow-closing-alerts="allowClosingAlerts"
        :is-small-mobile="isSmallMobile"
        @sendAnalytics="sendDropdownAnalytics"
        @updateAlertsState="updateAlertsState"
        @updateClosingAllowed="updateClosingAllowed"
        @clickedPrimaryBtn="clickedAnswersForNotification"
        @clickedSecondaryBtn="clickedDetailNotification"
        @clickedMoreBtn="clickedMoreNotifications"
        @clickedPushNotificationsBtn="clickedPushNotificationsBtn"
      >
        <a
          v-if="userInfo !== null"
          slot="userNotifications"
          :class="$style['user-notifications']"
          :href="notificationsLink"
          data-test="user-notifications"
          @click="clickUserNotificationsBlock"
        >
          <span>{{ notificationsText }}</span>
          <jtn-ui-badge
            v-if="newUserNoticesCount > 0"
            :content="newUserNoticesCount"
            :class="$style['user-notifications-badge']"
            size="big"
            data-test="user-notifications-badge"
          />
        </a>
      </notifications-dropdown>
    </transition>

    <feedback-to-editors-modal
      v-if="isFeedbackToEditorsModalRender"
      v-model="isFeedbackToEditorsModalOpen"
      :params="feedbackToEditorsModalParams"
    />
  </div>
</template>

<script>
import { JtnUiBtnIcon, JtnUiBadge } from '@jtnews/jtn-ui';

import { StoreMixin } from '../../../shared/mixins';
import NotificationsDropdown from './notifications-dropdown';
import { clickOutside } from '@portal/utils/util-click-outside';

const FeedbackToEditorsModal = () =>
  import('@jtnews/layout/modals').then(m => m.FeedbackToEditorsModal);

export default {
  name: 'Notifications',
  components: {
    JtnUiBtnIcon,
    JtnUiBadge,
    NotificationsDropdown,
    FeedbackToEditorsModal
  },
  mixins: [StoreMixin],
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isSmallMobile: {
      type: Boolean,
      default: false
    },
    isShowBadge: {
      type: Boolean,
      default: false
    },
    newUserNoticesCount: {
      type: Number,
      default: 0
    },
    alerts: {
      type: Array,
      default: []
    },
    alertsOpened: {
      type: Boolean,
      default: false
    },
    alertState: {
      type: Object,
      default: null
    },
    allowClosingAlerts: {
      type: Boolean,
      default: false
    },
    hasNewAlerts: {
      type: Boolean,
      default: false
    },
    hasSubscribeToPushBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFeedbackToEditorsModalRender: false,
      isFeedbackToEditorsModalOpen: false,
      feedbackToEditorsModalParams: { piecePage: 'Меню' },
    }
  },
  computed: {
    userInfo() {
      return this.store.commonModule?.userInfo || null;
    },
    notificationsText() {
      if (this.userInfo === null) {
        return '';
      }

      return this.newUserNoticesCount > 0
        ? `Новых уведомлений в профиле`
        : 'В профиле нет новых уведомлений';
    },
    notificationsLink() {
      return `${this.userInfo.link}?tab=notices`;
    }
  },
  watch: {
    alertsOpened(isOpened) {
      if (isOpened) {
        this.setClickOutside();
      }
    }
  },
  methods: {
    setClickOutside() {
      clickOutside(this.$refs.notifications, () => {
        const value = this.alertsOpened ? 'close' : 'open';

        if (this.alertsOpened) {
          this.updateAlertsState({
            value,
            changedBy: 'header-toolbar'
          });

          if (this.hasNewAlerts) {
            this.store.commonModule.updateAlertsReadingStatus(true);
          }
        }
      }, { isSkipFirstClick: false })
    },
    updateAlertsState(value) {
      this.store.commonModule.updateAlertsState(value);
    },
    updateClosingAllowed(value) {
      this.store.commonModule.updateAlertsClosingAllowedState(value);
    },
    changeAlertsState() {
      this.$emit('sendNewReachGoal', 'Колокольчик');

      const value = this.alertsOpened ? 'close' : 'open';

      this.updateAlertsState({
        value,
        changedBy: 'header-toolbar'
      });

      if (this.hasNewAlerts) {
        this.store.commonModule.updateAlertsReadingStatus(true);
      }
    },
    sendDropdownAnalytics(params) {
      this.store.analyticsModule.sendNewReachGoal(params);
    },
    clickUserNotificationsBlock() {
      this.$emit('sendNewReachGoal', { Колокольчик: 'Уведомления в профиле' });
    },
    clickedAnswersForNotification(title) {
      this.$emit('sendNewReachGoal', { Колокольчик: 'Ответить (кнопка)' });
      this.openFeedbackEditorModal(title);
    },
    clickedDetailNotification() {
      this.$emit('sendNewReachGoal', { Колокольчик: 'Ссылка (кнопка)' });
    },
    clickedMoreNotifications() {
      this.$emit('sendNewReachGoal', { Колокольчик: 'Ещё (кнопка)' });
    },
    clickedPushNotificationsBtn() {
      this.$emit('clickedPushNotificationsBtn');
    },
    openFeedbackEditorModal(title) {
      this.isFeedbackToEditorsModalRender = true;
      this.isFeedbackToEditorsModalOpen = true;
      this.feedbackToEditorsModalParams.subjectPostfix = title;
    }
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.notifications {
  .notifications-label {
    position: absolute;
    top: 8px;
    right: 7px;

    &.with-count {
      top: 3px;
      right: -1px;
    }
  }

  @include on-min-resize($tablet) {
    .notifications-label {
      top: 10px;
      right: 7px;
    }
  }
}

.notifications-btn-group {
  position: relative;

  @media (max-width: 374px) {
    display: none;
    max-width: 0;
  }
}

.user-notifications {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  margin: 0 10px 12px;
  width: calc(100% - 20px);
  padding: 13px 20px;

  @include typography-deprecated();
  @include link('link-white-type-2');

  text-align: center;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 12px;
}

.user-notifications-badge {
  margin: 0 0 0 10px;
}
</style>
