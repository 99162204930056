// eslint-disable-next-line import/no-unassigned-import
import Vue from 'vue';
import Router from 'vue-router';
import { Store } from 'vuex';
import { sync } from 'vuex-router-sync';

import { observeVisibilityPlugin } from '@jtnews/shared/observe-visibility';

import { initSentryClient, captureHydrateError } from '../sentry';

import App from './app-deprecated.vue';
import { createStore, RootModule } from './core';
import { createRouter } from './router';
import { LazyLoadImage } from './shared/directives/lazy-load-image.directive';

void import(/* webpackChunkName: "core-services" */ './core/services');

const isClient = process.env.VUE_ENV !== 'server';

initSentryClient();

if (typeof window !== 'undefined') {
  window.onerror = function (message, source, lineno, colno, error) {
    // eslint-disable-next-line no-console
    console.log('window.onerror: ', error);
  };
}

const errorHandler = (errorOrMessage: Error | string): void => {
  const error =
    typeof errorOrMessage === 'string' ? new Error(errorOrMessage) : errorOrMessage;

  // eslint-disable-next-line no-console
  console.error('Vue.errorHandler: ', error);

  captureHydrateError(error);
};

Vue.config.errorHandler = errorHandler;

Vue.config.warnHandler = errorHandler;

// plugins
if (isClient) {
  /* eslint-disable */
  // TODO: сделать свои альтернативы и использовать
  // только в нужных компонентах
  Vue.use(require('vue-js-modal').default);
  Vue.use(require('v-click-outside'));
  /* eslint-enable */
}

// TODO: использовать только в нужных компонентах
Vue.directive('observe-visibility', observeVisibilityPlugin.directive);
Vue.directive('lazyload-image', LazyLoadImage);

export type Application = {
  app: Vue;
  store: Store<RootModule>;
  router: Router;
};

export function createApp(): Application {
  const router = createRouter();

  router.beforeEach((to, from, next) => {
    const cacheUrlsRegex = new RegExp(
      /(webcache\.googleusercontent\.com|yandexwebcache\.net)/,
      'gi'
    );
    if (
      typeof window !== 'undefined' &&
      window.location.href.match(cacheUrlsRegex) !== null
    ) {
      return false;
    }
    next();
  });

  const store = createStore();

  sync(store, router);

  const app = new Vue({
    router,
    store,
    render: h => h(App)
  });

  return { app, router, store };
}
