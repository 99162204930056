import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';

import type {
  BaseApiResponsePageResult,
  ConfigurationSettings
} from '@jtnews/shared/newsapi/base';
import { BlockConfiguration } from '@jtnews/shared/newsapi/base';
import { BaseRecordDTO, VideoItemDTO } from '@jtnews/shared/newsapi/dtos';

type ThemeItem = {
  hash: string;
  image: null | string;
  path: string;
  status: string;
  title: string;
  updatedAt: string;
  type: 'theme';
  advCompanyName: string;
  advToken: string;
};

type PictureOfDaySettingsType = 'default' | 'with_counters' | 'no_counters';

type PictureOfDayRecord = {
  id: number;
  title: string;
  link: string;
  viewsCount: number;
  commentsCount: number;
  commentsLink: string;
  isCommentsAllowed: boolean;
  type: 'news';
};

type PictureOfDayVideo = {
  id: number;
  title: string;
  link: string;
  previewUrl: string;
  type: 'videos';
};

export type ThemesBlock = {
  data: ThemeItem[];
  settings: ConfigurationSettings;
};

export type CaptchaSettingsDTO = {
  key: string;
  scriptSrc: string;
  privacyPolicyText: string;
  type: 'recaptcha' | 'hcaptcha';
};

export type CaptchaSettingsDO = {
  publicKey: string;
  scriptSrc: string;
  privacyPolicyText: string;
  type: 'recaptcha' | 'hcaptcha';
};

export type OneSignalConfigDTO = {
  appId: string;
  segment: string;
} | null;

export type OneSignalConfigDO = {
  appId: string;
  segment: {
    key: string;
    value?: string;
  };
  swParams?: {
    param: {
      scope: string;
    };
    path: string;
  };
  promptSettings: {
    actionMessage: string;
    acceptButton: string;
    cancelButton: string;
  };
  welcomeNotification: {
    title: string;
    message: string;
  };
};

export type PictureOfDayBlock = null | {
  data: {
    news: PictureOfDayRecord[];
    videos: PictureOfDayVideo[];
  };
  settings: {
    type: PictureOfDaySettingsType;
  };
};

export class CommonMapper {
  public static oneSignalConfigToDO(
    pageDTO: BaseApiResponsePageResult,
    hasSW: boolean
  ): OneSignalConfigDO | null {
    const path = pageDTO.config.common.onesignal_config?.path ?? '';
    const config = guardEmptyString(path)
      ? (pageDTO.data[path].data as OneSignalConfigDTO)
      : null;

    return guardUnspecified(config) && guardEmptyString(config.appId)
      ? {
          appId: config.appId,
          segment: {
            key: config.segment
          },
          promptSettings: {
            actionMessage: 'Включите уведомления, чтобы не пропустить важные новости',
            acceptButton: 'Включить',
            cancelButton: 'Не сейчас'
          },
          welcomeNotification: {
            title: 'Спасибо за подписку на новости!',
            message: 'Теперь вы не пропустите главное'
          },
          swParams: hasSW
            ? {
                param: {
                  scope: '/push/onesignal/'
                },
                path: 'push/onesignal/OneSignalSDKWorker.js'
              }
            : undefined
        }
      : null;
  }

  public static captchaSettingsToDO(
    pageDTO: BaseApiResponsePageResult
  ): CaptchaSettingsDO | null {
    const path = pageDTO.config.common.captcha?.path;
    const data = (pageDTO.data[path]?.data as CaptchaSettingsDTO) ?? null;

    return guardUnspecified(data)
      ? {
          publicKey: data.key,
          scriptSrc: data.scriptSrc,
          privacyPolicyText: data.privacyPolicyText,
          type: data.type
        }
      : null;
  }

  public static themesToDO(pageDTO: BaseApiResponsePageResult): ThemesBlock {
    const themesDO: ThemesBlock = {
      data: [],
      settings: {
        types: {
          mobile: true,
          tablet: true,
          laptop: true,
          desktop: true
        }
      }
    };

    const themesPath = pageDTO.config.header.themes?.path;
    const themesSettings = pageDTO.config.header.themes?.settings;

    if (guardUnspecified(themesPath)) {
      const themes = (pageDTO.data[themesPath].data as ThemeItem[]) || [];

      themesDO.data = themes.map(item => ({
        hash: item.hash,
        image: item.image,
        path: item.path,
        status: item.status,
        title: item.title,
        updatedAt: item.updatedAt,
        type: 'theme',
        advCompanyName: item.advCompanyName,
        advToken: item.advToken
      }));

      themesDO.settings = themesSettings || {
        types: {
          mobile: false,
          tablet: false,
          laptop: false,
          desktop: false
        }
      };
    }

    return themesDO;
  }

  public static pictureOfDayToDO(pageDTO: BaseApiResponsePageResult): PictureOfDayBlock {
    const pictureOfDayConfig = <
      | BlockConfiguration<'picture_of_the_day_and_videos', PictureOfDaySettingsType>
      | undefined
    >pageDTO.config.content.find(block => block.type === 'picture_of_the_day_and_videos');

    if (!guardUnspecified(pictureOfDayConfig)) {
      return null;
    }

    const pictureOfDayDO: PictureOfDayBlock = {
      data: {
        news: [],
        videos: []
      },
      settings: {
        type: 'default'
      }
    };

    pictureOfDayDO.settings.type = pictureOfDayConfig.settings?.type || 'default';

    const newsPath = <string>pictureOfDayConfig.params?.picture_of_the_day_path;
    pictureOfDayDO.data.news = this._pictureOfDayNewsToDO(pageDTO, newsPath);

    const videosPath = <string>pictureOfDayConfig.params?.videos_path;
    pictureOfDayDO.data.videos = this._pictureOfDayVideosToDO(pageDTO, videosPath);

    return pictureOfDayDO;
  }

  private static _pictureOfDayNewsToDO(
    pageDTO: BaseApiResponsePageResult,
    path: string
  ): PictureOfDayRecord[] {
    const pictureOfDay = <BaseRecordDTO[]>pageDTO.data[path].data;

    if (!guardUnspecified(pictureOfDay) || !Array.isArray(pictureOfDay)) {
      return [];
    }

    return pictureOfDay.map(item => ({
      id: item.id,
      title: item.header,
      link: item.urls.url,
      viewsCount: item.viewsCount ?? 0,
      commentsCount: item.commentsCount ?? 0,
      commentsLink: item.urls.urlComments,
      isCommentsAllowed: item.isCommentsAllowed,
      type: 'news'
    }));
  }

  private static _pictureOfDayVideosToDO(
    pageDTO: BaseApiResponsePageResult,
    path: string
  ): PictureOfDayVideo[] {
    const videoOfDay = <VideoItemDTO[]>pageDTO.data[path].data;

    if (!guardUnspecified(videoOfDay)) {
      return [];
    }

    return videoOfDay.map(item => {
      return {
        id: item.id,
        title: item.header,
        link: item.videoDayUrl,
        previewUrl: item.video.previewImage.url,
        type: 'videos'
      };
    });
  }
}
