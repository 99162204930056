import { componentFactoryOf } from 'vue-tsx-support';
import { InputHTMLAttributes } from 'vue-tsx-support/types/dom';

import { JtnUiSearchInput } from '@jtnews/jtn-ui';
import { injectStylesMixin } from '@jtnews/shared';

import styles from './search-bar.styles.scss?module';

interface Events {
  onSubmitSearch: string;
  onResetSearch: Event;
}

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'SearchInput',
    props: {
      viewType: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        searchQuery: ''
      };
    },
    mounted() {
      const inputEl = this.$children[0].$refs.input as HTMLInputElement;
      inputEl.focus();
    },
    methods: {
      onKeydown(event: KeyboardEvent) {
        if (event.key === 'Enter') {
          event.preventDefault();
          this.navigateToSearchPage(null);
        }
      },
      navigateToSearchPage(event: Event | null) {
        event !== null && event.preventDefault();

        const inputEl = this.$children[0].$refs.input as HTMLInputElement;
        inputEl.value = '';
        inputEl.blur();
        this.$emit('submitSearch', this.searchQuery);
      },
      resetInput(event: Event) {
        this.searchQuery = '';
        this.$emit('resetSearch', event);
      }
    },
    render() {
      return (
        <form class={[styles['search-input'], styles[this.viewType]]}>
          <JtnUiSearchInput
            searchType="header"
            placeholder="Введите слово для поиска"
            value={this.searchQuery}
            hasReset={this.viewType !== 'mobile'}
            data-test="search-bar-input"
            onResetBtn={(event: Event) => this.resetInput(event)}
            onSearchIconClicked={(event: Event) => this.navigateToSearchPage(event)}
            onInput={(event: SyntheticEvent<InputHTMLAttributes, Event>) => {
              this.searchQuery = event.target.value as string;
            }}
            onKeydown={(event: KeyboardEvent) => this.onKeydown(event)}
          />
        </form>
      );
    }
  });
