<template>
  <LazyHydrate
    :never="ssrOnly"
    :on-interaction="onInteraction"
    :idle-timeout="idleTimeout"
    :trigger-hydration="triggerHydration"
    :when-idle="whenIdle"
    :when-visible="whenVisible"
  >
    <slot />
  </LazyHydrate>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

export default {
  components: {
    LazyHydrate
  },
  props: {
    idleTimeout: {
      default: 2000,
      type: Number
    },
    onInteraction: {
      type: [Array, Boolean, String]
    },
    ssrOnly: {
      type: Boolean
    },
    triggerHydration: {
      type: Boolean,
      default: false
    },
    whenIdle: {
      type: Boolean
    },
    whenVisible: {
      type: [Boolean, Object]
    }
  }
};
</script>
