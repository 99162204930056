import { getPreloadImageString } from '@portal/utils/util-dom';
import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';

import {
  ImageAspectRatio,
  ImageDecoding,
  ImageLoading,
  ImageSourceVM,
  ResponsiveImageDataVM,
} from './images.vm';

const WEBP_EXT = '.webp-portal';
const WEBP_TYPE = 'image/webp';

export type ResponsiveImageValues = {
  width: number;
  breakpoint: number;
  noMedia?: boolean;
  hasRetina?: boolean;
};

export type ResponsiveImageParams = {
  width: number;
  url: string;
  hasCommercialLabel?: boolean;
  hasHeight?: boolean;
  isCrop?: boolean;
  aspectRatio?: ImageAspectRatio;
  isLazy?: boolean;
  isPreloadImage?: boolean;
  values: ResponsiveImageValues[];
};

export const getHeightByWidth = (width: number, aspectRatio: ImageAspectRatio): number =>
  Math.round(width / aspectRatio);

export const getImageUrl = (
  url: string,
  width: number,
  height = 0,
  crop = true,
): string => {
  const replacePattern = '##';

  if (!guardEmptyString(url)) {
    throw new Error('getImageUrl: url is empty');
  }

  if (!url.includes(replacePattern)) {
    throw new Error('getImageUrl: replace pattern not found');
  }

  const endReplaceValue = height > 0 ? `_${height}${crop ? '_c' : ''}` : '';

  return url.replace(replacePattern, `_${width}${endReplaceValue}.`);
};

export const getWebpImageUrl = (url: string): string => `${url}${WEBP_EXT}`;

export const getImageSources = (input: ResponsiveImageParams): ImageSourceVM[] => {
  try {
    const {
      url,
      values,
      aspectRatio = ImageAspectRatio.Wide,
      isCrop = true,
      hasHeight = true,
    } = input;

    return values.map((val) => {
      const { width, breakpoint, noMedia = false, hasRetina = true } = val;
      const media = !noMedia && breakpoint > 0 ? `(max-width: ${breakpoint}px)` : '';
      const height = hasHeight ? getHeightByWidth(width, aspectRatio) : 0;

      const imageUrl = getImageUrl(url, width, height, isCrop);
      const webpImageUrl = getWebpImageUrl(imageUrl);
      let srcset = webpImageUrl;

      if (hasRetina) {
        const width2x = width * 2;
        const height2x = hasHeight ? getHeightByWidth(width2x, aspectRatio) : 0;
        const image2x = getImageUrl(url, width2x, height2x, isCrop);
        const webpImage2x = getWebpImageUrl(image2x);

        srcset = `${webpImageUrl} 1x, ${webpImage2x} 2x`;
      }

      return {
        media,
        srcset,
        type: WEBP_TYPE,
      };
    });
  } catch {
    return [];
  }
};

export const getImagePreloadLink = (input: ResponsiveImageParams): string => {
  const {
    url,
    values,
    aspectRatio = ImageAspectRatio.Wide,
    isCrop = true,
    hasHeight = true,
  } = input;

  if (!guardEmptyString(url)) {
    return '';
  }

  /* eslint-disable no-param-reassign */
  return values.reduce((preload, item, index) => {
    const { width, breakpoint, noMedia = false, hasRetina = true } = item;
    const isFirst = index === 0;
    const isLast = index === values.length - 1;
    const media = !noMedia && breakpoint > 0 ? `(max-width: ${breakpoint}px)` : '';
    const height = hasHeight ? getHeightByWidth(width, aspectRatio) : 0;
    let srcset = '';
    let preloadImageUrl = '';
    let imageType = WEBP_TYPE;

    try {
      const imageUrl = getImageUrl(url, width, height, isCrop);

      preloadImageUrl = getWebpImageUrl(imageUrl);

      if (hasRetina) {
        const width2x = width * 2;
        const height2x = hasHeight ? getHeightByWidth(width2x, aspectRatio) : 0;
        const image2x = getImageUrl(url, width2x, height2x, isCrop);
        const webpImage2x = getWebpImageUrl(image2x);

        srcset = `${preloadImageUrl} 1x, ${webpImage2x} 2x`;
      } else {
        srcset = preloadImageUrl;
      }
    } catch {
      preloadImageUrl = url;
      imageType = '';
    }

    if (isFirst && !noMedia) {
      preload = `${preload}${getPreloadImageString(
        preloadImageUrl,
        imageType,
        media,
        srcset,
      )}`;
    } else if (!isLast && !noMedia) {
      const prev = values[index - 1];

      if (!prev.noMedia) {
        preload = `${preload}${getPreloadImageString(
          preloadImageUrl,
          imageType,
          `(min-width: ${prev.breakpoint}.1px) and ${media}`,
          srcset,
        )}`;
      }
    } else if (isLast) {
      const prev = values[index - 1];

      if (guardUnspecified(prev) && !prev.noMedia) {
        preload = `${preload}${getPreloadImageString(
          preloadImageUrl,
          imageType,
          `(min-width: ${prev.breakpoint}.1px)`,
          srcset,
        )}`;
      }
    }

    return preload;
    /* eslint-enable no-param-reassign */
  }, '');
};

export const getResponsiveImageData = (
  input: ResponsiveImageParams,
): ResponsiveImageDataVM => {
  const {
    url,
    width,
    aspectRatio = ImageAspectRatio.Wide,
    hasCommercialLabel = false,
    hasHeight = true,
    isPreloadImage = true,
    isCrop = true,
    isLazy = true,
  } = input;

  try {
    const img = {
      src: getImageUrl(
        url,
        width,
        hasHeight ? getHeightByWidth(width, aspectRatio) : 0,
        isCrop,
      ),
      sources: getImageSources(input),
      loading: isLazy ? ImageLoading.Lazy : ImageLoading.Eager,
      decoding: isPreloadImage ? ImageDecoding.Async : ImageDecoding.Auto,
      aspectRatio,
      hasCommercialLabel,
    };
    const preload = isPreloadImage ? getImagePreloadLink(input) : '';

    return {
      data: {
        ...img,
        /**
         * @deprecated не использовать
         */
        img,
      },
      preload,
    };
  } catch {
    return {
      data: {
        src: url,
        loading: ImageLoading.Lazy,
        decoding: ImageDecoding.Auto,
        sources: [],
        hasCommercialLabel,
        /**
         * @deprecated не использовать
         */
        img: {
          src: url,
          sources: [],
          hasCommercialLabel,
        },
        aspectRatio,
      },
      preload: '',
    };
  }
};
