import { Regions } from '@smh/projects/regions';

export type ActiveRegions =
  | Regions.Nsk
  | Regions.Omsk
  | Regions.Krasnoyarsk
  | Regions.Ufa
  | Regions.Arkhangelsk
  | Regions.Volgograd
  | Regions.Kurgan
  | Regions.Perm
  | Regions.Rostov
  | Regions.Samara
  | Regions.Tyumen
  | Regions.Chelyabinsk
  | Regions.Yaroslavl
  | Regions.Yekaterinburg
  | Regions.NizhnyNovgorod
  | Regions.Kemerovo
  | Regions.KhantyMansiysk
  | Regions.KrasnodarRugion
  | Regions.Mosk
  | Regions.Vladivostok;

export const MACRO_REGIONS = [
  Regions.Nsk,
  Regions.Omsk,
  Regions.Krasnoyarsk,
  Regions.Kemerovo,
  Regions.Irkutsk,
  Regions.Tomsk,
  Regions.Kazan,
  Regions.KrasnodarRugion,
  Regions.KhantyMansiysk,
  Regions.Tolyatty,
  Regions.Mosk,
  Regions.Chita,
  Regions.Vladivostok,
  Regions.Barnaul,
  Regions.Voronezh,
  Regions.Saratov,
  Regions.Orenburg,
  Regions.Tula,
  Regions.Yakutsk,
  Regions.Murmansk,
  Regions.Ulyanovsk,
  Regions.Izhevsk,
];

export const MACRO_REGIONS_WITHOUT_NSK = MACRO_REGIONS.filter((id) => id !== Regions.Nsk);

export const RUGION_REGIONS = [
  Regions.Ufa,
  Regions.Arkhangelsk,
  Regions.Volgograd,
  Regions.Kurgan,
  Regions.Perm,
  Regions.Rostov,
  Regions.Samara,
  Regions.Tyumen,
  Regions.Chelyabinsk,
  Regions.Yaroslavl,
  Regions.Magnitogorsk,
];

export const BRANCHLESS_REGIONS = [
  Regions.KrasnoyarskRugion,
  Regions.Stavropol,
  Regions.Vologda,
  Regions.KemerovoRugion,
  Regions.Kirov,
  Regions.Lipetsk,
  Regions.VelikiiNovgorod,
  Regions.NovosibirskRugion,
  Regions.OmskRugion,
  Regions.Pskov,
  Regions.Ryazan,
  Regions.YekaterinburgRugion,
  Regions.Tambov,
  Regions.SaintPetersburg,
  Regions.YamaloNenetsAutonomousArea,
  Regions.TomskRugion,
  Regions.Sochi,
  Regions.Sterlitamak,
  Regions.DoubleNsk,
];

export const BRANCHLESS_REGIONS_WITHOUT_SOCHI = BRANCHLESS_REGIONS.filter(
  (id) => id !== Regions.Sochi,
);

export const REGIONS_EDITORS_MAIL: { [key in ActiveRegions]: string } = {
  [Regions.Nsk]: 'news@ngs.ru',
  [Regions.Krasnoyarsk]: 'news@ngs24.ru',
  [Regions.Omsk]: 'news55@ngs.ru',
  [Regions.NizhnyNovgorod]: 'news52@iportal.ru',
  [Regions.Yekaterinburg]: 'news@corp.e1.ru',
  [Regions.Ufa]: '02@rugion.ru',
  [Regions.Volgograd]: '34@rugion.ru',
  [Regions.Rostov]: '61@rugion.ru',
  [Regions.Arkhangelsk]: '29@rugion.ru',
  [Regions.Kurgan]: '45@rugion.ru',
  [Regions.Perm]: '59@rugion.ru',
  [Regions.Samara]: '63@rugion.ru',
  [Regions.Tyumen]: '72@rugion.ru',
  [Regions.Chelyabinsk]: '74@rugion.ru',
  [Regions.Yaroslavl]: '76@rugion.ru',
  [Regions.Kemerovo]: 'ngs42@iportal.ru',
  [Regions.KhantyMansiysk]: '86@iportal.ru',
  [Regions.KrasnodarRugion]: '93@iportal.ru',
  [Regions.Mosk]: '',
  [Regions.Vladivostok]: 'vladivostok1@sholding.ru',
};

export const AFFILIATE_REGIONS = [
  Regions.Arkhangelsk,
  Regions.Volgograd,
  Regions.Yekaterinburg,
  Regions.Irkutsk,
  Regions.KrasnodarRugion,
  Regions.Krasnoyarsk,
  Regions.Kemerovo,
  Regions.Kurgan,
  Regions.Magnitogorsk,
  Regions.NizhnyNovgorod,
  Regions.Nsk,
  Regions.Omsk,
  Regions.Perm,
  Regions.Rostov,
  Regions.Samara,
  Regions.Sochi,
  Regions.Tolyatty,
  Regions.Tyumen,
  Regions.Ufa,
  Regions.KhantyMansiysk,
  Regions.Chelyabinsk,
  Regions.Yaroslavl,
  Regions.Kazan,
];

export const HEADER_BIDDING_REGIONS = [
  Regions.Ufa,
  Regions.Kazan,
  Regions.KrasnodarRugion,
  Regions.Krasnoyarsk,
  Regions.Arkhangelsk,
  Regions.Volgograd,
  Regions.Kurgan,
  Regions.NizhnyNovgorod,
  Regions.Nsk,
  Regions.Omsk,
  Regions.Perm,
  Regions.Rostov,
  Regions.Samara,
  Regions.Yekaterinburg,
  Regions.Tyumen,
  Regions.Chelyabinsk,
  Regions.Chita,
  Regions.Yaroslavl,
  Regions.Irkutsk,
  Regions.Mosk,
  Regions.Tolyatty,
];

export const REGIONS_AS_SOFTWARE = [
  Regions.Nsk,
  Regions.Yekaterinburg,
  Regions.Chelyabinsk,
  Regions.Chita,
  Regions.Tyumen,
  Regions.NizhnyNovgorod,
  Regions.Samara,
  Regions.Perm,
  Regions.Krasnoyarsk,
  Regions.Vladivostok,
];

export const REGIONS_AS_SOFTWARE_PART_2 = [
  Regions.Stavropol,
  Regions.Arkhangelsk,
  Regions.Kemerovo,
  Regions.Kurgan,
  Regions.Lipetsk,
  Regions.Omsk,
  Regions.Orenburg,
  Regions.Ryazan,
  Regions.Yaroslavl,
  Regions.KhantyMansiysk,
  Regions.YamaloNenetsAutonomousArea,
  Regions.KrasnodarRugion,
  Regions.Kazan,
  Regions.Rostov,
  Regions.Saratov,
  Regions.Barnaul,
  Regions.Irkutsk,
  Regions.Magnitogorsk,
  Regions.Mosk,
  Regions.Sochi,
  Regions.Tolyatty,
  Regions.Tomsk,
  Regions.Ufa,
  Regions.Volgograd,
];

export const OUR_AWARDS_REGIONS = [
  Regions.Chelyabinsk,
  Regions.Mosk,
  Regions.Perm,
  Regions.Arkhangelsk,
  Regions.Samara,
  Regions.Chita,
  Regions.Yekaterinburg,
  Regions.Irkutsk,
  Regions.NizhnyNovgorod,
  Regions.Tyumen,
  Regions.Ufa,
  Regions.Krasnoyarsk,
  Regions.Kemerovo,
  Regions.KrasnodarRugion,
  Regions.Kurgan,
  Regions.Nsk,
  Regions.Volgograd,
  Regions.Rostov,
  Regions.Omsk,
  Regions.Yaroslavl,
];
