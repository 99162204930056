export interface RecordTextWidgetInfo {
  text: string;
}

export class RecordTextWidget implements RecordTextWidgetInfo {
  public readonly text: string;

  constructor(info: RecordTextWidgetInfo) {
    this.text = info.text;
  }
}
