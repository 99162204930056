import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { createVuexStore } from 'vuex-simple';

import { RootModule } from './store';

Vue.use(Vuex);

export const createStore = (): Store<RootModule> => {
  const store = new RootModule();

  return createVuexStore(store) as Store<RootModule>;
};
export * from './store';
export * from './adv';
