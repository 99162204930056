import { RecordImageInfo, RecordImage } from '../record-image';

export interface RecordLogotypeInfo {
  image: RecordImageInfo;
}

export class RecordLogotype implements RecordLogotypeInfo {
  public readonly image: RecordImage;

  public readonly link: string;

  constructor(info: RecordLogotypeInfo & { link: string }) {
    this.image = new RecordImage(info.image);
    this.link = info.link;
  }
}
