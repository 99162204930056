import { RUBRICS_REGEX_IDS } from '@jtnews/shared/data';

type LongreadPageType = typeof import('./pages/longread/longread.page');
type RecordPageType = typeof import('./pages/record.vue');

export const RECORD_ROUTING = [
  // роуты для страницы лонгрида
  {
    name: 'longread',
    path: '/news/longread/:id(\\d+|\\d+.html)',
    component: (): Promise<LongreadPageType> =>
      import(/* webpackChunkName: "longread-page" */ './pages/longread/longread.page'),
    alias: [
      '/text/longread/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/:id(\\d+)',
      `/text/longread/:rubricId${RUBRICS_REGEX_IDS}/:id(\\d+|\\d+.html)`
    ]
  },
  // роуты для страницы материала
  {
    name: 'record',
    path: '/news/preview/:id(\\d+|\\d+.html)',
    component: (): Promise<RecordPageType> =>
      import(/* webpackChunkName: "record-page" */ './pages/record.vue'),
    alias: [
      '/news/spool/news_id-:id(\\d+)([\\w\\d_-]*).html',
      '/news/:id(\\d+)',
      '/preview/:id(\\d+|\\d+.html)',
      '/text/preview/:id(\\d+)',
      `/text/:rubricId${RUBRICS_REGEX_IDS}/:id(\\d+)`,
      `/text/:rubricId${RUBRICS_REGEX_IDS}/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/:id(\\d+)`
    ]
  }
];
