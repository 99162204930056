type MainPageType = typeof import('./pages/main.vue');

export const MAIN_ROUTING = [
  // главная страница
  {
    name: 'main',
    path: '/',
    component: (): Promise<MainPageType> =>
      import(/* webpackChunkName: "main-page" */ './pages/main.vue')
  }
];
