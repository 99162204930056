import { differenceInSeconds } from '@portal/utils/util-date';
import { Getter, Action, State, Mutation } from 'vuex-simple';

import { Image, Format, Theme, Rubric } from '@jtnews/shared/news';

import { UserDataMapper } from '../services/data-mappers';

import { RootModule } from './store';

const CONFIRM_MOMENT_LOCAL_STORAGE_KEY = 'jtnews_confirm_email_moment';
const LAST_RECORD_VIEWED_LOCAL_STORAGE_KEY = 'jtnews_last_record_viewed';
const PROFILE_ID_LOCAL_STORAGE_KEY = 'jtnews_profile_id';

type ConfirmEmailParams = {
  profileId: number;
  data: FormData;
};

type RecordParams = {
  profileId: number;
  recordId: number;
};

type BookmarkState = {
  id: string;
  type: string;
  bookmark: { id: number };
};

type BookmarkRecord = {
  id: number;
  header: string;
  mainPhoto: Image | null;
  publishAt: string;
  formats?: Format[];
  themes?: Theme[];
  rubrics: Rubric[];
  subheader?: string;
  urls: {
    url: string;
    urlComments: string;
    urlCanonical: string;
  };
  viewsCount?: number;
  commentsCount?: number;
  isCommentsAllowed: boolean;
  isCommercial: boolean;
};

type Bookmark = {
  id: string;
  bookmark: BookmarkRecord;
  type: string;
};

type ChangeBookmarkParams = {
  regionId: number;
  profileId: number;
  recordId: number;
  bookmarkId: string;
  type: string;
};

type AddBookmarkParams = {
  regionId: number;
  profileId: number;
  recordId: number;
  type: string;
};

type DeleteBookmarkParams = {
  regionId: number;
  profileId: number;
  recordId: number;
  bookmarkId: string;
};

type SendBookmarkAnalyticsParams = {
  recordId: number;
  bookmarkId: string;
  isAuthorized?: boolean;
  showcaseParams?: {
    fieldType: string;
    place: number;
    articleType: string;
  };
  willDeprecate?: boolean;
};

type BookmarksApiErrorDetails = {
  name: string;
  reason: string;
};

type BookmarksApiError = {
  title: string;
  status: number;
  detail: string;
  type: string;
  errors: BookmarksApiErrorDetails[];
};

type BaseUserResponse = {
  detail: null;
  status: number;
  title: string;
};

type LastViewedRecord = {
  recordId: number;
  regionId: number;
};

export class UserModule {
  private _userDataMapper: UserDataMapper;

  constructor(private _root: RootModule) {}

  public init(): void {
    this._userDataMapper = new UserDataMapper(this._root.envType || '');
  }

  @State()
  public bookmarks: BookmarkState[] = [];

  @State()
  public recordsWithBookmark: number[] = [];

  @State()
  public bookmarkApiError: BookmarksApiError | null = null;

  @Getter()
  public get isBookmarkLimitError(): boolean {
    const errors = this.bookmarkApiError?.errors || [];
    return errors[0].name === 'LimitExceeded';
  }

  @Getter()
  public get bookmarkIdsByRecord(): Record<number, string> {
    const bookmarkIdsByRecordMap = {};

    this.bookmarks
      .filter(({ type }) => type === 'record')
      .forEach(({ id, bookmark }) => (bookmarkIdsByRecordMap[bookmark.id] = id));

    return bookmarkIdsByRecordMap;
  }

  @Mutation()
  updateBookmarksState(bookmarks: BookmarkState[]): void {
    this.bookmarks = [...bookmarks];
  }

  @Mutation()
  updateRecordsWithBookmark(recordIds: number[]): void {
    this.recordsWithBookmark = [...recordIds];
  }

  @Mutation()
  updateApiError(error: BookmarksApiError | null): void {
    this.bookmarkApiError = error === null ? error : { ...error };
  }

  @Action()
  public setSendConfirmMomentToLocalStorage(): void {
    localStorage.setItem(CONFIRM_MOMENT_LOCAL_STORAGE_KEY, Date.now().toString());
  }

  @Action()
  public getLastMomentConfirmEmailInSeconds(): number | undefined {
    try {
      const timestamp = localStorage.getItem(CONFIRM_MOMENT_LOCAL_STORAGE_KEY);
      return differenceInSeconds(Date.now(), Number(timestamp));
    } catch (err) {
      console.error(err);
    }
  }

  @Action()
  public async confirmNotifyEmail(params: ConfirmEmailParams): Promise<void> {
    const { profileId, data } = params;
    try {
      await this._userDataMapper.confirmNotifyEmail(this._root.regionId, profileId, data);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  @Action()
  public async sendRecordToBrowsingHistory(params: RecordParams): Promise<void> {
    const { profileId, recordId } = params;
    const isLastRecordRepeated = this._checkIfLastRecordIsRepeated(
      recordId,
      this._root.regionId
    );
    const isProfileIdRepeated = this._checkIfProfileIdIsRepeated(profileId);

    if (isLastRecordRepeated && isProfileIdRepeated) {
      return;
    } else if (!isProfileIdRepeated) {
      this._setProfileIdToLocalStorage(profileId);
    }

    try {
      await this.saveRecordInBrowsingHistory({ recordId, profileId });
      this._setLastRecordViewedToLocalStorage(recordId, this._root.regionId);
    } catch (err) {
      console.error(err);
    }
  }

  @Action()
  public async saveRecordInBrowsingHistory({
    recordId,
    profileId
  }: RecordParams): Promise<BaseUserResponse | undefined> {
    try {
      return await this._userDataMapper.saveRecordInBrowsingHistory({
        regionId: this._root.regionId,
        profileId,
        type: 'record',
        recordId
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  @Action()
  public async changeBookmarkStatus(
    params: ChangeBookmarkParams
  ): Promise<Bookmark | void> {
    const { regionId, profileId, recordId, type, bookmarkId } = params;

    if (bookmarkId !== '') {
      return await this.deleteBookmark({
        regionId,
        profileId,
        bookmarkId,
        recordId
      });
    } else {
      return await this.addBookmark({
        regionId,
        profileId,
        recordId,
        type
      });
    }
  }

  @Action()
  public async addBookmark(params: AddBookmarkParams): Promise<Bookmark> {
    const recordsWithBookmarkBeforeAdd = [...this.recordsWithBookmark];
    this.updateRecordsWithBookmark([...this.recordsWithBookmark, params.recordId]);

    try {
      const newBookmark = await this._userDataMapper.addBookmark(params);
      const newBookmarkState = {
        id: newBookmark.id,
        type: newBookmark.type,
        bookmark: {
          id: newBookmark.bookmark.id
        }
      };

      this.updateBookmarksState([...this.bookmarks, newBookmarkState]);
      this.updateApiError(null);
      return newBookmark;
    } catch (error) {
      this.updateApiError(error);
      this.updateRecordsWithBookmark(recordsWithBookmarkBeforeAdd);
      throw error;
    }
  }

  @Action()
  public async deleteBookmark(params: DeleteBookmarkParams): Promise<void> {
    const recordsWithBookmarkBeforeDelete = [...this.recordsWithBookmark];

    this.updateRecordsWithBookmark([
      ...this.recordsWithBookmark.filter(recordId => recordId !== params.recordId)
    ]);

    try {
      await this._userDataMapper.deleteBookmark(params);

      const { bookmarkId } = params;
      const filteredBookmarks = this.bookmarks.filter(({ id }) => id !== bookmarkId);
      this.updateBookmarksState(filteredBookmarks);
      this.updateApiError(null);
    } catch (error) {
      this.updateApiError(error);
      this.updateRecordsWithBookmark(recordsWithBookmarkBeforeDelete);
      throw error;
    }
  }

  @Action()
  public sendBookmarkReachGoal({
    recordId,
    bookmarkId,
    showcaseParams,
    isAuthorized = true,
    willDeprecate = false
  }: SendBookmarkAnalyticsParams): void {
    const action = this._getBookmarkAction(isAuthorized, bookmarkId !== '');
    const fieldType = showcaseParams === undefined ? action : showcaseParams.fieldType;

    const valueName =
      showcaseParams === undefined
        ? recordId
        : {
            [showcaseParams.place.toString()]: {
              [action]: {
                [recordId.toString()]: showcaseParams.articleType
              }
            }
          };

    this._root.analyticsModule.sendNewReachGoal({
      blockType: 'Контент',
      fieldType,
      valueName,
      goalName: 'addFavorites',
      willDeprecate
    });
  }

  private _getBookmarkAction(isAuthorized: boolean, hasBookmarkId: boolean): string {
    if (!isAuthorized) {
      return 'Клик';
    }

    return hasBookmarkId ? 'Удалено из Избранного' : 'Добавлено в Избранное';
  }

  private _checkIfLastRecordIsRepeated(recordId: number, regionId: number): boolean {
    try {
      const lastViewedRecord = JSON.parse(
        localStorage.getItem(LAST_RECORD_VIEWED_LOCAL_STORAGE_KEY) || 'null'
      ) as LastViewedRecord | null;

      if (lastViewedRecord === null) {
        return false;
      }

      const { recordId: lastViewedRecordId, regionId: lastViewedRecordRegionId } =
        lastViewedRecord;

      return lastViewedRecordId === recordId && lastViewedRecordRegionId === regionId;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  private _checkIfProfileIdIsRepeated(profileId: number): boolean {
    try {
      const storedProfileId = localStorage.getItem(PROFILE_ID_LOCAL_STORAGE_KEY);
      return storedProfileId === profileId.toString();
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  private _setLastRecordViewedToLocalStorage(recordId: number, regionId: number): void {
    const lastRecord = { recordId, regionId };

    localStorage.setItem(
      LAST_RECORD_VIEWED_LOCAL_STORAGE_KEY,
      JSON.stringify(lastRecord)
    );
  }

  private _setProfileIdToLocalStorage(profileId: number): void {
    localStorage.setItem(PROFILE_ID_LOCAL_STORAGE_KEY, profileId.toString());
  }
}
