<template>
  <div v-if="info" :class="$style.widgetTraffic">
    <jtn-ui-informer-mini
      v-if="!miniWidget"
      :has-icon="true"
      :class="$style.informerMini"
      data-test="widget-traffic"
    >
      <template slot="icon">
        <traffic-icon :fill="mapColorsTraffic[info.icon]" />
      </template>
      <template slot="text">
        <span :class="$style.mobileHidden">Пробки </span>
        <span>{{ info.level }}/10</span>
      </template>
    </jtn-ui-informer-mini>
    <jtn-ui-informer-mini
      v-else
      :type="componentType"
      :has-icon="true"
      :title="`Пробки ${info.level}/10`"
      :href="componentType === 'link' ? info.link : undefined"
      :target="componentType === 'link' ? '_blank' : undefined"
      :class="$style.informerMini"
      data-test="widget-traffic"
      @click="sendTrafficCTR($event)"
    >
      <template slot="icon">
        <svg :class="[$style.icon, $style[info.icon]]" :width="24" :height="24">
          <use xlink:href="jtn-critical-traffic"></use>
        </svg>
      </template>
      <template slot="text">
        <span :class="$style.mobileHidden">Пробки </span>
        <span>{{ info.level }}/10</span>
      </template>
    </jtn-ui-informer-mini>
  </div>
</template>

<script>
import { JtnUiInformerMini } from '@jtnews/jtn-ui';

import { StoreMixin } from '../../mixins';

export default {
  name: 'TrafficWidget',
  components: {
    JtnUiInformerMini
  },
  mixins: [StoreMixin],
  props: {
    miniWidget: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    data() {
      return this.store.commonModule.commonTrafficBlock;
    },
    info() {
      return this.data.data;
    },
    componentType() {
      if (!this.info.link || this.info.link.startsWith('?')) {
        return 'block';
      }

      return 'link';
    }
  },
  methods: {
    sendTrafficCTR(event) {
      this.$emit('sendNewReachGoal');
    }
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.widgetTraffic {
  display: block;
}

.icon {
  &.red {
    color: $color-error;
  }

  &.green {
    color: #71b732;
  }

  &.yellow {
    color: #fc0;
  }
}

.informerMini {
  @include on-min-resize($laptop) {
    height: $header-height;
  }
}

.mobileHidden {
  @include on-res($mobile) {
    display: none;
  }
}
</style>
