import { WebSocketService } from '../web-socket/web-socket.service';

type WSData = {
  profileId: number;
};

export class UserModerationStatusService extends WebSocketService<WSData> {
  // eslint-disable-next-line
  private readonly _channelUrl = 'wss/public-profiles/subscribe';

  public startWS(env: string, params: string): void {
    const wsUrl = `${this.host}${env}/${this._channelUrl}?${params}`;
    super.createWS(wsUrl);
  }
}

export const userModerationStatusService = new UserModerationStatusService();
