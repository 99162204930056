/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/member-ordering */
import { State, Getter, Mutation } from 'vuex-simple';

import { getBlockWithParams } from '../../shared/utils/store/getBlockWithParams';
import { AdvService, advService } from '../services/adv.service';

import type { AdvData, AdvLogoBlock, Layout, MobileBrandingCreatives } from './adv.types';
import { RootModule } from './store';

const FIELDS = ['settings', 'type'];

export class AdvModule {
  // eslint-disable-next-line
  private _isAdvCorrectData(block: AdvData): boolean {
    return typeof block?.data !== 'undefined';
  }

  @State()
  public mobileBrandingCreatives: MobileBrandingCreatives | null = null;
  @State()
  public layout: Layout = null;

  @Mutation()
  public setMobileBrandingCreatives(creatives: MobileBrandingCreatives): void {
    this.mobileBrandingCreatives = creatives;
  }

  @Mutation()
  public setLayout(layout: Layout): void {
    this.layout = layout;
  }

  public advService: AdvService = advService;

  @Getter()
  public get topAdvBlock(): AdvData {
    const topAdv = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'header_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(topAdv) ? topAdv : null;
  }

  @Getter()
  public get bottomAdvBlock(): AdvData {
    const bottomAdv = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'footer_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(bottomAdv) ? bottomAdv : null;
  }

  @Getter()
  public get underHeaderAdvBlock(): AdvData {
    const advBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'under_header_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(advBlock) ? advBlock : null;
  }

  @Getter()
  public get fullscreenAdvBlock(): AdvData {
    const fullscreenAdv = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'fullscreen',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(fullscreenAdv) ? fullscreenAdv : null;
  }

  @Getter()
  public get fullscreenMainAdvBlock(): AdvData {
    const fullscreenAdv = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'fullscreen-main',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(fullscreenAdv) ? fullscreenAdv : null;
  }

  @Getter()
  public get desktopRecordInreadAdvert(): AdvData {
    const desktopInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'desktop_record_inread_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(desktopInread) ? desktopInread : null;
  }

  @Getter()
  public get desktopRecordInreadAdvert2(): AdvData {
    const desktopInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'desktop_record_inread_advert_2',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(desktopInread) ? desktopInread : null;
  }

  public get desktopRecordInreadAdvert3(): AdvData {
    const desktopInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'desktop_record_inread_advert_3',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(desktopInread) ? desktopInread : null;
  }

  @Getter()
  public get mobileRecordInreadAdvert(): AdvData {
    const mobileInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_record_inread_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(mobileInread) ? mobileInread : null;
  }

  @Getter()
  public get mobileRecordInreadAdvert2(): AdvData {
    const mobileInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_record_inread_advert_2',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(mobileInread) ? mobileInread : null;
  }

  @Getter()
  public get mobileRecordInreadAdvert3(): AdvData {
    const mobileInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_record_inread_advert_3',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(mobileInread) ? mobileInread : null;
  }

  @Getter()
  public get mobileRecordInreadAdvert4(): AdvData {
    const mobileInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_record_inread_advert_4',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(mobileInread) ? mobileInread : null;
  }

  @Getter()
  public get mobileRecordInreadAdvert5(): AdvData {
    const mobileInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_record_inread_advert_5',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(mobileInread) ? mobileInread : null;
  }

  @Getter()
  public get mobileRecordInreadAdvert6(): AdvData {
    const mobileInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_record_inread_advert_6',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(mobileInread) ? mobileInread : null;
  }

  @Getter()
  public get aTemplateBlock(): AdvData {
    const branding = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'branding'
    ) as AdvData;

    return branding;
  }

  @Getter()
  public get interscrollerAdvBlock(): AdvData {
    const interscroller = getBlockWithParams(
      this._root.config,
      'content',
      this._root.blocks,
      'interscroller_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(interscroller) ? interscroller : null;
  }

  @Getter()
  public get brandingAdfoxBlock(): AdvData {
    const brandingAdfox = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'branding_adfox',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(brandingAdfox) ? brandingAdfox : null;
  }

  @Getter()
  public get logoAdvertBlock(): AdvData {
    const logoAdvert = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'logo_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(logoAdvert) ? logoAdvert : null;
  }

  @Getter()
  public get betweenArchiveAdvBlock1(): AdvData {
    const betweenArchiveAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_place_archive_records_1',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenArchiveAdvBlock) ? betweenArchiveAdvBlock : null;
  }

  @Getter()
  public get betweenArchiveAdvBlock2(): AdvData {
    const betweenArchiveAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_place_archive_records_2',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenArchiveAdvBlock) ? betweenArchiveAdvBlock : null;
  }

  @Getter()
  public get betweenArchiveAdvBlock3(): AdvData {
    const betweenArchiveAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_place_archive_records_3',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenArchiveAdvBlock) ? betweenArchiveAdvBlock : null;
  }

  @Getter()
  public get betweenArchiveDesktopAdvBlock1(): AdvData {
    const betweenArchiveAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_place_archive_records_1_d',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenArchiveAdvBlock) ? betweenArchiveAdvBlock : null;
  }

  @Getter()
  public get betweenArchiveDesktopAdvBlock2(): AdvData {
    const betweenArchiveAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_place_archive_records_2_d',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenArchiveAdvBlock) ? betweenArchiveAdvBlock : null;
  }

  @Getter()
  public get betweenArchiveDesktopAdvBlock3(): AdvData {
    const betweenArchiveAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_place_archive_records_3_d',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenArchiveAdvBlock) ? betweenArchiveAdvBlock : null;
  }

  @Getter()
  public get betweenArchiveMobileAdvBlock1(): AdvData {
    const betweenArchiveAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_place_archive_records_1_m',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenArchiveAdvBlock) ? betweenArchiveAdvBlock : null;
  }

  @Getter()
  public get betweenArchiveMobileAdvBlock2(): AdvData {
    const betweenArchiveAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_place_archive_records_2_m',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenArchiveAdvBlock) ? betweenArchiveAdvBlock : null;
  }

  @Getter()
  public get betweenArchiveMobileAdvBlock3(): AdvData {
    const betweenArchiveAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_place_archive_records_3_m',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenArchiveAdvBlock) ? betweenArchiveAdvBlock : null;
  }

  @Getter()
  public get desktopVideoBlockAdvert(): AdvData {
    const desktopInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_between_videos_block',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(desktopInread) ? desktopInread : null;
  }

  @Getter()
  public get mobileVideoBlockAdvert(): AdvData {
    const mobileInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_mob_video_block',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(mobileInread) ? mobileInread : null;
  }

  @Getter()
  public get desktopVideosOfTheDayBlockAdvert(): AdvData {
    const desktopInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'desktop_video_block_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(desktopInread) ? desktopInread : null;
  }

  @Getter()
  public get desktopVideoPageInreadAdvert(): AdvData {
    const desktopInread = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'desktop_video_inread_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(desktopInread) ? desktopInread : null;
  }

  @Getter()
  public get betweenCommentsDesktopAdvBlock(): AdvData {
    const betweenCommentsAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'desktop_comments_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenCommentsAdvBlock)
      ? betweenCommentsAdvBlock
      : null;
  }

  @Getter()
  public get betweenCommentsMobileAdvBlock(): AdvData {
    const betweenCommentsAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_comments_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(betweenCommentsAdvBlock)
      ? betweenCommentsAdvBlock
      : null;
  }

  @Getter()
  public get advInImageBlock(): AdvData {
    const advInImageBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_in_image_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(advInImageBlock) ? advInImageBlock : null;
  }

  @Getter()
  public get mobileStickyAdvBlock(): AdvData {
    const mobileStickyAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_sticky_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(mobileStickyAdvBlock) ? mobileStickyAdvBlock : null;
  }

  @Getter()
  public get mobileStickyRSYAdvBlock(): AdvData {
    const mobileStickyAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_sticky_rsy_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(mobileStickyAdvBlock) ? mobileStickyAdvBlock : null;
  }

  @Getter()
  public get galleryAdvBlock(): AdvData {
    const galleryAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'gallery_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(galleryAdvBlock) ? galleryAdvBlock : null;
  }

  @Getter()
  public get personalSelectionMiddleDesktopAdvBlock(): AdvData {
    const personalSelectionAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'desktop_middle_recommendation_advert',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(personalSelectionAdvBlock)
      ? personalSelectionAdvBlock
      : null;
  }

  @Getter()
  public get personalSelectionDesktop1AdvBlock(): AdvData {
    const personalSelectionAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'desktop_recommendation_advert_1',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(personalSelectionAdvBlock)
      ? personalSelectionAdvBlock
      : null;
  }

  @Getter()
  public get personalSelectionDesktop2AdvBlock(): AdvData {
    const personalSelectionAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'desktop_recommendation_advert_2',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(personalSelectionAdvBlock)
      ? personalSelectionAdvBlock
      : null;
  }

  @Getter()
  public get personalSelectionDesktop3AdvBlock(): AdvData {
    const personalSelectionAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'desktop_recommendation_advert_3',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(personalSelectionAdvBlock)
      ? personalSelectionAdvBlock
      : null;
  }

  @Getter()
  public get personalSelectionMobile1AdvBlock(): AdvData {
    const personalSelectionAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_recommendation_advert_1',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(personalSelectionAdvBlock)
      ? personalSelectionAdvBlock
      : null;
  }

  @Getter()
  public get personalSelectionMobile2AdvBlock(): AdvData {
    const personalSelectionAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_recommendation_advert_2',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(personalSelectionAdvBlock)
      ? personalSelectionAdvBlock
      : null;
  }

  @Getter()
  public get personalSelectionMobile3AdvBlock(): AdvData {
    const personalSelectionAdvBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_recommendation_advert_3',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(personalSelectionAdvBlock)
      ? personalSelectionAdvBlock
      : null;
  }

  @Getter()
  public get inImageAdvBlock(): AdvData {
    const inImageBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'mobile_in_image_button',
      FIELDS
    ) as AdvData;

    return this._isAdvCorrectData(inImageBlock) ? inImageBlock : null;
  }

  @Getter()
  public get advLogo(): AdvLogoBlock {
    const advLogoBlock = getBlockWithParams(
      this._root.config,
      'common',
      this._root.blocks,
      'adv_logo',
      FIELDS
    ) as AdvLogoBlock;

    return this._isAdvCorrectData(advLogoBlock) ? advLogoBlock : null;
  }

  // eslint-disable-next-line
  constructor(private _root: RootModule) {}
}
