import { RouteConfig } from 'vue-router';

type OpinionInfoPageType = typeof import('../../views/opinion-info/opinion-info.page');
type CreditsPageType = typeof import('../../views/credits/credits.page');
type CookiePolicyPageType = typeof import('../../views/cookie-policy.page.vue');
type RecommenderPageType = typeof import('../../views/recommender.page.vue');
type SoftwareDescriptionPage =
  typeof import('../../views/sofware-policy/software-policy.page');
type OurAwardsPageType = typeof import('../../views/our-awards/our-awards.page');

export const CORE_ROUTING: RouteConfig[] = [
  // страница информации об отправке мнения пользователя
  {
    path: '/opinion/',
    component: (): Promise<OpinionInfoPageType> =>
      import(
        /* webpackChunkName: "opinion-info-page" */ '../../views/opinion-info/opinion-info.page'
      )
  },
  {
    path: '/credits/',
    component: (): Promise<CreditsPageType> =>
      import(/* webpackChunkName: "credits-page" */ '../../views/credits/credits.page')
  },
  {
    path: '/cookie-policy/',
    component: (): Promise<CookiePolicyPageType> =>
      import(/* webpackChunkName: "cookie-policy" */ '../../views/cookie-policy.page.vue')
  },
  {
    path: '/recommender/',
    component: (): Promise<RecommenderPageType> =>
      import(/* webpackChunkName: "cookie-policy" */ '../../views/recommender.page.vue')
  },
  {
    path: '/nashi-nagrady/',
    component: (): Promise<OurAwardsPageType> =>
      import(
        /* webpackChunkName: "our-awards" */ '../../views/our-awards/our-awards.page'
      )
  },
  // страницы с документацией ПО
  {
    path: '/opisanie-funkcionalnyh-harakteristik-po/',
    component: (): Promise<SoftwareDescriptionPage> =>
      import(
        /* webpackChunkName: "opisanie-funkcionalnyh-harakteristik-po" */ '../../views/sofware-policy/software-policy.page'
      ),
    alias: [
      '/usloviya-ispolzovaniya-veb-portala-i-politiku-konfidencialnosti-personalnyh-dannyh/',
      '/rukovodstvo-polzovatelya/',
      '/web-portal/'
    ]
  }
];
