










import { Component, Prop, Vue } from 'vue-property-decorator';
import { LazyHydrate } from '@jtnews/shared/ui';
import { useStore } from 'vuex-simple';
import { guardUnspecified } from '@portal/utils/util-guards';
import { Creative } from '../adv-creative/creative';
import { AdvCreative } from '../../_adv';
import { getCookie, setCookie } from 'tiny-cookie';
import { RootModule } from '@jtnews/core';
import { PagesType } from '@jtnews/shared/data';

type AdvData = {
  data: Creative | string;
  path: string;
  settings: {
    place: string;
    placeId: number;
    sticky: boolean;
    subPlace: string;
    types: {
      desktop: boolean;
      laptop: boolean;
      mobile: boolean;
      tablet: boolean;
    };
  };
  type: string;
} | null;

@Component({
  name: 'AdvFullscreen',
  components: {
    LazyHydrate,
    AdvCreative
  }
})
export default class AdvFullscreen extends Vue {
  @Prop({
    required: true,
    type: Object
  })
  adv: AdvData;

  store = useStore<RootModule>(this.$store);

  disabledFullscreenCookie = 'jtnews_fullscreen_disabled';
  conterFullscreenCookie = 'jtnews_fullscreen_counter';
  maxDisplayCount = 2;
  timeOutFullscreen = 10800000; // 3h

  checkLayoutCode = `
          var manager = await window.hsmCreativeManagerReady;
          await manager.geoReady;

          if (manager.configManager.layout === 'INTERNATIONAL') {
            return;
          }
    `;

  get checkInternationalLayout() {
    return this.store.pageType === PagesType.Record && this.store.regionId === 1077;
  }

  get defaultPartnerStatId() {
    return this.store.pageType === PagesType.Record ? 1001 : 0;
  }

  get defaultPuid54() {
    return this.store.pageType === PagesType.Record ? 'ump_a' : '';
  }

  get advData() {
    if (this.isScroogeTest) {
      return `
          <div id="fullscreen"></div>
          <script>
          (async () => {
            ${this.checkInternationalLayout ? this.checkLayoutCode : ''}

            var adfoxSettings = window.SDK_SETTINGS?.adfox || {};
            var partnerStatId = adfoxSettings['partner-stat-id'] || ${
              this.defaultPartnerStatId
            };
            var puid54 = adfoxSettings.puid54 || '${this.defaultPuid54}';
            var hasBlockerCookie = document.cookie.includes('${
              this.disabledFullscreenCookie
            }');
            var urlSearchParams = new URLSearchParams(window.location.search);
            var hasBlokedAdunitsParam = urlSearchParams.get('blockedAdunits')?.includes('fullscreen')|| urlSearchParams.get('blockedAdunits')?.includes('all');
            var isPushReferrer = urlSearchParams.get('utm_source') === 'push';
            var unallowedAdvReferrers = ['iframe-toloka.com', 'raterhub.com', 'iframe-tasks.yandex', 'iframe-yang.yandex', 'yandex-team.ru'];
            var referrer = new URLSearchParams(window.location.search).get('_testReferrer') || document.referrer;
            var isUnallowedAdvReferrer = unallowedAdvReferrers.some(domain => referrer.includes(domain));

            if (!hasBlockerCookie && !isPushReferrer && !hasBlokedAdunitsParam && !isUnallowedAdvReferrer) {
              const initFullscreenScript = (() => {
                return window.yaContextCb.push(()=>{
                    Ya.adfoxCode.create({
                      ownerId: 320882,
                      containerId: 'fullscreen',
                      fullscreenBannerBlacklist: ['banner.transfer'],
                      type: 'fullscreen',
                      params: {
                        p1: "${this.adv?.data?.adfoxConfig?.p1}",
                        p2: "${this.adv?.data?.adfoxConfig?.p2}",
                        'partner-stat-id': partnerStatId,
                        puid54
                      },
                      onError: function(error) { console.warn('[Inline Fullscreen] ошибка отрисовки', error); },
                      onRender: function() { console.log('[Inline Fullscreen] отрисован'); },
                      onStub: function() { console.warn('[Inline Fullscreen] заглушка'); },
                    });
                  });
                });

              if (${this.store.regionId} === 75) {
                setTimeout(() => {
                  initFullscreenScript();
                }, 1000);
              } else {
                initFullscreenScript();
              }
            }
          })();
          <\/script>
        `;
    }
    return this.adv.data;
  }

  get placeId() {
    return this.adv.settings.placeId;
  }

  get isScroogeTest() {
    return this.store.layoutModule.isScroogeTest;
  }

  setDisabledFullscreenCookie() {
    const date = new Date(Date.now() + this.timeOutFullscreen);
    setCookie(this.disabledFullscreenCookie, 'true', {
      expires: date.toUTCString()
    });
  }

  private async checkFullscreenOnScrooge() {
    const manager = await window.hsmCreativeManagerReady;
    await manager.geoReady;

    if (manager.configManager.layout === 'INTERNATIONAL') {
      return;
    }

    const disabledFullscreen = getCookie(this.disabledFullscreenCookie);
    const fullscreenCounter = getCookie(this.conterFullscreenCookie);

    if (!guardUnspecified(disabledFullscreen)) {
      const counter = Number(fullscreenCounter) + 1;
      const date = new Date(Date.now() + this.timeOutFullscreen);
      setCookie(this.conterFullscreenCookie, String(counter), {
        expires: date.toUTCString()
      });

      if (counter >= this.maxDisplayCount) {
        this.setDisabledFullscreenCookie();
      }
    }
  }

  mounted() {
    if (this.store.route.query?.utm_source === 'push') {
      this.setDisabledFullscreenCookie();
    }

    if (this.isScroogeTest) {
      void this.checkFullscreenOnScrooge();
    }
  }
}
