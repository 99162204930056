type HoroscopePageType = typeof import('./pages/horoscope.page');

export const HOROSCOPE_ROUTING = [
  {
    name: 'horoscope',
    path: '/horoscope/:period(daily|tomorrow|weekly)/',
    component: (): Promise<HoroscopePageType> =>
      import(/* webpackChunkName: "horoscope-page" */ './pages/horoscope.page')
  }
];
