import { BehaviorSubject } from 'rxjs';

type SearchBarState = 'close' | 'open';

const initialState: SearchBarState = 'close';

export class SearchBarService {
  // eslint-disable-next-line
  private _state: BehaviorSubject<SearchBarState> = new BehaviorSubject(initialState);

  // eslint-disable-next-line
  public get state() {
    return this._state;
  }

  // eslint-disable-next-line
  public changeState(state: SearchBarState) {
    this._state.next(state);
  }
}

export const searchBarService = new SearchBarService();
