import {
  initSentryClient as _initSentryClient,
  capture as _capture,
  checkAccessToSendError as validateError
} from '@portal/sentry-client';
import { guardEmptyString } from '@portal/utils/util-guards';

const isClient = process.env.VUE_ENV !== 'server';
const isProduction = process.env.NODE_ENV === 'production';

const HYDRATE_ERROR_MESSAGE =
  'The client-side rendered virtual DOM tree is not matching server-rendered content';

const SENTRY_DSN =
  isClient && isProduction
    ? `${window.location.protocol}//${window.location.host}/_report/13`
    : '';

export const capture =
  (message: string, include: string[] = [], exclude: string[] = []) =>
  (error: Error): void => {
    if (!guardEmptyString(SENTRY_DSN)) {
      return;
    }
    if (validateError(error, include, exclude)) {
      try {
        _capture(error, message);
      } catch (captureError) {
        // eslint-disable-next-line no-console
        console.error('SentryClient: ', captureError);
      }
    }
  };

export const initSentryClient = (): void => {
  try {
    if (guardEmptyString(SENTRY_DSN)) {
      _initSentryClient({
        dsn: SENTRY_DSN,
        useProxy: true,
        captureUnhandledErrors: false
      });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('SentryClient: ', error);
  }
};

export const captureHydrateError = capture('Hydrate error', [HYDRATE_ERROR_MESSAGE]);
