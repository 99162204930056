import { Regions } from '@smh/projects/regions';
import Vue from 'vue';
import { componentFactory } from 'vue-tsx-support';

type Logos = 'base' | 'ny' | 'award';

type PropsData = {
  type: Logos;
  isMobile: boolean;
  regionId: Regions;
};

export default componentFactory.create({
  name: 'LogoSvg',
  props: {
    type: {
      // обычные, новогодние, траурные и т.п.
      type: String as () => Logos,
      default: 'base',
    },
    isMobile: {
      type: Boolean,
      require: true,
    },
    regionId: {
      type: Number as () => Regions,
      require: true,
    },
  },
  beforeCreate() {
    const { regionId, isMobile, type = 'base' } = this.$options.propsData as PropsData;
    Vue.component(
      `logo-${regionId}`,
      () => import(`./${type}/${regionId}${isMobile ? '-mobile' : ''}.tsx`),
    );
  },
  render() {
    const COMPONENT = `logo-${this.regionId}`;
    return <COMPONENT />;
  },
});
