import { Getter } from 'vuex-simple';

import { ConfigurationSettings } from '@jtnews/shared/newsapi/base';

import { RecordUrls } from '../../../shared/models/record';
import { getBlockWithParams } from '../../../shared/utils/store/getBlockWithParams';
import { RootModule } from '../store';

type BlockWithParams = {
  params?: { title: string };
  path: string;
  settings: ConfigurationSettings;
};

type TelegramButtonBlock = {
  path: 'telegram_theme_subscription';
  data: {
    link: string;
    title: string;
  };
};

type SearchResultData = {
  commentsCount: number;
  formats: {
    id: number;
    name: string;
    url: string;
  }[];
  hasVideoMark: boolean;
  header: string;
  id: number;
  isCommentsAllowed: boolean;
  isCommercial: boolean;
  mainPhoto: {
    author: string;
    description: string;
    fileMask: string;
    height: number;
    id: string;
    sourceName: string;
    type: string;
    url: string;
    width: number;
  };
  publishAt: string;
  rubrics: {
    buildPath: string;
    id: number;
    name: string;
    path: string;
  }[];
  subheader: string;
  themes: {
    description: string;
    id: number;
    name: string;
    path: string;
    url: string;
  }[];
  urls: RecordUrls;
  viewsCount: number;
};

type SearchResultBlock = BlockWithParams & {
  meta: { themes: unknown[]; total: number };
  data: SearchResultData[];
};

type ThemeBlock = BlockWithParams & {
  data: {
    description: string;
    id: number;
    name: string;
  };
};

type NotificationsResultBlock = BlockWithParams & {
  meta: { total: number };
  data: {
    datetime: string;
    id: number;
    link: string;
    text: string;
    timestamp: number;
    title: string;
  };
};

export class ArchiveModule {
  public configName = 'content';

  constructor(private _root: RootModule) {}

  @Getter()
  public get searchResultBlock(): SearchResultBlock {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'search_result',
      ['meta']
    ) as SearchResultBlock;
  }
  @Getter()
  public get themeBlock(): ThemeBlock {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'theme'
    );
  }
  @Getter()
  public get notificationsResultBlock(): NotificationsResultBlock {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'notifications_result',
      ['meta']
    ) as NotificationsResultBlock;
  }
  @Getter()
  public get telegramBtn(): TelegramButtonBlock {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'telegram_theme_subscription'
    );
  }
}
