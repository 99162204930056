<template>
  <div
    v-if="hasInfo"
    :class="$style.widgetWeather"
    @mouseenter="renderDropdown = true"
    @mouseleave="renderDropdown = false"
  >
    <jtn-ui-informer-mini
      v-if="!miniWidget"
      :has-icon="true"
      :class="$style.informerMini"
      data-test="widget-weather"
    >
      <template slot="icon">
        <span :class="$style.weatherIcon">
          <img :src="weatherIcon" alt="" />
        </span>
      </template>
      <template slot="text">
        <span :class="$style.mobileHidden">СЕЙЧАС </span>
        <span>{{ temperature }}°С</span>
      </template>
    </jtn-ui-informer-mini>

    <jtn-ui-informer-mini
      v-else
      :type="info.more ? 'link' : 'block'"
      :has-icon="true"
      :href="info.more"
      target="_blank"
      :class="$style.informerMini"
      data-test="widget-weather"
      @click="sendWeatherCTR($event)"
    >
      <template slot="icon">
        <span :class="$style.weatherIcon">
          <img :src="weatherIcon" alt="" />
        </span>
      </template>
      <template slot="text">
        <span :class="$style.mobileHidden">СЕЙЧАС </span>
        <span>{{ temperature }}°С</span>
      </template>
    </jtn-ui-informer-mini>

    <transition name="fade" mode="out-in" :duration="{ enter: 300, leave: 100 }">
      <jtn-ui-informer-dropdown
        v-if="!miniWidget && renderDropdown"
        :title="'Сейчас в ' + info.city_prepositional"
        :btn="{ href: info.more, title: 'Подробнее' }"
        :clickable="Boolean(info.more)"
        :class="$style.informerDropdown"
        data-test="widget-weather-dropdown"
        @click="sendWeatherCTR($event)"
      >
        <div slot="content">
          <div :class="$style.weatherDetails">
            <span :class="[$style.weatherIcon, $style.big]">
              <img :src="weatherIcon" alt="" />
            </span>
            <jtn-ui-typography
              :class="$style.weatherDegrees"
              tag="p"
              type="font-article-quote"
            >
              {{ temperature }}°
              <svg
                fill="currentColor"
                :width="7"
                :height="12"
                :class="[
                  $style.arrow,
                  $style[info.temperature_trend === 1 ? 'rotate' : '']
                ]"
              >
                <use xlink:href="jtn-weather-util-arrow-down"></use>
              </svg>
            </jtn-ui-typography>
            <jtn-ui-typography
              :class="$style.weatherInfo"
              tag="p"
              type="font-vijet-type-3"
            >
              <span v-if="info.cloud" :class="$style.weatherType">
                {{ info.cloud.title }}
              </span>
              <span>Ощущается как {{ temperatureFeelLike }}</span>
            </jtn-ui-typography>
          </div>
          <div :class="$style.weatherFallout">
            <div :class="$style.weatherFalloutItem">
              <svg
                fill="currentColor"
                :width="32"
                :height="22"
                :class="`${windDirection}`"
              >
                <use xlink:href="jtn-weather-util-wind"></use>
              </svg>
              <jtn-ui-typography tag="div" type="font-vijet-type-3">
                {{ wind.speed }} м/с
                <span v-if="wind.direction"> , {{ wind.direction.title }} </span>
              </jtn-ui-typography>
            </div>
            <div :class="$style.weatherFalloutItem">
              <svg fill="currentColor" :width="24" :height="24">
                <use xlink:href="jtn-weather-util-pressure"></use>
              </svg>
              <jtn-ui-typography tag="div" type="font-vijet-type-3">
                {{ info.pressure }}мм
              </jtn-ui-typography>
            </div>
            <div :class="$style.weatherFalloutItem">
              <svg fill="currentColor" :width="14" :height="24">
                <use xlink:href="jtn-weather-util-wet"></use>
              </svg>
              <jtn-ui-typography tag="div" type="font-vijet-type-3">
                {{ info.humidity }}%
              </jtn-ui-typography>
            </div>
          </div>
        </div>
      </jtn-ui-informer-dropdown>
    </transition>
  </div>
</template>

<script>
import {
  JtnUiTypography,
  JtnUiInformerDropdown,
  JtnUiInformerMini
} from '@jtnews/jtn-ui';

import CloudySvg from 'jtn-ui/svg/weather/cloudy.svg';
import CloudyRainSvg from 'jtn-ui/svg/weather/cloudy-rain.svg';
import CloudyStormSvg from 'jtn-ui/svg/weather/cloudy-storm.svg';
import CloudySnowSvg from 'jtn-ui/svg/weather/cloudy-snow.svg';
import MostlyCloudySvg from 'jtn-ui/svg/weather/mostly-cloudy.svg';
import MostlyCloudyRainSvg from 'jtn-ui/svg/weather/mostly-cloudy-rain.svg';
import MostlyCloudySnowSvg from 'jtn-ui/svg/weather/mostly-cloudy-snow.svg';
import MostlyCloudyStormSvg from 'jtn-ui/svg/weather/mostly-cloudy-storm.svg';
import SunSvg from 'jtn-ui/svg/weather/sun.svg';
import SunCloudSvg from 'jtn-ui/svg/weather/sun-cloud.svg';
import SunCloudRainSvg from 'jtn-ui/svg/weather/sun-cloud-rain.svg';
import SunCloudSnowSvg from 'jtn-ui/svg/weather/sun-cloud-snow.svg';
import SunCloudStormSvg from 'jtn-ui/svg/weather/sun-cloud-storm.svg';
import MoonSvg from 'jtn-ui/svg/weather/moon.svg';
import MoonCloudSvg from 'jtn-ui/svg/weather/moon-cloud.svg';
import MoonCloudRainSvg from 'jtn-ui/svg/weather/moon-cloud-rain.svg';
import MoonCloudSnowSvg from 'jtn-ui/svg/weather/moon-cloud-snow.svg';
import MoonCloudStormSvg from 'jtn-ui/svg/weather/moon-cloud-storm.svg';

import { StoreMixin } from '../../mixins';

const WEATHER_SVG_MAP = {
  ['cloudy']: CloudySvg,
  ['cloudy-rain']: CloudyRainSvg,
  ['cloudy-snow']: CloudySnowSvg,
  ['cloudy-storm']: CloudyStormSvg,
  ['mostly-cloudy']: MostlyCloudySvg,
  ['mostly-cloudy-rain']: MostlyCloudyRainSvg,
  ['mostly-cloudy-snow']: MostlyCloudySnowSvg,
  ['mostly-cloudy-storm']: MostlyCloudyStormSvg,
  ['sun']: SunSvg,
  ['sun-cloud']: SunCloudSvg,
  ['sun-cloud-rain']: SunCloudRainSvg,
  ['sun-cloud-snow']: SunCloudSnowSvg,
  ['sun-cloud-storm']: SunCloudStormSvg,
  ['moon']: MoonSvg,
  ['moon-cloud']: MoonCloudSvg,
  ['moon-cloud-rain']: MoonCloudRainSvg,
  ['moon-cloud-snow']: MoonCloudSnowSvg,
  ['moon-cloud-storm']: MoonCloudStormSvg
};

export default {
  name: 'WidgetWeather',
  components: {
    JtnUiTypography,
    JtnUiInformerDropdown,
    JtnUiInformerMini,
    CloudySvg,
    CloudyRainSvg,
    CloudySnowSvg,
    CloudyStormSvg,
    MostlyCloudySvg,
    MostlyCloudyRainSvg,
    MostlyCloudySnowSvg,
    MostlyCloudyStormSvg,
    SunSvg,
    SunCloudSvg,
    SunCloudRainSvg,
    SunCloudSnowSvg,
    SunCloudStormSvg,
    MoonSvg,
    MoonCloudSvg,
    MoonCloudRainSvg,
    MoonCloudSnowSvg,
    MoonCloudStormSvg
  },
  mixins: [StoreMixin],
  props: {
    miniWidget: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      renderDropdown: false,
      weatherTypeMap: {
        cloudy_none_day: 'cloudy',
        cloudy_none_night: 'cloudy',
        cloudy_light_rain_day: 'cloudy-rain',
        cloudy_light_rain_night: 'cloudy-rain',
        cloudy_rain_day: 'cloudy-rain',
        cloudy_rain_night: 'cloudy-rain',
        cloudy_light_snow_day: 'cloudy-snow',
        cloudy_light_snow_night: 'cloudy-snow',
        cloudy_snow_day: 'cloudy-snow',
        cloudy_snow_night: 'cloudy-snow',
        cloudy_thunderstorm_day: 'cloudy-storm',
        cloudy_thunderstorm_night: 'cloudy-storm',
        cloudy_rainless_day: 'sun-cloud',

        mostly_cloudy_none_day: 'mostly-cloudy',
        mostly_cloudy_none_night: 'mostly-cloudy',
        mostly_cloudy_light_rain_day: 'mostly-cloudy-rain',
        mostly_cloudy_light_rain_night: 'mostly-cloudy-rain',
        mostly_cloudy_rain_day: 'mostly-cloudy-rain',
        mostly_cloudy_rain_night: 'mostly-cloudy-rain',
        mostly_cloudy_light_snow_day: 'mostly-cloudy-snow',
        mostly_cloudy_light_snow_night: 'mostly-cloudy-snow',
        mostly_cloudy_snow_day: 'mostly-cloudy-snow',
        mostly_cloudy_snow_night: 'mostly-cloudy-snow',
        mostly_cloudy_thunderstorm_day: 'mostly-cloudy-storm',
        mostly_cloudy_thunderstorm_night: 'mostly-cloudy-storm',

        sunshine_none_day: 'sun',
        partly_cloudy_none_day: 'sun-cloud',
        partly_cloudy_light_rain_day: 'sun-cloud-rain',
        sunshine_rain_day: 'sun-cloud-rain',
        sunshine_light_rain_day: 'sun-cloud-rain',
        partly_cloudy_rain_day: 'sun-cloud-rain',
        partly_cloudy_light_snow_day: 'sun-cloud-snow',
        partly_cloudy_snow_day: 'sun-cloud-snow',
        sunshine_light_snow_day: 'sun-cloud-snow',
        sunshine_snow_day: 'sun-cloud-snow',
        partly_cloudy_thunderstorm_day: 'sun-cloud-storm',

        sunshine_none_night: 'moon',
        partly_cloudy_none_night: 'moon-cloud',
        partly_cloudy_light_rain_night: 'moon-cloud-rain',
        partly_cloudy_rain_night: 'moon-cloud-rain',
        sunshine_light_rain_night: 'moon-cloud-rain',
        sunshine_rain_night: 'moon-cloud-rain',
        partly_cloudy_light_snow_night: 'moon-cloud-snow',
        partly_cloudy_snow_night: 'moon-cloud-snow',
        sunshine_light_snow_night: 'moon-cloud-snow',
        sunshine_snow_night: 'moon-cloud-snow',
        partly_cloudy_thunderstorm_night: 'moon-cloud-storm'
      }
    };
  },
  computed: {
    data() {
      return this.store.commonModule.commonWeatherBlock;
    },
    info() {
      return this.data.data || {};
    },
    weatherIcon() {
      const type = this.weatherTypeMap[this.info.icon];
      return WEATHER_SVG_MAP[type];
    },
    temperature() {
      const temp = this.info.temperature || 0;
      return temp >= 0 ? `+${temp}` : `${temp}`;
    },
    temperatureFeelLike() {
      const temp = this.info.temperature_feel_like || 0;
      return temp >= 0 ? `+${temp}` : `${temp}`;
    },
    wind() {
      return this.info.wind || {};
    },
    windDirection() {
      if (this.wind && this.wind.direction && this.wind.direction.value) {
        return this.wind.direction.value;
      }
      return 'north';
    },
    hasInfo() {
      return this.data.data !== undefined;
    }
  },
  methods: {
    sendWeatherCTR(event) {
      this.$emit('sendNewReachGoal');
    }
  }
};
</script>

<style lang="scss" module>
@use '~jtn-ui/z-index';

@import 'styles';

.widgetWeather {
  .informerMini {
    @include on-min-resize($laptop) {
      height: $header-height;
    }
  }

  .informerDropdown {
    z-index: z-index.$above;

    width: 340px;
  }

  :global svg {
    &.north {
      transform: rotate(180deg);
    }

    &.north_east {
      transform: rotate(225deg);
    }

    &.east {
      transform: rotate(270deg);
    }

    &.south_east {
      transform: rotate(315deg);
    }

    &.south {
      transform: rotate(0deg);
    }

    &.south_west {
      transform: rotate(45deg);
    }

    &.west {
      transform: rotate(90deg);
    }

    &.north_west {
      transform: rotate(135deg);
    }
  }

  @include on-min-resize($laptop) {
    position: relative;
    display: inline-block;
  }
}

.mobileHidden {
  @include on-res($mobile) {
    display: none;
  }
}

.weatherDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 6px 0 16px;
  border-bottom: 1px solid $color-surface-100;

  .weatherDegrees {
    margin: 0 25px 0 15px;

    color: $color-surface-600;

    font-weight: normal;
    font-size: 32px;
    white-space: nowrap;

    .arrow {
      position: relative;
      top: 0;
      left: -5px;
      color: #1588e2;

      &.rotate {
        top: -10px;

        transform: rotate(180deg);
      }
    }
  }

  .weatherInfo {
    min-width: 123px;

    letter-spacing: normal;

    span {
      display: block;

      &.weatherType:first-letter {
        text-transform: capitalize;
      }
    }
  }
}

.weatherFallout {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 20px 0 10px;
}

.weatherFalloutItem {
  display: flex;
  align-items: center;

  box-sizing: border-box;
  width: calc(100% / 3);
  padding-right: 10px;

  div {
    display: inline-block;

    margin-left: 8px;

    color: $color-surface-600;

    letter-spacing: normal;
  }

  svg {
    color: $color-surface-400;
  }
}

.weatherIcon {
  & img {
    width: 26px;
    height: 26px;
  }

  &.big img {
    width: 54px;
    height: 54px;
  }
}
</style>
