import { RecordImageInfo, RecordImage } from '../record-image';

export interface RecordGalleryInfo {
  images: RecordImageInfo[];
}

export class RecordGallery implements RecordGalleryInfo {
  public readonly images: RecordImage[] = [];

  constructor(info: RecordGalleryInfo) {
    if (info?.images?.length > 0) {
      info.images.forEach(imageInfo => {
        this.images.push(new RecordImage(imageInfo));
      });
    }
  }
}
