type ProfileModuleType = typeof import('./pages/profile/profile.page');
type ProfileModuleSettingsType = typeof import('./pages/profile-settings/profile-settings.page');

export const PROFILE_ROUTING = [
  // страница профиля комментатора
  {
    path: '/profile/:profileId(\\d+)',
    component: (): Promise<ProfileModuleType> =>
      import(/* webpackChunkName: "profile-page" */ './pages/profile/profile.page')
  },
  // страница настройки профиля комментатора
  {
    path: '/profile/',
    component: (): Promise<ProfileModuleSettingsType> =>
      import(
        /* webpackChunkName: "profile-settings-page" */ './pages/profile-settings/profile-settings.page'
      )
  }
];
