export enum PagesType {
  Static = 'STATIC',
  Main = 'MAIN',
  Record = 'RECORD',
  Longread = 'LONGREAD',
  Comment = 'COMMENT',
  Comments = 'COMMENTS',
  CommentsRules = 'COMMENTS_RULES',
  Profile = 'PROFILE',
  ProfileSettings = 'PROFILE_SETTINGS',
  Search = 'SEARCH',
  Archive = 'ARCHIVE',
  ArchiveByDate = 'ARCHIVE_BY_DATE',
  Format = 'FORMAT',
  Tag = 'TAG',
  Theme = 'THEME',
  Networks = 'NETWORKS',
  Longreads = 'LONGREADS',
  Notifications = 'NOTIFICATIONS',
  Rubric = 'RUBRIC',
  PhotosOfTheDay = 'PHOTOS_OF_THE_DAY',
  VideosOfTheDay = 'VIDEOS_OF_THE_DAY',
  Author = 'AUTHOR',
  Horoscope = 'HOROSCOPE',
  Subscription = 'SUBSCRIPTION',
  ReactivateSubscription = 'REACTIVATE_SUBSCRIPTION',
  Credits = 'CREDITS',
  CookiePolicy = 'COOKIE_POLICY',
  Recommender = 'RECOMMENDER',
  OpinionInfo = 'OPINION_INFO',
  EmailConfirm = 'EMAIL_CONFIRM',
  RegistrationConfirm = 'REGISTRATION_CONFIRM',
  UnsubscribeComments = 'UNSUBSCRIBE_COMMENTS_NOTICES',
  UnsubscribeAutoMailing = 'UNSUBSCRIBE_AUTO_MAILING',
  SoftwareDescription = 'SOFTWARE_DESCRIPTION',
  TermsOfUse = 'TERMS_OF_USE',
  UserManual = 'USER_MANUAL',
  OurAwards = 'OUR_AWARDS',
  NotFound = '404',
}

export const PAGE_NAME: Record<PagesType, string> = {
  [PagesType.Main]: 'Главная',
  [PagesType.Record]: 'Материал',
  [PagesType.Longread]: 'Лонгрид',
  [PagesType.Comment]: 'Комментарий',
  [PagesType.Comments]: 'Комментарии',
  [PagesType.CommentsRules]: 'Правила комментирования',
  [PagesType.Profile]: 'Профиль',
  [PagesType.ProfileSettings]: 'Настройки профиля',
  [PagesType.Search]: 'Поиск',
  [PagesType.Archive]: 'Архив',
  [PagesType.ArchiveByDate]: 'Архив по дате',
  [PagesType.Format]: 'Форматы',
  [PagesType.Tag]: 'Тег',
  [PagesType.Theme]: 'Тема',
  [PagesType.Longreads]: 'Лонгриды',
  [PagesType.Networks]: 'Сетевые материалы',
  [PagesType.Notifications]: 'Нотификации',
  [PagesType.Rubric]: 'Рубрика',
  [PagesType.PhotosOfTheDay]: 'Фото дня',
  [PagesType.VideosOfTheDay]: 'Видео дня',
  [PagesType.Author]: 'Страница автора',
  [PagesType.Horoscope]: 'Гороскопы',
  [PagesType.Subscription]: 'Подписка',
  [PagesType.ReactivateSubscription]: 'Страница реактивации подписки на рассылку',
  [PagesType.Credits]: 'Правила использования материалов',
  [PagesType.CookiePolicy]: 'Политика использования cookies',
  [PagesType.Recommender]: 'Применение рекомендательных технологий',
  [PagesType.OpinionInfo]: 'Как стать автором',
  [PagesType.EmailConfirm]: 'Страница подтверждения e-mail',
  [PagesType.RegistrationConfirm]: 'Страница подтверждения регистрации',
  [PagesType.UnsubscribeComments]: 'Страница отписки от уведомлений о комментариях',
  [PagesType.UnsubscribeAutoMailing]: 'Страница отписки от авторассылки',
  [PagesType.SoftwareDescription]: 'Описание функциональных характеристик ПО',
  [PagesType.TermsOfUse]: 'Условия использования ПО',
  [PagesType.UserManual]: 'Руководство пользователя ПО',
  [PagesType.OurAwards]: 'Наши награды',
  [PagesType.NotFound]: '404',
  [PagesType.Static]: 'Неизвестная страница',
};

export const PAGE_ANALYTICS_NAME: Record<PagesType, string> = {
  [PagesType.Main]: 'main',
  [PagesType.Record]: 'record',
  [PagesType.Longread]: 'longread',
  [PagesType.Comment]: 'comment',
  [PagesType.Comments]: 'comments',
  [PagesType.CommentsRules]: 'commentsrules',
  [PagesType.Profile]: 'profile',
  [PagesType.ProfileSettings]: 'profile-setting',
  [PagesType.Search]: 'search',
  [PagesType.Archive]: 'archive',
  [PagesType.ArchiveByDate]: 'archiveByDate',
  [PagesType.Format]: 'format',
  [PagesType.Tag]: 'tag',
  [PagesType.Theme]: 'theme',
  [PagesType.Longreads]: 'longreads',
  [PagesType.Notifications]: 'notifications',
  [PagesType.Networks]: 'networks',
  [PagesType.Rubric]: 'rubric',
  [PagesType.PhotosOfTheDay]: 'photos',
  [PagesType.VideosOfTheDay]: 'videos',
  [PagesType.Author]: 'author',
  [PagesType.Horoscope]: 'horoscope',
  [PagesType.Subscription]: 'subscription',
  [PagesType.ReactivateSubscription]: 'reactivate-subscription',
  [PagesType.Credits]: 'credits',
  [PagesType.CookiePolicy]: 'cookie-policy',
  [PagesType.Recommender]: 'recommender',
  [PagesType.OpinionInfo]: 'opinionInfo',
  [PagesType.EmailConfirm]: 'email-confirm',
  [PagesType.RegistrationConfirm]: 'registration-confirm',
  [PagesType.UnsubscribeComments]: 'unsubscribe-comments-notices',
  [PagesType.UnsubscribeAutoMailing]: 'unsubscribe-auto-mailing',
  [PagesType.SoftwareDescription]: 'software-description',
  [PagesType.TermsOfUse]: 'terms-of-use',
  [PagesType.UserManual]: 'user-manual',
  [PagesType.OurAwards]: 'our-awards',
  [PagesType.NotFound]: 'not-found',
  [PagesType.Static]: 'default',
};
