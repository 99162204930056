import { Regions } from '@smh/projects/regions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { componentFactory } from 'vue-tsx-support';

import { Logo, injectStylesMixin, tsStoreMixin } from '@jtnews/shared';
import { PagesType } from '@jtnews/shared/data';
import {
  getPageYOffset,
  getElementOffset,
  getScrollDirection
} from '@jtnews/shared/scroll-and-position';

import type { AlertsState } from '../../../core/services/alerts.service';
import { AdvLogoData } from '../../../core/store/adv.types';
import { WidgetAdvHeaderLogo } from '../../../shared/widgets/widget-adv-header-logo';
import AppMenu from '../../components/app-menu/app-menu.component';
import AppWidgets from '../../components/app-widgets/app-widgets.vue';
import { ToolbarMenu } from '../toolbar-menu';

import styles from './sticky-header.styles.scss?module';

type ReachGoalValue = string | Record<string, string>;

type ComponentData = {
  isStickyHeader: boolean;
  disabledHeaderTransform: boolean;
  currentPageOffset: number;
  minTransformValue: number;
  headerTransformValue: number;
  headerPaddingValue: string | 0;
  headerWidth: string;
  destroy$: Subject<unknown>;
  bodyObserver: null | MutationObserver;
  isSmallMobile: boolean;
  isAnimated: boolean;
};

type HeaderStyles = {
  paddingRight: string | 0;
  width: string;
  transform: string;
};

type NewFormatReachGoal = {
  goalName: string;
  actionType: 'Просмотр' | 'Клик';
  prop1?: string;
  prop2?: string;
};

const MOBILE_HEADER_HEIGHT = 150;
const DESKTOP_HEADER_HEIGHT = 56;

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'StickyHeader',
    props: {
      disabledSticky: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        isStickyHeader: false,
        disabledHeaderTransform: false,
        currentPageOffset: 0,
        minTransformValue: 0,
        headerTransformValue: 0,
        headerPaddingValue: 0,
        headerWidth: '100%',
        destroy$: new Subject(),
        bodyObserver: null,
        isSmallMobile: false,
        isAnimated: false
      };
    },
    computed: {
      isMobile(): boolean {
        return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
      },
      regionId(): Regions {
        return this.store.regionId;
      },
      advHeaderLogo(): AdvLogoData | null {
        const data = this.store.advModule.advLogo?.data;
        if (Array.isArray(data) && data.length === 0) {
          return null;
        }
        return this.store.advModule.advLogo?.data as AdvLogoData;
      },
      blockType(): 'Залипающее Меню' | 'Меню' {
        return this.isStickyHeader ? 'Залипающее Меню' : 'Меню';
      },
      headerStyles(): HeaderStyles {
        return {
          paddingRight: this.headerPaddingValue,
          width: this.headerWidth,
          transform:
            this.isVideoPage && this.isMobile
              ? 'none'
              : `translate3d(0, ${this.headerTransformValue}px, 0)`
        };
      },
      logoTitle(): string {
        return this.store.commonModule.logo?.title ?? '';
      },
      isVideoPage() {
        return this.store.pageType === PagesType.VideosOfTheDay;
      }
    },
    watch: {
      disabledSticky(value) {
        if (value) {
          this.resetStickyData();
          window.removeEventListener('scroll', () => this.onScroll(), false);
        } else {
          window.addEventListener('scroll', () => this.onScroll());
        }
      }
    },
    beforeMount() {
      this.destroy$ = new Subject();
      const bodyElement = document.querySelector('body') as HTMLElement;

      this.bodyObserver = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'attributes') {
            if (this.isStickyHeader) {
              this.headerPaddingValue = bodyElement.style.paddingRight || 0;
              this.headerWidth = bodyElement.style.paddingRight
                ? `calc(100% - ${bodyElement.style.paddingRight})`
                : '100%';
            }
          }
        });
      });

      if (this.isMobile) {
        this.store.commonModule.alertsState$
          .pipe(takeUntil(this.destroy$))
          .subscribe((state: AlertsState) => {
            this.disabledHeaderTransform = state.value === 'open';
          });
      }

      const config = { attributes: true, childList: false, characterData: false };
      this.bodyObserver.observe(bodyElement, config);
    },
    mounted() {
      const WIDTH = 375;
      const NEGATIVE_MULTIPLIER = -1;

      this.minTransformValue =
        (this.isMobile ? MOBILE_HEADER_HEIGHT : DESKTOP_HEADER_HEIGHT) *
        NEGATIVE_MULTIPLIER;

      if (!this.disabledSticky) {
        window.addEventListener('scroll', () => this.onScroll());
      }

      if (this.isMobile) {
        this.isSmallMobile = window.innerWidth < WIDTH;

        this.$nextTick(() => {
          window.addEventListener('resize', () => {
            this.isSmallMobile = window.innerWidth < WIDTH;
          });
        });
      }
    },
    beforeDestroy() {
      if (this.destroy$ !== null) {
        this.destroy$.next();
        this.destroy$.unsubscribe();
      }

      if (this.bodyObserver) {
        this.bodyObserver.disconnect();
      }

      if (!this.disabledSticky) {
        window.removeEventListener('scroll', () => this.onScroll(), false);
      }
    },
    methods: {
      sendLogoClickedRG() {
        this.sendNewReachGoal(this.blockType, 'Логотип', 'Логотип', 'clickMenu');
        this.sendNewFormatReachGoal({
          goalName: 'ClickMenuInformer',
          actionType: 'Клик',
          prop1: 'Информеры',
          prop2: 'Логотип'
        });
      },
      sendNewFormatReachGoal({ goalName, actionType, prop1, prop2 }: NewFormatReachGoal) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Меню',
          goalName,
          actionType,
          prop1,
          prop2
        });
      },
      sendNewReachGoal(
        blockType: string,
        fieldType: string,
        valueName: ReachGoalValue,
        goalName: string
      ) {
        this.store.analyticsModule.sendNewReachGoal({
          blockType,
          fieldType,
          valueName,
          goalName,
          willDeprecate: true
        });
      },
      onScroll() {
        const pageYOffset = getPageYOffset();
        const direction = getScrollDirection(pageYOffset, this.currentPageOffset);

        const { top, bottom } = getElementOffset(this.$el as HTMLElement);
        const sticky =
          this.isVideoPage && this.isMobile
            ? pageYOffset > top
            : (direction === 'up' ? top : bottom) < pageYOffset;

        this.isAnimated =
          bottom - this.minTransformValue < pageYOffset || direction === 'up';

        if (!this.disabledHeaderTransform) {
          this.setHeadersTransform(sticky, pageYOffset);
        }

        if (this.headerTransformValue === this.minTransformValue && !this.isMobile) {
          this.store.commonModule.updateAlertsState({
            value: 'close',
            changedBy: 'sticky-header'
          });
        }

        this.store.commonModule.emitHeaderServiceState({
          sticky,
          stickyHeaderHeight: this.headerTransformValue - this.minTransformValue
        });

        this.isStickyHeader = sticky;
        this.currentPageOffset = pageYOffset <= 0 ? 0 : pageYOffset;
      },
      setHeadersTransform(sticky: boolean, pageYOffset: number) {
        if (!this.needToCalculateTransform) {
          return;
        }

        this.headerTransformValue = sticky ? this.calculateTransform(pageYOffset) : 0;
      },
      needToCalculateTransform() {
        return (
          this.headerTransformValue > this.minTransformValue &&
          this.headerTransformValue < 0
        );
      },
      calculateTransform(pageYOffset: number) {
        const direction = getScrollDirection(pageYOffset, this.currentPageOffset);

        if (direction === 'down') {
          return this.minTransformValue;
        }

        return 0;
      },
      resetStickyData() {
        this.isStickyHeader = false;
        this.headerTransformValue = 0;
        this.headerPaddingValue = 0;
        this.headerWidth = '100%';
      }
    },
    render() {
      return (
        <div
          class={[
            styles.stickyHeaderWrap,
            this.isStickyHeader && styles.fixed,
            this.isAnimated && styles.animated
          ]}
          data-ref="sticky-header-wrap"
        >
          <div
            style={this.headerStyles}
            class={[styles.stickyHeader, this.isStickyHeader ? styles.fixed : '']}
            data-test="sticky-header"
          >
            <div class={styles.group}>
              {this.isMobile && (
                <AppMenu
                  class={styles.appMenu}
                  onSendNewReachGoal={(event: string) => {
                    this.sendNewReachGoal('Меню', 'Рубрики', event, 'clickMenu');
                    this.sendNewFormatReachGoal({
                      goalName: 'ClickMenu',
                      actionType: 'Клик',
                      prop1: 'Блок рубрик',
                      prop2: event
                    });
                  }}
                  onClickedFeedbackBtn={() => {
                    this.sendNewReachGoal(
                      'Меню',
                      'Форма связи с редакцией (Меню)',
                      'Открыта',
                      'clickMenu'
                    );
                    this.sendNewFormatReachGoal({
                      goalName: 'ClickMenu',
                      actionType: 'Клик',
                      prop1: 'Блок рубрик',
                      prop2: 'Написать в редакцию'
                    });
                  }}
                />
              )}
              <div class={styles.logoWrap}>
                <Logo
                  class={styles.logo}
                  hasLink={true}
                  onClick={() => this.sendLogoClickedRG()}
                />
              </div>
              {!this.isMobile && (
                <AppWidgets
                  advHeaderLogo={this.advHeaderLogo}
                  onSendNewReachGoal={(event: ReachGoalValue) => {
                    this.sendNewReachGoal(this.blockType, 'Информер', event, 'clickMenu');
                    this.sendNewFormatReachGoal({
                      goalName: 'ClickMenuInformer',
                      actionType: 'Клик',
                      prop1: 'Информеры',
                      prop2: event
                    });
                  }}
                />
              )}

              <ToolbarMenu
                blockType={this.blockType}
                isMobile={this.isMobile}
                isSmallMobile={this.isSmallMobile}
              />
            </div>
          </div>
          {this.advHeaderLogo && this.isMobile && (
            <WidgetAdvHeaderLogo advData={this.advHeaderLogo} />
          )}
        </div>
      );
    }
  });
