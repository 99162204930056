import { guardUnspecified } from '@portal/utils/util-guards';
import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import AdvLabel from './adv-label/adv-label';
import styles from './jtn-ui-image.styles.scss?module';

type Source = {
  srcset: string;
  type?: string;
  media?: string;
};

export enum AspectRatio {
  None = 0,
  Square = 1,
  Classic = 3 / 2,
  Wide = 16 / 9,
  VerticalClassic = 2 / 3,
  VerticalWide = 9 / 16
}

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiImage',
  props: {
    sources: {
      type: Array as () => Source[],
      default() {
        return [];
      }
    },
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    aspectRatio: {
      type: Number as () => AspectRatio,
      default: AspectRatio.None
    },
    loading: {
      type: String as () => 'lazy' | 'eager',
      default: 'lazy'
    },
    decoding: {
      type: String as () => 'async' | 'sync' | 'auto',
      default: 'auto'
    },
    fetchPriority: {
      type: String as () => 'high' | 'low' | 'auto',
      default: 'auto'
    },
    isFullscreen: {
      type: Boolean,
      default: false
    },
    hasCommercialLabel: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isHidden: false
    };
  },
  computed: {
    aspectRatioClass(): string {
      switch (this.aspectRatio) {
        case AspectRatio.Square:
          return styles.aspectRatioSquare;
        case AspectRatio.Classic:
          return styles.aspectRatioClassic;
        case AspectRatio.Wide:
          return styles.aspectRatioWide;
        case AspectRatio.VerticalClassic:
          return styles.aspectRatioVerticalClassic;
        case AspectRatio.VerticalWide:
          return styles.aspectRatioVerticalWide;
        default:
          return '';
      }
    },
    imgWidth(): string | number {
      return this.width ?? '100%';
    }
  },
  methods: {
    getDefaultImage() {
      const attributes = {
        attrs: {
          ...this.$attrs
        },
        on: this.$listeners
      };

      return (
        <img
          src={this.src}
          alt={this.alt}
          loading={this.loading}
          fetchpriority={this.fetchPriority}
          decoding={this.decoding}
          width={this.imgWidth}
          height={this.height}
          onError={() => {
            this.isHidden = true;
          }}
          class={this.isHidden ? styles.hiddenImage : ''}
          {...attributes}
        />
      );
    }
  },
  render() {
    return (
      <div class={styles.wrapper}>
        <picture
          class={[
            styles.imageWrap,
            this.aspectRatioClass,
            this.isFullscreen ? styles.fullScreen : ''
          ]}
        >
          {this.sources.map(source => (
            <source {...{ attrs: source }} />
          ))}
          {guardUnspecified(this.$slots.default)
            ? this.$slots.default
            : this.getDefaultImage()}
        </picture>
        {this.hasCommercialLabel && <AdvLabel class={styles.label} />}
      </div>
    );
  }
});
