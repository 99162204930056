import { smoothScroll } from '@jtnews/shared/smooth-scroll';

type ElementsOffset = {
  top: number;
  bottom: number;
  left: number;
  right: number;
};

export const getPageYOffset = (): number =>
  window.pageYOffset || document.documentElement.scrollTop;

export const getElementOffset = (el: HTMLElement): ElementsOffset => {
  const top = el.getBoundingClientRect().top + getPageYOffset();
  const bottom = el.getBoundingClientRect().bottom + getPageYOffset();
  const { left } = el.getBoundingClientRect();
  const { right } = el.getBoundingClientRect();

  return {
    top,
    bottom,
    left,
    right,
  };
};

export const scrollToElement = (
  el: HTMLElement,
  speed: number,
  offsetData?: number | { up: number; down?: number },
): void => {
  let offset = 0;
  if (typeof offsetData === 'number') {
    offset = offsetData;
  } else if (typeof offsetData !== 'undefined') {
    const pageYOffset = getPageYOffset();
    const currentPageOffset = getElementOffset(el).top;
    const direction: string = getScrollDirection(pageYOffset, currentPageOffset);

    offset = direction === 'down' ? offsetData.up : offsetData.down || 0;
  }

  const elTopCoords: number = getElementOffset(el).top - offset;

  smoothScroll(elTopCoords, speed);
};

export const getScrollDirection = (
  pageYOffset: number,
  currentPageOffset: number,
): string => {
  return pageYOffset > currentPageOffset ? 'down' : 'up';
};
