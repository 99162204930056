import { guardUnspecified } from '@portal/utils/util-guards';
import type { Route } from 'vue-router';

import { HEADER_HEIGHT } from '../constants';

type RoutePosition = { x: number; y: number };
type PositionWithHash = {
  selector: string;
  offset: RoutePosition;
};

const hashStartsWithNumberRE = /^#\d/;

const getPosition = (data: PositionWithHash): RoutePosition | null => {
  const el: HTMLElement | null = hashStartsWithNumberRE.test(data.selector)
    ? document.getElementById(data.selector.slice(1))
    : document.querySelector(data.selector);

  if (!guardUnspecified(el)) {
    return null;
  }

  const { offset } = data;
  const docEl = document.documentElement;
  const docRect = docEl.getBoundingClientRect();
  const elRect = el.getBoundingClientRect();

  return {
    x: elRect.left - docRect.left - offset.x,
    y: elRect.top - docRect.top - offset.y
  };
};

export const scrollToHash = (route: Route): void => {
  let targetPosition: PositionWithHash | null = null;

  if (!guardUnspecified(route.meta)) {
    return;
  }

  if (!route.meta.notScroll) {
    if (!route.hash) {
      return;
    }

    targetPosition = {
      selector: route.hash,
      offset: { x: 0, y: HEADER_HEIGHT }
    };

    const position = getPosition(targetPosition);

    if (!guardUnspecified(position)) {
      route.meta.position = null;
      return;
    }

    route.meta.position = position;

    window.scrollTo(position.x, position.y);
  }
};
