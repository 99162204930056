import { BehaviorSubject } from 'rxjs';

export class Media {
  // eslint-disable-next-line
  private _playingMediaUrl: BehaviorSubject<string> = new BehaviorSubject('');

  // eslint-disable-next-line
  get playingMediaUrl() {
    return this._playingMediaUrl.asObservable();
  }

  // eslint-disable-next-line
  setPlayingMediaUrl(url: string) {
    this._playingMediaUrl.next(url);
  }
}

export const media = new Media();
