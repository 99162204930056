import { Regions } from '@smh/projects/regions';

import { PAGE_NAME, PagesType } from '@jtnews/shared/data';
import { AnalyticsHttpClient } from '@jtnews/shared/newsapi/analytics';

// eslint-disable-next-line
interface GoalValueParams {
  [prop: string]: Record<string, GoalValueParams | string> | string;
}

export type ValueName =
  | string
  | number
  | string[]
  | Record<string, string | GoalValueParams>;

// eslint-disable-next-line
export interface ReachGoalParams {
  regionId: Regions;
  currentUrl: string;
  referrerUrl: string;
  pageType: PagesType;
  blockType?: string;
  fieldType?: string;
  valueName?: ValueName;
  productName?: string;
  goalName: string;
  experiments: string[];
}

export type ReachGoalType = 'new_reachgoal' | 'reachgoal';

export class AnalyticsDataMapper {
  // eslint-disable-next-line
  private readonly _analyticsHttpClient: AnalyticsHttpClient;

  constructor() {
    this._analyticsHttpClient = new AnalyticsHttpClient({
      envType: typeof window !== 'undefined' ? window.jtnConfig.envType : '',
      protocol: 'https'
    });
  }

  public sendClickhouseReachGoal(incomingParams: ReachGoalParams): void {
    const {
      regionId,
      currentUrl,
      referrerUrl,
      pageType,
      blockType,
      fieldType,
      valueName,
      goalName,
      productName = 'Продукт',
      experiments
    } = incomingParams;
    const currentPageType = PAGE_NAME[pageType] || '';
    const paramsByTypes =
      blockType !== undefined && fieldType !== undefined
        ? this._getParamsByTypes(currentPageType, blockType, fieldType, productName)
        : {};

    const goalParamsStartIndex = Object.keys(paramsByTypes).length + 1;
    const goalParams =
      valueName !== undefined ? this._getGoalParams(valueName, goalParamsStartIndex) : {};

    const params = {
      ...paramsByTypes,
      ...goalParams,
      ...this._getExperimentParams(experiments)
    };

    void this._analyticsHttpClient.sendReachGoal(
      regionId,
      decodeURI(currentUrl),
      decodeURI(referrerUrl).trim(),
      goalName,
      params
    );
  }

  // eslint-disable-next-line
  private _getParamsByTypes(
    currentPageType: string,
    blockType: string,
    fieldType: string,
    productName: string
  ) {
    return {
      param1: productName,
      param2: currentPageType,
      param3: blockType,
      param4: fieldType
    };
  }

  // eslint-disable-next-line
  private _getExperimentParams(experiments: string[]): Record<string, string> {
    const experimentParams = {};

    experiments.forEach((exp, index) => {
      experimentParams[`exp${index + 1}`] = exp;
    });

    return experimentParams;
  }

  // eslint-disable-next-line
  private _getGoalParams(
    valueName: ValueName,
    firstParamIndex: number
  ): Record<string, string | number> {
    const plainParams = {};
    const paramValues: (string | number)[] = [];

    const saveNestedParams = (valueName: ValueName) => {
      if (typeof valueName === 'object' && !Array.isArray(valueName)) {
        const [nestedKey] = Object.keys(valueName);
        const nestedParam = valueName[nestedKey];

        paramValues.push(nestedKey);
        saveNestedParams(nestedParam);
      } else if (Array.isArray(valueName)) {
        paramValues.push(...valueName);
      } else {
        paramValues.push(valueName);
      }
    };

    saveNestedParams(valueName);

    paramValues.forEach((value: string | number, index) => {
      plainParams[`param${index + firstParamIndex}`] = value;
    });

    return plainParams;
  }
}

export const analyticsDataMapper = new AnalyticsDataMapper();
