<template>
  <jtn-ui-btn-icon
    :class="[$style['up-button'], $style[hideButton ? 'hidden' : '']]"
    :btn-type="'button-other-type-3'"
    :size="46"
    @click="scrollToTop()"
  >
    <svg width="24" height="24">
      <use xlink:href="jtn-common-arrow-up"></use>
    </svg>
  </jtn-ui-btn-icon>
</template>
<script>
  import { JtnUiBtnIcon } from '@jtnews/jtn-ui';
  import { smoothScroll } from '@jtnews/shared/smooth-scroll';

  import { StoreMixin } from '../../mixins';

  const OFFSET_TOP = 800;
  const DEFAULT_BOTTOM_COORD = 16;
  const MIN_LAPTOP_WIDTH = 600;
  const MAX_LAPTOP_WIDTH = 1280;

  export default {
    name: 'UpButton',
    components: {
      JtnUiBtnIcon
    },
    mixins: [StoreMixin],
    data() {
      return {
        hideButton: true
      };
    },
    computed: {
      regionId() {
        return this.store.regionId;
      },
      isMobile() {
        return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
      }
    },
    mounted() {
      // на случай, если обновили страницу где-то на середине или внизу
      this.showIfNeed();

      window.addEventListener('scroll', this.showIfNeed);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.showIfNeed, false);
      window.removeEventListener('resize', this.recalculateBtnStyles, false);
    },
    methods: {
      showIfNeed() {
        if (window.pageYOffset > OFFSET_TOP) {
          this.hideButton = false;

          if (!this.isMobile) {
            this.recalculateBtnStyles();
            window.addEventListener('resize', this.recalculateBtnStyles);
          }
        } else {
          this.hideButton = true;
          window.removeEventListener('resize', this.recalculateBtnStyles, false);
        }
      },
      recalculateBtnStyles() {
        const stickyAdv = this.getPageFooterStickyAdv();

        if (stickyAdv !== null) {
          const { el: stickyAdvEl, visible: stickyAdvElVisible } = stickyAdv;
          const isUpBtnBottomStyleEmpty = this.$el.style.bottom === '';

          if (this.isLaptopResolution() && stickyAdvElVisible) {
            if (!isUpBtnBottomStyleEmpty) {
              return;
            }

            const stickyAdvHeight = stickyAdvEl.clientHeight;
            this.$el.style.bottom = `${stickyAdvHeight + DEFAULT_BOTTOM_COORD}px`;
            return;
          }
        }

        this.$el.style.bottom = '';
      },
      getPageFooterStickyAdv() {
        const advElement = document.getElementsByClassName('_footer-sticky');
        if (advElement[0] && advElement[0].clientHeight > 20) {
          const el = advElement[0];

          return {
            el,
            visible: el.classList.contains('_show')
          };
        }

        return null;
      },
      isLaptopResolution() {
        return (
          window.innerWidth >= MIN_LAPTOP_WIDTH && window.innerWidth < MAX_LAPTOP_WIDTH
        );
      },
      scrollToTop() {
        // TODO: выпилить и заменить на свою альтернативу
        smoothScroll(0, 300);
      }
    }
  };
</script>
<style lang="scss" module>
  @use '~jtn-ui/z-index';

  @import 'styles';

  .up-button {
    display: none;

    > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @include on-min-resize($tablet) {
      position: fixed;
      right: 16px;
      bottom: 16px;
      z-index: z-index.$fixed;

      display: block;

      box-shadow: 0 1px 15px rgba(0, 0, 0, 0.3);

      cursor: pointer;

      transition: transform 0.15s ease, bottom 1s ease;
      transform: scale(1);

      color: $color-primary;

      &.hidden {
        transform: scale(0);
      }
    }
  }
</style>
