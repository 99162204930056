import { Regions } from '@smh/projects/regions';

import { BaseRecord } from '@jtnews/shared/newsapi/base';
import type { ApiErrorDTO } from '@jtnews/shared/newsapi/dtos';
import { NetworkContentHttpClient } from '@jtnews/shared/newsapi/network-content-http-client';

import type { NetworkContentItem, NetworkContentBlock } from '../domain';

import { createNetworkContentItem } from './network-content-mapper';

type ErrorResponse = {
  response: {
    data: ApiErrorDTO;
  };
};

export class NetworkContentDataMapper {
  private readonly _networkContentHttpClient: NetworkContentHttpClient;

  private readonly _regionId: Regions;

  constructor(envType: string, regionId: Regions) {
    this._regionId = regionId;
    this._networkContentHttpClient = new NetworkContentHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async fetchNetworkContentBlock(): Promise<NetworkContentBlock> {
    let items: NetworkContentItem[] = [];
    let title = '';

    try {
      const result = await this._networkContentHttpClient.getNetworkContentBlock({
        regionId: this._regionId
      });
      items = NetworkContentMapper.toDO(result.data.data);
      title = (result.data?.params?.title as string) || '';
    } catch (err) {
      throw (err as ErrorResponse).response.data;
    }

    return { items, title };
  }
}

export class NetworkContentMapper {
  public static toDO(networkContentDTO: BaseRecord[]): NetworkContentItem[] {
    return networkContentDTO !== null
      ? networkContentDTO.map(record => ({
          ...createNetworkContentItem(record)
        }))
      : ([] as NetworkContentItem[]);
  }
}
