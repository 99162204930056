import { queryById } from '@portal/utils/util-dom';
import {
  guardEmptyString,
  guardUnspecified,
  guardZeroNumber
} from '@portal/utils/util-guards';

import { CaptchaInterface } from './captcha.service';

export class HCaptchaService implements CaptchaInterface {
  private _widgetId: string;
  constructor(private _publicKey: string, private _elementId: string) {}

  public execute(): PromiseLike<string> {
    const widgetId = this._render();

    if (guardEmptyString(widgetId)) {
      this._widgetId = widgetId;
    }

    return new Promise((res, rej) => {
      if (!guardUnspecified(window.hcaptcha)) {
        return rej();
      }

      window.hcaptcha
        .execute(this._widgetId, { async: true })
        .then(({ response }) => {
          return res(response);
        })
        .catch(rej);
    });
  }

  private _render(): string {
    if (!guardUnspecified(window.hcaptcha)) {
      return '';
    }

    const el = queryById(this._elementId);
    if (!guardUnspecified(el)) {
      return '';
    }

    if (guardZeroNumber(el.childNodes.length)) {
      return '';
    }

    return window.hcaptcha.render(el, {
      sitekey: this._publicKey,
      size: 'invisible'
    });
  }
}
