/* eslint-disable */
import { Subject } from 'rxjs';

import { OBSERVER_VISIBILITY_CONFIG } from './config';
import type {
  ObsVisibilityHook,
  ObsVisibilityOptions,
  ObsVisibilityEvent
} from './observerVisibility.types';

/**
 * @deprecated
 */
export class ObserverVisibility {
  private readonly _hooks: ObsVisibilityHook | ObsVisibilityHook[];
  private readonly _customOptions: ObsVisibilityOptions | undefined;
  private readonly _event$: Subject<ObsVisibilityEvent>;
  private readonly _options: ObsVisibilityOptions = {};

  private get _normalizeHooks(): ObsVisibilityHook[] {
    return typeof this._hooks === 'string' ? [this._hooks] : [...this._hooks];
  }

  // eslint-disable-next-line @portal/portal-plugin/observable-dollar-suffix
  get event(): Subject<ObsVisibilityEvent> {
    return this._event$;
  }

  constructor(
    hooks: ObsVisibilityHook | ObsVisibilityHook[],
    customOption?: ObsVisibilityOptions
  ) {
    this._hooks = hooks;
    this._customOptions = customOption;
    this._event$ = new Subject<ObsVisibilityEvent>();
    this._configureOptions();
  }

  getOptions(hook: ObsVisibilityHook): ObsVisibilityOptions[keyof ObsVisibilityOptions] {
    try {
      this._checkHook(hook);

      return this._options[hook];
    } catch (e) {
      console.error(e);
    }
  }

  destroy(): void {
    this._event$.complete();
    this._event$.unsubscribe();
  }

  private _configureOptions(): void {
    this._normalizeHooks.forEach(hook => {
      const customOption =
        (this._customOptions?.[hook] as ObsVisibilityOptions | undefined) ?? {};
      this._options[hook] = Object.assign(
        {},
        OBSERVER_VISIBILITY_CONFIG[hook],
        customOption
      );

      this._options[hook] = {
        ...this._options[hook],
        callback: this._getCallback(hook, this.event)
      };
    });
  }

  private _getCallback(
    hook: ObsVisibilityHook | string,
    event$: Subject<ObsVisibilityEvent>
  ): (value: boolean, entry: IntersectionObserverEntry) => void {
    return function (value: boolean, entry: IntersectionObserverEntry) {
      event$.next({
        hook,
        value,
        target: entry.target,
        isIntersecting: entry.isIntersecting
      });
    };
  }

  private _checkHook(hook: string): void {
    if (!this._options[hook]) {
      throw new Error(`Hook '${hook}' for observer visibility options is not defined`);
    }
  }
}
