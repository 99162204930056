import {
  TABLET_MIN_WIDTH,
  LAPTOP_MIN_WIDTH,
  DESKTOP_MIN_WIDTH
} from '@jtnews/shared/data';

export type DeviceType = 'desktop' | 'laptop' | 'tablet' | 'mobile';

export const getBreakpointByWindowWidth = (windowWidth: number): DeviceType => {
  if (windowWidth >= DESKTOP_MIN_WIDTH) {
    return 'desktop';
  }
  if (windowWidth >= LAPTOP_MIN_WIDTH) {
    return 'laptop';
  }
  if (windowWidth >= TABLET_MIN_WIDTH) {
    return 'tablet';
  }
  return 'mobile';
};
