




import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import type { AdvData } from './adv-mobile-sticky-rsya-body.component';
import AdvMobileStickyRsyaBody from './adv-mobile-sticky-rsya-body.component';
import { ICreativeManager, IStickyRsyaManager } from '../adv-creative';
import { guardUnspecified } from '@portal/utils/util-guards';

const STICKY_RSYA_DELAY = 15 * 1000;
const BOTTOM_OFFSET = 80;

@Component({
  name: 'AdvMobileStickyRsya',
  components: {
    AdvMobileStickyRsyaBody
  }
})
export default class AdvMobileStickyRsya extends Vue {
  @Prop({
    required: true,
    type: Boolean
  })
  isHidden: boolean;

  @Prop({
    default: null,
    type: Object as () => AdvData
  })
  adv: AdvData | null;

  @Prop({
    default: false,
    type: Boolean
  })
  isBottomIntend: boolean;

  stickyRsyaManager: IStickyRsyaManager | undefined = undefined;
  shouldDelaySticky: boolean = true;
  delayTimeout: NodeJS.Timeout | null = null;

  get isStickyDisplayed() {
    return !this.isHidden && !this.shouldDelaySticky;
  }

  get bottomOffset(): number {
    return this.isBottomIntend ? BOTTOM_OFFSET : 0;
  }

  handleStickyRsyaManager() {
    const stickyRsyaManager: IStickyRsyaManager | undefined = (
      (window as any).HSMCreativeManager as ICreativeManager
    )?.stickyRsya;

    if (!guardUnspecified(stickyRsyaManager)) {
      console.error('HSMCreativeManager.stickyRsya is not defined');
      return;
    }

    this.stickyRsyaManager = stickyRsyaManager;

    this.stickyRsyaManager.setBottomOffset(this.bottomOffset);
    this.stickyRsyaManager.setzIndex(1000);
  }

  delaySticky() {
    this.delayTimeout = setTimeout(() => {
      this.shouldDelaySticky = false;
    }, STICKY_RSYA_DELAY);
  }

  @Watch('isBottomIntend')
  onIsBottomIntendChange() {
    this.stickyRsyaManager?.setBottomOffset(this.bottomOffset);
  }

  mounted() {
    this.handleStickyRsyaManager();
    this.delaySticky();
  }

  beforeDestroy() {
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout);
    }
  }
}
