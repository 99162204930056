export default (
  image /* : any */,
  crop /* : boolean */ = true,
  width /* ?: number */,
  height /* ?: number */
) /* : string */ => {
  const replacePattern /* : string */ = '##';

  if (!image || !image.url) {
    return '';
  }

  const { url } = image;

  if (url.indexOf(replacePattern) === -1) {
    return '';
  }

  const cropTemplate = image.sourceName === 'E1Img' ? '' : `${crop ? '_c' : ''}.`;

  // TODO: неправильная логика
  // пофиксить
  if (!width) {
    return url.replace(replacePattern, `_${image.width}_${image.height}${cropTemplate}`);
  }

  if (!height) {
    return url.replace(replacePattern, `_${width}${cropTemplate}`);
  }

  return url.replace(replacePattern, `_${width}_${height}${cropTemplate}`);
};
