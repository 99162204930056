import { clickOutside } from '@portal/utils/util-click-outside';
import { differenceInCalendarDays } from '@portal/utils/util-date';
import { isSameRegion, Regions } from '@smh/projects/regions';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { componentFactory } from 'vue-tsx-support';

import { JtnUiBadge, JtnUiBtnIcon } from '@jtnews/jtn-ui';
import { injectStylesMixin, tsStoreMixin } from '@jtnews/shared';
import type { NotificationBlock } from '@jtnews/shared/newsapi/base';

import type { AlertsState } from '../../../core/services/alerts.service';
import AppMenu from '../../components/app-menu/app-menu.component';
import Notifications from '../../components/header-notifications/notifications.vue';
import { SearchBar } from '../../components/search-bar';
import { SpWidget } from '../../components/sp-widget';
import { UserBar } from '../../components/user-bar';

import styles from './toolbar-menu.styles.scss?module';

type SearchToggleEvent = {
  isShowed: boolean;
  event: Event;
};

type StickyHeaderState = {
  sticky: boolean;
  stickyHeaderHeight: number;
};

type ForumProfileData = {
  id: number;
  login: string;
  email: string;
  photo: string;
  sex: string;
  menu: ForumMenuItem[];
} | null;

type ForumMenuItem = {
  id: string;
  name: string;
  link: string;
  count: number;
  desktop?: boolean;
};

type ComponentData = {
  destroy$: Subject<void>;
  hasNewAlerts: boolean;
  hasNewAlert: boolean;
  alertsOpened: boolean;
  allowClosingAlerts: boolean;
  alertState: {
    value: string;
    changedBy: string;
  };
  showSpWidget: boolean;
  isNotificationsBlockedModalOpen: boolean;
  isNotificationsBlockedModalRendered: boolean;
};

type NewFormatReachGoal = {
  goalName: string;
  actionType: 'Просмотр' | 'Клик';
  prop1?: string;
  prop2?: string;
};

const LOCAL_STORAGE_ALERT_KEY = 'jtn_last_alert_timestamp';
const DAYS_COUNT_FOR_BADGE_AT_ALERTS = 7;
const TOOLBAR_LIST = ['new_topics', 'new_blog_events', 'sp'];
const BUTTON_SIZE = 47;
const ICON_SIZE = 24;

// eslint-disable-next-line @typescript-eslint/naming-convention
const NotificationsBlockedModal = () =>
  import(
    /* webpackChunkName: "browser-notifications-blocked-modal" */ '@jtnews/layout/modals'
  ).then(m => m.BrowserNotificationsBlockedModal);

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'ToolbarMenu',
    props: {
      blockType: {
        type: String,
        default: 'Меню'
      },
      isMobile: {
        type: Boolean,
        default: false
      },
      isSmallMobile: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        alertsOpened: false,
        allowClosingAlerts: true,
        alertState: {
          value: 'close',
          changedBy: 'default'
        },
        destroy$: new Subject(),
        hasNewAlerts: false,
        hasNewAlert: false,
        showSpWidget: false,
        isNotificationsBlockedModalOpen: false,
        isNotificationsBlockedModalRendered: false
      };
    },
    computed: {
      regionId(): Regions {
        return this.store.regionId;
      },
      alerts(): NotificationBlock[] {
        return this.store.notifications || [];
      },
      lastAlertTimestamp(): number {
        return this.alerts[0] ? this.alerts[0].timestamp || 0 : 0;
      },
      isShowBadge(): boolean {
        return this.hasNewAlerts || this.newUserNoticesCount > 0;
      },
      newUserNoticesCount(): number {
        return this.store.commonModule?.newProfileNotices ?? 0;
      },
      envType(): string {
        return this.store.envType;
      },
      isNN(): boolean {
        return isSameRegion(this.regionId, Regions.NizhnyNovgorod);
      },
      isUserBarShown(): boolean {
        return !isSameRegion(this.regionId, Regions.DoubleNsk);
      },
      isNotificationsShown(): boolean {
        return !isSameRegion(this.regionId, Regions.DoubleNsk);
      },
      hasUserForums(): boolean {
        return this.isNN && !this.isMobile;
      },
      forumProfile(): ForumProfileData {
        return this.store.commonModule?.forumProfileData;
      },
      forumMenu(): ForumMenuItem[] {
        return (
          this.forumProfile?.menu.map((item: ForumMenuItem) => ({
            ...item,
            desktop: TOOLBAR_LIST.includes(item.id)
          })) || []
        );
      },
      forumsToolbarMenu(): ForumMenuItem[] {
        return this.forumMenu?.filter((item: ForumMenuItem) => item.desktop);
      },
      isBrowserPermissionPushDenied(): boolean {
        return this.store.pushModule?.isBrowserNotificationsDenied ?? false;
      },
      hasSubscribeToPushBtn(): boolean {
        return (
          this.store.pushModule?.isPushNotificationsReady &&
          !this.store.pushModule?.isPushNotificationsEnabled
        );
      }
    },
    watch: {
      alerts() {
        this.checkReadingAlert(this.lastAlertTimestamp);
      }
    },
    beforeMount() {
      this.checkReadingAlert(this.lastAlertTimestamp);

      this.store.commonModule.alertsState$
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: AlertsState) => {
          this.alertsOpened = state.value === 'open';
          this.alertState = state;

          if (this.alertState.value === 'open' && this.hasNewAlert) {
            this.hasNewAlert = false;
            this.setLastAlert(this.alerts[0]);
          }
        });

      this.store.commonModule.alertsReadingStatus$
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: boolean) => {
          this.hasNewAlerts = !state;
        });

      this.store.commonModule.alertsClosingAllowed$
        .pipe(takeUntil(this.destroy$))
        .subscribe((state: boolean) => {
          this.allowClosingAlerts = state;
        });

      this.store.commonModule?.searchBarState$
        .pipe(
          takeUntil(this.destroy$),
          filter((state: string) => state === 'close')
        )
        .subscribe(() => {
          this.closeSearchBar();
        });

      this.store.commonModule?.headerServiceState$
        .pipe(takeUntil(this.destroy$))
        .subscribe((value: StickyHeaderState) => {
          if (value.sticky && value.stickyHeaderHeight === 0) {
            this.closeSearchBar();
          }
        });
    },
    beforeDestroy() {
      this.destroy$.next();
      this.destroy$.unsubscribe();
    },
    methods: {
      updateBrowserNotificationsSettings() {
        if (this.isBrowserPermissionPushDenied) {
          this.openNotificationsBlockedModal();
        } else {
          this.store.pushModule.showNativePrompt();
        }

        this.$emit('sendNewReachGoal', { ['Уведомления']: 'Включить push-уведомления' });
      },
      openNotificationsBlockedModal() {
        this.isNotificationsBlockedModalOpen = true;
        this.isNotificationsBlockedModalRendered = true;
      },
      setLastAlert(alert: NotificationBlock) {
        try {
          localStorage.setItem(LOCAL_STORAGE_ALERT_KEY, alert.timestamp.toString());
        } catch (exception) {
          console.error(exception);
        }
      },
      checkReadingAlert(lastAlertTimestamp: number) {
        if (lastAlertTimestamp === 0) {
          return;
        }

        try {
          const daysFromLastAlert = differenceInCalendarDays(
            Date.now(),
            lastAlertTimestamp * 1000
          );
          if (daysFromLastAlert > DAYS_COUNT_FOR_BADGE_AT_ALERTS) {
            return;
          }
        } catch (e) {
          return;
        }

        const savedAlertTimestamp = localStorage.getItem(LOCAL_STORAGE_ALERT_KEY);
        if (!savedAlertTimestamp) {
          this.hasNewAlert = true;
          this.store.commonModule.updateAlertsReadingStatus(false);
          return;
        }

        if (lastAlertTimestamp > Number(savedAlertTimestamp)) {
          this.hasNewAlert = true;
          this.store.commonModule.updateAlertsReadingStatus(false);
        }
      },
      sendReachGoal(valueName: string | string[], fieldType: string, goalName: string) {
        this.store.analyticsModule?.sendNewReachGoal({
          blockType: this.blockType,
          fieldType,
          valueName,
          goalName,
          willDeprecate: true
        });
      },
      closeSearchBar() {
        if (typeof this.$refs.searchBar !== 'undefined') {
          (this.$refs.searchBar as InstanceType<typeof SearchBar>).closeSearchBar();
        }
      },
      sendNewFormatReachGoal({ goalName, actionType, prop1, prop2 }: NewFormatReachGoal) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Меню',
          goalName,
          actionType,
          prop1,
          prop2
        });
      },
      toggleSearchBar(data: SearchToggleEvent) {
        if (data.isShowed) {
          this.sendReachGoal('Поиск', 'Пользователь', 'clickMenu');
          this.sendNewFormatReachGoal({
            goalName: 'ClickMenuInformer',
            actionType: 'Клик',
            prop1: 'Информеры',
            prop2: 'Поиск'
          });
        }
        this.store.commonModule?.emitSearchBarState(data.isShowed ? 'open' : 'close');
        this.store.commonModule?.updateAlertsState({
          value: 'close',
          changedBy: 'user-forums'
        });
      },
      navigateToSearchPage(keywords: string) {
        const query = keywords !== '' ? `?keywords=${keywords}` : '';
        window.location.href = `/search/${query}`;
      },
      clickMenuItem(id: string) {
        let value = '';

        if (id === 'sp') {
          value = 'Мои покупки';
          this.toggleSpWidget();
        }
        if (id === 'new_topics') {
          value = 'Слежу за темами';
        }
        if (id === 'new_blog_events') {
          value = 'Подписка на блоги';
        }

        this.sendReachGoal({ [value]: 'Шапка' }, 'Пользователь', 'clickMenu');
      },
      toggleSpWidget() {
        this.showSpWidget = !this.showSpWidget;

        if (this.showSpWidget && this.$refs.spWidget !== null) {
          clickOutside(this.$refs.spWidget[0] as HTMLElement, () => this.closeSpWidget());
        }
      },
      closeSpWidget() {
        this.showSpWidget = false;
      },
      isSp(id: string): boolean {
        return id === 'sp';
      },
      hasBadgeForSp(id: string) {
        return id === 'sp' && this.hasSpNotify;
      },
      hasBadgeWithCount(count: number, id: string) {
        return count > 0 && id !== 'sp';
      },
      getIcon(name: string) {
        switch (name) {
          case 'new_topics':
            return (
              <svg fill="none" width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-eye-small"></use>
              </svg>
            );

          case 'new_blog_events':
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-flag"></use>
              </svg>
            );

          case 'sp':
            return (
              <svg width={ICON_SIZE} height={ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-cart"></use>
              </svg>
            );

          default:
            return '';
        }
      }
    },
    render() {
      return (
        <div>
          <ul class={styles.toolbarMenu}>
            <li class={[styles.item, styles.search]}>
              <SearchBar
                ref="searchBar"
                onSubmitSearch={keywords => this.navigateToSearchPage(keywords)}
                onToggleSearch={data => this.toggleSearchBar(data)}
              />
            </li>
            {
              this.isNotificationsShown && (
                <li
                class={[
                  styles.item,
                  styles.notification,
                  this.isSmallMobile ? styles.hidden : ''
                ]}
              >
                <Notifications
                  isMobile={this.isMobile}
                  isSmallMobile={this.isSmallMobile}
                  alerts={this.alerts}
                  newUserNoticesCount={this.newUserNoticesCount}
                  isShowBadge={this.isShowBadge}
                  alertsOpened={this.alertsOpened}
                  alertState={this.alertState}
                  hasNewAlerts={this.hasNewAlerts}
                  allowClosingAlerts={this.allowClosingAlerts}
                  hasSubscribeToPushBtn={this.hasSubscribeToPushBtn}
                  onSendNewReachGoal={(event: string | string[]) => {
                    this.sendReachGoal(event, 'Пользователь', 'clickMenu');
                  }}
                  onClickedPushNotificationsBtn={() =>
                    this.updateBrowserNotificationsSettings()
                  }
                />
              </li>
              )
            }
            {this.hasUserForums &&
              this.forumsToolbarMenu.map((item: ForumMenuItem) => (
                <li
                  class={[styles.item, styles[item.id]]}
                  refInFor={true}
                  ref={this.isSp(item.id) ? 'spWidget' : ''}
                >
                  <div class={styles.btnWrap}>
                    <JtnUiBtnIcon
                      url={item.link}
                      title={item.name}
                      size={BUTTON_SIZE}
                      btnType="button-other-transparent"
                      target="_blank"
                      data-test={`forums-toolbar-btn-${item.id}`}
                      onClick={() => {
                        this.clickMenuItem(item.id);
                      }}
                    >
                      {this.getIcon(item.id)}
                    </JtnUiBtnIcon>
                    {this.hasBadgeWithCount(item.count, item.id) && (
                      <JtnUiBadge
                        class={[styles.badge, styles.withCount]}
                        content={item.count}
                        size="big"
                      />
                    )}
                    {this.hasBadgeForSp(item.id) && (
                      <JtnUiBadge class={styles.badge} size="small" />
                    )}
                  </div>

                  {this.isSp(item.id) && (
                    <transition
                      name="fade"
                      mode="out-in"
                      duration={{ enter: 500, leave: 100 }}
                    >
                      {this.showSpWidget && (
                        <SpWidget
                          class={styles.spWidget}
                          onClickCloseBtn={() => this.closeSpWidget()}
                        />
                      )}
                    </transition>
                  )}
                </li>
              ))}
            {this.isUserBarShown && (
                <li class={[styles.item, styles.userBar]}>
                  <UserBar
                    isMobile={this.isMobile}
                    isSmallMobile={this.isSmallMobile}
                    newUserNoticesCount={this.newUserNoticesCount}
                    hasUserForums={this.isNN}
                    isShowBadge={this.isShowBadge}
                  />
                </li>
              )}
            {!this.isMobile && (
              <li class={styles.item}>
                <AppMenu
                  onSendNewReachGoal={(event: string | string[]) => {
                    this.sendReachGoal(event, 'Рубрики', 'clickMenu');
                    this.sendNewFormatReachGoal({
                      goalName: 'ClickMenu',
                      actionType: 'Клик',
                      prop1: 'Блок рубрик',
                      prop2: event
                    });
                  }}
                  onClickedFeedbackBtn={() => {
                    this.sendReachGoal(
                      'Открыта',
                      'Форма связи с редакцией (Меню))',
                      'ClickMenu'
                    );
                    this.sendNewFormatReachGoal({
                      goalName: 'ClickMenu',
                      actionType: 'Клик',
                      prop1: 'Блок рубрик',
                      prop2: 'Написать в редакцию'
                    });
                  }}
                />
              </li>
            )}
          </ul>

          {this.isNotificationsBlockedModalRendered && (
            <NotificationsBlockedModal vModel={this.isNotificationsBlockedModalOpen} />
          )}
        </div>
      );
    }
  });
