import { RUBRICS_REGEX_IDS } from '@jtnews/shared/data';

type CommentsRulesPageType = typeof import('./pages/comments-rules.page');
type CommentsPageType = typeof import('./pages/comments.page');
type CommentPageType = typeof import('./pages/comment.page');

export const COMMENTS_ROUTING = [
  // страница правил комментирования
  {
    path: '/comments/rules/',
    component: (): Promise<CommentsRulesPageType> =>
      import(/* webpackChunkName: "comments-rules-page" */ './pages/comments-rules.page'),
    alias: ['/news/rules/']
  },
  // страница комментариев
  {
    path: `/text/:rubricId${RUBRICS_REGEX_IDS}/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/:recordId(\\d+)/comments/`,
    component: (): Promise<CommentsPageType> =>
      import(/* webpackChunkName: "comments-page" */ './pages/comments.page')
  },
  // страница комментария
  {
    path: `/text/:rubricId${RUBRICS_REGEX_IDS}/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/:recordId(\\d+)/comments/:commentId(\\d+)/`,
    component: (): Promise<CommentPageType> =>
      import(/* webpackChunkName: "comment-page" */ './pages/comment.page')
  }
];
