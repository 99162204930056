import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { componentFactory } from 'vue-tsx-support';

import { tsStoreMixin } from '../../../mixins';
import { injectStylesMixin } from '../../../mixins/inject-styles.mixin';
import { AdvCreative } from '../adv-creative';

import styles from './adv-sticky-bottom.styles.scss?module';

const STORAGE_NAME = 'jtnews_close_adv_sticky_bottom';
const ADV_HIDDING_TIME_MS = 24 * 60 * 60 * 1000; // сутки
const SHOW_ADV_SCROLL_POSITION = 300;
const SCROLL_DEBOUNCE_TIME = 20;
const AUTO_SHOW_CLOSE_TIME = 3000;

type AdvData = {
  data: string;
  path: string;
  settings: {
    place: string;
    placeId: number;
    sticky: boolean;
    subPlace: string;
    types: {
      desktop: boolean;
      laptop: boolean;
      mobile: boolean;
      tablet: boolean;
    };
  };
  type: string;
} | null;

type ComponentData = {
  isDisplayed: boolean;
  isVisibleCloseBtn: boolean;
  wrapperWidth: number;
  destroy$: Subject<Event>;
  hasAdv: boolean;
};

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'AdvStickyBottom',
    props: {
      adv: {
        type: Object as () => AdvData,
        required: true as const
      }
    },
    data(): ComponentData {
      return {
        isDisplayed: false,
        isVisibleCloseBtn: false,
        wrapperWidth: 900,
        destroy$: new Subject(),
        hasAdv: false
      };
    },
    computed: {
      isAdblock(): boolean {
        return this.store.isAdblock;
      },
      isDesktop(): boolean {
        return this.store.deviceInfo.isDesktop;
      },
      isBrandingPage(): boolean {
        const { aTemplateBlock } = this.store.advModule;
        return (
          this.isDesktop &&
          !this.isAdblock &&
          typeof (aTemplateBlock?.data as any)?.adfox_branding === 'string'
        );
      }
    },
    watch: {
      isDisplayed(value) {
        const timeout = setTimeout(() => {
          this.isVisibleCloseBtn = true;
        }, AUTO_SHOW_CLOSE_TIME);

        if (!value) {
          clearTimeout(timeout);
        }
      }
    },
    mounted() {
      const valueLS = window.localStorage.getItem(STORAGE_NAME);

      if (Number(valueLS) > new Date().valueOf()) {
        return;
      }

      fromEvent(window, 'scroll')
        .pipe(takeUntil(this.destroy$), debounceTime(SCROLL_DEBOUNCE_TIME))
        .subscribe(() => this.windowScrollHandler());
    },
    beforeDestroy() {
      this.destroy$.next();
      this.destroy$.unsubscribe();
    },
    methods: {
      onClickCloseBtn() {
        this.isDisplayed = false;

        const closeDate = new Date().valueOf() + ADV_HIDDING_TIME_MS;
        window.localStorage.setItem(STORAGE_NAME, String(closeDate));

        this.destroy$.next();
        this.destroy$.unsubscribe();
      },
      windowScrollHandler() {
        const advContent =
          ((this.$refs.advWrapper as HTMLElement)?.children[0] as HTMLElement) || '';

        if (advContent && advContent.offsetWidth > 0) {
          this.wrapperWidth = advContent.offsetWidth;

          const [footer] = document.getElementsByClassName('footer-wrapper');

          if (
            window?.jtnewsAdvEventsInfo?.isStickyTopAdvRendered ||
            this.isUnallowedAdvReferrer()
          ) {
            this.isDisplayed = false;
            this.destroy$.next();
            this.destroy$.unsubscribe();

            return;
          }

          if (footer) {
            const footerRect = footer.getBoundingClientRect();
            const footerTop = footerRect.top - footerRect.height + window.pageYOffset;
            this.isDisplayed =
              window.pageYOffset > SHOW_ADV_SCROLL_POSITION &&
              window.pageYOffset < footerTop;
          } else {
            this.isDisplayed = window.pageYOffset > SHOW_ADV_SCROLL_POSITION;
          }
        }
      },
      isUnallowedAdvReferrer() {
        const unallowedAdvReferrers = [
          'iframe-toloka.com',
          'raterhub.com',
          'iframe-tasks.yandex',
          'iframe-yang.yandex',
          'yandex-team.ru'
        ];
        const referrer =
          new URLSearchParams(window.location.search).get('_testReferrer') ||
          document.referrer;

        return unallowedAdvReferrers.some(domain => referrer.includes(domain));
      },
      onDisplay() {
        this.hasAdv = true;
      }
    },
    render() {
      return (
        <div
          class={[
            styles.adv,
            this.isDisplayed ? styles.isDisplayed : '',
            this.isBrandingPage ? styles.branding : '',
            !this.hasAdv ? styles.hide : ''
          ]}
        >
          <div
            ref="advWrapper"
            class={styles.advWrapper}
            style={`width: ${this.wrapperWidth}px`}
          >
            <AdvCreative adv={this.adv} onDisplay={this.onDisplay} />
            <div class={styles.closeBtnOverlay} />
            {this.isVisibleCloseBtn && (
              <button
                class={styles.closeBtn}
                onClick={() => {
                  this.onClickCloseBtn();
                }}
              >
                <svg width="24" height="24">
                  <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-close"></use>
                </svg>
              </button>
            )}
          </div>
        </div>
      );
    }
  });
