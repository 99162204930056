import { guardEmptyString } from '@portal/utils/util-guards';
import { Regions } from '@smh/projects/regions';
import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin, tsStoreMixin } from '@jtnews/shared';

import { AdvLogoData, AdvLogoDataSignature } from '../../../core/store/adv.types';

import styles from './widget-adv-header-logo.styles.scss?module';

type ComponentData = {
  touchCount: number;
  isShowInformer: boolean;
};

const LOGO_WIDTH = 146;
const LOGO_HEIGTH = 32;
const ADV_SIGNATURE_TEXT: Record<AdvLogoDataSignature, string> = {
  ADV: 'Реклама',
  INFO: 'Информация'
};

export default componentFactory
  .mixin(tsStoreMixin)
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      advData: {
        type: Object as () => AdvLogoData,
        required: true as const
      }
    },
    data(): ComponentData {
      return {
        touchCount: 0,
        isShowInformer: false
      };
    },
    computed: {
      isMobile(): boolean {
        return this.store.deviceInfo.isMobile;
      },
      regionId(): Regions {
        return this.store.regionId;
      },
      logoSrc(): string {
        if (this.isMobile && guardEmptyString(this.advData.logoMobile)) {
          return this.advData.logoMobile;
        }
        return this.advData.logoDesktop;
      },
      logoLink() {
        return this.advData.clickUrl;
      },
      logoInformer() {
        return this.advData.adInfo;
      },
      advMarkText(): string {
        return ADV_SIGNATURE_TEXT[this.advData.signature] || '';
      },
      isAdvMarkInfoSignature(): boolean {
        return this.advData.signature === 'INFO';
      }
    },
    methods: {
      handleLogoClick() {
        window.open(this.logoLink, '_blank');
      },
      handleAdvMarkClick() {
        this.isShowInformer = !this.isShowInformer;
      },
      closeInformer() {
        this.isShowInformer = false;
      },
      handleDocumentClick(e: MouseEvent) {
        const target = e.target as HTMLElement;

        if (!target.matches('[data-click-area]') && !target.closest('[data-informer]')) {
          this.closeInformer();
        }
      }
    },
    mounted() {
      document.addEventListener('click', this.handleDocumentClick);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleDocumentClick);
    },
    render() {
      return (
        <div class={styles.widgetWrapper}>
          <div
            class={[
              styles.widget,
              this.advData.adLabelEnabled ? styles.advBorder : styles.mobileBorder
            ]}
          >
            <span role="button" class={styles.link} onClick={this.handleLogoClick}>
              <img
                class={styles.image}
                src={this.logoSrc}
                width={LOGO_WIDTH}
                height={LOGO_HEIGTH}
                alt={''}
              />
            </span>
            {this.advData.adLabelEnabled && (
              <div
                class={[
                  styles.advMark,
                  this.isAdvMarkInfoSignature ? styles.advMarkInfoSignature : '',
                  this.logoInformer ? styles.clickable : ''
                ]}
              >
                <div
                  class={styles.clickArea}
                  data-click-area=""
                  role="button"
                  onClick={this.handleAdvMarkClick}
                >
                  {this.advMarkText}
                </div>
              </div>
            )}
          </div>
          {this.logoInformer && this.isShowInformer && (
            <div class={styles.informer} data-informer="">
              <span domPropsInnerHTML={this.logoInformer}></span>
              <button class={styles.closeBtn} onClick={this.closeInformer}>
                <svg width="16" height="16">
                  <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-close"></use>
                </svg>
              </button>
            </div>
          )}
        </div>
      );
    }
  });
