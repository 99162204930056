export const getBlockValuesByPath = (blocks, blockPath, fields = []) => {
  const block = blocks[blockPath];
  const result = {
    data: []
  };

  if (block && block.data) {
    result.data = block.data;

    if (fields.length === 0) {
      return result;
    }

    fields.forEach(field => {
      if (block[field]) {
        result[field] = block[field];
      }
    });

    return result;
  }

  return result;
};
