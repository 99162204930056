import { guardEmptyArray, guardUnspecified } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';
import { Regions } from '@smh/projects/regions';

import { JtnUiDropdown, JtnUiBtn, JtnUiTypography } from '@jtnews/jtn-ui';
import { injectStylesMixin, tsStoreMixin } from '@jtnews/shared';

import MobileApps from '../../../shared/components/mobile-apps/mobile-apps.vue';
import { SocialsList } from '../../../shared/components/socials-list';
import { SocialItem } from '../../../shared/models/socials/socials';

import styles from './app-menu.style.scss?module';
import MenuRubrics from './menu-rubrics.component';
import MenuThemes from './menu-themes.component';

type SocialListPayload = {
  event: PointerEvent;
  social: SocialItem;
};

const CLOSE_ICON_SIZE = 20;
const MENU_ICON_SIZE = 24;

// eslint-disable-next-line @typescript-eslint/naming-convention
const FeedbackToEditorsModal = () =>
  import('@jtnews/layout/modals').then(m => m.FeedbackToEditorsModal);

export default componentFactoryOf()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'AppMenu',
    data() {
      return {
        feedbackToEditorsModalParams: { piecePage: 'Хедер' },
        isFeedbackToEditorsModalOpen: false
      };
    },
    computed: {
      isMobile() {
        return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
      },
      isFeedbackButtonEnabled () {
        return this.store.regionId !== Regions.DoubleNsk;
      },
      isSafari() {
        return this.store.deviceInfo.isSafari && this.isMobile;
      },
      rubricsBlock() {
        return this.store.headerModule.headerRubrics;
      },
      themesBlock() {
        return this.store.headerModule.headerThemes;
      },
      mobileAppsBlock() {
        return this.store.commonModule.commonMobileAppsBlock;
      },
      socialsBlock() {
        return this.store.commonModule.commonSocialsBlock;
      },
      hasMobileAppsBlock() {
        return (
          guardUnspecified(this.mobileAppsBlock.data) &&
          guardEmptyArray(this.mobileAppsBlock.data)
        );
      },
      socialBlockWidth() {
        const BUTTON_WIDTH = 176;
        const isTablet = this.store.deviceInfo.isMobile || this.store.deviceInfo.isTablet;
        return {
          width: isTablet
            ? 'auto'
            : `calc(100% - ${this.mobileAppsBlock.data.length * BUTTON_WIDTH}px)`
        };
      }
    },
    methods: {
      toggleMenu(isShownMenu: boolean) {
        if (isShownMenu) {
          this.$emit('sendNewReachGoal', 'Открыл');
        }
      },
      clickFeedbackBtn() {
        this.store.commonModule.updateMenuState({
          value: 'close',
          changedBy: 'header-menu'
        });

        this.isFeedbackToEditorsModalOpen = true;
        this.$emit('clickedFeedbackBtn');
      },
      sendNewReachGoal(event: string) {
        this.$emit('sendNewReachGoal', event);
      },
      changeModalState(value: boolean) {
        this.isFeedbackToEditorsModalOpen = value;
      }
    },
    render() {
      return (
        <JtnUiDropdown
          class={styles.menu}
          disabled={this.isFeedbackToEditorsModalOpen}
          onToggle={isShownMenu => this.toggleMenu(isShownMenu)}
        >
          <template slot="iconOpen">
            <svg width={MENU_ICON_SIZE} height={MENU_ICON_SIZE} class={styles.icon}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-menu"></use>
            </svg>
          </template>
          <template slot="iconClose">
            <svg width={CLOSE_ICON_SIZE} height={CLOSE_ICON_SIZE} class={styles.icon}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-close"></use>
            </svg>
          </template>
          <div
            class={[
              'headerInner',
              styles.headerMenu,
              this.isSafari ? styles['_bottom-indent'] : ''
            ]}
          >
            <div class={styles.content}>
              {this.isMobile && this.isFeedbackButtonEnabled && (
                <div class={[styles.box, styles.boxFeedbackBtnWrap]}>
                  <JtnUiBtn onClick={() => this.clickFeedbackBtn()}>
                    Написать в редакцию
                  </JtnUiBtn>
                </div>
              )}

              <div class={[styles.box, styles.rubrics]}>
                <JtnUiTypography
                  tag="h3"
                  type="font-header-title-rubric"
                  class={[styles.title]}
                >
                  {this.rubricsBlock.params.title}
                </JtnUiTypography>
                <MenuRubrics
                  items={this.rubricsBlock.data}
                  type="header"
                  onSendNewReachGoal={(event: string) => this.sendNewReachGoal(event)}
                />
              </div>

              <div class={[styles.box, styles.themes]}>
                <JtnUiTypography
                  tag="h3"
                  type="font-header-title-rubric"
                  class={[styles.title]}
                >
                  {this.themesBlock.params.title}
                </JtnUiTypography>
                <MenuThemes
                  items={this.themesBlock.data}
                  onSendNewReachGoal={(event: string) => this.sendNewReachGoal(event)}
                />

                {!this.isMobile && this.isFeedbackButtonEnabled && (
                  <div class={styles.feedbackBtnWrap}>
                    <JtnUiBtn onClick={() => this.clickFeedbackBtn()}>
                      Написать в редакцию
                    </JtnUiBtn>
                  </div>
                )}
              </div>
            </div>

            <div class={styles.footer}>
              {!!this.socialsBlock.data?.length && (
                <div class={[styles.box, styles.socials]} style={this.socialBlockWidth}>
                  <JtnUiTypography
                    tag="h3"
                    type="font-header-title-rubric"
                    class={[styles.title]}
                  >
                    {this.socialsBlock.params.title}
                  </JtnUiTypography>
                  <SocialsList
                    socials={this.socialsBlock.data || []}
                    onClickBtn={(event: SocialListPayload) =>
                      this.sendNewReachGoal(event.social.name)
                    }
                  />
                </div>
              )}
              {this.hasMobileAppsBlock && (
                <div class={[styles.box, styles.apps]}>
                  <JtnUiTypography
                    tag="h3"
                    type="font-header-title-rubric"
                    class={[styles.title]}
                  >
                    {this.mobileAppsBlock.params.title}
                  </JtnUiTypography>
                  <MobileApps
                    data={this.mobileAppsBlock}
                    onClickAppBtn={(event: string) => this.sendNewReachGoal(event)}
                  />
                </div>
              )}
            </div>

            <FeedbackToEditorsModal
              isOpen={this.isFeedbackToEditorsModalOpen}
              params={this.feedbackToEditorsModalParams}
              onChangeModalState={(value: boolean) => this.changeModalState(value)}
            />
          </div>
        </JtnUiDropdown>
      );
    }
  });
