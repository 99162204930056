/* eslint-disable */
import { getBlockValuesByPath } from './getBlockValuesByPath';
import { getConfigItemByType } from './getConfigItemByType';

export const getBlockWithParams = (
  config,
  subConfigName,
  blocks,
  blockType,
  fields = []
) => {
  const targetConfig = config[subConfigName];

  let result = {};
  const configItem = getConfigItemByType(targetConfig, blockType);

  if (configItem && configItem.path) {
    result = getBlockValuesByPath(blocks, configItem.path, fields);
  }

  return {
    params: configItem ? configItem.params : {},
    settings: configItem ? configItem.settings : {},
    path: configItem ? configItem.path : '',
    data: result.data,
    ...result
  };
};
