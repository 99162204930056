<template>
  <div
      id="header-alerts"
      ref="headerAlerts"
      :class="$style.headerNotifications"
      :style="{ 'max-height': heightAlertsBlock + 'px' }"
  >
    <div :class="$style.notificationsWrap">
      <jtn-ui-typography
          :class="$style.notificationsTitle"
          type="font-vijet-top-5-number"
      >
        Уведомления
      </jtn-ui-typography>
      <jtn-ui-btn-icon
          v-if="isSmallMobile"
          :width="18"
          :height="18"
          btn-type="button-other-transparent"
          @click="closeDropdown"
      >
        <svg
            :width="18"
            :height="18"
            :class="$style.notificationsIcon">
          <use xlink:href="jtn-critical-close"></use>
        </svg>
      </jtn-ui-btn-icon>
    </div>

    <slot name="userNotifications"/>
    <div :class="$style.notificationsListWrap">
      <div :class="$style.scrollWrap">
        <div v-if="alerts.length > 0" :class="$style['notifications-list']">
            <jtn-ui-notifications-item
                v-for="(alert, index) in alerts"
                :key="`${alert.id}${index}`"
                :publish-date="dateFormatted(alert.datetime)"
                :title="alert.title"
                :url="alert.link || ''"
                :target-url="getTargetLink(alert.link || '')"
                :text="alert.text"
                theme="header"
                class="alert-item"
                data-test="notifications-item"
                @clickedPrimaryBtn="openFeedbackEditors(alert.title)"
                @clickedSecondaryBtn="emitClickedSecondaryBtn()"
            />
        </div>
        <jtn-ui-typography v-else tag="div" type="font-toolbar-notice-txt" class="empty">
          Новых уведомлений нет
        </jtn-ui-typography>

        <jtn-ui-default-btn
            :text="moreTitle"
            url="/text/notifications/"
            class="more-btn"
            btn-type="button-blue-type-3"
            data-test="notifications-more-btn"
            @click="emitClickedMoreBtn"
        />
      </div>
    </div>
    <jtn-ui-btn
        v-if="hasPushSubscribeBtn"
        :class="$style.pushBtn"
        @click="handlePushNotificationsBtnClicked"
    >
      <template slot="left">
        <svg :width="24" :height="24">
          <use xlink:href="jtn-common-push"></use>
        </svg>
      </template>
      Включить уведомления о важных новостях
    </jtn-ui-btn>
  </div>
</template>
<script>
import { Subject } from 'rxjs';
import { dateFormat } from '@portal/utils/util-date';
import { DEFAULT_DATE_FORMAT } from '@jtnews/shared/data';
import { isAbsoluteUrl } from '@portal/utils/util-url';


import {
  JtnUiTypography,
  JtnUiDefaultBtn,
  JtnUiBtn,
  JtnUiNotificationsItem,
  JtnUiBtnIcon
} from '@jtnews/jtn-ui';


export default {
  name: 'NotificationsDropdown',
  components: {
    JtnUiBtn,
    JtnUiDefaultBtn,
    JtnUiBtnIcon,
    JtnUiNotificationsItem,
    JtnUiTypography
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isSmallMobile: {
      type: Boolean,
      default: false
    },
    alerts: {
      type: Array,
      required: true
    },
    alertState: {
      type: Object,
      required: true
    },
    allowClosingAlerts: {
      type: Boolean,
      default: true
    },
    hasPushSubscribeBtn: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      vcoConfig: {
        handler: this.onClickOutside,
        events: ['click']
      },
      allowOutsideClose: false,
      destroy: new Subject(),
      windowHeight: 0,
      maxHeight: 440
    };
  },
  computed: {
    moreTitle() {
      return this.alerts.length > 0 ? 'Еще' : 'Посмотреть все';
    },
    heightAlertsBlock() {
      return this.alerts && this.alerts.length > 0 ? this.maxHeight : 115;
    }
  },
  watch: {
    allowClosingAlerts(value) {
      if (!value) {
        this.allowOutsideClose = false;
      }
    }
  },
  mounted() {
    this.initDropdown();

    if (this.isMobile === true) {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
        this.onResize();
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    this.destroy.next();
    this.destroy.unsubscribe();
  },
  methods: {
    initDropdown() {
      if (
          this.alertState.value === 'open' &&
          window.matchMedia('(max-width: 599px)').matches
      ) {
        this.calcMaxHeight();
      }

      if (
          this.alertState.value === 'open' &&
          this.alertState.changedBy !== 'default' &&
          !this.allowClosingAlerts
      ) {
        this.allowOutsideClose = false;
      } else {
        this.allowOutsideClose = true;
      }
    },
    dateFormatted(date) {
      try {
        return dateFormat(date, DEFAULT_DATE_FORMAT);
      } catch (e) {
        return '';
      }
    },
    onResize() {
      this.windowHeight = document.documentElement.clientHeight;
      if (window.matchMedia('(max-width: 599px)').matches) {
        this.calcMaxHeight();
      }
    },
    openFeedbackEditors(title) {
      this.$emit('updateClosingAllowed', false);
      this.$emit('clickedPrimaryBtn', title);
      this.sendNewReachGoal(
          'Меню',
          'Форма связи с редакцией (Колокольчик)',
          'Открыта',
          'clickMenu'
      );
    },
    sendNewReachGoal(blockType, fieldType, valueName, goalName) {
      this.$emit('sendAnalytics', {
        blockType,
        fieldType,
        valueName,
        goalName
      });
    },
    closeDropdown() {
      this.$emit('updateAlertsState', {
        value: 'close',
        changedBy: 'header-alerts'
      });
    },
    onClickOutside() {
      if (this.allowOutsideClose) {
        this.$emit('updateAlertsState', {
          value: 'close',
          changedBy: 'header-alerts'
        });
      } else {
        this.allowOutsideClose = true;
        if (!this.allowClosingAlerts) {
          this.$emit('updateClosingAllowed', true);
        }
      }
    },
    // eslint-disable-next-line
    calcMaxHeight() {
      if (!this.$refs.headerAlerts) {
        return false;
      }

      this.maxHeight =
          this.windowHeight - this.$refs.headerAlerts.getBoundingClientRect().top;
    },
    getTargetLink(path) {
      return isAbsoluteUrl(path) ? '_blank' : '_self';
    },
    emitClickedSecondaryBtn() {
      this.$emit('clickedSecondaryBtn');
    },
    emitClickedMoreBtn() {
      this.$emit('clickedMoreBtn');
    },
    emitClickedPushNotificationsBtn() {
      this.$emit('clickedPushNotificationsBtn');
    },
    handlePushNotificationsBtnClicked() {
      this.closeDropdown()

      this.emitClickedPushNotificationsBtn()
    }
  }
};
</script>
<style lang="scss" module>
@use '~jtn-ui/z-index';

@import 'styles';

.headerNotifications {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: z-index.$above;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  overflow-y: scroll;

  background-color: $color-midnight-blue;

  :global .alert-item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .notificationsWrap {
    position: sticky;
    top: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 10px 12px;

    background-color: $color-midnight-blue;
  }

  .notificationsTitle {
    color: $color-surface;
  }

  .notificationsIcon {
    color: $color-surface-200;
  }

  .pushBtn {
    position: sticky;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;

    border-radius: 0 !important;
  }

  @include on-min-resize($tablet) {
    right: 0;

    width: 340px;
    height: auto;
  }
}

.notificationsListWrap {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  padding-bottom: 58px;

  &:last-child {
    padding-bottom: 20px;
  }

  @include on-min-resize($tablet) {
    padding-right: 8px;
  }

  @include on-min-resize($laptop) {
    &::-webkit-scrollbar-track {
      background-color: rgba($color-surface, 0.2);
    }

    &::-webkit-scrollbar {
      width: 4px;

      background-color: rgba($color-surface, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-surface;
    }
  }
}

.scrollWrap {
  padding: 0 $mobile-padding;

  color: $color-surface;

  :global .more-btn {
    display: inline-block;

    width: 100%;
    padding: 12px 10px;

    font-size: 11px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }

  @include on-min-resize($laptop) {
    padding: 0 $mobile-padding;
  }
}
</style>
