import { componentFactory } from 'vue-tsx-support';

import { JtnUiTypography, JtnUiBtnIcon } from '@jtnews/jtn-ui';

import { injectStylesMixin } from '../../mixins/inject-styles.mixin';

import styles from './legacy-browser-warning.styles.scss?module';

type ComponentData = {
  hasBlockContent: boolean;
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'LegacyBrowserWarning',
  data(): ComponentData {
    return {
      hasBlockContent: true
    };
  },
  render() {
    const CHROME_LINK = 'https://www.google.com/chrome/';
    const FIREFOX_LINK = 'https://www.mozilla.org/ru/firefox/';
    const OPERA_LINK = 'https://www.opera.com/ru';
    const WARNING_ICON_WIDTH = 54;
    const WARNING_ICON_HEIGHT = 62;
    const CLOSE_ICON_SIZE = 24;

    return (
      <div class={styles.container}>
        {this.hasBlockContent ? (
          <div class={styles.content}>
            <svg stroke-width="2" width={WARNING_ICON_WIDTH} height={WARNING_ICON_HEIGHT}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-attention"></use>
            </svg>

            <section class={styles.textContainer}>
              <JtnUiTypography
                class={[styles.text, styles.title]}
                tag="h2"
                type="font-settings-message-title"
              >
                Простите, ваш браузер не поддерживается
              </JtnUiTypography>
              <JtnUiTypography class={styles.text} tag="p" type="font-settings-message">
                Вы используете непопулярный браузер, поэтому мы не можем гарантировать
                правильную работу сайта.
              </JtnUiTypography>
              <JtnUiTypography class={styles.text} tag="p" type="font-settings-message">
                Рекомендуем установить последнюю версию{' '}
                <a href={CHROME_LINK} target="_blank">
                  Chrome
                </a>
                {', '}
                <a href={FIREFOX_LINK} target="_blank">
                  Firefox
                </a>
                {', '}
                <a href={OPERA_LINK} target="_blank">
                  Opera
                </a>
                {'.'}
              </JtnUiTypography>
            </section>

            <JtnUiBtnIcon
              class={styles.closeBtn}
              btnType="button-other-transparent"
              size={CLOSE_ICON_SIZE}
              onClick={() => {
                this.hasBlockContent = false;
              }}
            >
              <svg width={CLOSE_ICON_SIZE} height={CLOSE_ICON_SIZE}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-close"></use>
              </svg>
            </JtnUiBtnIcon>
          </div>
        ) : null}
      </div>
    );
  }
});
