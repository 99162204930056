import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiBadge } from '@jtnews/jtn-ui';
import { injectStylesMixin } from '@jtnews/shared';

import { SpModal } from '../../containers/modals/sp-modal';

import UserBarSettingsBtn from './user-bar-settings-btn.component';
import styles from './user-bar.styles.scss?module';

type ImageSourceVM = {
  srcset: string;
  sizes?: string;
  type?: string;
  media?: string;
};

type ForumMenuItem = {
  id: string;
  name: string;
  link: string;
  count: number;
  desktop?: boolean;
};

interface Events {
  onForumLogoutClicked: void;
  onForumProfileClicked: void;
  onForumMenuClicked: string;
}

const FORUM_MENU_LIST = ['new_topics', 'new_blog_events', 'sp'];

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'UserBarForum',
    props: {
      isMobile: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: ''
      },
      email: {
        type: String,
        default: ''
      },
      link: {
        type: String,
        default: ''
      },
      avatarData: {
        type: Object as () => { src: string; sources: ImageSourceVM[] },
        default: { src: '', sources: [] }
      },
      sex: {
        type: String,
        default: ''
      },
      menu: {
        type: Array as () => ForumMenuItem[],
        default: []
      }
    },
    computed: {
      bookmarkUrl(): string {
        return `${this.link}?tab=bookmarks`;
      },
      forumMenu(): ForumMenuItem[] {
        return (
          this.menu.map((item: ForumMenuItem) => ({
            ...item,
            desktop: FORUM_MENU_LIST.includes(item.id)
          })) || []
        );
      }
    },
    methods: {
      isGrayBadgeColor(id: string): boolean {
        return id === 'new_topics' || id === 'new_blog_events';
      },
      logout(event: Event) {
        this.$emit('forumLogoutClicked', event);
      },
      openProfile(event: Event) {
        this.$emit('forumProfileClicked', event);
      },
      clickMenuItem(id: string) {
        this.$emit('forumMenuClicked', id);

        if (id === 'sp') {
          this.$modal.show('sp-modal');
        }
      }
    },
    render() {
      return (
        <ul class={styles.sublist}>
          <li class={styles.sublistItem}>
            <UserBarSettingsBtn
              name={this.name}
              email={this.email}
              avatarData={this.avatarData}
              sex={this.sex}
              link={this.link}
              hasSettings={false}
              onProfileClicked={(event: Event) => this.openProfile(event)}
            />
          </li>

          {this.forumMenu.map(item => {
            if (this.isMobile) {
              return (
                <li class={styles.sublistItem}>
                  {item.id === 'sp' ? (
                    <button onClick={() => this.clickMenuItem(item.id)}>
                      <span class={styles.text}>{item.name}</span>
                      <JtnUiBadge class={styles.badge} size="small" />
                    </button>
                  ) : (
                    <a href={item.link} onClick={() => this.clickMenuItem(item.id)}>
                      <span class={styles.text}>{item.name}</span>
                      {item.count > 0 && (
                        <JtnUiBadge
                          class={[
                            styles.badge,
                            this.isGrayBadgeColor(item.id) ? styles.gray : ''
                          ]}
                          content={item.count}
                          size="big"
                        />
                      )}
                    </a>
                  )}
                </li>
              );
            } else if (!item.desktop) {
              return (
                <li class={styles.sublistItem}>
                  <a href={item.link} onClick={() => this.clickMenuItem(item.id)}>
                    <span class={styles.text}>{item.name}</span>
                    {item.count > 0 && (
                      <JtnUiBadge
                        class={[
                          styles.badge,
                          this.isGrayBadgeColor(item.id) ? styles.gray : ''
                        ]}
                        content={item.count}
                        size="big"
                      />
                    )}
                  </a>
                </li>
              );
            }
          })}

          <li class={styles.sublistItem}>
            <button onClick={(event: Event) => this.logout(event)}>Выйти</button>
          </li>

          <SpModal />
        </ul>
      );
    }
  });
