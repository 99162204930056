import { AxiosRequestConfig } from 'axios';

import { Regions, getDomainByRegionId } from '@jtnews/shared/seedwork/bff/domain';

import { BaseHttpClient } from '../base/base-http-client';
import type { BaseConfig } from '../base/base-http-client';

export class AnalyticsHttpClient extends BaseHttpClient {
  private readonly _hitsEvent = '/hits/event/';

  constructor(config: BaseConfig) {
    super(config);
  }

  async sendReachGoal(
    regionId: Regions,
    currentUrl: string,
    referrerUrl: string,
    eventName: string,
    params: Record<string, string | number>,
  ): Promise<void> {
    const host = this.getHost(regionId);
    const url = `${host}${this.v}${this._hitsEvent}`;
    const options: AxiosRequestConfig = {
      withCredentials: true,
      method: 'POST',
      url,
      params: {
        url: currentUrl,
        referrer: referrerUrl,
        eventName,
        ...params,
      },
    };

    await this.httpClient(options);
  }

  protected override getHost(regionId: Regions): string {
    return `${this.protocol}://analytics.${getDomainByRegionId(regionId)}${
      this.envType
    }/`;
  }
}
