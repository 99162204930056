export type SourceNameType = 'NgsStaticImg' | 'E1Img';

export interface RecordImageInfo {
  id: string;
  author: string;
  description: string;
  fileMask: string;
  width: number;
  height: number;
  url: string;
  sourceName: SourceNameType;

  getImageUrl(crop: boolean, width?: number, height?: number): string;
}

export class RecordImage implements RecordImageInfo {
  public readonly id: string;

  public readonly author: string;

  public readonly description: string;

  public readonly fileMask: string;

  public readonly width: number;

  public readonly height: number;

  public readonly url: string;

  public readonly sourceName: SourceNameType;

  private _replacePattern = '##';

  constructor(imageInfo: RecordImageInfo) {
    this.id = imageInfo.id;
    this.author = imageInfo.author;
    this.description = imageInfo.description;
    this.fileMask = imageInfo.fileMask;
    this.width = imageInfo.width;
    this.height = imageInfo.height;
    this.url = imageInfo.url;
    this.sourceName = imageInfo.sourceName;
  }

  getImageUrl(crop = true, width?: number, height?: number): string {
    if (this.url.indexOf(this._replacePattern) === -1) {
      return '';
    }

    const cropTemplate = this.sourceName === 'E1Img' ? '' : `${crop ? '_c' : ''}.`;

    if (height !== undefined) {
      return this.url.replace(this._replacePattern, `_${width}_${height}${cropTemplate}`);
    }

    if (width !== undefined) {
      return this.url.replace(this._replacePattern, `_${width}${cropTemplate}`);
    }

    return this.url.replace(
      this._replacePattern,
      `_${this.width}_${this.height}${cropTemplate}`
    );
  }
}
