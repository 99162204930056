/* eslint-disable @typescript-eslint/naming-convention */
import { guardEmptyArray, guardUnspecified } from '@portal/utils/util-guards';
import { isAbsoluteUrl } from '@portal/utils/util-url';

import { PagesType } from '@jtnews/shared/data';
import {
  getResponsiveImageData,
  ImageAspectRatio,
  ImageDTO,
  ImageVM
} from '@jtnews/shared/images';
import { Format, Rubric, Theme } from '@jtnews/shared/news';

type RecordId = number;

type ThemeVM = {
  url: string;
  text: string;
};

type RubricVM = {
  url: string;
  text: string;
};

type FormatVM = {
  url: string;
  text: string;
};

type WindowItemDTO = {
  id: number;
  commentsCount: number;
  formats?: Format[];
  hasVideoMark: boolean;
  header: string;
  isActiveTranslation: boolean;
  hasMainPhotoCommercialLabel: boolean;
  isCommentsAllowed: boolean;
  isCommercial: boolean;
  mainPhoto?: ImageDTO;
  rubrics: Rubric[];
  subheader: string;
  themes?: Theme[];
  urls: {
    url: string;
    urlCanonical: string;
    urlComments: string;
  };
  viewsCount: number;
  position: number;
  windowVideo?: {
    value: {
      videoId: string;
      profileId: number;
    };
  } | null;
  viewType: 'articles' | 'news';
  types: {
    mobile: boolean;
    tablet: boolean;
    laptop: boolean;
    desktop: boolean;
  };
};

export type WindowBaseItemVM = {
  id: RecordId;
  urls: {
    url: string;
    urlComments: string;
    urlCanonical: string;
  };
  position: number;
  targetUrl: '_blank' | '_self';
  header: string;
  viewsCount: number;
  commentsCount: number;
  hasCommercialLabel: boolean;
  isCommentsAllowed: boolean;
  isActiveTranslation: boolean;
  hasVideoMark: boolean;
  types: {
    mobile: boolean;
    tablet: boolean;
    laptop: boolean;
    desktop: boolean;
  };
};

export type WindowNewsVM = WindowBaseItemVM & {
  viewType: 'news';
  rubrics: Rubric[];
};

export type WindowArticleVM = WindowBaseItemVM & {
  viewType: 'articles';
  isMain: boolean;
  subheader: string;
  theme: ThemeVM | null;
  rubric: RubricVM | null;
  format: FormatVM | null;
  windowVideo: {
    value: {
      videoId: string;
      profileId: number;
    };
  } | null;
  imageData: ImageVM;
};

export class WindowMapper {
  public static getWindowItemVM(
    itemsDTO: WindowItemDTO[],
    pageType: PagesType,
    hasMainItem: boolean,
    isABWindow = false
  ): (WindowArticleVM | WindowNewsVM)[] {
    const isMainPage = pageType === PagesType.Main;

    return itemsDTO.map((item, index) => {
      const itemVM: WindowBaseItemVM = {
        id: item.id,
        position: item.position,
        header: item.header,
        targetUrl: isAbsoluteUrl(item.urls.url) ? '_blank' : '_self',
        hasCommercialLabel: item.hasMainPhotoCommercialLabel,
        urls: {
          url: item.urls.url,
          urlComments: item.urls.urlComments,
          urlCanonical: item.urls.urlCanonical
        },
        viewsCount: item.viewsCount,
        commentsCount: item.commentsCount,
        isCommentsAllowed: item.isCommentsAllowed,
        isActiveTranslation: item.isActiveTranslation,
        hasVideoMark: item.hasVideoMark,
        types: {
          mobile: item.types.mobile ?? false,
          tablet: item.types.tablet ?? false,
          laptop: item.types.laptop ?? false,
          desktop: item.types.desktop ?? false
        }
      };

      if (item.viewType === 'articles') {
        const isMainItem = index === 0 && hasMainItem;
        const hasWindowVideo = isMainPage && guardUnspecified(item.windowVideo);
        const aspectRatio =
          isMainItem && isABWindow ? ImageAspectRatio.Square : ImageAspectRatio.Wide;
        const image = getResponsiveImageData({
          url: item.mainPhoto?.url ?? '',
          width: item.mainPhoto?.width ?? 0,
          hasCommercialLabel: item.mainPhoto?.hasCommercialLabel,
          aspectRatio,
          isLazy: !isMainItem,
          isPreloadImage: isMainItem,
          values: [
            {
              breakpoint: 375,
              width: 375
            },
            {
              breakpoint: 599,
              width: 599
            },
            {
              breakpoint: 899,
              width: 795
            },
            {
              breakpoint: 1279,
              width: isMainItem ? 911 : 445
            },
            {
              breakpoint: 0,
              width: isMainItem ? 666 : 318,
              noMedia: true
            }
          ]
        });

        return {
          ...itemVM,
          isMain: isMainItem,
          viewType: item.viewType,
          subheader: item.subheader,
          hasVideoMark: item.hasVideoMark && !hasWindowVideo,
          windowVideo: hasWindowVideo ? item.windowVideo ?? null : null,
          theme: this._getThemeItem(item.themes ?? []),
          rubric: !guardEmptyArray(item.themes ?? [])
            ? this._getRubricItem(item.rubrics ?? [])
            : null,
          format: this._getFormatItem(item.formats ?? []),
          imageData: {
            src: image.data.src,
            sources: image.data.sources,
            loading: image.data.loading,
            decoding: image.data.decoding,
            aspectRatio: image.data.aspectRatio,
            hasCommercialLabel: image.data.hasCommercialLabel
          }
        };
      }

      return {
        ...itemVM,
        viewType: item.viewType,
        rubrics: item.rubrics
      };
    });
  }

  private static _getThemeItem(themes: Theme[]): ThemeVM | null {
    return guardEmptyArray(themes)
      ? {
          url: themes[0].path,
          text: themes[0].name
        }
      : null;
  }

  private static _getRubricItem(rubrics: Rubric[]): RubricVM | null {
    return guardEmptyArray(rubrics)
      ? {
          url: rubrics[0].path,
          text: rubrics[0].name
        }
      : null;
  }

  private static _getFormatItem(formats: Format[]): FormatVM | null {
    return guardEmptyArray(formats)
      ? {
          url: formats[0].url,
          text: formats[0].name
        }
      : null;
  }
}
