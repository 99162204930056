type SearchPageType = typeof import('./pages/search.vue');

export const SEARCH_ROUTING = [
  // страница поиска
  {
    path: '/search/',
    component: (): Promise<SearchPageType> =>
      import(/* webpackChunkName: "search-page" */ './pages/search.vue')
  }
];
