type ActionResultModuleActionResultMessage =
  typeof import('./pages/action-result-message/action-result-message.page');

export const ACTION_RESULT_ROUTING = [
  // страница подтверждения e-mail
  {
    name: 'email-confirm',
    path: '/profile/email/confirm/',
    component: (): Promise<ActionResultModuleActionResultMessage> =>
      import(
        /* webpackChunkName: "action-result-message-page" */ './pages/action-result-message/action-result-message.page'
      )
  },
  // страница отписки от уведомлений о комментариях
  {
    name: 'unsubscribe-comments-notices',
    path: '/profile/:profileId(\\d+)/comments/:type?/email/unsubscribe/:token',
    component: (): Promise<ActionResultModuleActionResultMessage> =>
      import(
        /* webpackChunkName: "action-result-message-page" */ './pages/action-result-message/action-result-message.page'
      )
  },
  // страница отписки от авторассылки для зарегистрированных
  {
    name: 'unsubscribe-auto-mailing',
    path: '/subscription/register/:subscriberId(\\d+)/unsubscribe/',
    component: (): Promise<ActionResultModuleActionResultMessage> =>
      import(
        /* webpackChunkName: "action-result-message-page" */ './pages/action-result-message/action-result-message.page'
      )
  },
  // страница реактивации подписки на рассылку
  {
    name: 'reactivate-subscription',
    path: '/subscriptions/:subscriberId(\\d+)/reactivation/',
    component: (): Promise<ActionResultModuleActionResultMessage> =>
      import(
        /* webpackChunkName: "action-result-message-page" */ './pages/action-result-message/action-result-message.page'
      )
  },
  // страница подтверждения регистрации
  {
    name: 'registration-confirm',
    path: '/users/confirm/email/',
    component: (): Promise<ActionResultModuleActionResultMessage> =>
      import(
        /* webpackChunkName: "action-result-message-page" */ './pages/action-result-message/action-result-message.page'
      )
  }
];
