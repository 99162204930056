export const ComputedBlockDataMixin = {
  computed: {
    params() {
      if (this.data && this.data.params) {
        return this.data.params;
      }
    },
    items() {
      if (this.data && this.data.data) {
        return this.data.data;
      }

      return [];
    },
    title() {
      return this.params.title;
    },
    titleLink() {
      return this.params.link;
    },
    subtitle() {
      return this.params.subtitle;
    },
    buttonTitle() {
      return this.params.button_title;
    },
    buttonLink() {
      return this.params.button_link;
    },
    buttonData() {
      return {
        text: this.buttonTitle,
        url: this.buttonLink,
        align: 'right'
      };
    }
  },
  methods: {
    reduceTitle(title) {
      if (title.length <= 40) {
        return title;
      }

      const words = title.split(' ');
      let result = '';

      words.forEach(word => {
        if (word.length + result.length <= 36) {
          result += ` ${word}`;
        } else if (result[result.length - 1] === ',') {
          result = result.substring(0, result.length - 1);
        }
      });

      return `${result} ...`;
    },
    formatPrice(price) {
      return `${price.toLocaleString('ru-RU')} руб.`;
    }
  }
};
