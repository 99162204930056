<template>
  <div :class="[$style.mobileApps, isFooter && $style.footerApps]">
    <jtn-ui-btn
      v-for="item of items"
      :key="item.name"
      :class="$style.btn"
      theme="transparent2"
      :href="item.link"
      :title="item.name"
      target="_blank"
      @click="$emit('clickAppBtn', item.name)"
    >
      <div :class="$style.btnContent">
        <svg v-if="item.name === 'android'" :class="$style.icon" width="16" height="16">
          <use xlink:href="jtn-social-android"></use>
        </svg>
        <svg v-if="item.name === 'ios'" :class="$style.icon" width="16" height="16">
          <use xlink:href="jtn-social-ios"></use>
        </svg>
        <svg
          v-if="item.name === 'appgallery'"
          :class="$style.icon"
          width="16"
          height="16"
        >
          <use xlink:href="jtn-social-appgallery"></use>
        </svg>
        <svg v-if="item.name === 'rustore'" :class="$style.icon" width="16" height="16">
          <use xlink:href="jtn-social-rustore"></use>
        </svg>
        <svg v-if="item.name === 'apk'" :class="$style.icon" width="16" height="16">
          <use xlink:href="jtn-social-download"></use>
        </svg>
        <span :class="$style.label">
          {{ LABELS_MAP[item.name] }}
        </span>
      </div>
    </jtn-ui-btn>
  </div>
</template>
<script>
import { JtnUiBtn } from '@jtnews/jtn-ui';

export default {
  name: 'MobileApps',
  components: {
    JtnUiBtn
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          params: {},
          data: []
        };
      }
    },
    isFooter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      LABELS_MAP: {
        android: 'Google Play',
        apk: 'APK android',
        appgallery: 'AppGallery',
        ios: 'App Store',
        rustore: 'RuStore'
      }
    };
  },
  computed: {
    items() {
      return this.data.data;
    }
  }
};
</script>
<style lang="scss" module>
@import 'styles';

.mobileApps {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @include on-min-resize($laptop) {
    flex-direction: row;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 16px;
    width: 100%;
    height: 46px;
    min-width: initial;

    &:last-child {
      margin-right: 0;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    @include on-min-resize($laptop) {
      min-width: 160px;
      max-width: 160px;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  .btnContent {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
  }

  .label {
    width: 100%;

    text-align: center;
  }

  .icon {
    width: 16px;
    height: 16px;

    @include on-min-resize($laptop) {
      width: 24px;
      height: 24px;
    }
  }
}

.footerApps {
  .btn {
    @include on-min-resize($tablet) {
      min-width: 160px;
      max-width: 160px;
      white-space: nowrap;
    }

    @include on-min-resize($desktop) {
      min-width: auto;
    }
  }

  @include on-min-resize($tablet) {
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  @include on-min-resize($desktop) {
    .label {
      display: none;
    }

    .btn {
      margin-right: 12px;
    }
  }
}
</style>
