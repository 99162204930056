<template>
  <div
    :class="[
      $style['responsive-list'],
      isPictureRedesign ? $style['isPictureRedesign'] : '',
      types.desktop ? $style['_desktop'] : '',
      types.laptop ? $style['_laptop'] : '',
      types.tablet ? $style['_tablet'] : '',
      types.mobile ? $style['_mobile'] : ''
    ]"
    v-on="$listeners"
  >
    <text-list-with-dropdown
      v-if="!isMobile"
      :class="[$style['list-dropdown'], $style['themes']]"
      :type="type"
      :items="items"
      @clickedSublistBtn="$emit('showMoreLinks')"
      @clickedLink="$emit('sendNewReachGoal', $event.path)"
    />

    <mobile-text-slider
      v-else
      :type="type"
      :is-picture-redesign="isPictureRedesign"
      :items="items"
      @linkClicked="$emit('sendNewReachGoal', $event)"
    />
  </div>
</template>

<script>
import { StoreMixin } from '../../../mixins/store';
import { ComputedBlockDataMixin } from '../../../mixins/computed-block-data.mixin';

import MobileTextSlider from '../mobile-text-slider';
import { TextListWithDropdown } from '../text-list-with-dropdown';

export default {
  name: 'ResponsiveList',
  components: {
    MobileTextSlider,
    TextListWithDropdown
  },
  mixins: [ComputedBlockDataMixin, StoreMixin],
  props: {
    type: {
      type: String,
      default: 'themes'
    },
    data: {
      type: Object,
      default() {
        return {
          params: {},
          data: []
        };
      }
    }
  },
  computed: {
    isMobile() {
      return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
    },
    types() {
      return this.store.headerModule.headerThemes.settings?.types;
    },
    isPictureRedesign() {
      return this.store.commonModule.isPictureRedesign;
    },
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.responsive-list {
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0;
  &.isPictureRedesign {
    &._desktop {
      height: 48px;
    }
  }
  &._desktop {
    height: 56px;

    @include on-min-resize($desktop) {
      display: flex;
    }
    @include on-res($desktop) {
      display: none;
    }
    @include on-res($tablet) {
      display: none;
    }
    @include on-res($mobile) {
      display: none;
    }
  }

  &._laptop {
    @include on-res($desktop) {
      display: flex;
    }
    @include on-res($tablet) {
      display: none;
    }
    @include on-res($mobile) {
      display: none;
    }
  }

  &._tablet {
    @include on-res($tablet) {
      display: flex;
    }
    @include on-res($mobile) {
      display: none;
    }
  }

  &._mobile {
    @include on-res($mobile) {
      display: flex;
    }
  }
}
</style>
