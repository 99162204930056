import { RecordImageInfo, RecordImage } from '../record-image';

// могут быть много типов, так как могут добавлять видео из вк
// а в вк уже пользователи могут заливать из многих источников
export type VideoSourceName = 'youtube' | 'vk' | 'vimeo'; // и т.д.

export interface RecordVideoInfo {
  author: string;
  description: string;
  height: number;
  width: number;
  previewImage: RecordImageInfo | null;
  sourceName: VideoSourceName;
  url: string;
  urls: {
    big: string;
    original: string;
    small: string;
  };
}

export class RecordVideo implements RecordVideoInfo {
  public readonly author: string;

  public readonly description: string;

  public readonly height: number;

  public readonly width: number;

  public readonly previewImage: RecordImage | null = null;

  public readonly sourceName: VideoSourceName;

  public readonly url: string;

  public readonly urls: {
    big: string;
    original: string;
    small: string;
  };

  constructor(info: RecordVideoInfo) {
    this.author = info.author;
    this.description = info.description;
    if (info.previewImage) {
      this.previewImage = new RecordImage(info.previewImage);
    }
    this.sourceName = info.sourceName;
    this.url = info.url;
    this.urls = info.urls;
    this.height = info.height;
    this.width = info.width;
  }
}
