import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Action, Getter, Mutation, State } from 'vuex-simple';

import { RootModule } from '@jtnews/store';

import type { HotNews, NewsSliderData } from '../domain';
import { HotNewsService, LayoutMapper } from '../services';
import { NewsSliderMapper } from '../services/news-slider-mapper.service';

export const LAYOUT_STORE_NAMESPACE = ['layout_store'];

export class LayoutStore {
  private readonly _defaultHotNewsData: HotNews = {
    data: {
      id: '',
      recordId: '',
      text: '',
      url: '',
      type: 'topic'
    },
    settings: {
      types: {
        mobile: true,
        tablet: true,
        laptop: true,
        desktop: true
      }
    }
  };

  private readonly _hotNewsService: HotNewsService;

  private readonly _destroy$ = new Subject();

  constructor(private readonly _root: RootModule) {
    this._hotNewsService = new HotNewsService(this._root.envType, this._root.regionId);

    const hotNewsData = LayoutMapper.hotNewsToDO(this._root.pageSpec);
    this.setHotNewsData(hotNewsData);

    this._hotNewsService.state$.pipe(takeUntil(this._destroy$)).subscribe(data => {
      this.setHotNewsData(data || this._defaultHotNewsData);
    });

    this._newsSliderData = NewsSliderMapper.newsSliderToDO(this._root.pageSpec);
  }

  @State()
  private _hotNewsData: HotNews;

  @State()
  private readonly _newsSliderData: NewsSliderData | null;

  @Getter()
  public get newsSliderData(): NewsSliderData | null {
    return this._newsSliderData;
  }

  @Getter()
  public get hotNewsData(): HotNews {
    return this._hotNewsData || this._defaultHotNewsData;
  }

  @Action()
  public createWS(): void {
    this._hotNewsService.createWS();
  }

  @Action()
  public closeWS(): void {
    this._hotNewsService.closeWS();
  }

  @Action()
  public beforeDestroy(): void {
    this._destroy$.next();
    this._destroy$.unsubscribe();
  }

  @Mutation()
  public setHotNewsData(data: HotNews): void {
    this._hotNewsData = data;
  }
}
