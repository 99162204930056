import { guardEmptyString } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiBadge, JtnUiAvatar, JtnUiImage } from '@jtnews/jtn-ui';
import { injectStylesMixin } from '@jtnews/shared';

import styles from './user-bar.styles.scss?module';

type AvatarSources = {
  srcset: string;
  sizes?: string;
  type?: string;
  media?: string;
}[];

interface Events {
  onProfileClicked: Event;
  onSettingsClicked: Event;
}

const PROFILE_URL = '/profile/';

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'UserBarSettingsBtn',
    props: {
      name: {
        type: String,
        default: ''
      },
      email: {
        type: String,
        default: ''
      },
      avatarData: {
        type: Object as () => { src: string; sources: AvatarSources },
        default() {
          return {
            src: '',
            sources: []
          };
        }
      },
      sex: {
        type: String,
        default: ''
      },
      link: {
        type: String,
        default: ''
      },
      newUserNoticesCount: {
        type: Number,
        default: 0
      },
      hasSettings: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      hasNotifications(): boolean {
        return this.newUserNoticesCount > 0;
      },
      mainName(): string {
        return this.name ? this.name : this.email;
      },
      hasEmail(): boolean {
        return this.email !== '';
      }
    },
    methods: {
      openProfile(event: Event) {
        this.$emit('profileClicked', event);
      },
      clickToSettings(event: Event) {
        this.$emit('settingsClicked', event);
      }
    },
    render() {
      return (
        <div class={styles.settingsBtn}>
          <a
            href={this.link}
            class={styles.userNameWrap}
            data-test="user-bar-link"
            onClick={(event: Event) => this.openProfile(event)}
          >
            <div class={styles.userAvatarWrap}>
              <JtnUiAvatar sex={this.sex} size={36}>
                {guardEmptyString(this.avatarData.src) && (
                  <JtnUiImage
                    slot="photo"
                    src={this.avatarData.src}
                    sources={this.avatarData.sources}
                    loading="lazy"
                    decoding="async"
                    alt="Фото пользователя"
                  />
                )}
              </JtnUiAvatar>
            </div>
            <div class={styles.userName}>
              <div class={styles.userNicknameWrap}>
                <span class={styles.userNickname}>{this.mainName}</span>
                {this.hasNotifications && (
                  <JtnUiBadge
                    class={styles.userNotifications}
                    content={this.newUserNoticesCount}
                    size="big"
                    data-test="notifications-bt-badge"
                  />
                )}
              </div>
              {this.hasEmail && <span class={styles.userEmail}>{this.email}</span>}
            </div>
          </a>
          {this.hasSettings && (
            <a
              href={PROFILE_URL}
              class={styles.settingsIcon}
              onClick={(event: Event) => this.clickToSettings(event)}
            >
              <svg width={24} height={24}>
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-settings"></use>
              </svg>
            </a>
          )}
        </div>
      );
    }
  });
