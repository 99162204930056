import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../../mixins/inject-styles';
import { JtnUiDefaultBtn } from '../../jtn-ui-default-btn';

import styles from './jtn-ui-notifications-item.styles.scss?module';

interface Events {
  onClickedPrimaryBtn: void;
  onClickedSecondaryBtn: Event;
}

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiNotificationsItem',
    components: {
      JtnUiDefaultBtn
    },
    props: {
      text: {
        type: String,
        default: ''
      },
      url: {
        type: String,
        default: ''
      },
      targetUrl: {
        type: String as () => '_self' | '_blank',
        default: '_self'
      },
      theme: {
        type: String as () => 'default' | 'header',
        default: 'default'
      },
      publishDate: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      }
    },
    computed: {
      btnType(): 'button-white' | 'button-blue-type-3' {
        return this.theme === 'default' ? 'button-white' : 'button-blue-type-3';
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs,
        on: this.$listeners
      };

      return (
        <article class={[styles.notificationsItem, styles[this.theme]]} {...attributes}>
          <div class={styles.col}>
            <span class={styles.date}>{this.publishDate}</span>
          </div>

          <div class={styles.col}>
            <h4 class={styles.title} domPropsInnerHTML={this.title} />
            {this.text ? <div class={styles.text} domPropsInnerHTML={this.text} /> : null}
            <div class={styles.actions}>
              <jtn-ui-default-btn
                text="Ответить"
                onClick={() => {
                  this.$emit('clickedPrimaryBtn');
                }}
              />
              {this.url.length > 0 ? (
                <jtn-ui-default-btn
                  class={styles.btnLink}
                  btn-type={this.btnType}
                  text="Ссылка"
                  url={this.url}
                  target={this.targetUrl}
                  onClick={(event: Event) => {
                    this.$emit('clickedSecondaryBtn', event);
                  }}
                />
              ) : null}
            </div>
          </div>
        </article>
      );
    }
  });
