import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiTypography } from '@jtnews/jtn-ui';
import { injectStylesMixin } from '@jtnews/shared';

import styles from './menu-rubrics.style.scss?module';

type RubricsItem = {
  buildPath: string;
  id: number;
  name: string;
  path: string;
};

export default componentFactoryOf()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'MenuRubrics',
    props: {
      items: {
        type: Array as () => RubricsItem[],
        default: []
      }
    },
    methods: {
      toRubrics(name: string) {
        this.$emit('sendNewReachGoal', name);
      }
    },
    render() {
      return (
        <ul class={[styles.menuRubrics]}>
          {this.items.map(item => (
            <li key={item.id} class={styles.item}>
              <JtnUiTypography
                title={item.name}
                href={item.path}
                class={styles.link}
                tag="a"
                type="font-header"
                link-type="link-white"
                onClick={() => this.toRubrics(item.name)}
              >
                {item.name}
              </JtnUiTypography>
            </li>
          ))}
        </ul>
      );
    }
  });
