import { getResponsiveImageData } from '@jtnews/shared/images';
import { BaseRecord } from '@jtnews/shared/newsapi/base';

import type { NetworkContentItem } from '../domain';

const EMPTY_IMAGE = {
  author: '',
  description: '',
  fileMask: '',
  height: 0,
  id: '',
  sourceName: '',
  url: '',
  width: 0
};

const EMPTY_URLS = {
  url: '',
  urlCanonical: '',
  urlComments: ''
};

export const createNetworkContentItem = (record: BaseRecord): NetworkContentItem => {
  const img = getResponsiveImageData({
    url: record.mainPhoto?.url || '',
    hasCommercialLabel: record.mainPhoto?.hasCommercialLabel,
    width: 400,
    values: [
      {
        breakpoint: 599,
        width: 518
      },
      {
        breakpoint: 0,
        width: 400,
        noMedia: true
      }
    ]
  }).data;

  return {
    commentsCount: record.commentsCount ?? 0,
    header: record.header ?? '',
    id: record.id,
    isCommentsAllowed: record.isCommentsAllowed ?? false,
    mainPhoto: { ...(record.mainPhoto ?? EMPTY_IMAGE), ...img },
    urls: record.urls ?? EMPTY_URLS,
    viewsCount: record.viewsCount ?? 0
  };
};
