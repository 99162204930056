import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';
import { JtnUiBtnIcon } from '../jtn-ui-btn-icon';

import styles from './jtn-ui-search-input.styles.scss?module';

interface Events {
  onResetBtn: Event;
  onSearchIconClicked: Event;
}

const SEARCH_ICON_SIZE = 24;
const CLOSE_ICON_SIZE = 18;
const CLOSE_DARK_ICON_SIZE = 15;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiSearchInput',
    props: {
      value: {
        type: String,
        default: ''
      },
      searchType: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      hasReset: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      resetBtnSize(): number {
        return this.searchType === 'header' ? CLOSE_ICON_SIZE : CLOSE_DARK_ICON_SIZE;
      }
    },
    methods: {
      resetForm(event: Event) {
        this.$emit('resetBtn', event);
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs,
        on: this.$listeners
      };

      return (
        <div class={[styles.jtnUiSearchInput, styles[this.searchType]]}>
          <JtnUiBtnIcon
            class={styles.searchBtn}
            size={SEARCH_ICON_SIZE}
            btnType="button-other-transparent"
            nativeOnClick={(event: Event) => this.$emit('searchIconClicked', event)}
          >
            <svg
              style={{ color: this.searchType === 'header' ? '#fff' : '#37404d' }}
              width={SEARCH_ICON_SIZE}
              height={SEARCH_ICON_SIZE}
            >
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-search"></use>
            </svg>
          </JtnUiBtnIcon>
          <input
            ref="input"
            value={this.value}
            class={styles.input}
            placeholder={this.placeholder}
            {...attributes}
          />
          {this.hasReset && (
            <JtnUiBtnIcon
              class={styles.resetBtn}
              btn-type="button-other-transparent"
              size={this.resetBtnSize}
              nativeOnClick={(event: Event) => this.resetForm(event)}
            >
              <svg
                style={{ color: this.searchType === 'header' ? '#fff' : '#37404D' }}
                width={this.resetBtnSize}
                height={this.resetBtnSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-close"></use>
              </svg>
            </JtnUiBtnIcon>
          )}
        </div>
      );
    }
  });
