import { guardUnspecified } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-btn.styles.scss?module';

type BtnType =
  | 'primary'
  | 'white'
  | 'lightGray'
  | 'transparent1'
  | 'transparent2'
  | 'link'
  | 'transparentDark';
type BtnSize = 'large' | 'small';
type DataAttr = [string, string];

interface Events {
  onClick: Event;
}

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'UiButton',
    props: {
      theme: {
        type: String as () => BtnType,
        default: 'primary'
      },
      btnSize: {
        type: String as () => BtnSize,
        default: 'large'
      },
      isRounded: {
        type: Boolean,
        default: false
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      dataAttrs: {
        type: Array as () => DataAttr[],
        default: () => []
      }
    },
    computed: {
      hasCenter(): boolean {
        return guardUnspecified(this.$slots.default);
      },
      hasLeft(): boolean {
        return guardUnspecified(this.$slots.left);
      },
      hasRight(): boolean {
        return guardUnspecified(this.$slots.right);
      },
      isTextOnly(): boolean {
        return !this.hasLeft && !this.hasRight;
      },
      isIconOnly(): boolean {
        return this.hasLeft && !this.hasCenter;
      },
      themeClass(): string {
        return `${this.theme}Theme`;
      },
      heightClass(): string {
        return `${this.btnSize}Size`;
      }
    },
    render() {
      const attrs = {
        attrs: this.$attrs,
        on: this.$listeners
      };
      let TAG: 'a' | 'button' = 'button';

      if (guardUnspecified(attrs.attrs.href)) {
        TAG = 'a';
      }

      if (TAG === 'button' && this.isDisabled) {
        attrs.attrs.disabled = 'true';
      }

      if (this.dataAttrs.length > 0) {
        attrs.attrs = {
          ...attrs.attrs,
          ...Object.fromEntries(this.dataAttrs)
        };
      }

      return (
        <TAG
          class={[
            styles.jtnUiButton,
            styles[this.themeClass],
            styles[this.heightClass],
            this.isDisabled ? styles.disabled : '',
            this.isTextOnly ? styles.centerOnly : '',
            this.isIconOnly ? styles.leftOnly : '',
            this.isRounded ? styles.rounded : ''
          ]}
          {...attrs}
        >
          {this.hasLeft && <span class={styles.left}>{this.$slots.left}</span>}
          {this.hasCenter && <span class={styles.center}>{this.$slots.default}</span>}
          {this.hasRight && <span class={styles.right}>{this.$slots.right}</span>}
        </TAG>
      );
    }
  });
