import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-typography.styles.scss?module';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiTypography',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    type: {
      type: String,
      default: 'font-vijet-type-1'
    },
    linkType: {
      type: String,
      default: ''
    }
  },
  render() {
    const COMPONENT = this.tag;
    const attributes = {
      attrs: this.$attrs,
      on: this.$listeners
    };
    return (
      <COMPONENT class={[styles[this.type], styles[this.linkType]]} {...attributes}>
        {this.$slots.default}
      </COMPONENT>
    );
  }
});
