















import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import {
  AdUnit,
  Creative,
  CustomSlotParams,
  ICreativeManager,
  ISlot,
  SlotStatus
} from './creative';

import { LazyHydrate } from '@jtnews/shared/ui';
import { useStore } from 'vuex-simple';
import { guardUnspecified } from '@portal/utils/util-guards';

import { PagesType } from '@jtnews/shared/data';
import { MobileBrandingCreatives, RootModule } from '@jtnews/core';

type AdvData = {
  data: Creative;
  path: string;
  settings: {
    place: string;
    placeId: number;
    sticky: boolean;
    subPlace: string;
    types: {
      desktop: boolean;
      laptop: boolean;
      mobile: boolean;
      tablet: boolean;
    };
  };
  type: string;
} | null;

type creativeStatus = 'displayed' | 'stub' | 'closed' | 'error' | '';

const REPEAT_BLOCKS = [
  AdUnit.VbRight5,
  AdUnit.Inline,
  AdUnit.HbMid1,
  AdUnit.InImageButton
];

const RESPONSIVE_BLOCKS = [AdUnit.Inline1, AdUnit.Inline3];

const EXCLUDED_ADV_FOR_REFERRER = [
  AdUnit.Fullscreen,
  AdUnit.Sticky,
  AdUnit.StickyRsya,
  AdUnit.Flyroll
];

const UNALLOWED_ADV_REFERRERS = [
  'iframe-toloka.com',
  'raterhub.com',
  'iframe-tasks.yandex',
  'iframe-yang.yandex',
  'yandex-team.ru'
];

@Component({
  name: 'AdvCreative',
  components: {
    LazyHydrate
  }
})
export default class AdvCreative extends Vue {
  @Prop({
    required: true,
    type: Object
  })
  adv: AdvData;
  @Prop({
    type: Number,
    default: undefined
  })
  index: number;
  @Prop({
    type: Boolean,
    default: false
  })
  clientRender: boolean;
  @Prop({
    type: String,
    default: undefined
  })
  blockerCookie: string;

  store = useStore<RootModule>(this.$store);

  creativeManager: ICreativeManager | null = null;
  advSlot: ISlot | null = null;
  observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  };
  observer: IntersectionObserver | null = null;
  isViewPort = false;
  refreshInterval: NodeJS.Timer | undefined = undefined;
  status: creativeStatus = '';

  internationalBlock: Creative | null = null;
  layout: Window['__ADS_CONFIG__']['layout'] | null = null;

  get isMobile() {
    return this.store.deviceInfo.isMobile;
  }

  get pageType() {
    return this.store.pageType;
  }

  get isAdblock() {
    return this.store.isAdblock;
  }

  get isBrandingPage() {
    try {
      const { aTemplateBlock } = this.store.advModule;

      return (
        guardUnspecified((aTemplateBlock?.data as any)?.adfox_branding) &&
        !this.isAdblock &&
        !this.isMobile
      );
    } catch (error) {
      console.error(error);
    }
  }

  get isAllowedDesktop() {
    return this.adv?.settings?.types?.desktop || false;
  }

  get isAllowedLaptop() {
    return this.adv?.settings?.types?.laptop || false;
  }

  get isAllowedTablet() {
    return this.adv?.settings?.types?.tablet || false;
  }

  get isAllowedMobile() {
    return this.adv?.settings?.types?.mobile || false;
  }

  get isRightColumnPlace() {
    return this.adv?.settings?.place === 'right';
  }

  get isLeftColumnPlace() {
    return this.adv?.settings?.place === 'left';
  }

  get isCentralColumnPlace() {
    return this.adv?.settings?.place === 'central';
  }

  get isWindowPlaceAdvType() {
    return this.adv?.settings?.subPlace === 'window';
  }

  get isTextLink() {
    return this.advPlacement.includes('text-link');
  }

  get isSideSticky() {
    if (this.adv?.settings?.sticky === undefined) {
      return false;
    }
    return (
      this.adv?.settings?.sticky && (this.isRightColumnPlace || this.isLeftColumnPlace)
    );
  }

  get isResponsivePlaceholder() {
    return (
      this.isMobile &&
      RESPONSIVE_BLOCKS.includes(this.advPlacement as AdUnit) &&
      this.pageType === PagesType.Record
    );
  }

  get advType() {
    return this.adv?.type || '';
  }

  get isHeaderAdvType() {
    return this.advType === 'header_advert';
  }

  get isTopRightAdvType() {
    return this.advType === 'top_right_advert';
  }

  get isWindowBlockAdvType() {
    return this.advType === 'window_advert';
  }

  get isMobileInreadAdvType() {
    return this.advType === 'mobile_record_inread_advert';
  }

  get advData() {
    if (this.layout === 'INTERNATIONAL') {
      return this.internationalBlock || this.adv?.data || null;
    }

    return this.adv?.data || null;
  }

  get advPlacement() {
    return this.adv?.data?.placement || '';
  }

  get advIsMobile() {
    return this.adv?.data?.isMobile || false;
  }

  get advContainerId() {
    const suffix = this.advIsMobile ? '_m' : '_d';
    if (
      REPEAT_BLOCKS.includes(this.advPlacement as AdUnit) &&
      guardUnspecified(this.index)
    ) {
      return `${this.advPlacement}${suffix}-${this.index}`;
    }

    return `${this.advPlacement}${suffix}`;
  }

  get isPlaceholderEnabled() {
    return this.advData?.placeholderEnabled || false;
  }

  get placeholderSize(): string {
    if (!this.isPlaceholderEnabled || this.isResponsivePlaceholder) {
      return '';
    }

    const isInread2 = this.advPlacement === AdUnit.Inread2;
    const shouldChangeInreadMaxHeight =
      isInread2 &&
      (this.mobileBrandingCreatives?.verticalImage ||
        this.mobileBrandingCreatives?.verticalHtml);

    const placeholderHeight = shouldChangeInreadMaxHeight
      ? 600
      : this.advData?.placeholderConfig.height || 0;
    const shouldChangeMinHeight =
      this.advPlacement && ['inread', 'inread-2'].includes(this.advPlacement);
    const placeholderMinHeight =
      shouldChangeMinHeight && this.advIsMobile ? 280 : placeholderHeight;
    /*
     * ВАЖНО! Нужно оставить min-height и max-height вместо height,
     * так работают магические респонсивные плейсхолдеры яндекса
     */
    return placeholderHeight
      ? `min-height: ${placeholderMinHeight}px; max-height: ${placeholderHeight}px; --creative-height: ${placeholderHeight}px;`
      : '';
  }

  get isPlaceholderBackground() {
    return (
      (this.isPlaceholderEnabled && this.advData?.placeholderConfig.backgroundEnabled) ||
      false
    );
  }

  get isPlaceholderMark() {
    return (
      (this.isPlaceholderEnabled && this.advData?.placeholderConfig.advMarkEnabled) ||
      false
    );
  }

  get refreshIntervalStep() {
    return this.advData?.refreshConfig.refreshInterval || 0;
  }

  get customAdvParams(): CustomSlotParams {
    if (([AdUnit.StickyRsya] as string[]).includes(this.advPlacement)) {
      return {
        type: 'floorAd'
      };
    }

    if (
      this.advPlacement === AdUnit.Fullscreen ||
      this.advPlacement === AdUnit.FullscreenMain
    ) {
      return {
        type: 'fullscreen',
        fullscreenBannerBlacklist: ['banner.transfer', 'banner.fullscreen.html']
      };
    }

    return {};
  }

  get mobileBrandingCreatives(): MobileBrandingCreatives | null {
    return this.store.advModule.mobileBrandingCreatives;
  }

  get shouldRenderBrandingHtml(): boolean {
    return Boolean(this.brandingHtml);
  }

  get brandingHtml(): string | false {
    if (!this.mobileBrandingCreatives) {
      return false;
    }

    return this.brandingHtmlMap[this.advPlacement as AdUnit] || false;
  }

  get brandingHtmlMap(): Partial<Record<AdUnit, any>> {
    return {
      [AdUnit.Inread]:
        this.mobileBrandingCreatives?.horizontalImage ||
        this.mobileBrandingCreatives?.horizontalHtml,
      [AdUnit.Inread2]:
        this.mobileBrandingCreatives?.verticalImage ||
        this.mobileBrandingCreatives?.verticalHtml,
      [AdUnit.Sticky]: this.mobileBrandingCreatives?.stickyHtml
    };
  }

  get advHtml() {
    if (this.advPlacement === 'hb-top' && this.pageType === PagesType.Record) {
      return ''
    }

    return `
          <div id="${this.advContainerId}"></div>
          <script>
          var isMobileScreen = window.innerWidth < 900;
          var allowMobileDevice = isMobileScreen && ${this.advIsMobile};
          var allowDesktopDevice = !isMobileScreen && !${this.advIsMobile};
          var allowCurrentDevice = allowMobileDevice || allowDesktopDevice;
          var hasBlockerCookie = document.cookie.includes('${this.blockerCookie}');
          var isPushReferrer = new URLSearchParams(window.location.search).get('utm_source') === 'push';
          var isBlockFullscreen = isPushReferrer && ${this.advPlacement.includes(
            'fullscreen'
          )};
          var referrer = new URLSearchParams(window.location.search).get('_testReferrer') || document.referrer;
          var isUnallowedAdvReferrer = ${JSON.stringify(
            UNALLOWED_ADV_REFERRERS
          )}.some(domain => referrer.includes(domain)) && ${JSON.stringify(
      EXCLUDED_ADV_FOR_REFERRER
    )}.includes('${this.advPlacement}');

          if (!hasBlockerCookie && allowCurrentDevice && !isBlockFullscreen && !isUnallowedAdvReferrer) {
            const container = document.getElementById(\`${this.advContainerId}\`);
            if(typeof window.HSMCreativeManager !== 'undefined') {
              window.HSMCreativeManager.createSlot({
                container,
                adunit: '${this.advPlacement}',
                params: ${JSON.stringify(this.customAdvParams)}
              }).then(creativeSlot => {
                  creativeSlot?.refresh();
                });
            } else {
              window.onHSMCreativeManagerReady = window.onHSMCreativeManagerReady || [];
              window.onHSMCreativeManagerReady.push((cm) => {
                cm.createSlot({
                container,
                adunit: '${this.advPlacement}',
                params: ${JSON.stringify(this.customAdvParams)}
              }).then(creativeSlot => {
                  creativeSlot?.refresh();
                });
              });
            }
          }
          <\/script>
      `;
  }

  get hideOnInternationalLayout() {
    return this.layout === 'INTERNATIONAL' && !this.internationalBlock;
  }

  get isRender() {
    if (this.hideOnInternationalLayout) {
      return false;
    }

    if (this.isBrandingPage && !this.isAllowedLaptop) {
      return false;
    }

    if (this.isBrandingPage && this.isHeaderAdvType) {
      return false;
    }

    return true;
  }

  get classes() {
    return [
      this.$style.adv,
      this.isAllowedDesktop ? this.$style.desktop : '',
      this.isAllowedLaptop ? this.$style.laptop : '',
      this.isAllowedTablet ? this.$style.tablet : '',
      this.isAllowedMobile ? this.$style.mobile : '',
      this.isHeaderAdvType ? this.$style.top : '',
      this.isTopRightAdvType ? this.$style.topRight : '',
      this.isWindowBlockAdvType ? this.$style.windowBlock : '',
      this.isWindowPlaceAdvType ? this.$style.windowPlace : '',
      this.isMobileInreadAdvType ? this.$style.mobileInread : '',
      this.isLeftColumnPlace ? this.$style.leftColumnPlace : '',
      this.isRightColumnPlace ? this.$style.rightColumnPlace : '',
      this.isCentralColumnPlace ? this.$style.centralColumnPlace : '',
      this.isSideSticky ? this.$style.sideSticky : '',
      this.isBrandingPage ? this.$style.isBranding : '',
      this.isPlaceholderEnabled ? this.$style.placeholderEnabled : '',
      this.isPlaceholderBackground ? this.$style.placeholderBackground : '',
      this.isPlaceholderMark ? this.$style.placeholderMark : '',
      this.isResponsivePlaceholder ? this.$style.responsivePlaceholder : '',
      this.isTextLink ? this.$style.isTextLink : ''
    ];
  }

  isUnallowedAdvReferrer() {
    const referrer =
      new URLSearchParams(window.location.search).get('_testReferrer') ||
      document.referrer;

    return (
      UNALLOWED_ADV_REFERRERS.some(domain => referrer.includes(domain)) &&
      EXCLUDED_ADV_FOR_REFERRER.includes(this.advPlacement as AdUnit)
    );
  }

  async init() {
    if (!this.creativeManager) {
      return;
    }

    if (this.isUnallowedAdvReferrer()) {
      return;
    }

    if (
      this.clientRender &&
      this.advPlacement === AdUnit.Inline &&
      guardUnspecified(this.index)
    ) {
      this.sendHBRequest();
    }

    await this.creativeManager.ready;

    if (this.clientRender) {
      await new Promise<void>(async resolve => {
        setTimeout(async () => {
          if (
            !document.cookie.includes(this.blockerCookie) &&
            this.advIsMobile === this.isMobile
          ) {
            await this.initCreative().catch(error => {
              return false;
            });
          }
          resolve();
        }, 0);
      });
    } else {
      this.advSlot = this.creativeManager.findSlots(this.advPlacement)[0] || null;
    }

    if (!this.advSlot) {
      return;
    }

    this.emitStatusEvent(this.advSlot.status);

    this.advSlot.on('display', this.onDisplay);
    this.advSlot.on('stub', this.onStub);
    this.advSlot.on('error', this.onError);
    this.advSlot.on('closed', this.onClose);

    this.initObserver();

    if (([AdUnit.StickyRsya] as string[]).includes(this.advPlacement) && this.advData?.refreshEnabled) {
      this.setRefreshTimeout();
    }
  }

  async initCreative() {
    const element = document.getElementById(this.advContainerId)!;

    this.advSlot = await this.creativeManager!.createSlot({
      container: element,
      adunit: this.advPlacement,
      params: this.customAdvParams
    }).catch(error => {
      throw error;
    });

    this.emitStatusEvent(this.advSlot.status);

    this.advSlot.on('display', this.onDisplay);
    this.advSlot.on('stub', this.onStub);
    this.advSlot.on('error', this.onError);
    this.advSlot.on('closed', this.onClose);

    await this.advSlot?.refresh();
  }

  emitStatusEvent(status: SlotStatus) {
    if (status === SlotStatus.READY) {
      this.onDisplay();
    } else if (status === SlotStatus.STUB) {
      this.onStub();
    } else if (status === SlotStatus.ERROR) {
      this.onError();
    }
  }

  @Emit('display')
  onDisplay() {
    this.status = 'displayed';
  }

  @Emit('stub')
  onStub() {
    this.status = 'stub';
  }

  @Emit('error')
  onError() {
    this.status = 'error';
  }

  @Emit('closed')
  onClose() {
    this.status = 'closed';

    if (this.observer) {
      this.observer.disconnect();
    }

    this.clearRefreshTimeout();
  }

  initObserver() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.isViewPort = entry.isIntersecting;
      });
    }, this.observerOptions);

    // На dev-средах бажит без проверки
    if (this.$el.tagName) {
      this.observer.observe(this.$el);
    }
  }

  setRefreshTimeout() {
    this.refreshInterval = setInterval(async () => {
      if (this.shouldRenderBrandingHtml) {
        return;
      }

      if (([AdUnit.StickyRsya] as string[]).includes(this.advPlacement)) {
        this.advSlot?.off('closed', this.onClose);

        await this.advSlot?.refresh();
        this.advSlot?.on('closed', this.onClose);
      } else {
        this.advSlot?.refresh();
      }
    }, this.refreshIntervalStep * 1000);
  }

  clearRefreshTimeout() {
    clearInterval(this.refreshInterval);
  }

  async getLayout() {
    if (!this.creativeManager) {
      return;
    }

    await this.creativeManager.geoReady;

    if (this.creativeManager.configManager.layout === 'INTERNATIONAL') {
      this.internationalBlock = this.creativeManager.configManager.findAdunit(
        this.advPlacement
      );
      this.layout = 'INTERNATIONAL';
    } else {
      this.layout = 'STANDART';
    }
  }

  sendHBRequest() {
    const w = window as any;
    const mobileIncrementAdUnits = w.mobileIncrementAdUnits || [];
    const desktopIncrementAdUnits = w.desktopIncrementAdUnits || [];

    const advContainerSuffix = this.isMobile ? '_m' : '_d';

    const adUnitParams = this.isMobile ? mobileIncrementAdUnits : desktopIncrementAdUnits;

    if (adUnitParams.length === 0) {
      return;
    }

    if (this.advContainerId.includes(advContainerSuffix)) {

      const newAdUnitParams = { ...adUnitParams[0], ...{ code: this.advContainerId } };

      w.Ya || (w.Ya = {});
      w.yaContextCb = w.yaContextCb || [];
      w.Ya.adfoxCode || (w.Ya.adfoxCode = {});
      w.Ya.adfoxCode.hbCallbacks || (w.Ya.adfoxCode.hbCallbacks = []);

      w.Ya.adfoxCode.hbCallbacks.push(function () {
        w.Ya.headerBidding.pushAdUnits([newAdUnitParams]);
      });
    }
  }

  @Watch('isViewPort')
  isViewPortChanged(value: boolean) {
    if (this.shouldRenderBrandingHtml) {
      this.clearRefreshTimeout();
      return;
    }

    if (this.advData?.refreshEnabled) {
      if (value) {
        this.setRefreshTimeout();
      } else {
        this.clearRefreshTimeout();
      }
    }
  }

  beforeMount() {
    this.creativeManager = (window as any).HSMCreativeManager;
    void this.getLayout();
  }

  mounted() {
    if (!this.isRender) {
      return false;
    }

    this.init().then(() => {
      /*
       * Двойной mounted
       */
      if (this.status === '' && this.advSlot?.status) {
        this.emitStatusEvent(this.advSlot.status);
      }
    });
  }

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    clearInterval(this.refreshInterval);

    if (this.advSlot) {
      this.advSlot.off('display');
      this.advSlot.off('stub');
      this.advSlot.off('closed');
      this.creativeManager?.destroySlot(this.advSlot);
    }
    this.advSlot = null;
  }
}
