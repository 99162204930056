import { guardUnspecified } from '@portal/utils/util-guards';
import { isSameRegion, Regions } from '@smh/projects/regions';
import { componentFactory } from 'vue-tsx-support';

import {
  injectStylesMixin,
  tsStoreMixin,
  TextListWithDropdown,
  ObserverVisibility
} from '@jtnews/shared';
import { PagesType } from '@jtnews/shared/data';

import { Adv, AdvCreative } from '../../../shared/components/_adv';

import styles from './app-header.styles.scss?module';
import StickyHeader from './sticky-header';

type ReachGoalValue = string | Record<string, string>;

type MenuItem = {
  path: string;
  title: string;
  position?: number;
  status?: string;
  hasAutoIcon?: boolean;
};

type AdvData = {
  data: string;
  path: string;
  settings: {
    place: string;
    placeId: number;
    sticky: boolean;
    subPlace: string;
    types: {
      desktop: boolean;
      laptop: boolean;
      mobile: boolean;
      tablet: boolean;
    };
  };
  type: string;
} | null;

type NewFormatReachGoal = {
  goalName: string;
  actionType: 'Просмотр' | 'Клик';
  prop1?: string;
  prop2?: string;
};

type ComponentData = {
  obsVisibility: ObserverVisibility;
};

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'AppHeader',
    props: {
      disabledSticky: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        obsVisibility: new ObserverVisibility('half', {
          custom: {
            once: true,
            intersection: {
              root: this.$el as HTMLElement,
              rootMargin: '0'
            }
          }
        })
      };
    },
    computed: {
      isMobile(): boolean {
        return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
      },
      menuItems(): MenuItem[] {
        return (this.store.headerModule.headerServices.data || []) as MenuItem[]; // eslint-disable-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      },
      underHeaderAdvBlock(): AdvData {
        return this.store.advModule.underHeaderAdvBlock;
      },
      isVideoPage(): boolean {
        return this.store.pageType == PagesType.VideosOfTheDay;
      },
      isHasAdv(): boolean {
        return (
          guardUnspecified(this.underHeaderAdvBlock) &&
          this.isMobile &&
          !(this.store.pageType == PagesType.VideosOfTheDay)
        );
      },
      regionId(): Regions {
        return this.store.regionId;
      },
      isChita(): boolean {
        return isSameRegion(this.regionId, Regions.Chita);
      },
      isScroogeTest() {
        return this.store.layoutModule.isScroogeTest;
      }
    },
    methods: {
      showedMoreProjects() {
        this.store.commonModule.emitSearchBarState('close');
        this.store.commonModule.updateAlertsState({
          value: 'close',
          changedBy: 'app-header'
        });
      },
      sendNewFormatReachGoal({ goalName, actionType, prop1, prop2 }: NewFormatReachGoal) {
        this.store.analyticsModule.sendNewFormatReachGoal({
          blockType: 'Меню',
          goalName,
          actionType,
          prop1,
          prop2
        });
      },
      sendNewReachGoal(valueName: ReachGoalValue) {
        const blockType = 'Меню';
        const fieldType = 'Проекты';
        const goalName = 'clickMenu';

        this.store.analyticsModule.sendNewReachGoal({
          blockType,
          fieldType,
          valueName,
          goalName,
          willDeprecate: true
        });

        this.sendNewFormatReachGoal({
          goalName: 'ClickMenuProject',
          actionType: 'Клик',
          prop1: 'Проект',
          prop2: typeof valueName === 'string' ? valueName : valueName.title
        });
      }
    },
    mounted() {
      this.obsVisibility.event.subscribe((state: { isIntersecting: boolean }) => {
        if (state.isIntersecting) {
          this.sendNewFormatReachGoal({
            goalName: 'ViewMenu',
            actionType: 'Просмотр'
          });
        }
      });
    },
    beforeDestroy() {
      this.obsVisibility.destroy();
    },
    render() {
      const ADV_COMPONENT = this.isScroogeTest ? AdvCreative : Adv;
      return (
        <header
          v-observe-visibility={this.obsVisibility.getOptions('half')}
          class={[styles.header, 'appHeader']}
          data-test="app-header"
        >
          <div class={styles.group}>
            <TextListWithDropdown
              class={styles.projectsMenu}
              items={this.menuItems}
              type="projects"
              isMobile={this.isMobile}
              isForceSelfTarget={this.isChita}
              onClickedLink={(event: MenuItem) => {
                this.sendNewReachGoal(event.title);
              }}
              onClickedSublistBtn={() => {
                this.showedMoreProjects();
              }}
            />
          </div>
          <StickyHeader disabled-sticky={this.disabledSticky} />
          {this.isHasAdv && (
            <ADV_COMPONENT adv={this.underHeaderAdvBlock} key="new-adv" />
          )}
        </header>
      );
    }
  });
