import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../../mixins/inject-styles';

import styles from './jtn-ui-badge.styles.scss?module';

const FIRST_BREAKPOINT = 10000;
const SECOND_BREAKPOINT = 100000;

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiBadge',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    content: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  computed: {
    contentCount(): string {
      if (this.content >= FIRST_BREAKPOINT && this.content < SECOND_BREAKPOINT) {
        return `${Math.floor(this.content / 1000)}K`;
      }
      if (this.content >= SECOND_BREAKPOINT) {
        return '99K+';
      }
      return this.content.toString();
    }
  },
  render() {
    const attributes = {
      attrs: this.$attrs,
      on: this.$listeners
    };
    const COMPONENT = this.tag;

    return (
      <COMPONENT
        class={[styles.badge, styles[this.size]]}
        {...attributes}
        domPropsInnerHTML={this.contentCount}
      />
    );
  }
});
