import { clickOutside } from '@portal/utils/util-click-outside';
import { getElementMaxHeightByViewportBottom, setStyle } from '@portal/utils/util-dom';
import { guardUnspecified } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-dropdown.style.scss?module';

interface Events {
  onToggle: boolean;
  onCLickedOutside: Event;
}

type ComponentData = {
  isShowDropdown: boolean;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiDropdown',
    props: {
      isShowDropdownByDefault: {
        type: Boolean,
        default: false
      },
      isCloseOnScroll: {
        type: Boolean,
        default: false
      },
      isCloseOnClickOutside: {
        type: Boolean,
        default: true
      },
      maxHeight: {
        type: Number,
        default: 0
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        isShowDropdown: this.isShowDropdownByDefault
      };
    },
    computed: {
      icon() {
        const iconOpen =
          this.$slots.iconOpen !== undefined ? (
            this.$slots.iconOpen
          ) : (
            <svg
              class={this.isShowDropdown ? styles.iconRotate : ''}
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 1L6 6L1 1"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          );
        if (this.isShowDropdown) {
          return this.$slots.iconClose !== undefined ? this.$slots.iconClose : iconOpen;
        }

        return iconOpen;
      }
    },
    methods: {
      toggleDropdown() {
        if (this.disabled) {
          return;
        }

        if (this.isShowDropdown) {
          this.closeDropdown();
        } else {
          this.openDropdown();
        }

        this.$emit('toggle', this.isShowDropdown);

        void this.$nextTick(() => {
          this.updatePopupHeight();
        });
      },
      closeDropdown() {
        this.isShowDropdown = false;
        if (this.isCloseOnScroll) {
          document.removeEventListener('scroll', () => this.closeDropdown());
        }
      },
      openDropdown() {
        this.isShowDropdown = true;

        if (this.isCloseOnClickOutside && this.$refs.dropdownEl !== null) {
          clickOutside(
            this.$refs.dropdownEl as HTMLElement,
            (event: Event) => {
              if (!this.disabled) {
                this.$emit('clickedOutside', event);
                this.closeDropdown();
              }
            },
            { isSkipFirstClick: false }
          );
        }

        if (this.isCloseOnScroll) {
          document.addEventListener('scroll', () => this.closeDropdown());
        }
      },
      updatePopupHeight() {
        if (this.$refs.contentEl !== null && this.$refs.contentEl !== undefined) {
          const maxH =
            this.maxHeight > 0
              ? `${this.maxHeight}px`
              : `${getElementMaxHeightByViewportBottom(
                  this.$refs.contentEl as HTMLElement
                )}px`;
          setStyle(this.$refs.contentEl as HTMLElement, 'maxHeight', maxH);
        }
      }
    },
    render() {
      const attrs = {
        attrs: this.$attrs
      };
      return (
        <div class={styles.dropdown} ref="dropdownEl" {...attrs}>
          <button
            class={[styles.caption, this.disabled ? styles.disabled : '']}
            data-test="list-visible-more-btn"
            onClick={() => {
              this.toggleDropdown();
            }}
          >
            {guardUnspecified(this.$slots.caption) && <span>{this.$slots.caption}</span>}
            {this.icon}
          </button>

          <transition
            enter-class={styles.hide}
            enter-to-class={styles.show}
            leave-class={styles.show}
            leave-to-class={styles.hide}
          >
            {this.isShowDropdown && guardUnspecified(this.$slots.default) && (
              <div class={styles.wrap} ref="contentEl">
                {this.$slots.default}
              </div>
            )}
          </transition>
        </div>
      );
    }
  });
