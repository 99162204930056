import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-btn-icon.styles.scss?module';

type SizeStyles = {
  width: string;
  height: string;
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiBtnIcon',
  props: {
    url: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 48
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    btnType: {
      type: String,
      default: 'button-other-type-1'
    }
  },
  computed: {
    sizeStyles(): SizeStyles {
      return {
        width: `${this.width ? this.width : this.size}px`,
        height: `${this.height ? this.height : this.size}px`
      };
    }
  },
  render() {
    const COMPONENT = this.url ? 'a' : 'button';
    const attributes = {
      attrs: this.$attrs,
      on: this.$listeners
    };

    if (this.url !== '') {
      attributes.attrs = {
        ...this.$attrs,
        href: this.url
      };
    }

    return (
      <COMPONENT
        class={[styles['btn-icon'], styles[this.btnType]]}
        style={this.sizeStyles}
        {...attributes}
      >
        {this.$slots.default}
      </COMPONENT>
    );
  }
});
