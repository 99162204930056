import { BehaviorSubject } from 'rxjs';

export type MenuState = {
  value: 'close' | 'open';
  changedBy: string;
  name?: string;
};

export class MenuService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static DEFAULT_PARAMS_STATE: MenuState = {
    value: 'close',
    changedBy: 'default',
    name: 'menu'
  };

  // eslint-disable-next-line
  private _state: BehaviorSubject<MenuState> = new BehaviorSubject(
    MenuService.DEFAULT_PARAMS_STATE
  );

  // eslint-disable-next-line
  private _name = 'menu';

  get state(): BehaviorSubject<MenuState> {
    return this._state;
  }

  public changeState(state: MenuState): void {
    const name = this._name;
    this._state.next({ ...state, name });
  }
}

export const menuService = new MenuService();
