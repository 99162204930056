import { component } from 'vue-tsx-support';
import { useStore } from 'vuex-simple';

import { RootModule } from '@jtnews/store';

export const tsStoreMixin = component({
  data() {
    return {
      store: useStore<RootModule>(this.$store)
    };
  }
});
