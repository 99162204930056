import { Regions } from '@smh/projects/regions';
import { AxiosRequestConfig } from 'axios';

import { BaseHttpClient } from '../base/base-http-client';
import type { BaseConfig } from '../base/base-http-client';

import type { LogoutInfoDTO, ForumProfileDataDTO, AuthUserInfoDTO } from './auth-dtos';

export class AuthHttpClient extends BaseHttpClient {
  private readonly _authUrl = '/public/account/';
  private readonly _logoutUrl = '/public/account/logout/';
  // урлы для форумов NN
  private readonly _hostNN = `${this.protocol}://www.nn.ru`;
  private readonly _authProfileNNUrl = '/jtnews/profile/';
  private readonly _sessionProfileNNUrl = '/jtnews/session/';

  constructor(config: BaseConfig) {
    super(config);
  }

  async checkAuthorization(regionId: Regions): Promise<AuthUserInfoDTO | null> {
    const host = this.getHost(regionId);
    const url = `${host}${this.v}${this._authUrl}`;

    const options: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        accept: 'vnd.news.v1.jtnews+json',
      },
      params: {
        regionId,
      },
    };
    let response = null;

    try {
      response = await this.httpClient.get<AuthUserInfoDTO>(url, options);
    } catch (err) {
      return null;
    }

    return response.data;
  }

  async logout(regionId: Regions): Promise<LogoutInfoDTO | null> {
    const host = this.getHost(regionId);
    const url = `${host}${this.v}${this._logoutUrl}`;

    const options: AxiosRequestConfig = {
      withCredentials: true,
      headers: {
        accept: 'vnd.news.v1.jtnews+json',
      },
      params: {
        regionId,
      },
    };

    const response = await this.httpClient.post<LogoutInfoDTO>(url, null, options);

    return response.data;
  }

  async checkAuthOnForumNN(): Promise<ForumProfileDataDTO | null> {
    const url = `${this._hostNN}${this._authProfileNNUrl}`;
    const options: AxiosRequestConfig = {
      withCredentials: true,
    };
    let response = null;

    try {
      response = await this.httpClient.get<ForumProfileDataDTO>(url, options);
    } catch (err) {
      return null;
    }

    return response.data;
  }

  async logoutFromForumNN(): Promise<void> {
    const url = `${this._hostNN}${this._sessionProfileNNUrl}`;
    const options: AxiosRequestConfig = {
      withCredentials: true,
    };

    try {
      await this.httpClient.delete(url, options);
    } catch (error) {
      throw (error as { response: unknown }).response;
    }
  }
}
