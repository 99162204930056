import * as tsx from 'vue-tsx-support';
import { useModule } from 'vuex-simple';

import { JtnUiSuperNews } from '@jtnews/jtn-ui';
import { injectStylesMixin, tsStoreMixin } from '@jtnews/shared';

import { HotNews } from '../../domain';
import { LayoutStore, LAYOUT_STORE_NAMESPACE } from '../../store';

import styles from './hot-news.styles.scss?module';

type DeviceTypes = {
  mobile?: boolean;
  tablet?: boolean;
  laptop?: boolean;
  desktop?: boolean;
};

export default tsx.componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'HotNews',
    computed: {
      layoutStore(): LayoutStore | undefined {
        return useModule<LayoutStore>(this.$store, LAYOUT_STORE_NAMESPACE);
      },
      blockData(): HotNews | undefined {
        return this.layoutStore?.hotNewsData;
      },
      isMobileOnly(): boolean {
        return this.store.deviceInfo.isMobile && !this.store.deviceInfo.isTablet;
      },
      id(): string {
        return this.blockData?.data.id ?? '';
      },
      recordId(): string {
        return this.blockData?.data.recordId ?? '';
      },
      text(): string {
        return this.blockData?.data.text ?? '';
      },
      url(): string {
        return this.blockData?.data.url ?? '';
      },
      type(): 'urgent' | 'topic' {
        return this.blockData?.data.type || 'topic';
      },
      deviceTypes(): DeviceTypes {
        return this.blockData?.settings.types ?? {};
      }
    },
    mounted() {
      this.layoutStore?.createWS();
    },
    beforeDestroy() {
      this.layoutStore?.beforeDestroy();
      this.layoutStore?.closeWS();
    },
    methods: {
      sendReachGoalsForClickLink(event: Event) {
        let valueName: string;
        if (this.url === '') {
          this.sendNewReachGoal('0');
          event.preventDefault();
        } else {
          if (this.type === 'topic' && this.recordId !== '') {
            valueName = this.recordId;
          } else {
            valueName = this.id;
          }
          this.sendNewReachGoal(valueName || this.url);
        }
      },
      sendNewReachGoal(valueName: string) {
        const blockType = 'Меню';
        const fieldType = this.type === 'topic' ? 'Тема дня' : 'Срочная новость';

        this.store.analyticsModule.sendNewReachGoal({
          blockType,
          fieldType,
          valueName,
          goalName: 'clickMenu',
          willDeprecate: true
        });

        this.store.analyticsModule.sendNewFormatReachGoal({
          goalName: 'ClickMenuThemeDay',
          blockType,
          actionType: 'Клик',
          prop1: fieldType,
          prop2: valueName
        });
      }
    },
    render() {
      return (
        <div
          v-show={this.text}
          class={[styles['super-news'], styles[this.type], this.deviceTypes]}
        >
          <JtnUiSuperNews
            link={this.url}
            text={this.text}
            type={this.type}
            nativeOnClick={(event: Event) => this.sendReachGoalsForClickLink(event)}
          />
        </div>
      );
    }
  });
