import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiBtn } from '@jtnews/jtn-ui';

import { injectStylesMixin } from '../../mixins';

import styles from './socials-list.styles.scss?module';

type IconName =
  | 'instagram'
  | 'ok'
  | 'tamtam'
  | 'telegram'
  | 'tiktok'
  | 'twitter'
  | 'viber'
  | 'vk'
  | 'wa'
  | 'yandex'
  | 'youtube';

type BtnType = 'primary' | 'white' | 'lightGray' | 'transparent1' | 'transparent2';
type BtnSize = 'large' | 'small';

type SocialItem = {
  link: string;
  display_name?: string; // eslint-disable-line @typescript-eslint/naming-convention
  displayName?: string;
  name: IconName;
  type?: string;
  fill?: string;
  width?: number;
  height?: number;
};

export default componentFactoryOf()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'SocialsList',
    props: {
      theme: {
        type: String as () => BtnType,
        default: 'transparent2'
      },
      socials: {
        type: Array as () => SocialItem[],
        default() {
          return [];
        }
      },
      iconsSize: {
        type: Number,
        default: 24
      },
      btnSize: {
        type: String as () => BtnSize,
        default: 'large'
      },
      targetLink: {
        type: String,
        default: '_blank'
      },
      isFooter: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      getIcon(social: SocialItem) {
        switch (social.name) {
          case 'instagram':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-instagram"></use>
              </svg>
            );

          case 'ok':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-ok"></use>
              </svg>
            );

          case 'tamtam':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-tamtam"></use>
              </svg>
            );

          case 'telegram':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-telegram"></use>
              </svg>
            );

          case 'tiktok':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-tiktok"></use>
              </svg>
            );

          case 'twitter':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-twitter"></use>
              </svg>
            );

          case 'viber':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-viber"></use>
              </svg>
            );

          case 'vk':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-vk"></use>
              </svg>
            );

          case 'wa':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-wa"></use>
              </svg>
            );

          case 'yandex':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-yandex-zen"></use>
              </svg>
            );

          case 'youtube':
            return (
              <svg
                style={{ color: social.fill ? social.fill : null }}
                width={social.width || this.iconsSize}
                height={social.height || this.iconsSize}
              >
                <use xlinkHref="/dist/legacy/svg-sprites/jtn-social.478c99898e9d4753ae7bc5e64d408199.svg#jtn-social-youtube"></use>
              </svg>
            );

          default:
            break;
        }
      },
      getSocialName(social: SocialItem) {
        return social.display_name || social.displayName || social.name;
      }
    },
    render() {
      return (
        <ul class={[styles.socialsList, this.isFooter && styles.footerSocials]}>
          {this.socials.map(
            (social, index) =>
              social.link && (
                <li key={index} class={styles.item}>
                  <JtnUiBtn
                    class={styles.link}
                    theme={this.theme}
                    btnSize={this.btnSize}
                    isRounded
                    href={social.link}
                    target={this.targetLink}
                    title={this.getSocialName(social)}
                    onClick={(event: Event) => this.$emit('clickBtn', { event, social })}
                  >
                    <template slot="left">{this.getIcon(social)}</template>
                  </JtnUiBtn>
                </li>
              )
          )}
        </ul>
      );
    }
  });
