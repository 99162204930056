/* eslint-disable @typescript-eslint/naming-convention */

import { dateFormat } from '@portal/utils/util-date';
import { plural } from '@smh/utils/strings';

import { DEFAULT_DATE_TIME_FORMAT } from '@jtnews/shared/data';

import { RecordAuthorInfo, RecordAuthor } from '../record-author/record-author';
import { RecordImage } from '../record-image';
import { RecordVotesInfo, RecordVotes } from '../record-votes';
import { RecordWidget, RecordWidgetInfo } from '../record-widget';

export interface RecordInfo {
  id: number;
  header: string;
  publishAt: string;
  subheader?: string;
  viewsCount: number;
  commentsCount: number;
  isCommentsAllowed: boolean;
  urls: RecordUrls;
  mainPhoto?: RecordImage | null;
  updatesStatus?: 'started' | 'stoped';
  text?: RecordWidgetInfo[];
  posts?: RecordPostInfo[];
  votes?: RecordVotesInfo | null;
  cards?: RecordCardsInfo[];
  sourceAndAuthors?: string;
  isCommercial: boolean;
  commercialLabel: string;
  customUrl: string;
  formats: RecordFormat[];
  headerKeywords: string[];
  links?: RecordLinks;
  authors?: RecordAuthorInfo[];
  tags?: RecordTag[];
  template: RecordTemplate;
  voicedTextUrl: string;

  getFormattedPublishAt(dateFormat: string): string;
}

export type RecordUrls = {
  url: string;
  urlCanonical: string;
  urlComments?: string;
};

export type RecordLinks = {
  data: RecordInfo[];
  meta: {
    editorialRecordIDs: number[];
    neuralRecordIDs: number[];
  };
};

export type RecordFormat = {
  // TODO: поменять на int (в АПИ должны сделать правки)
  id: string;
  name: string;
  url: string;
};

export type RecordTag = {
  name: string;
  url: string;
};

export type RecordPostInfo = {
  id: number;
  isKeypoint: boolean;
  keylabel?: string;
  date: string;
  data: RecordWidgetInfo[];
};

export type RecordCardsInfo = {
  id: number;
  header: string;
  data: RecordWidgetInfo[];
};

export type RecordPost = RecordPostInfo & {
  data: RecordWidget[];
};

export type RecordTemplate = 'default' | 'longread' | 'opinions' | 'cards' | 'online';

export type RecordCards = RecordCardsInfo & {
  data: RecordWidget[];
};

export class Record implements RecordInfo {
  public readonly id: number;

  public readonly header: string;

  public readonly publishAt: string;

  public readonly subheader?: string;

  public readonly viewsCount: number;

  public readonly commentsCount: number;

  public readonly isCommentsAllowed: boolean;

  public readonly urls: RecordUrls;

  public readonly mainPhoto?: RecordImage;

  public readonly updatesStatus?: 'started' | 'stoped';

  public readonly text: RecordWidget[] = [];

  public readonly posts: RecordPost[] = [];

  public readonly cards: RecordCards[] = [];

  public readonly votes: RecordVotes;

  public readonly sourceAndAuthors?: string;

  public readonly isCommercial: boolean;

  public readonly commercialLabel: string;

  public readonly customUrl: string;

  public readonly formats: RecordFormat[] = [];

  public readonly headerKeywords: string[] = [];

  public readonly links: RecordLinks = {
    data: [],
    meta: {
      editorialRecordIDs: [],
      neuralRecordIDs: []
    }
  };

  public readonly authors: RecordAuthor[] = [];

  public readonly tags: RecordTag[] = [];

  public readonly template: RecordTemplate;

  public readonly voicedTextUrl: string = '';

  public get formattedViewsCount(): string {
    return this._formatNumberToString(this.viewsCount);
  }

  public get formattedCommentsCount(): string {
    return this._formatNumberToString(this.commentsCount);
  }

  public get url(): string {
    return this.urls.url ? this.urls.url : '';
  }

  public get commentsUrl(): string {
    return this.urls.urlComments ? this.urls.urlComments : '';
  }

  public get isBroadcast(): boolean {
    return this.template === 'online';
  }

  public get pluralCommentsCount(): string {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${this.commentsCount} ${plural(this.commentsCount, [
      'комментарий',
      'комментария',
      'комментариев'
    ])}`;
  }

  constructor(recordInfo: RecordInfo) {
    this.id = recordInfo.id;
    this.header = recordInfo.header;
    this.publishAt = recordInfo.publishAt;
    this.subheader = recordInfo.subheader;
    this.viewsCount = recordInfo.viewsCount;
    this.commentsCount = recordInfo.commentsCount;
    this.isCommentsAllowed = recordInfo.isCommentsAllowed;
    this.urls = recordInfo.urls;
    this.template = recordInfo.template;
    this.voicedTextUrl = recordInfo.voicedTextUrl;

    if (recordInfo.mainPhoto) {
      this.mainPhoto = new RecordImage(recordInfo.mainPhoto);
    }

    this.updatesStatus = recordInfo.updatesStatus;

    if (recordInfo.text !== undefined && recordInfo.text.length > 0) {
      this.text = this._getWidgets(recordInfo.text);
    }

    if (recordInfo.posts !== undefined && recordInfo.posts.length > 0) {
      this.posts = recordInfo.posts.map(post => ({
        ...post,
        data: this._getWidgets(post.data)
      }));
    }

    if (recordInfo.cards !== undefined && recordInfo.cards.length > 0) {
      this.cards = recordInfo.cards.map(card => ({
        ...card,
        data: this._getWidgets(card.data)
      }));
    }

    this.sourceAndAuthors = recordInfo.sourceAndAuthors;
    this.isCommercial = recordInfo.isCommercial;
    this.commercialLabel = recordInfo.commercialLabel;
    this.customUrl = recordInfo.customUrl;
    this.formats = recordInfo.formats;
    this.headerKeywords = recordInfo.headerKeywords;

    if (recordInfo.authors !== undefined && recordInfo.authors.length > 0) {
      recordInfo.authors.forEach(author => {
        this.authors.push(new RecordAuthor(author));
      });
    }

    if (recordInfo.tags !== undefined) {
      this.tags = recordInfo.tags;
    }

    if (recordInfo.votes) {
      this.votes = new RecordVotes(recordInfo.votes);
    }
  }

  public getFormattedPublishAt(format = DEFAULT_DATE_TIME_FORMAT): string {
    try {
      return dateFormat(this.publishAt, format);
    } catch (e) {
      return '';
    }
  }

  private _formatNumberToString(n: number): string {
    return n.toLocaleString('ru-RU');
  }

  private _getWidgets(widgetsInfo: RecordWidgetInfo[]): RecordWidget[] {
    return widgetsInfo.map(info => new RecordWidget(info));
  }
}
