/* eslint-disable @typescript-eslint/member-ordering */
import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { Regions } from '@smh/projects/regions';
import { Action, Getter, Mutation, State } from 'vuex-simple';

import { RootModule } from '@jtnews/core';
import { NewsApiClientError } from '@jtnews/shared/newsapi/error';
import { PushNotificationsHttpClient } from '@jtnews/shared/newsapi/push';
import { OneSignalNotifications } from '@jtnews/shared/push-notifications';
import type { PushNotificationsConfig } from '@jtnews/shared/push-notifications';

import {
  CommonMapper,
  OneSignalConfigDO
} from '../services/mappers/common-mapper.service';

enum UserPushNotifications {
  NewAnswer = 'COMMENTS_NEW_ANSWER'
}

type UserBrowserNotificationSettings = {
  isAnswersPushEnabled: boolean;
} | null;

type UserParamsForPushApi = {
  profileId: string;
  notificationTypes?: string[];
};

const PUSH_NOTIFICATIONS_LOCAL_STORAGE_KEY = 'jtnews_push_notifications';

export class PushModule {
  private _pushNotificationsService: OneSignalNotifications;
  private _pushNotificationsApi: PushNotificationsHttpClient;

  // user/profile module?
  @State()
  private _savedUserBrowserNotificationSettings: UserBrowserNotificationSettings = null;
  @State()
  private _isPushNotificationsEnabled = false;
  @State()
  private _isPushNotificationsReady = false;
  @State()
  private _isPushNotificationsNotAvailable = false;
  @State()
  private _isBrowserNotificationsDenied = false;

  @Getter()
  public get isPushNotificationsEnabled(): boolean {
    return this._isPushNotificationsEnabled ?? false;
  }

  @Getter()
  public get isPushNotificationsReady(): boolean {
    return this._isPushNotificationsReady ?? false;
  }

  @Getter()
  public get isPushNotificationsNotAvailable(): boolean {
    return (
      !guardUnspecified(this.oneSignalConfig) || this._isPushNotificationsNotAvailable
    );
  }

  @Getter()
  public get isBrowserNotificationsDenied(): boolean {
    return this._isBrowserNotificationsDenied ?? false;
  }

  // перенести user/profile module?
  @Getter()
  public get hasSavedUserBrowserNotificationSettings(): boolean {
    return guardUnspecified(this._savedUserBrowserNotificationSettings);
  }

  // перенести user/profile module?
  @Getter()
  public get savedUserBrowserNotificationSettings(): UserBrowserNotificationSettings {
    return this._savedUserBrowserNotificationSettings;
  }

  @Getter()
  private get playerId(): string {
    return this._pushNotificationsService?.userPlayerId;
  }

  @Getter()
  private get oneSignalConfig(): OneSignalConfigDO | null {
    return CommonMapper.oneSignalConfigToDO(this._root.pageSpec, true);
  }

  constructor(private readonly _root: RootModule) {}

  public init({ deps, isUnsupportedPlatform }: PushNotificationsConfig): void {
    if (isUnsupportedPlatform) {
      this._updatePushNotificationsNotAvailable(true);
      return;
    }

    if (guardUnspecified(this.oneSignalConfig)) {
      this._pushNotificationsService = new OneSignalNotifications({
        ...this.oneSignalConfig,
        deps
      });
    }

    // перенести user/profile module?
    this._pushNotificationsApi = new PushNotificationsHttpClient({
      envType: this._root.envType || '',
      protocol: 'https'
    });
    const userBrowserNotificationSettings =
      this._getDataFromLocalStorage<UserBrowserNotificationSettings>(
        PUSH_NOTIFICATIONS_LOCAL_STORAGE_KEY
      );
    this._updateSavedUserBrowserNotificationSettings(userBrowserNotificationSettings);
  }

  @Action()
  public async setup(): Promise<void> {
    try {
      await this._pushNotificationsService.setup();
    } finally {
      this._updatePushNotificationsNotAvailable();
      this._updatePushNotificationsReady();
      this._updateBrowserNotificationsDenied();
      this._updatePushNotificationsEnabled();
    }
  }

  @Action()
  public async getUserPlayerId(): Promise<void> {
    await this._pushNotificationsService.getUserPlayerId();
  }

  @Action()
  public showPopoverNotifications(force = false): void {
    this._pushNotificationsService.showPopoverNotifications(force);
  }

  @Action()
  public closePopoverNotifications(): void {
    this._pushNotificationsService.closePopoverNotifications();
  }

  @Action()
  public showNativePrompt(): void {
    this._pushNotificationsService.showNativePrompt();
  }

  @Action()
  public subscribeToNotificationsEnabledChange(cb?: (isEnabled: boolean) => void): void {
    this._pushNotificationsService.subscribeToNotificationsEnabledChange(isEnabled => {
      this._updatePushNotificationsEnabled();
      guardUnspecified(cb) && cb(isEnabled);
    });
  }

  @Action()
  public subscribeToBrowserNotificationsDeniedChange(
    cb?: (value: boolean) => void
  ): void {
    this._pushNotificationsService.subscribeToBrowserNotificationsDeniedChange(
      (value: boolean) => {
        this._updateBrowserNotificationsDenied();
        guardUnspecified(cb) && cb(value);
      }
    );
  }

  @Action()
  public subscribePopoverShown(cb: () => void): void {
    this._pushNotificationsService.subscribePopoverShown(cb);
  }

  @Action()
  public subscribePopoverActions(cb: (event: string) => void): void {
    this._pushNotificationsService.subscribePopoverActions(cb);
  }

  @Action()
  public subscribePopoverClosed(cb: () => void): void {
    this._pushNotificationsService.subscribePopoverClosed(cb);
  }

  @Action()
  public unsubscribePopoverActions(): void {
    this._pushNotificationsService.unsubscribePopoverActions();
  }

  @Action()
  public unsubscribePopoverClosed(): void {
    this._pushNotificationsService.unsubscribePopoverClosed();
  }

  @Action()
  public unsubscribePopoverShown(): void {
    this._pushNotificationsService.unsubscribePopoverShown();
  }

  @Action()
  public unsubscribeToNotificationsEnabledChange(): void {
    this._pushNotificationsService.unsubscribeToNotificationsEnabledChange();
  }

  @Action()
  public unsubscribeToBrowserNotificationsDeniedChange(): void {
    this._pushNotificationsService.unsubscribeToBrowserNotificationsDeniedChange();
  }

  // перенести user/profile module?
  @Action()
  public saveUserPushNotificationBrowserSettings({
    profileId,
    isAfterAuth = false
  }: {
    profileId: string;
    isAfterAuth?: boolean;
  }): void {
    if (
      !this.isPushNotificationsReady ||
      !guardEmptyString(this.playerId) ||
      this.isPushNotificationsNotAvailable
    ) {
      return;
    }

    if (guardUnspecified(this.savedUserBrowserNotificationSettings) && !isAfterAuth) {
      return;
    }

    const isAnswersPushNotificationsEnabled =
      guardUnspecified(this.savedUserBrowserNotificationSettings) && isAfterAuth
        ? this.savedUserBrowserNotificationSettings.isAnswersPushEnabled
        : this.isPushNotificationsEnabled;

    const params = {
      profileId,
      notificationTypes: [UserPushNotifications.NewAnswer]
    };

    if (isAnswersPushNotificationsEnabled) {
      void this.subscribeUserOnPushNotifications(params);
    } else if (!isAfterAuth) {
      void this.unsubscribeUserFromPushNotifications(params);
    }
  }

  // перенести user/profile module?
  @Action()
  public async subscribeUserOnPushNotifications(
    params: UserParamsForPushApi
  ): Promise<void> {
    if (!guardEmptyString(this.playerId)) {
      return;
    }

    try {
      await this._pushNotificationsApi.saveOneSignalSubscription({
        ...params,
        playerId: this.playerId,
        regionId: this._root.regionId
      });

      const currentSettings = {
        isAnswersPushEnabled: true
      };
      void this._updateSubscriptionDataInLS(currentSettings);
      this._updateSavedUserBrowserNotificationSettings(currentSettings);
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      throw (error as NewsApiClientError)?.response?.data;
    }
  }

  // перенести user/profile module?
  @Action()
  public async unsubscribeUserFromPushNotifications(
    params: UserParamsForPushApi & { isNeedUpdateData?: boolean }
  ): Promise<void> {
    if (!guardEmptyString(this.playerId) || this.isPushNotificationsNotAvailable) {
      return;
    }

    const { profileId, notificationTypes, isNeedUpdateData = true } = params;
    try {
      await this._pushNotificationsApi.unsubscribeUser({
        profileId,
        notificationTypes,
        playerId: this.playerId,
        regionId: this._root.regionId
      });

      if (isNeedUpdateData) {
        const currentSettings = {
          isAnswersPushEnabled: false
        };
        void this._updateSubscriptionDataInLS(currentSettings);
        this._updateSavedUserBrowserNotificationSettings(currentSettings);
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      throw (error as NewsApiClientError)?.response?.data;
    }
  }

  // перенести user/profile module?
  @Action()
  public clearSubscriptionsDataFromLS(): void {
    localStorage.removeItem(PUSH_NOTIFICATIONS_LOCAL_STORAGE_KEY);
  }

  // перенести user/profile module?
  @Action()
  private _updateSubscriptionDataInLS(data: UserBrowserNotificationSettings): void {
    if (guardUnspecified(data)) {
      const savedData = guardUnspecified(this._savedUserBrowserNotificationSettings)
        ? {
            ...this._savedUserBrowserNotificationSettings,
            ...data
          }
        : { ...data };

      this._setDataToLocalStorage(PUSH_NOTIFICATIONS_LOCAL_STORAGE_KEY, savedData);
    }
  }

  @Mutation()
  private _updatePushNotificationsEnabled() {
    this._isPushNotificationsEnabled =
      this._pushNotificationsService.isNotificationsEnabled;
  }
  @Mutation()
  private _updatePushNotificationsReady() {
    this._isPushNotificationsReady = this._pushNotificationsService.isNotificationsReady;
  }
  @Mutation()
  private _updatePushNotificationsNotAvailable(value?: boolean) {
    if (guardUnspecified(value)) {
      this._isPushNotificationsNotAvailable = value;
    } else {
      this._isPushNotificationsNotAvailable =
        this._pushNotificationsService.isNotificationsNotAvailable;
    }
  }
  @Mutation()
  private _updateBrowserNotificationsDenied() {
    this._isBrowserNotificationsDenied =
      this._pushNotificationsService.isBrowserNotificationsDenied;
  }
  // перенести user/profile module?
  @Mutation()
  private _updateSavedUserBrowserNotificationSettings(
    data: UserBrowserNotificationSettings
  ): void {
    this._savedUserBrowserNotificationSettings = data;
  }

  private _setDataToLocalStorage<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private _getDataFromLocalStorage<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    return guardUnspecified(value) ? (JSON.parse(value) as T) : null;
  }
}
