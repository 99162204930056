import { getDomainByRegionId } from '@jtnews/shared/seedwork/bff/domain';
import { Action } from 'vuex-simple';

import {
  SharingService,
  sharingService,
  SocialType as SocialTypeAlias
} from '../services/sharing/sharing.service';

export type SocialType = SocialTypeAlias;

export type BaseShareParams = {
  socialTarget: SocialType;
  title: string;
  utmParams?: UtmParams;
};
export type MediaShareParams = BaseShareParams & {
  id: string;
  url?: string;
};
export type CommentShareParams = BaseShareParams & {
  url: string;
};
export type TestShareParams = BaseShareParams & {
  countCorrectAnswers: string;
};
export type RecordImageShareParams = BaseShareParams & {
  imageId: string;
};
export type PostShareParams = BaseShareParams & {
  postId: string;
};
export type CardShareParams = BaseShareParams & {
  cardPosition: number;
};

export type UtmParams = {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
};

export class SharingModule {
  public sharingService: SharingService = sharingService;

  @Action()
  public sharePhotoDay(params: MediaShareParams): void {
    const { socialTarget, id, title, utmParams } = params;
    this.sharingService.sharePhotoDay(socialTarget, id, title, utmParams);
  }
  @Action()
  public shareVideoDay(params: MediaShareParams): void {
    const { socialTarget, id, title, utmParams, url = undefined } = params;
    this.sharingService.shareVideoDay(socialTarget, id, title, utmParams, url);
  }
  @Action()
  public shareRecord(params: BaseShareParams): void {
    const { socialTarget, title, utmParams } = params;
    this.sharingService.shareRecord(socialTarget, title, utmParams);
  }
  @Action()
  public sharePost(params: PostShareParams): void {
    const { socialTarget, title, postId, utmParams } = params;
    this.sharingService.sharePost(socialTarget, postId, title, utmParams);
  }
  @Action()
  public shareCard(params: CardShareParams): void {
    const { socialTarget, title, cardPosition, utmParams } = params;
    this.sharingService.shareCard(socialTarget, cardPosition, title, utmParams);
  }
  @Action()
  public shareComment(params: CommentShareParams): void {
    const { socialTarget, url, title, utmParams } = params;
    this.sharingService.shareComment(socialTarget, url, title, utmParams);
  }
  @Action()
  public shareTestResult(params: TestShareParams): void {
    const { socialTarget, countCorrectAnswers, title, utmParams } = params;
    this.sharingService.shareTestResult(
      socialTarget,
      countCorrectAnswers,
      title,
      utmParams
    );
  }
  @Action()
  public shareRecordImage(params: RecordImageShareParams): void {
    const { socialTarget, imageId, title, utmParams } = params;
    this.sharingService.shareRecordImage(socialTarget, imageId, title, utmParams);
  }

  public getUtmParams(
    utmSource: string,
    utmCampaign: string,
    regionId: number
  ): UtmParams {
    const utmMedium = getDomainByRegionId(regionId);
    return {
      utmSource,
      utmMedium,
      utmCampaign
    };
  }

  public getPhotoDayShareLink(id: string, utmParams?: UtmParams): string {
    return this.sharingService.definePhotoDayShareLink(id, utmParams);
  }

  public getRecordShareLink(utmParams?: UtmParams): string {
    return this.sharingService.defineRecordShareLink(utmParams);
  }

  public getRecordImageShareLink(imageId: string, utmParams?: UtmParams): string {
    return this.sharingService.defineRecordImageShareLink(imageId, utmParams);
  }

  public getPostShareLink(postId: string, utmParams?: UtmParams): string {
    return this.sharingService.definePostShareLink(postId, utmParams);
  }

  public getCardShareLink(cardPosition: number, utmParams?: UtmParams): string {
    return this.sharingService.defineCardShareLink(cardPosition, utmParams);
  }

  public getTestResultShareLink(
    countCorrectAnswers: string,
    utmParams?: UtmParams
  ): string {
    return this.sharingService.defineTestResultShareLink(countCorrectAnswers, utmParams);
  }
}
