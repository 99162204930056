import { componentFactory } from 'vue-tsx-support';

import { JtnUiTypography } from '@jtnews/jtn-ui';
import { injectStylesMixin } from '@jtnews/shared';

import styles from './age-limit.style.scss?module';

const ICON_SIZE = 31;

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'AgeLimit',
  render() {
    return (
      <div class={styles.ageLimit}>
        <svg width={ICON_SIZE} height={ICON_SIZE} class={styles.icon}>
          <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-only-18"></use>
        </svg>
        <JtnUiTypography class={styles.tooltip} tag="div">
          <p>
            Информация о возрастных ограничениях в отношении информационной продукции,
            подлежащая распространению на основании норм Федерального закона «О защите
            детей от информации, причиняющей вред их здоровью и развитию».
          </p>
          <p>
            Некоторые материалы настоящего раздела могут содержать информацию, запрещенную
            для детей.
          </p>
        </JtnUiTypography>
      </div>
    );
  }
});
