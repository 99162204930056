type SubscriptionPageType = typeof import('./pages/subscription.page');

export const SUBSCRIPTION_ROUTING = [
  // страница подписки на рассылку
  {
    path: '/subscription/',
    component: (): Promise<SubscriptionPageType> =>
      import(/* webpackChunkName: "subscription-page" */ './pages/subscription.page')
  }
];
