import type { BaseApiResponsePageResult } from '@jtnews/shared/newsapi/base';

import type { HotNews } from '../domain';

type HotNewsDTO = {
  id?: number;
  recordId: string;
  text?: string;
  url?: string;
  type: 'topic' | 'urgent';
};

export class LayoutMapper {
  public static hotNewsToDO(pageDTO: BaseApiResponsePageResult): HotNews {
    let hotNewsDO: HotNews = {
      data: {
        id: '',
        recordId: '',
        text: '',
        url: '',
        type: 'topic'
      },
      settings: {
        types: {
          mobile: true,
          tablet: true,
          laptop: true,
          desktop: true
        }
      }
    };
    const path = pageDTO.config.common['hot_news']?.path;
    const settings = pageDTO.config.common['hot_news']?.settings;

    if (typeof path !== 'undefined') {
      const data = (pageDTO.data[path].data as HotNewsDTO) || {};

      hotNewsDO = {
        data: {
          id: data.id ? `${data.id}` : '0',
          recordId: data.recordId || '',
          text: data.text || '',
          url: data.url || '',
          type: data.type || ''
        },
        settings: settings || {
          types: {
            mobile: false,
            tablet: false,
            laptop: false,
            desktop: false
          }
        }
      };
    }

    return hotNewsDO;
  }
}
