/* eslint-disable */
export const VisibilityMixin = {
  data() {
    return {
      obsVisibilityOptions: {
        callback: this.visibilityChanged,
        once: true,
        intersection: {
          root: null,
          rootMargin: '1100px',
          threshold: 0,
          throttle: 50
        }
      },
      componentVisible: false
    };
  },
  methods: {
    async visibilityChanged(isVisible) {
      if (isVisible && this.componentVisible !== true) {
        this.componentVisible = true;
        await this.showComponent();
      }
    }
  }
};
