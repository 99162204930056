/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable @typescript-eslint/member-ordering */
import { Regions } from '@smh/projects/regions';
import { Observable } from 'rxjs';
import { Mutation, State, Getter, Module } from 'vuex-simple';

import type { DeviceInfo } from '@jtnews/shared/data';
import { PagesType } from '@jtnews/shared/data';
import type {
  BaseApiResponsePageResult,
  MetatagsBlock,
  NotificationBlock
} from '@jtnews/shared/newsapi/base';

import { NetworkContentDataMapper } from '../../layout/services';
import {
  RequestErrorEventEmitter,
  requestErrorEventEmitter
} from '../services/request-errors-event-emitter.service';
import type { RequestError } from '../services/request-errors-event-emitter.service';
import { SharingService, sharingService } from '../services/sharing/sharing.service';

import { AdvModule } from './adv';
import { AnalyticsModule } from './analytics';
import { CommonModule } from './common';
import { ArchiveModule, PagesModule } from './content';
import { HeaderModule } from './header';
import { LayoutModule } from './layout';
import { PushModule } from './push.module';
import { SharingModule } from './sharing.module';
import { UserModule } from './user.module';

type NoticesIds = {
  newAnswersIds: string[];
  newVotesIds: string[];
  noticeIds: string[];
};

export class RootModule {
  public errorsEventEmitter: RequestErrorEventEmitter = requestErrorEventEmitter;

  public sharingService: SharingService = sharingService;

  @Module()
  public analyticsModule = new AnalyticsModule(this);
  @Module()
  public advModule = new AdvModule(this);
  @Module()
  public pushModule = new PushModule(this);
  @Module()
  public sharingModule = new SharingModule();
  @Module()
  public commonModule = new CommonModule(this);
  @Module()
  public headerModule = new HeaderModule(this);
  @Module()
  public layoutModule = new LayoutModule(this);
  @Module()
  public archivesModule = new ArchiveModule(this);
  @Module()
  public pagesModule = new PagesModule(this);
  @Module()
  public userModule = new UserModule(this);

  @State()
  public regionId: Regions = 24;
  @State()
  public geoRegionId: Regions = 24;
  @State()
  public serverDate = '';
  @State()
  public release = 0;
  @State()
  public ampCounters = '';
  @State()
  public counters = '';
  @State()
  public domain = '';
  @State()
  public envType = '';
  @State()
  public pageType: PagesType;
  @State()
  public reCaptcha = '';
  @State()
  public staticPath = '';
  @State()
  public url = '';
  @State()
  public regionType = '';
  @State()
  public isAmp = false;
  @State()
  public dataFetching = false;
  @State()
  public deviceInfo: DeviceInfo = {
    isDesktop: false,
    isIE: false,
    isMobile: false,
    isSafari: false,
    isIOS: false,
    isTablet: false,
    isLegacy: false
  };
  @State()
  public newsApiClient: unknown = null;
  @State()
  public userNewNotices: NoticesIds | null = null;
  @State()
  public route: unknown = null;
  @State()
  public isAdblock = false;
  @State()
  public lastBreakpoint = 'mobile';
  @State()
  public data: BaseApiResponsePageResult = {
    config: {
      header: {},
      common: {},
      content: [],
      footer: {}
    },
    data: {},
    notifications: [],
    metatags: {
      canonical: '',
      title: '',
      result: ''
    },
    settings: {
      style: 'ngs'
    },
    reCaptchaSettings: null
  };
  @State()
  public networkContentDataMapper: NetworkContentDataMapper | null = null;

  @Getter()
  public get experimentNames(): string[] {
    return (
      this.commonModule?.commonReachGoal?.data?.map(
        ({ name }: Record<string, unknown>) => name as string
      ) || []
    );
  }
  @Getter()
  public get requestError$(): Observable<RequestError> {
    return this.errorsEventEmitter.error;
  }
  @Getter()
  public get pageSpec(): RootModule['data'] {
    return this.data;
  }
  @Getter()
  public get config(): BaseApiResponsePageResult['config'] {
    return this.data.config;
  }
  @Getter()
  public get blocks(): BaseApiResponsePageResult['data'] {
    return this.data.data;
  }
  @Getter()
  public get settings(): BaseApiResponsePageResult['settings'] {
    return this.data.settings;
  }
  @Getter()
  public get notifications(): NotificationBlock[] {
    return this.data.notifications || [];
  }
  @Getter()
  public get metatags(): MetatagsBlock {
    return this.data.metatags || {};
  }
  @Getter()
  public get fullDomain(): string {
    return `https://${this.domain}`;
  }
  @Getter()
  public get recaptchaKey(): string {
    return this.data.reCaptchaSettings?.publicKey ?? '';
  }
  @Getter()
  public get currentBreakpoint(): string {
    return this.lastBreakpoint;
  }

  @Mutation()
  public emitRequestError(error: RequestError): void {
    this.errorsEventEmitter.emit(error);
  }
  @Mutation()
  public updateCurrentBreakpoint(bp: string): void {
    this.lastBreakpoint = bp;
  }
  @Mutation()
  public setUserNewNotices(noticesIds: NoticesIds): void {
    this.userNewNotices = { ...noticesIds };
  }
  @Mutation()
  public setDataFromApi(data: BaseApiResponsePageResult): void {
    this.data.data = data.data;
    this.data.config.content = data.config.content;
    this.data.config.common = data.config.common;
    this.data.config.header = data.config.header;
    this.data.metatags = data.metatags;
    this.data.notifications = data.notifications;
    this.data.reCaptchaSettings = data.reCaptchaSettings;
  }
  @Mutation()
  public setDataFetching(value: boolean): void {
    this.dataFetching = value;
  }
  @Mutation()
  public setMetatags(metatags: MetatagsBlock): void {
    this.data.metatags = metatags;
  }
  @Mutation()
  public setNotifications(notifications: NotificationBlock[]): void {
    this.data.notifications = notifications;
  }
  @Mutation()
  public setIsAdblock(value: boolean): void {
    this.isAdblock = value;
  }
}
