/* eslint-disable */
import { Regions } from '@smh/projects/regions';

import { AuthHttpClient } from '@jtnews/shared/newsapi/auth';
import type { ForumMenuItemDTO } from '@jtnews/shared/newsapi/auth';
import { getResponsiveImageData, ImageAspectRatio } from '@jtnews/shared/images';

type ForumMenuItem = {
  id: string;
  name: string;
  link: string;
  count: number;
};

// @TODO: ну лучше бы заюзать feature/users
export class AuthDataMapper {
  private readonly _AuthHttpClient: AuthHttpClient;

  constructor(envType: string) {
    this._AuthHttpClient = new AuthHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async checkAuthorization(regionId: Regions) {
    try {
      const result = await this._AuthHttpClient.checkAuthorization(regionId);

      if (result === null) {
        return result;
      }
      const avatarData = getResponsiveImageData({
        url: result.avatar,
        width: 36,
        aspectRatio: ImageAspectRatio.Square,
        values: [
          {
            width: 36,
            breakpoint: 0,
            noMedia: true
          }
        ]
      });

      return {
        avatar: result.avatar,
        avatarData: {
          src: avatarData.data.src,
          sources: avatarData.data.sources
        },
        commentFormProfileLink: result.commentFormProfileLink,
        email: result.email,
        hasMailAccount: result.hasMailAccount,
        hasMailsCounter: result.hasMailsCounter,
        hasProfile: result.hasProfile,
        isBlocked: result.isBlocked,
        link: result.link,
        login: result.login,
        sex: result.sex,
        userId: result.userId,
        gameStat: result.gameStat,
        bookmarks: result.bookmarks,
        notifyAboutNewAnswers: result.notifyAboutNewAnswers,
        notifyAboutNewVotes: result.notifyAboutNewVotes,
        notifyAboutCommentModeration: result.notifyAboutCommentModeration,
        notifyEmail: result.notifyEmail,
        isNotifyEmailValidated: result.isNotifyEmailValidated,
        featureFlags: result.featureFlags,
        isTrusted: result.trusted
      };
    } catch (err) {
      throw err;
    }
  }

  public logout(regionId: Regions) {
    return this._AuthHttpClient.logout(regionId);
  }

  public async checkAuthOnForumNN() {
    try {
      const result = await this._AuthHttpClient.checkAuthOnForumNN();
      return result !== null
        ? {
            ...result,
            avatarData: {
              src: result.avatar,
              sources: []
            },
            menu: this._getForumMenuItems(result.menu)
          }
        : null;
    } catch (err) {
      throw err;
    }
  }

  public logoutFromForumNN() {
    return this._AuthHttpClient.logoutFromForumNN();
  }

  private _getForumMenuItems(menu: ForumMenuItemDTO[]): ForumMenuItem[] {
    return menu.map(item => ({
      ...item,
      count: item.count || 0
    }));
  }
}
