import { toQueryString } from '@portal/utils/util-url';

export type MediascopeEventRequiredParams = {
  /**
   * Идентификатор каталога
   * */
  idc: string;
  /**
   * Идентификатор клиента
   * */
  cid: string;
  /**
   * Идентификатор тематического раздела
   * */
  tms: string;
};

export type MediascopeAuditoryRequiredParams = {
  /**
   * Название раздела (согласуется с Mediascope). Имеет вид "mx3_site_ru".
   * */
  tmsec: string;
  /**
   * Имя клиента.
   * */
  account: string;
};

export type MediascopeAdditionalParams = {
  /**
   * ID контента в пределах вашего каталога (например, id материала)
   * */
  idlc?: string;
  /**
   * Таймстемп кадра потока. Для статичного материала = 0.
   * */
  fts: string;
  /**
   * Идентификатор типа источника. 1 - Web, 2 - App, 3 - OTT
   * */
  typ: string;
  /**
   * Номер версии ЕК. Версии могут отличаться содержимым ЕК. Значение по умолчанию: 0
   * */
  ver: string;
  /**
   * Тип контакта.
   * 0 - Не определено,
   * 1 - Live вещание,
   * 2 - VOD, 3 - Catch-up,
   * 4 - Контакт с текстовым материалом (статья),
   * 5 - пост в соцсети,
   * 6 - live аудио,
   * 7 - аудио по запросу
   * */
  type: string;
};

export type MediascopeRequiredParams = MediascopeEventRequiredParams &
  MediascopeAuditoryRequiredParams;

export type MediascopeParams = MediascopeRequiredParams & MediascopeAdditionalParams;

export class MediascopeAnalyticsService {
  public fetchCounter(
    counterParams: MediascopeEventRequiredParams & unknown,
    recordId: number,
    urlc: string
  ): void {
    const requestUrl = 'https://tns-counter.ru/e/ec01';
    const { idc, cid, tms } = counterParams;
    const query = toQueryString(
      {
        idc,
        cid,
        tms,
        urlc,
        fts: '0',
        typ: '1',
        ver: '0',
        type: '4',
        idlc: recordId.toString()
      },
      '&'
    );
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('GET', `${requestUrl}${query}`);
    xhr.send();
    xhr.onerror = err => {
      console.error(`Ошибка получения счетчика Mediascope:\n `, err);
    };
  }

  public getAuditoryCounterScript(
    counterParams: MediascopeAuditoryRequiredParams & unknown
  ): string {
    const { account, tmsec } = counterParams;
    return `((counterHostname) => {
      window.MSCounter = {
        counterHostname: counterHostname
      };
      window.mscounterCallbacks = window.mscounterCallbacks || [];
      window.mscounterCallbacks.push(() => {
        msCounterExampleCom = new MSCounter.counter({
          'account': '${account}',
          'tmsec': '${tmsec}',
          'autohit': true
        });
      });

      const newScript = document.createElement('script');
      newScript.async = true;
      newScript.src = counterHostname + '/ncc/counter.js';

      const referenceNode = document.querySelector('script');
      if (referenceNode) {
        referenceNode.parentNode.insertBefore(newScript, referenceNode);
      } else {
        document.firstElementChild.appendChild(newScript);
      }
    })('https://tns-counter.ru/');`;
  }
}
