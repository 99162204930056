<template>
  <div :class="[$style.mobileTextSlider, $style[type]]">
    <div v-show="showLeftOverlay" :class="[$style.overlay, $style.overlayLeft]" />
    <div
      ref="wrapper"
      :class="[
        $style['wrapper'],
        hasScrolled ? '' : $style['scrollHidden'],
        isPictureRedesign ? $style['isPictureRedesign'] : ''
      ]"
      @scroll="wrapperScroll()"
    >
      <ul :class="$style.list">
        <li
          v-for="(item, index) of items"
          :key="item.path || index"
          :class="$style.item"
          :style="{order: themeOrders[index] || 0}"
        >
          <jtn-ui-typography
              v-if="item.type === 'theme' || item.type === 'supercover'"
              :class="[$style.link, $style[item.status], $style[type]]"
              :href="item.path"
              :title="item.title"
              :target="getTargetLink(item.path)"
              :type="fontType"
              :link-type="getLinkType(item.status)"
              tag="a"
              @click="clickLink(item.path)"
          >
            {{ item.title }}
          </jtn-ui-typography>
          <div
            v-if="item.status === 'advert'"
            :class="$style.advMarkerWrap"
            @click="clickAdvMarker($event, item)"
          />
        </li>
        <template v-if="advLogoInThemes">
          <widget-adv-logo-in-themes
            v-for="(advLogo, index) of advLogoInThemes"
            :key="index"
            :class="$style.item"
            :style="getAdvLogoStyle(advLogo)"
            :advData="advLogo"
          />
        </template>
      </ul>

    </div>
    <div
      v-if="isShowAdvPopup"
      :class="$style.advPopupArrow"
      :style="advPopupArrowStyle"
    />
    <jtn-ui-typography
      v-if="isShowAdvPopup"
      ref="advPopup"
      :class="$style.advPopup"
      :style="advPopupStyle"
      :type="`font-photoday-type-1`"
      @click="advPopupCliked()"
    >
      <div v-html="currentAdvCompanyName" />
      <div v-html="currentAdvToken" />
      <svg width="10" height="10" :class="$style.closeIcon">
        <use xlink:href="jtn-critical-close"></use>
      </svg>
    </jtn-ui-typography>
    <div v-show="!hideRightScroll" :class="[$style.overlay, $style.overlayRight]" />
    <svg
        v-if="!showLeftOverlay && !hideRightScroll"
        :class="$style.arrow"
        :stroke-width="2"
        :width="18"
        :height="18">
      <use xlink:href="jtn-critical-arrow-right"></use>
    </svg>
  </div>
</template>

<script>
import { isAbsoluteUrl } from '@portal/utils/util-url';
import { JtnUiTypography } from '@jtnews/jtn-ui';

import { ADV_LOGO_IN_THEMES } from '@jtnews/shared/data';
import { StoreMixin } from '../../../mixins';

import { WidgetAdvLogoInThemes } from '../../../widgets/widget-adv-logo-in-themes';


export default {
  name: 'JtnMobileTextSlider',
  components: {
    JtnUiTypography,
    WidgetAdvLogoInThemes
  },
  mixins: [StoreMixin],
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    type: {
      type: String,
      default: 'themes'
    },
    isPictureRedesign: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      wrapperScrollLeft: 0,
      wrapperClientWidth: 0,
      wrapperScrollWidth: 1,
      hasScrolled: false,
      isShowAdvPopup: false,
      currentAdvCompanyName: '',
      currentAdvToken: '',
      advPopupLeft: 0,
      advPopupArrowLeft: 0
    };
  },
  computed: {
    showLeftOverlay() {
      return this.wrapperScrollLeft > 5;
    },
    hideRightScroll() {
      return (
          this.wrapperClientWidth + this.wrapperScrollLeft >= this.wrapperScrollWidth ||
          this.wrapperClientWidth === this.wrapperScrollWidth
      );
    },
    fontType() {
      let type = '';

      switch (this.type) {
        case 'themes':
        case 'supercover':
          type = 'font-header-themes';
          break;
        case 'project':
        default:
          type = 'font-header';
      }

      return type;
    },
    regionId() {
      return this.store.regionId;
    },
    advLogoInThemes() {
      return ADV_LOGO_IN_THEMES[this.regionId] || null;
    },
    advPositions() {
      if (this.advLogoInThemes) {
        return this.advLogoInThemes.map(item => item.position);
      }
    },
    themeOrders() {
      let orders = [];
      if (this.advPositions) {
        for (let order = 1, index = 0; index < this.items.length; order++) {
          if (!this.advPositions.includes(order)) {
            orders[index] = order;
            index++;
          }
        }
      }

      return orders;
    },
    getAdvLogoStyle() {
      return function (advLogo) {
        const style = {
          order: advLogo.position,
        };

        if (advLogo.position === 0) {
          style.marginLeft = '0';
          style.marginRight = '6px';
        }

        return style;
      };
    },
    advPopupStyle() {
      return `left: ${this.advPopupLeft}px;`;
    },
    advPopupArrowStyle() {
      return `left: ${this.advPopupArrowLeft}px;`;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setScrollParams();
    });
  },
  methods: {
    setScrollParams() {
      this.wrapperScrollLeft = this.$refs.wrapper.scrollLeft;
      this.wrapperClientWidth = this.$refs.wrapper.clientWidth;
      this.wrapperScrollWidth = this.$refs.wrapper.scrollWidth;
    },
    wrapperScroll() {
      this.hasScrolled = true;
      this.setScrollParams();

      this.isShowAdvPopup = false;
    },
    getArrowStroke() {
      return this.type === 'supercover' ? '#fff' : '#8d96a1';
    },
    clickLink(link) {
      this.$emit('linkClicked', link);
      this.$emit('click-link');
    },
    getLinkType(itemStatus = 'default') {
      let type = '';

      switch (this.type) {
        case 'themes':
          type = itemStatus === 'default' || itemStatus === 'advert' ? 'link-dark-type-2' : 'link-white-type-2';
          break;
        case 'supercover':
          type = 'link-white-type-2';
          break;
        case 'project':
        default:
          type = 'link-white';
      }

      return type;
    },
    getTargetLink(path) {
      return isAbsoluteUrl(path) ? '_blank' : '_self';
    },
    clickAdvMarker(event, item) {
      this.isShowAdvPopup = true;
      this.currentAdvCompanyName = item.advCompanyName;
      this.currentAdvToken = `Erid:&nbsp;${item.advToken}`;

      const targetInfo = event.target?.getBoundingClientRect();
      const wrapperInfo = this.$refs.wrapper?.getBoundingClientRect();
      this.$nextTick(() => {
        const popupInfo = this.$refs.advPopup?.$el.getBoundingClientRect();

        this.advPopupArrowLeft = targetInfo?.left + targetInfo?.width/2 - wrapperInfo?.left;

        if (window.innerWidth <= popupInfo.width) {
          return false;
        }

        this.advPopupLeft = (targetInfo.left + (targetInfo.width - popupInfo.width)/2) - wrapperInfo.left;
        if (this.advPopupLeft + popupInfo.width > window.innerWidth) {
          this.advPopupLeft = window.innerWidth - popupInfo.width;
        }
        if (this.advPopupLeft < 0) {
          this.advPopupLeft = 0;
        }

      });
    },
    advPopupCliked() {
      this.isShowAdvPopup = false;
    }
  }
};
</script>

<style lang="scss" module>
@use '~jtn-ui/z-index';

@import 'styles';

$shadow-color: #1a1b1d;

.mobileTextSlider {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  padding: 0;
}

.wrapper {
  position: relative;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  width: 100%;
  height: 100%;
  padding: 13px 0 0;

  transform: translate(0, 0);

  &.isPictureRedesign {
    padding: 10px 0 11px;
  }

  &.scrollHidden {
    @include scrollbar-hidden(x);
  }

  @include on-min-resize($tablet) {
    padding-bottom: 14px;
  }
}

.list {
  @include ul-clear;

  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  max-width: 100%;
}

.link {
  display: flex;
  align-items: center;

  padding: 6px 8px;
  height: auto;

  background-color: $color-surface-100;

  &.hot {
    background-color: $color-error;
    color: $color-surface;
  }

  &.tragic {
    background-color: $color-surface-600;
    color: $color-surface;
  }

  .supercover & {
    background: transparent;
  }
}

.item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  flex: 1 0 auto;
  padding: 0 0 13px;

  border-radius: 3px;
  overflow: hidden;

  white-space: nowrap;

  & + * {
    margin-left: 6px;
  }

  .link {
    @include on-res($mobile) {
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  z-index: z-index.$above;

  width: 27px;
  height: 100%;

  &.overlayLeft {
    left: 0;

    background: linear-gradient(90deg, #fff 7.51%, rgba(255, 255, 255, 0) 100%);

    .supercover & {
      background: linear-gradient(
              90deg,
              $shadow-color 8.51%,
              rgba($shadow-color, 0) 100%
      );
    }
  }

  &.overlayRight {
    right: 0;

    background: linear-gradient(270deg, #fff 7.51%, rgba(255, 255, 255, 0) 100%);

    .supercover & {
      background: linear-gradient(
              270deg,
              $shadow-color 7.51%,
              rgba($shadow-color, 0) 100%
      );
    }
  }
}

.arrow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: z-index.$above;

  margin: auto;

  color: $color-surface-400;
}

.advMarkerWrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10px;

  &:after {
    content: 'Реклама';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    width: 40px;
    margin: 0 auto;

    color: #8E8E8E;

    font-size: 6px;
    font-family: Roboto,sans-serif,Arial;
    letter-spacing: 2.28px;
    text-transform: uppercase;
  }
}

.advPopup {
  position: absolute;
  z-index: 10;
  top: 56px;
  width: max-content;
  max-width: 100%;
  margin-left: -12px;
  padding: 16px 40px 16px 16px;
  background-color: $color-surface;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px #ccc;
  word-wrap: break-word;

  color: $color-surface-600;
  cursor: pointer;

  @media screen and (min-width: 380px) {
    max-width: 380px;
  }
}

.advPopupArrow {
  position: absolute;
  z-index: 99;
  top: 56px;

  &::after {
    content: "";
    position: absolute;
    left: calc(50% - 6px);
    top: -10px;
    border: 6px solid transparent;
    border-bottom: 6px solid $color-surface;
  }
}

.closeIcon {
  position: absolute;

  top: 10px;
  right: 10px;

  color: $color-surface-400;
}
</style>
