import { BehaviorSubject } from 'rxjs';

type ImageViewerState = {
  id: string;
  isOpen: boolean;
  imageId: string;
  name?: string;
  position?: number;
};

type PostImageSortType = 'asc' | 'desc';

type ScrollState = {
  value: 'enable' | 'disable';
  name?: string;
};

export class ImageViewerService {
  static defaultParamsState: ImageViewerState = {
    id: '',
    isOpen: false,
    imageId: '',
    name: 'imageViewer',
    position: undefined
  };

  static defaultImageSortType: PostImageSortType = 'asc';

  static defaultImageScrollState: ScrollState = {
    value: 'enable',
    name: 'imageViewer'
  };

  // eslint-disable-next-line
  private _state$: BehaviorSubject<ImageViewerState> = new BehaviorSubject(
    ImageViewerService.defaultParamsState
  );

  // eslint-disable-next-line
  private _postImageSortType$: BehaviorSubject<PostImageSortType> = new BehaviorSubject(
    ImageViewerService.defaultImageSortType
  );

  // eslint-disable-next-line
  private _name = 'imageViewer';

  // eslint-disable-next-line
  private _scrollState$: BehaviorSubject<ScrollState> = new BehaviorSubject(
    ImageViewerService.defaultImageScrollState
  );

  public get state$(): BehaviorSubject<ImageViewerState> {
    return this._state$;
  }

  public get postImageSortType$(): BehaviorSubject<PostImageSortType> {
    return this._postImageSortType$;
  }

  public get scrollState$(): BehaviorSubject<ScrollState> {
    return this._scrollState$;
  }

  public changePostImageSortType(type: PostImageSortType): void {
    this._postImageSortType$.next(type);
  }

  public changeState({ id, isOpen, imageId = '', position }: ImageViewerState): void {
    const state = {
      id,
      isOpen,
      imageId,
      name: this._name,
      position
    };
    this._state$.next(state);
  }

  public changeScrollState(state: ScrollState): void {
    const extendedState = {
      ...state,
      name: this._name
    };
    this._scrollState$.next(extendedState);
  }
}

export const imageViewerService = new ImageViewerService();
