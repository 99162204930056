import { guardUnspecified, guardEmptyString } from '@portal/utils/util-guards';
import { Action, Getter } from 'vuex-simple';

import {
  getResponsiveImageData,
  ImageAspectRatio,
  ResponsiveImageDataVM
} from '@jtnews/shared/images';
import { Block, ConfigurationSettings } from '@jtnews/shared/newsapi/base';

import { RecordUrls } from '../../../shared/models/record';
import { getBlockWithParams } from '../../../shared/utils/store/getBlockWithParams';
import { RootModule } from '../store';

type BlockWithParams = {
  params?: { title: string };
  path: string;
  settings: ConfigurationSettings;
};

type AuthorRecordsData = {
  commentsCount: number;
  formats: {
    id: number;
    name: string;
    url: string;
  }[];
  hasVideoMark: boolean;
  header: boolean;
  id: number;
  isCommentsAllowed: boolean;
  mainPhoto: {
    author: string;
    description: string;
    fileMask: string;
    height: number;
    id: string;
    sourceName: string;
    type: string;
    url: string;
    width: number;
  };
  publishAt: string;
  rubrics: {
    buildPath: string;
    id: number;
    name: string;
    path: string;
  }[];
  subheader: string;
  themes: {
    description: string;
    id: number;
    name: string;
    path: string;
    url: string;
  }[];
  urls: RecordUrls;
  viewsCount: number;
};

type AuthorRecordsBlock = BlockWithParams & {
  meta: { themes: unknown[]; total: number };
  data: AuthorRecordsData[];
};

type AuthorInfoBlock = BlockWithParams & {
  data: {
    description: string;
    email: string;
    id: number;
    image: {
      author: string;
      description: string;
      fileMask: string;
      height: number;
      id: string;
      sourceName: string;
      type: string;
      url: string;
      width: number;
    };
    jobPosition: string;
    name: string;
    socialLinks: {
      link: string;
      name: string;
    }[];
    url: string;
  };
};

type Image = {
  id: number;
  author: string;
  description: string;
  fileMask: string;
  height: number;
  sourceName: string;
  url: string;
  width: number;
  hasCommercialLabel: boolean;
};

type Format = {
  id: number;
  name: string;
  url: string;
};

type Theme = {
  id: number;
  name: string;
  path: string;
  url: string;
};

type Rubric = {
  id: string;
  name: string;
  path: string;
};

type LastNewsRecord = {
  id: number;
  block: 'block_commercial' | 'block_not_commercial';
  header: string;
  mainPhoto: Image | ResponsiveImageDataVM | null;
  formats: Format[];
  themes: Theme[];
  rubrics: Rubric[];
  subheader: string;
  urls: RecordUrls;
  viewsCount: number;
  commentsCount: number;
  isCommentsAllowed: boolean;
  publishAt: string;
  timeOrder: string;
  viewType: string;
  hasVideoMark?: boolean;
};

type LastNewsResultData = Block<LastNewsRecord[]> & {
  data: {
    data: LastNewsRecord[];
  };
};

export class PagesModule {
  public configName = 'content';
  public fields = 'content';

  // eslint-disable-next-line @typescript-eslint/naming-convention
  constructor(private readonly _root: RootModule) {}

  @Getter()
  public get authorRecordsBlock(): AuthorRecordsBlock {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'records',
      ['meta']
    ) as AuthorRecordsBlock;
  }
  @Getter()
  public get authorInfoBlock(): AuthorInfoBlock {
    return getBlockWithParams(
      this._root.config,
      this.configName,
      this._root.blocks,
      'author'
    );
  }

  @Action()
  public async fetchRecordLastNews(params: {
    excludeIds: number[];
    datesFrom: Record<string, string>;
    headers?: string;
  }): Promise<LastNewsResultData | undefined> {
    const { excludeIds, datesFrom, headers } = params;
    try {
      // TODO: перенести getRecordLastNews в news-api-client.ts
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      const result = (await this._root.newsApiClient.getRecordLastNews({
        regionId: this._root.regionId,
        excludeIds,
        datesFrom,
        headers
      })) as LastNewsResultData;

      if (guardUnspecified(result.data)) {
        result.data.data.map((item: LastNewsRecord) => {
          if (guardUnspecified(item.mainPhoto)) {
            const data = getResponsiveImageData({
              url: (item.mainPhoto.url as string) ?? '',
              width: item.mainPhoto.width as number,
              hasCommercialLabel: item.mainPhoto.hasCommercialLabel,
              aspectRatio: ImageAspectRatio.Wide,
              isLazy: false,
              isPreloadImage: true,
              values: [
                {
                  breakpoint: 0,
                  width: 316,
                  noMedia: true
                }
              ]
            });

            item.mainPhoto = data;
          }

          if (
            item.commentsCount === 0 &&
            guardUnspecified(item.urls.urlComments) &&
            guardEmptyString(item.urls.urlComments)
          ) {
            item.urls.urlComments = `${item.urls.urlComments}?discuss=1`;
          }

          return item;
        });
      }

      return result;
    } catch (err) {
      console.error(err);
    } finally {
      this._root.setDataFetching(false);
    }
  }
}
