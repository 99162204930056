import { Subject } from 'rxjs';

export type RequestError = {
  status: number;
  detail: string;
  title?: string;
  type?: string;
};

export class RequestErrorEventEmitter {
  // eslint-disable-next-line
  private _error: Subject<RequestError> = new Subject<RequestError>();

  // eslint-disable-next-line
  public get error() {
    return this._error.asObservable();
  }

  // eslint-disable-next-line
  public emit(error: RequestError) {
    this._error.next(error);
  }
}

export const requestErrorEventEmitter = new RequestErrorEventEmitter();
