/* eslint-disable @typescript-eslint/naming-convention */
import { guardUnspecified } from '@smh/utils/guards';
import { addQueryString, getQueryParamValue } from '@smh/utils/url';

export type SocialType = 'vk' | 'fb' | 'twitter' | 'ok' | 'telegram' | 'wa';

export type VkParams = {
  url: string;
  title: string;
  noparse: boolean;
};

export type FbParams = {
  u: string;
};

export type TwitterParams = {
  url: string;
  text: string;
};

export type OkParams = {
  'st.cmd': string;
  'st.shareUrl': string;
  title: string;
};

export type TelegramParams = {
  url: string;
};

export type WAParams = {
  text: string;
};

export type QueryParams = {
  name: string;
  value: string;
};

export type UtmParams = {
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
};

export class SharingService {
  static PHOTO_DAY_SHARE_PARAM = 'share';

  static VIDEO_DAY_SHARE_PARAM = 'shareVideoDay';

  static TEST_RESULT_SHARE_PARAM = 'share';

  static RECORD_IMAGE_SHARE_PARAM = 'shareRecordImage';

  static POST_SHARE_PARAM = 'sharePost';

  static CARD_SHARE_PARAM = 'shareCard';

  static VK_SHARE_URL = 'https://vk.com/share.php';

  static FB_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php';

  static TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet';

  static OK_SHARE_URL = 'https://connect.ok.ru/dk';

  static TELEGRAM_SHARE_URL = 'https://telegram.me/share/url';

  static WA_SHARE_URL = 'https://wa.me/';

  static EXCEPTION_QUERY_PARAMS = ['erid'];

  definePhotoDayShareLink(id: string, utmParams?: UtmParams): string {
    let params = [
      {
        name: SharingService.PHOTO_DAY_SHARE_PARAM,
        value: id
      }
    ];
    if (utmParams !== undefined) {
      params = this._getParamsWithUtm(params, utmParams);
    }
    return this._getURLWithQueryParam(params);
  }

  sharePhotoDay(
    socialTarget: SocialType,
    id: string,
    title: string,
    utmParams?: UtmParams
  ): void {
    const targetUrl = this.definePhotoDayShareLink(id, utmParams);
    this._shareBySocialType(socialTarget, targetUrl, title);
  }

  shareVideoDay(
    socialTarget: SocialType,
    id: string,
    title: string,
    utmParams?: UtmParams,
    url?: string
  ): void {
    let params = [
      {
        name: SharingService.VIDEO_DAY_SHARE_PARAM,
        value: id
      }
    ];
    if (utmParams !== undefined) {
      params = this._getParamsWithUtm(params, utmParams);
    }
    const targetUrl = this._getURLWithQueryParam(params, url);
    this._shareBySocialType(socialTarget, targetUrl, title);
  }

  defineRecordShareLink(utmParams?: UtmParams): string {
    let params = null;
    if (utmParams !== undefined) {
      params = this._getParamsWithUtm([], utmParams);
    }
    return this._getURLWithQueryParam(params);
  }

  shareRecord(socialTarget: SocialType, title: string, utmParams?: UtmParams): void {
    const targetUrl = this.defineRecordShareLink(utmParams);
    this._shareBySocialType(socialTarget, targetUrl, title);
  }

  definePostShareLink(postId: string, utmParams?: UtmParams): string {
    let params = [
      {
        name: SharingService.POST_SHARE_PARAM,
        value: postId
      }
    ];
    if (utmParams !== undefined) {
      params = this._getParamsWithUtm(params, utmParams);
    }

    return this._getURLWithQueryParam(params);
  }

  sharePost(
    socialTarget: SocialType,
    postId: string,
    title: string,
    utmParams?: UtmParams
  ): void {
    const targetUrl = this.definePostShareLink(postId, utmParams);
    this._shareBySocialType(socialTarget, targetUrl, title);
  }

  defineCardShareLink(cardPosition: number, utmParams?: UtmParams): string {
    let params = [
      {
        name: SharingService.CARD_SHARE_PARAM,
        value: `${cardPosition}`
      }
    ];
    if (utmParams !== undefined) {
      params = this._getParamsWithUtm(params, utmParams);
    }
    return this._getURLWithQueryParam(params);
  }

  shareCard(
    socialTarget: SocialType,
    cardPosition: number,
    title: string,
    utmParams?: UtmParams
  ): void {
    const targetUrl = this.defineCardShareLink(cardPosition, utmParams);
    this._shareBySocialType(socialTarget, targetUrl, title);
  }

  shareComment(
    socialTarget: SocialType,
    url: string,
    title: string,
    utmParams?: UtmParams
  ): void {
    const params = this._getParamsWithUtm([], utmParams);
    const targetUrl = window.location.origin + url;
    const targetUrlComplete = this._getURLWithQueryParam(params, targetUrl);
    this._shareBySocialType(socialTarget, targetUrlComplete, title);
  }

  defineTestResultShareLink(countCorrectAnswers: string, utmParams?: UtmParams): string {
    let params = [
      {
        name: SharingService.TEST_RESULT_SHARE_PARAM,
        value: countCorrectAnswers
      }
    ];
    if (utmParams !== undefined) {
      params = this._getParamsWithUtm(params, utmParams);
    }
    return this._getURLWithQueryParam(params);
  }

  shareTestResult(
    socialTarget: SocialType,
    countCorrectAnswers: string,
    title: string,
    utmParams?: UtmParams
  ): void {
    const targetUrl = this.defineTestResultShareLink(countCorrectAnswers, utmParams);
    this._shareBySocialType(socialTarget, targetUrl, title);
  }

  public defineRecordImageShareLink(imageId: string, utmParams?: UtmParams): string {
    let params = [
      {
        name: SharingService.RECORD_IMAGE_SHARE_PARAM,
        value: imageId
      }
    ];

    if (utmParams !== undefined) {
      params = this._getParamsWithUtm(params, utmParams);
    }

    return this._getURLWithQueryParam(params);
  }

  shareRecordImage(
    socialTarget: SocialType,
    imageId: string,
    title: string,
    utmParams?: UtmParams
  ): void {
    const targetUrl = this.defineRecordImageShareLink(imageId, utmParams);
    this._shareBySocialType(socialTarget, targetUrl, title);
  }

  private _shareBySocialType(socialTarget: SocialType, targetUrl: string, title: string) {
    switch (socialTarget) {
      case 'vk':
        this._share(SharingService.VK_SHARE_URL, {
          url: targetUrl,
          title,
          noparse: true
        });
        break;

      case 'fb':
        this._share(SharingService.FB_SHARE_URL, {
          u: targetUrl
        });
        break;

      case 'twitter':
        this._share(SharingService.TWITTER_SHARE_URL, {
          text: title,
          url: targetUrl
        });
        break;

      case 'ok':
        this._share(SharingService.OK_SHARE_URL, {
          'st.cmd': 'WidgetSharePreview',
          'st.shareUrl': targetUrl,
          title
        });
        break;

      case 'telegram':
        this._share(SharingService.TELEGRAM_SHARE_URL, {
          url: targetUrl
        });
        break;

      case 'wa':
        this._share(SharingService.WA_SHARE_URL, {
          text: targetUrl
        });
        break;

      default:
        break;
    }
  }

  private _share(
    shareUrl: string,
    params: VkParams | FbParams | TwitterParams | OkParams | TelegramParams | WAParams
  ) {
    const url = Object.entries(params).reduce((acc, current, index) => {
      if (index === 0) {
        return `${acc}?${current[0]}=${encodeURIComponent(current[1].toString())}`;
      }

      return `${acc}&${current[0]}=${encodeURIComponent(current[1].toString())}`;
    }, shareUrl);

    window.open(url, '_blank', 'width=400,height=400');
  }

  private _getURLWithQueryParam(params: QueryParams[] | null, targetUrl = ''): string {
    if (typeof window === 'undefined') {
      return targetUrl;
    }

    const { origin, pathname, search } = window.location;
    const exceptionParams = this._getExceptionQueryParams(search);
    let url = targetUrl || origin + pathname;
    if (Object.keys(exceptionParams).length > 0) {
      url = addQueryString(url, exceptionParams);
    }

    if (params === null) return url;

    return params.reduce((acc: string, current: QueryParams) => {
      return addQueryString(acc, { [current.name]: current.value });
    }, url);
  }

  private _getExceptionQueryParams(query: string) {
    return SharingService.EXCEPTION_QUERY_PARAMS.reduce<Record<string, string>>(
      (acc, param) => {
        const paramValue = getQueryParamValue(query, param);
        if (guardUnspecified(paramValue)) {
          acc[param] = paramValue;
        }
        return acc;
      },
      {}
    );
  }

  private _getParamsWithUtm(
    params: QueryParams[],
    utms: UtmParams | undefined
  ): QueryParams[] {
    const utmParams = [
      { name: 'utm_source', value: utms?.utmSource || '' },
      { name: 'utm_medium', value: utms?.utmMedium || '' },
      { name: 'utm_campaign', value: utms?.utmCampaign || '' }
    ];
    return [...params, ...utmParams];
  }
}

export const sharingService = new SharingService();
