import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiBadge } from '@jtnews/jtn-ui';
import { injectStylesMixin } from '@jtnews/shared';
import type { UserInfo } from '@jtnews/shared/news';

import { AuthParams } from '../../containers/modals/auth-modal/auth-modal.container';

import UserBarForum from './user-bar-forum.component';
import UserBarProfile from './user-bar-profile.component';
import styles from './user-bar.styles.scss?module';

type ImageSourceVM = {
  srcset: string;
  sizes?: string;
  type?: string;
  media?: string;
};

type ForumProfileData = null | {
  id: number;
  login: string;
  link: string;
  email: string;
  avatarData: {
    src: string;
    sources: ImageSourceVM[];
  };
  sex: string;
  menu: ForumMenuItem[];
};

type ForumMenuItem = {
  id: string;
  name: string;
  link: string;
  count: number;
  desktop?: boolean;
};

interface IEvents {
  onLogoutClicked: Event;
  onAlertsClicked: Event;
  onProfileClicked: Event;
  onBookmarksClicked: Event;
  onMailClicked: Event;
  onLoginClicked: AuthParams;
  onForumLogoutClicked: void;
  onForumProfileClicked: void;
  onForumMenuClicked: string;
  onSettingsClicked: Event;
}

const USER_ICON_SIZE = 28;
const EMAIL_ICON_SIZE = 24;

export default componentFactoryOf<IEvents>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'UserBarDropdown',
    props: {
      userInfo: {
        type: Object as () => UserInfo,
        default: null
      },
      isMobile: {
        type: Boolean,
        default: false
      },
      isSmallMobile: {
        type: Boolean,
        default: false
      },
      newUserNoticesCount: {
        type: Number,
        default: 0
      },
      isShowBadge: {
        type: Boolean,
        default: false
      },
      hasUserForums: {
        type: Boolean,
        default: false
      },
      forumProfile: {
        type: Object as () => ForumProfileData,
        default: null
      }
    },
    computed: {
      isUserAuth(): boolean {
        return this.userInfo !== null;
      },
      isForumAuth(): boolean {
        return this.forumProfile !== null;
      },
      name(): string {
        return this.userInfo?.login ?? '';
      },
      email(): string {
        return this.userInfo?.isNotifyEmailValidated && this.userInfo?.notifyEmail
          ? this.userInfo.notifyEmail
          : '';
      },
      link(): string {
        return this.userInfo.hasProfile
          ? this.userInfo.link
          : `${this.userInfo.link}${this.userInfo.userId}`;
      },
      avatarData(): UserInfo['avatarData'] {
        return this.userInfo?.avatarData ?? { src: '', sources: [] };
      },
      sex(): string {
        return this.userInfo?.sex ?? '';
      }
    },
    methods: {
      showModal() {
        this.$emit('loginClicked', { typeProfile: 'news' });
      },
      showModalForum() {
        this.$emit('loginClicked', { typeProfile: 'forums' });
      },
      logout(event: Event) {
        this.$emit('logoutClicked', event);
      },
      openAlerts(event: Event) {
        this.$emit('alertsClicked', event);
      },
      openBookmarks(event: Event) {
        this.$emit('bookmarksClicked', event);
      },
      openProfile(event: Event) {
        this.$emit('profileClicked', event);
      },
      forumLogout() {
        this.$emit('forumLogoutClicked');
      },
      openForumProfile() {
        this.$emit('forumProfileClicked');
      },
      openForumMenuItem(id: string) {
        this.$emit('forumMenuClicked', id);
      },
      openSettings(event: Event) {
        this.$emit('settingsClicked', event);
      }
    },
    render() {
      return (
        <div class={styles.userBarDropdown}>
          <ul class={styles.list}>
            {this.isSmallMobile && (
              <li class={styles.listItem}>
                <button
                  class={styles.listItemContent}
                  onClick={(event: Event) => this.openAlerts(event)}
                >
                  <div class={styles.listItemTitle}>
                    <div class={styles.listItemIcon}>
                      <svg width={EMAIL_ICON_SIZE} height={EMAIL_ICON_SIZE}>
                        <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-alert"></use>
                      </svg>
                    </div>
                    <span class={styles.listItemName}>Уведомления</span>

                    {this.isShowBadge && (
                      <JtnUiBadge
                        class={styles.userNotifications}
                        content={this.newUserNoticesCount}
                        size={this.newUserNoticesCount > 0 ? 'big' : 'small'}
                        data-test="notifications-bt-badge"
                      />
                    )}
                  </div>
                </button>
              </li>
            )}
            <li class={[styles.listItem, this.isUserAuth ? styles.hasSubmenu : '']}>
              <button class={styles.listItemContent} onClick={() => this.showModal()}>
                <div class={styles.listItemTitle}>
                  <div class={styles.listItemIcon}>
                    <svg width={USER_ICON_SIZE} height={USER_ICON_SIZE}>
                      <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-user"></use>
                    </svg>
                  </div>
                  <span class={styles.listItemName}>Профиль</span>
                </div>

                {!this.isUserAuth && (
                  <div class={styles.listItemLogin}>
                    <svg width={24} height={24} class={styles.loginIcon}>
                      <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-login"></use>
                    </svg>
                  </div>
                )}
              </button>

              {this.isUserAuth && (
                <UserBarProfile
                  name={this.name}
                  email={this.email}
                  link={this.link}
                  avatarData={this.avatarData}
                  sex={this.sex}
                  newUserNoticesCount={this.newUserNoticesCount}
                  onLogoutClicked={(event: Event) => this.logout(event)}
                  onBookmarksClicked={(event: Event) => this.openBookmarks(event)}
                  onProfileClicked={(event: Event) => this.openProfile(event)}
                  onSettingsClicked={(event: Event) => this.openSettings(event)}
                />
              )}
            </li>

            {this.hasUserForums && (
              <li class={[styles.listItem, this.isForumAuth ? styles.hasSubmenu : '']}>
                <button
                  class={styles.listItemContent}
                  onClick={() => {
                    this.showModalForum();
                  }}
                >
                  <div class={styles.listItemTitle}>
                    <div class={styles.listItemIcon}>
                      <svg width="24" height="24">
                        <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-menu-forums"></use>
                      </svg>
                    </div>
                    <span class={styles.listItemName}>Форумы</span>
                  </div>

                  {!this.isForumAuth && (
                    <div class={styles.listItemLogin}>
                      <svg width={24} height={24} class={styles.loginIcon}>
                        <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-login"></use>
                      </svg>
                    </div>
                  )}
                </button>

                {this.isForumAuth && (
                  <UserBarForum
                    name={this.forumProfile?.login}
                    email={this.forumProfile?.email}
                    link={this.forumProfile?.link}
                    avatarData={this.forumProfile?.avatarData}
                    sex={this.forumProfile?.sex}
                    menu={this.forumProfile?.menu}
                    isMobile={this.isMobile}
                    onForumLogoutClicked={() => this.forumLogout()}
                    onForumProfileClicked={() => this.openForumProfile()}
                    onForumMenuClicked={(id: string) => this.openForumMenuItem(id)}
                  />
                )}
              </li>
            )}
          </ul>
        </div>
      );
    }
  });
