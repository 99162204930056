<template>
  <div
    id="app"
    :class="{ 'jtn-ui--new-record-design': isNewRecordDesign }"
    :style="{ 'min-height': contentMinHeight }"
  >
    <AdvFullscreen
      v-if="!isHoldoutTest && isMobile && fullscreenAdvBlock && !fetching"
      :adv="fullscreenAdvBlock"
      key="new-adv-fullscreen"
      @rendered="sendReachGoalForFullscreen('Просмотр', 'viewFullscreen')"
      @closed="sendReachGoalForFullscreen('Закрыть (крестик)', 'clickFullscreen')"
    />

    <legacy-browser-warning v-if="isLegacy" />

    <component
      v-if="topAdvBlock && !fetching"
      v-bind="bindAdvComponent"
      key="top-advert"
      :adv="topAdvBlock"
    />

    <component
      v-if="isBrandingPage && brandingAdfoxBlock"
      v-bind="bindAdvComponent"
      key="adfox-branding"
      :adv="brandingAdfoxBlock"
    />

    <div
      :class="[
        'global-wrapper',
        isBrandingPage ? '_branding' : '',
        isIE ? '_ie' : '',
        isLongread ? '_longread' : ''
      ]"
    >
      <template v-if="isLongread === false">
        <app-header />

        <noindex v-if="!isVideoPage">
          <hot-news />
        </noindex>

        <noindex v-if="!isVideoPage">
          <plots-line v-if="isPlotsLineFromUmp" :plots="plotsLineFromUmp" />
          <top-info v-else />
        </noindex>

        <noindex v-if="isShowNewsSlider">
          <news-slider />
        </noindex>
      </template>

      <div :class="['app-content', fetching ? '_data-fetching' : '']">
        <transition name="fade" mode="out-in">
          <div v-if="fetching" class="content-overlay" />
        </transition>

        <LongreadPage v-if="isHackLongread" />
        <transition name="fade" mode="out-in" v-else>
          <router-view />
        </transition>
      </div>

      <div
        v-observe-visibility="obsVisibilityOptions"
        :class="['footer-wrapper', isAdvBottomIntend ? 'withStickyPanel' : '']"
      >
        <template v-if="renderFooter">
          <longread-footer v-if="isLongread" />
          <app-footer v-else />
        </template>
      </div>

      <up-button v-if="isShowUpButton" />
      <blocked-user-modal />
      <register-success-modal
        v-if="isRegisterSuccessModalRendered"
        v-model="isRegisterSuccessModalOpened"
      />
    </div>

    <AdvStickyBottom
      v-if="!isHoldoutTest && bottomAdvBlock && !fetching"
      key="footer-sticky-advert"
      :adv="bottomAdvBlock"
    />

    <template v-if="!isHoldoutTest && isAdvMobileStickyShown && !this.fetching">
      <AdvMobileStickyRsya
        v-if="isShowStickyRsya"
        key="footer-sticky-rsya-mobile-advert"
        :adv="mobileStickyRSYAdvBlock"
        :isHidden="isAdvStickyHidden"
        :isBottomIntend="isAdvBottomIntend"
      />
      <AdvMobileSticky
        v-else
        key="footer-sticky-mobile-advert"
        :adv="mobileStickyAdvBlock"
        :isHidden="isAdvStickyHidden"
        :isBottomIntend="isAdvBottomIntend"
      />
    </template>

    <div id="captchaWrap" />
  </div>
</template>

<script>
import { guardUnspecified } from '@portal/utils/util-guards';
import { useStore, registerModule, unregisterModule } from 'vuex-simple';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';

import { setCookie, getCookie, removeCookie } from 'tiny-cookie';
import { getDomainByRegionId } from '@jtnews/shared/seedwork/bff/domain';
import {
  COOKIES_ADBLOCK,
  BREAKPOINTS,
  PagesType,
  AB_YANDEX_ADBLOCK_COOKIE
} from '@jtnews/shared/data';
import { adblockCookieMatched } from '../../../../../../../libs/shared/adv-sdk/src/adblock-cookie-matched';
import { NetworkContentDataMapper } from '../../services';

import {
  LegacyBrowserWarning,
  getBreakpointByWindowWidth,
  scrollToHash
} from '@jtnews/shared';

import { VisibilityMixin } from '../../../shared/mixins/visibility.mixin';
import {
  AdvFullscreen,
  Adv,
  AdvCreative,
  AdvStickyBottom,
  AdvMobileSticky,
  AdvMobileStickyRsya
} from '../../../shared/components/_adv';
import UpButton from '../../../shared/components/up-button/up-button';
import NewsClient from '../../../core/http/news-client.api';

import TopInfo from '../../components/top-info';
import PlotsLine from '../../components/plots-line/plots-line';
import { LAYOUT_STORE_NAMESPACE, LayoutStore } from '../../store';
import { LongreadFooter } from '../longread-footer';
import AppHeader from '../app-header/app-header';
import { HotNews } from '../hot-news';
import { Regions } from '@jtnews/shared/seedwork/frontend/domain';

const LongreadPage = () =>
  import(
    /* webpackChunkName: "longread-page" */ '../../../features/record/pages/longread/longread.page'
  );

const AppFooter = () =>
  import(/* webpackChunkName: "app-footer" */ '../app-footer/app-footer');

const BlockedUserModal = () =>
  import(
    /* webpackChunkName: "blocked-user-modal" */ '../modals/blocked-user-modal'
  ).then(m => m.BlockedUserModal);

const NewsSlider = () =>
  import(
    /* webpackChunkName: "news-slider" */ '../../../layout/containers/news-slider'
  ).then(m => m.NewsSlider);

const RegisterSuccessModal = () =>
  import(/* webpackChunkName: "register-success-modal" */ '@jtnews/layout/modals').then(
    m => m.RegisterSuccessModal
  );

const CHECK_VIQEO_INTERVAL = 50;
const POPOVER_CLOSE_TIMEOUT = 8000;

export default {
  serverPrefetch() {
    return new Promise(resolve => {
      this.registerModule();
      resolve();
    });
  },
  name: 'App',
  inject: ['logger'],
  components: {
    LongreadPage,
    AdvStickyBottom,
    AdvFullscreen,
    Adv,
    AdvCreative,
    AdvMobileSticky,
    AdvMobileStickyRsya,
    TopInfo,
    PlotsLine,
    HotNews,
    AppHeader,
    AppFooter,
    RegisterSuccessModal,
    UpButton,
    LongreadFooter,
    BlockedUserModal,
    LegacyBrowserWarning,
    NewsSlider
  },
  // TODO: сделать более гибким, чтобы
  // можно было использовать разные колбеки
  // для разных компонентов
  mixins: [VisibilityMixin],
  data() {
    return {
      store: useStore(this.$store),
      destroy: new Subject(),
      currentUrl: '',
      renderFooter: false,
      currentContentHeight: 0,
      savedContentHeight: 0,
      openedOverlay: '',
      isHackLongread: false,
      isAdvStickyHidden: true,
      isAdvBottomIntend: false,
      isRegisterSuccessModalRendered: false,
      isRegisterSuccessModalOpened: false,
      deviceType: undefined,
      scrollHandlerExist: false
    };
  },
  computed: {
    envType() {
      return this.store.envType;
    },
    regionId() {
      return this.store.regionId;
    },
    regionType() {
      return this.store.regionType;
    },
    pageType() {
      return this.store.pageType;
    },
    isIOS() {
      return (
        this.store.deviceInfo.isIOS || (this.isSafari && this.deviceType === 'tablet')
      );
    },
    isIE() {
      return this.store.deviceInfo.isIE;
    },
    isSafari() {
      return this.store.deviceInfo.isSafari;
    },
    isLegacy() {
      return this.store.deviceInfo.isLegacy;
    },
    isTablet() {
      return this.store.deviceInfo.isTablet;
    },
    isMobile() {
      return this.store.deviceInfo.isMobile;
    },
    isDesktop() {
      return this.store.deviceInfo.isDesktop;
    },
    isMobileOnly() {
      return this.isMobile && !this.isTablet;
    },
    isLongread() {
      return this.pageType === PagesType.Longread;
    },
    isVideoPage() {
      return this.store.pageType === PagesType.VideosOfTheDay;
    },
    isShowUpButton() {
      return !this.isMobile && this.deviceType !== 'tablet' && !this.isLongread;
    },
    fetching() {
      return this.store.dataFetching;
    },
    topAdvBlock() {
      return this.store.advModule.topAdvBlock;
    },
    brandingAdfoxBlock() {
      return this.store.advModule.brandingAdfoxBlock;
    },
    bottomAdvBlock() {
      return this.store.advModule.bottomAdvBlock;
    },
    fullscreenAdvBlock() {
      if (this.isScroogeTest && this.pageType === PagesType.Main) {
        return this.store.advModule.fullscreenMainAdvBlock;
      }
      return this.store.advModule.fullscreenAdvBlock;
    },
    mobileStickyAdvBlock() {
      return this.store.advModule.mobileStickyAdvBlock;
    },
    mobileStickyRSYAdvBlock() {
      return this.store.advModule.mobileStickyRSYAdvBlock;
    },
    aTemplateBlock() {
      return this.store.advModule.aTemplateBlock;
    },
    userInfo() {
      return this.store.commonModule.userInfo;
    },
    isNewRecordDesign() {
      return this.store.layoutModule.isNewRecordDesign;
    },
    isShowNewsSlider() {
      return this.isMobileOnly && this.store.commonModule.sliderAbcTest !== 'A';
    },
    isBrandingPage() {
      try {
        return (
          this.isDesktop &&
          !this.isAdblock &&
          guardUnspecified(this.aTemplateBlock?.data?.adfox_branding)
        );
      } catch (error) {
        console.error(error);
      }
    },
    oneSignalConfig() {
      return this.store.commonModule.oneSignalConfig;
    },
    commonGAEvents() {
      if (this.store.commonModule.commonGAEvents) {
        return this.store.commonModule.commonGAEvents.data;
      }

      return [];
    },
    contentMinHeight() {
      return `${this.currentContentHeight}px`;
    },
    isAdblock() {
      return this.store.isAdblock;
    },
    bookmarks() {
      return this.store.userModule.bookmarks;
    },
    guardTabletDevices() {
      return this.isMobile || this.deviceType === 'tablet';
    },
    isAdvMobileStickyShown() {
      if (this.store.advModule.layout === null) {
        return false;
      }

      const isExistAdvBlock =
        guardUnspecified(this.mobileStickyAdvBlock) ||
        guardUnspecified(this.mobileStickyRSYAdvBlock);
      return this.guardTabletDevices && isExistAdvBlock;
    },
    isHoldoutTest() {
      return this.store.layoutModule.isHoldoutTest;
    },
    isUserAuth() {
      return this.store.commonModule.authUserChecked;
    },
    isPushNotificationsEnabled() {
      return this.store.pushModule.isPushNotificationsEnabled;
    },
    isScroogeTest() {
      return this.store.layoutModule.isScroogeTest;
    },
    bindAdvComponent() {
      return {
        is: this.isScroogeTest ? 'AdvCreative' : 'Adv'
      };
    },
    isShowStickyRsya() {
      if (this.store.advModule.layout === 'INTERNATIONAL') {
        return false;
      }

      const hasStickyMobileBranding =
        this.store.advModule.mobileBrandingCreatives?.stickyHtml;
      return !hasStickyMobileBranding && guardUnspecified(this.mobileStickyRSYAdvBlock);
    },
    isE1RecordPage() {
      return (
        this.pageType === PagesType.Record && this.regionId === Regions.Yekaterinburg
      );
    },
    isPushNotificationsForbiddenPage() {
      return (
        this.pageType === PagesType.Record ||
        this.pageType === PagesType.Comment ||
        this.pageType === PagesType.Comments
      );
    },
    isPushNotificationsAllowed() {
      if (!this.isMobile) {
        return true;
      }

      if (this.isE1RecordPage) {
        return true;
      }

      if (!this.isPushNotificationsForbiddenPage) {
        return true;
      }

      return false;
    },
    plotsLineFromUmp() {
      return this.store.commonModule.commonPlotsLineBlock.data;
    },
    isPlotsLineFromUmp() {
      return guardUnspecified(this.plotsLineFromUmp);
    }
  },
  watch: {
    isUserAuth(value) {
      if (value && this.isPushNotificationsEnabled) {
        this.updateUserPushNotificationsBySettings();
      }
    },
    isAdvMobileStickyShown(value) {
      if (value && !this.scrollHandlerExist) {
        window.addEventListener('scroll', () => {
          this.scrollHandlerExist = true;
          this.onScrollPageForBottomStickyElement();
        });
      }
    }
  },
  beforeMount() {
    this.registerModule();

    this.isHackLongread =
      (location.hostname.includes('egk') ||
        location.hostname.includes('ekaterinburg300') ||
        location.hostname.includes('bestinsamara.63.ru') ||
        location.hostname.includes('travel-tele2.e1.ru')) &&
      this.isLongread;

    this.initRouterHooks();
    this.initNewsApiClient();
    this.store.userModule.init();

    this.getUserInfo();

    if (this.$route.hash === '#new_user') {
      setTimeout(() => {
        this.isRegisterSuccessModalRendered = true;
        this.isRegisterSuccessModalOpened = true;
      });
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query
      });
    }
    // проверка на то, что  был сделан редирект после авторизации на facebook
    if (this.$route.hash && this.$route.hash === '#_=_') {
      this.$router.push({
        path: this.$route.path,
        params: this.$route.params,
        query: this.$route.query
      });
    }

    /**
     * Используется рекламным баннером
     */
    window.renderMobileBranding = creatives => {
      if (this.isMobile) {
        this.store.advModule.setMobileBrandingCreatives(creatives);
      }
    };
  },
  beforeCreate() {
    try {
      window.hsmCreativeManagerReady?.then(async manager => {
        await manager.geoReady;
        this.store.advModule.setLayout(manager.configManager.layout);
      });
    } catch (e) {}
  },
  mounted() {
    this.checkIsBackDirection();

    scrollToHash(this.$route);

    if (!this.$route.hash) {
      this.$route.meta.notScroll = false;
    }

    this.deviceType = getBreakpointByWindowWidth(window.innerWidth);

    this.store.pushModule.init({
      deps: { logger: this.logger },
      isUnsupportedPlatform: this.isIOS
    });

    this.setCurrentBreakpoint();

    this.sendReachGoalForCheckAdblock();

    this.initViqeoBannerListeners();

    if (this.isPushNotificationsAllowed) {
      this.initOneSignal();
    }

    this.store.commonModule.menuState$.pipe(takeUntil(this.destroy)).subscribe(state => {
      this.setBodyScrollLock(state);
    });

    this.store.commonModule.alertsState$
      .pipe(takeUntil(this.destroy))
      .subscribe(state => {
        this.setBodyScrollLock(state);
      });

    this.store.commonModule.userMenuState$
      .pipe(takeUntil(this.destroy))
      .subscribe(state => {
        this.setBodyScrollLock(state);
      });

    this.store.commonModule.userForumsState$
      .pipe(takeUntil(this.destroy))
      .subscribe(state => {
        this.setBodyScrollLock(state);
      });

    this.store.layoutModule.imageViewerService.scrollState$
      .pipe(takeUntil(this.destroy))
      .subscribe(state => {
        const overlay = document.getElementById('overlay-modal');
        if (overlay) {
          const formattedState = {
            value: state.value === 'disable' ? 'open' : 'close',
            name: state.name
          };

          this.setBodyScrollLock(formattedState);
        }
      });

    this.detectAdblock();
    this.testYandexAdblock();
  },
  beforeDestroy() {
    clearAllBodyScrollLocks();
    this.clearViqeoBannerListeners();

    this.unsubscribePushNotificationsStates();

    unregisterModule(this.$store, LAYOUT_STORE_NAMESPACE);

    this.destroy.next();
    this.destroy.unsubscribe();
  },
  methods: {
    checkIsBackDirection() {
      const TYPE_BACK_FORWARD = 'back_forward';
      const navigationType = window.performance.getEntriesByType('navigation')[0].type;

      this.$route.meta.isBackDirection = navigationType === TYPE_BACK_FORWARD;
    },
    onScrollPageForBottomStickyElement() {
      const pageYOffset = window.pageYOffset || document.documentElement.scrollTop;
      const heightScreen = window.innerHeight;
      const otherStickyEl = document.querySelector(
        '[data-sticky-id="bottomStickyElement"]'
      );

      if (guardUnspecified(otherStickyEl)) {
        this.isAdvBottomIntend = otherStickyEl.getAttribute('data-sticked') === 'true';
      }

      if (pageYOffset > heightScreen) {
        this.isAdvStickyHidden = false;
      }
    },
    sendNewFormatReachGoal({ blockType, actionType, goalName, prop1, prop2 }) {
      this.store.analyticsModule.sendNewFormatReachGoal({
        blockType,
        actionType,
        goalName,
        prop1,
        prop2
      });
    },
    registerModule() {
      registerModule(this.$store, LAYOUT_STORE_NAMESPACE, new LayoutStore(this.store));
    },
    async showComponent() {
      this.renderFooter = true;
    },
    async getUserInfo() {
      // Проверяем авторизован ли пользователь.
      // Если пользователь изначально пытался зайти на /profile не авторизованным и его перенаправили на главную,
      // то не нужно слать запрос на проверку авторизации, а просто ставим флаг проверки.

      const noRequest = this.$route.hash && this.$route.hash === '#auth';

      await this.store.commonModule.checkAuth(noRequest);
      await this.store.commonModule.checkAuthOnForumNN();

      this.setBookmarks();

      // чтобы не ломалась авторизация через соцсети
      if (noRequest) {
        this.$router.push({ path: this.$route.path });
      }
    },
    setBookmarks() {
      const bookmarks =
        this.userInfo !== null && this.userInfo.bookmarks !== null
          ? this.userInfo.bookmarks
          : [];
      this.store.userModule.updateBookmarksState(bookmarks);
      this.store.userModule.updateRecordsWithBookmark(
        bookmarks.map(({ bookmark }) => bookmark.id)
      );
    },
    async initOneSignal() {
      if (this.isIOS) {
        return;
      }

      await this.store.pushModule.setup();

      if (!this.store.pushModule.isPushNotificationsNotAvailable) {
        this.subscribePushNotificationsStates();
        this.store.pushModule.showPopoverNotifications();
      }
    },
    subscribePushNotificationsStates() {
      this.store.pushModule.subscribeToNotificationsEnabledChange(
        this.onChangeSubscribeToNotificationsState
      );

      this.store.pushModule.subscribeToBrowserNotificationsDeniedChange(isDenied => {
        this.sendRGBrowserPermissionClicked(isDenied);
        this.store.pushModule.unsubscribeToBrowserNotificationsDeniedChange();
      });

      this.store.pushModule.subscribePopoverShown(this.onPushNotificationsPopoverShown);
    },
    subscribePushNotificationsPopoverActions() {
      this.store.pushModule.subscribePopoverActions(event => {
        switch (event) {
          case 'allow':
            this.sendRGNotificationPopoverAllowedClicked();
            break;
          case 'disallow':
            this.sendRGNotificationPopoverDisallowedClicked();
            break;
          default:
            break;
        }
      });
    },
    subscribePushNotificationsPopoverClosed() {
      this.store.pushModule.subscribePopoverClosed(() => {
        this.store.pushModule.unsubscribePopoverActions();
        this.store.pushModule.unsubscribePopoverClosed();
      });
    },
    unsubscribePushNotificationsStates() {
      this.store.pushModule.unsubscribePopoverShown();
      this.store.pushModule.unsubscribePopoverClosed();
      this.store.pushModule.unsubscribePopoverActions();
      this.store.pushModule.unsubscribeToNotificationsEnabledChange();
      this.store.pushModule.unsubscribeToBrowserNotificationsDeniedChange();
    },
    onPushNotificationsPopoverShown() {
      this.setPositionForPushNotificationsPopover();
      this.sendRGForNotificationForms('Просмотр', 'viewFormPushSubscribe');
      this.subscribePushNotificationsPopoverActions();
      this.subscribePushNotificationsPopoverClosed();

      if (this.isMobile && this.isE1RecordPage) {
        this.closePopoverAfterTimeout();
      }
    },
    setPositionForPushNotificationsPopover() {
      const globalWrapperEl = document.querySelector('.global-wrapper');
      const topCoords = globalWrapperEl.getBoundingClientRect().top;

      if (!this.isMobile && topCoords > 0) {
        setTimeout(() => {
          const oneSignalPromptEl = document.querySelector(
            '#onesignal-slidedown-container'
          );
          if (guardUnspecified(oneSignalPromptEl)) {
            oneSignalPromptEl.style.top = `${topCoords}px`;
            oneSignalPromptEl.style.zIndex = '2147483640';
          }
        });
      }
    },
    closePopoverAfterTimeout() {
      setTimeout(() => {
        this.store.pushModule.closePopoverNotifications();
      }, POPOVER_CLOSE_TIMEOUT);
    },
    async onChangeSubscribeToNotificationsState(isEnabled) {
      try {
        if (isEnabled) {
          await this.store.pushModule.getUserPlayerId();
        }
      } finally {
        /**
         * @TODO подумать, как исключать запрос в случае
         * если действие инициализировано пользователем
         * в настроках профиля браузерных уведомлений
         */
        if (guardUnspecified(this.userInfo)) {
          this.updateUserPushNotificationsBySettings();
        }
      }
    },
    updateUserPushNotificationsBySettings() {
      if (guardUnspecified(this.userInfo)) {
        this.store.pushModule.saveUserPushNotificationBrowserSettings({
          profileId: this.userInfo.userId
        });
      }
    },
    initViqeoBannerListeners() {
      if (
        typeof window.jtnewsAdvEventsInfo !== 'undefined' &&
        window.jtnewsAdvEventsInfo.isViqeoBannerMounted
      ) {
        this.initViqeoBannerAnalytics();
      } else {
        document.removeEventListener(
          'jtnViqeoBannerMounted',
          window.setFlagViqeoHasMounted
        );

        document.addEventListener(
          'jtnViqeoBannerMounted',
          this.initViqeoBannerAnalytics,
          { once: true }
        );
      }
    },
    clearViqeoBannerListeners() {
      document.removeEventListener(
        'jtnViqeoBannerMounted',
        this.initViqeoBannerAnalytics
      );
    },
    initViqeoBannerAnalytics() {
      const checkViqeoTimer = setInterval(() => {
        if (window.VIQEO !== undefined) {
          this.initViqeoBannerTracking();
          clearInterval(checkViqeoTimer);
        }
      }, CHECK_VIQEO_INTERVAL);
    },
    initViqeoBannerTracking() {
      window.VIQEO.subscribeTracking(() => {
        this.sendViqeoAnalytics('Просмотр', 'viewStickyVideo');
      }, 'Player:ready');

      window.VIQEO.subscribeTracking(() => {
        this.sendViqeoAnalytics('Закрыть (крестик)', 'clickStickyVideo');
      }, 'Player:destroyed');
    },
    sendViqeoAnalytics(valueName, goalName) {
      const blockType = 'Контент';
      const fieldType = 'Залипающее видео';
      const productName = 'Реклама';

      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName,
        productName,
        willDeprecate: true
      });
      this.sendNewFormatReachGoal({
        blockType,
        actionType: valueName,
        goalName,
        prop1: fieldType,
        prop2: productName
      });
    },
    initNewsApiClient() {
      // @TODO: по-хорошему api-клиент не нужно хранить в стейте
      // придумать потом, как создать глобальный апи-клиент на основе
      // данных из store.envType

      // с клиента всегда ходим по https
      this.store.newsApiClient = new NewsClient(
        this.envType,
        'https',
        getDomainByRegionId(this.regionId)
      );

      this.store.networkContentDataMapper = new NetworkContentDataMapper(
        this.envType,
        this.regionId
      );
    },
    sendReachGoalForCheckAdblock() {
      const name = this.isAdblock ? 'userAdBlockTrue' : 'userAdBlockFalse';

      this.store.analyticsModule.sendReachGoal({
        name
      });
    },
    setBodyScrollLock({ value, name }) {
      const isNotPhone = !this.isMobile || this.isTablet;
      const isDesktopRubricsMenu = this.isDesktop && name === 'menu';
      const isDesktopImageViewer = this.isDesktop && name === 'imageViewer';

      const needExit = isNotPhone && !isDesktopRubricsMenu && !isDesktopImageViewer;

      if (needExit) {
        return;
      }

      this.$nextTick(() => {
        const menuIdMap = {
          alerts: 'header-alerts',
          imageViewer: 'overlay-modal',
          menu: 'header-menu',
          userForums: 'header-forums',
          userMenu: 'header-user-menu'
        };

        const dropdownElement = document.getElementById(menuIdMap[name]);

        if (value === 'open' && this.openedOverlay !== name) {
          if (this.openedOverlay !== null && this.openedOverlay !== '') {
            const openedOverlayElement = document.getElementById(
              menuIdMap[this.openedOverlay]
            );

            enableBodyScroll(openedOverlayElement);
          }

          this.openedOverlay = name;

          disableBodyScroll(dropdownElement, {
            reserveScrollBarGap: true
          });
        }

        if (value === 'close' && this.openedOverlay === name) {
          this.openedOverlay = '';

          enableBodyScroll(dropdownElement);
        }
      });
    },
    setCurrentBreakpoint() {
      const bp = getBreakpointByWindowWidth(window.innerWidth);
      // дополнительная логика из-за того, что в рекламе нет laptop-desktop
      const breakpoint =
        bp === 'laptop' && window.innerWidth >= BREAKPOINTS['laptop-desktop']
          ? 'laptop-desktop'
          : bp;
      this.store.updateCurrentBreakpoint(breakpoint);
    },
    initRouterHooks() {
      this.$router.onError(error => {
        // хак, чтобы если страница была доолго открыта и сделали релиз
        // то небходимого чанка с хэшем может не быть
        // тогда просто обновляем страницу по урлу
        if (error && error.message && error.message.indexOf('Loading chunk') !== -1) {
          if (typeof window !== 'undefined') {
            window.location.href = this.currentUrl;
          }
        }
      });
    },
    sendReachGoalForFullscreen(valueName, goalName) {
      const blockType = 'Контент';
      const fieldType = 'Фулскрин';
      const productName = 'Реклама';

      this.store.analyticsModule.sendNewReachGoal({
        blockType,
        fieldType,
        valueName,
        goalName,
        productName,
        willDeprecate: true
      });
      this.sendNewFormatReachGoal({
        blockType,
        actionType: valueName,
        goalName,
        prop1: fieldType,
        prop2: productName
      });
    },
    sendRGNotificationPopoverAllowedClicked() {
      this.sendRGForNotificationForms(
        { ['Клик']: { ['undefined']: 'Включить' } },
        'clickFormPushSubscribe'
      );
    },
    sendRGNotificationPopoverDisallowedClicked() {
      this.sendRGForNotificationForms(
        { ['Клик']: { ['undefined']: 'Не сейчас' } },
        'clickFormPushSubscribe'
      );
    },
    sendRGBrowserPermissionClicked(value) {
      const valueName = {
        ['Клик']: { ['undefined']: value ? 'Заблокировать' : 'Разрешить' }
      };
      this.sendRGForNotificationForms(valueName, 'clickFormPushBrowserSubscribe');
    },
    sendRGForNotificationForms(valueName, goalName) {
      const blockType = 'Формы';
      const fieldType = 'Подписка на push-уведомления';
      const productName = 'Продукт';

      this.store.analyticsModule.sendNewReachGoal({
        fieldType,
        valueName,
        goalName,
        willDeprecate: true
      });
      this.sendNewFormatReachGoal({
        blockType,
        actionType: valueName,
        goalName,
        prop1: fieldType,
        prop2: productName
      });
    },
    detectAdblock() {
      if (guardUnspecified(getCookie(COOKIES_ADBLOCK))) {
        adblockCookieMatched();
      } else {
        removeCookie(AB_YANDEX_ADBLOCK_COOKIE);
      }

      if (this.isScroogeTest) {
        if (
          !guardUnspecified(window.HSMCreativeManager) ||
          window.HSMCreativeManager.adblock.isEnabled
        ) {
          this.setAdblockCookie();
          return;
        }

        removeCookie(COOKIES_ADBLOCK);
        return;
      }

      //Подстраховка если AdBlock заблочит саму ASDK
      if (
        !guardUnspecified(window.ASDK) ||
        !guardUnspecified(window.ASDK.checkAdblockEnabled)
      ) {
        this.setAdblockCookie();
      }
    },
    setAdblockCookie() {
      const timeLive = 1209600000;
      const date = new Date(Date.now() + timeLive);
      setCookie(COOKIES_ADBLOCK, 'true', { expires: date.toUTCString() });
    },
    testYandexAdblock() {
      // Распределение и кука реализованы на nginx поэтому здесь прикручено это
      const testCookie = getCookie(AB_YANDEX_ADBLOCK_COOKIE);
      const adblockCookie = getCookie(COOKIES_ADBLOCK);
      if (guardUnspecified(testCookie) && guardUnspecified(adblockCookie)) {
        if (testCookie === 'A') {
          this.store.analyticsModule.sendReachGoal({
            name: 'YaAdblock_a'
          });
        }
        if (testCookie === 'B') {
          this.store.analyticsModule.sendReachGoal({
            name: 'YaAdblock_b'
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
@use '~jtn-ui/z-index';

@import 'styles';

#app {
  & .global-wrapper._branding {
    @include on-res-brand-visibility();
  }

  & .global-wrapper:not(._branding) {
    @include on-res-visibility();
  }

  & .global-wrapper._ie {
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.3s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }
  }
}

#onesignal-popover-container.slide-down,
#onesignal-slidedown-container.slide-down {
  top: 400px;
}

.global-wrapper {
  max-width: 1280px;
  margin: auto;

  &._branding {
    @include on-min-resize($desktop) {
      max-width: 1024px;
      padding: 250px 0 0;
    }
  }

  &._longread {
    max-width: 100%;
  }
}

.app-content {
  position: relative;

  box-sizing: border-box;
  width: 100%;
  height: 100%;

  &._data-fetching {
    min-height: 1500px;
  }
}

.content-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: z-index.$above;

  width: 100%;
  height: 100%;

  background-color: #fff;

  opacity: 0.95;
}

.v--modal-overlay {
  background-color: rgba(26, 26, 26, 0.7) !important;
  z-index: z-index.$modal !important;
}

.v--modal-box {
  border: 1px solid $color-surface-200;
  border-radius: 0 !important;
}

.footer-wrapper {
  position: relative;

  min-height: 100px;

  &.withStickyPanel {
    padding-bottom: 80px;
  }
}
</style>
