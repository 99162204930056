export const getConfigItemByType = (config, type) => {
  let item = {};
  if (config) {
    if (Array.isArray(config)) {
      item = config.find(i => i.type === type);

      if (item !== undefined) {
        return item;
      }
    }

    if (typeof config === 'object') {
      return config[type];
    }
  }

  return item;
};
