export interface RecordCustomWidgetInfo {
  script?: string;
  src?: string;
}

export class RecordCustomWidget implements RecordCustomWidgetInfo {
  public readonly script?: string;

  public readonly src?: string;

  public readonly content?: string;

  constructor(info: RecordCustomWidgetInfo & { content?: string }) {
    this.script = info.script;
    this.src = info.src;
    this.content = info.content;
  }
}
