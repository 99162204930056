import { isAbsoluteUrl } from '@portal/utils/util-url';
import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiTypography } from '@jtnews/jtn-ui';
import { injectStylesMixin } from '@jtnews/shared';

import styles from './menu-themes.style.scss?module';

type ThemesItem = {
  hash: string;
  path: string;
  status: string;
  title: string;
  updatedAt: string;
};

export default componentFactoryOf()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'MenuThemes',
    props: {
      items: {
        type: Array as () => ThemesItem[],
        default: []
      }
    },
    methods: {
      toThemes(title: string) {
        this.$emit('sendNewReachGoal', title);
      },
      getTargetLink(path: string) {
        return isAbsoluteUrl(path) ? '_blank' : '_self';
      }
    },
    render() {
      return (
        <ul class={styles.menuThemes}>
          {this.items.map(item => (
            <li key={item.hash} class={styles.item}>
              <JtnUiTypography
                title={item.title}
                href={item.path}
                class={styles.link}
                tag="a"
                type="font-header"
                link-type="link-white"
                target={this.getTargetLink(item.path)}
                onClick={() => this.toThemes(item.title)}
              >
                {item.title}
              </JtnUiTypography>
            </li>
          ))}
        </ul>
      );
    }
  });
