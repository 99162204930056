<template>
  <div :class="[$style['top-info'], deviceTypes]">
    <responsive-list
        v-if="hasThemes"
        :is-desktop="isDesktop"
        :data="themesBlock"
        @showMoreLinks="closeAlerts"
        @sendNewReachGoal="sendNewReachGoal($event)"
    />
  </div>
</template>
<script>
import { guardUnspecified, guardEmptyArray } from '@portal/utils/util-guards';
import ResponsiveList from '../../shared/components/_lists/responsive-list';
import { StoreMixin } from '../../shared/mixins';

export default {
  name: 'TopInfo',
  components: {
    ResponsiveList
  },
  mixins: [StoreMixin],
  props: {
    blockType: {
      type: String,
      default: 'Меню'
    }
  },
  computed: {
    themesBlock() {
      return this.store.commonModule.commonThemesBlock;
    },
    isDesktop() {
      return this.store.deviceInfo.deviceInfo;
    },
    deviceTypes() {
      return this.themesBlock.settings?.types;
    },
    hasThemes() {
      return guardUnspecified(this.themesBlock.data) && guardEmptyArray(this.themesBlock.data);
    }
  },
  methods: {
    sendNewReachGoal(valueName) {
      this.store.analyticsModule.sendNewReachGoal({
        blockType: this.blockType,
        fieldType: 'Строка сюжетов',
        valueName,
        goalName: 'clickMenu',
        willDeprecate: true
      });

      this.store.analyticsModule.sendNewFormatReachGoal({
        goalName: 'ClickMenuThemes',
        blockType: 'Меню',
        actionType: 'Клик',
        prop1: 'Сюжеты',
        prop2: valueName
      });
    },
    closeAlerts() {
      this.store.commonModule.updateAlertsState({
        value: 'close',
        changedBy: 'top-info'
      });
    }
  }
};
</script>
<style lang="scss" module>
@use '~jtn-ui/z-index';

@import 'styles';

.top-info {
  position: relative;
  z-index: z-index.$page--top-info;

  padding: 0 $mobile-padding;

  background: $color-surface;

  @include on-min-resize($tablet) {
    padding: 0 $desktop-padding;
  }
}
</style>
