import { Regions } from '@smh/projects/regions';

type AdvLogoInThemesPosition = 0 | 2 | 4 | 6;

export type AdvLogoInThemes = {
  imageSrc: string;
  altText: string;
  link: string;
  position: AdvLogoInThemesPosition;
  advMarker?: boolean;
};

export const mkb: AdvLogoInThemes = {
  imageSrc: 'https://cdn.ngs.ru/content/logos/adv/mkb/146x27-mkb.svg',
  altText: '',
  link: 'https://mkb.ru/careteam?utm_source=59.ru&utm_medium=brand_button&utm_campaign=careteam_perm?erid=2SDnjcZr7tN',
  position: 2,
  advMarker: true,
};

export const sberBiz: AdvLogoInThemes = {
  imageSrc: 'https://cdn.ngs.ru/content/logos/adv/sber-business/146x27-sber1.svg',
  altText: '',
  link: 'https://bs.serving-sys.ru/Serving/adServer.bs?cn=trd&pli=1087484735&adid=1087484736&ord=[timestamp]&LineID=2SDnjbou7qC',
  position: 2,
  advMarker: true,
};

export const fastar: AdvLogoInThemes = {
  imageSrc: 'https://cdn.ngs.ru/content/logos/adv/fastar/146_27.svg',
  altText: '',
  link: 'https://fastar-expert.ru/kreditovanie/?utm_source=finance&utm_medium=rk&utm_campaign=ngs/?erid=2SDnjeKBEsC',
  position: 2,
  advMarker: true,
};

/**
 * Не выпиливать! Рекламе могут еще логотипов подвезти
 */
export const ADV_LOGO_IN_THEMES: Partial<Record<Regions, AdvLogoInThemes[]>> = {
  [Regions.Perm]: [mkb],
  [Regions.Nsk]: [fastar],
  [Regions.Volgograd]: [sberBiz],
  [Regions.Samara]: [
    {
      ...sberBiz,
      position: 4,
    },
  ],
};
