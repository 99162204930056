import { ofType } from 'vue-tsx-support';

import LazyHydrate from './LazyHydrate.vue';

type LazyHydrateProps = {
  idleTimeout?: number;
  onInteraction?: string | string[] | boolean;
  ssrOnly?: boolean;
  triggerHydration?: boolean;
  whenIdle?: boolean;
  whenVisible?: boolean;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const LazyHydrateTs = ofType<LazyHydrateProps>().convert(LazyHydrate);

export { LazyHydrateTs as LazyHydrate };
