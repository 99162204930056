/* eslint-disable */

const axios = require('axios');

/**
 * @deprecated Новые методы добавлять libs/news-api-client, старые по возможности переносить туда же
 */
class NewsClient {
  constructor(envType = '', protocol = 'http', domain = 'ngs.ru') {
    this._envType = envType;
    this.ERROR_PREFIX = '[NewClient.api]';
    this.HOST = `${protocol}://newsapi.${domain}${this._envType}/`;
    this.VERSION = 'v1';

    this.SUBSCRIPTION_PAGE_URL = '/pages/jtnews/subscription/';
    this.STATIC_PAGE_URL = '/pages/jtnews/static/';

    this.AUTHORIZATION_URL = '/public/account/';
    this.LOGOUT_URL = '/public/account/logout/';
    this.USERS_PROFILE_PUBLIC_URL = '/public/users/';
    this.USERS_PROFILE_SPEC_URL = '/specs/users/';
    this.USER_UID = '/public/users/uid';
    this.RECORD_VOTE = '/public/records/';
    this.SUBSCRIPTION_PUBLIC_URL = '/public/subscriptions/';
    this.SEARCH_PUBLIC_URL = '/public/jtnews/records/';
    this.NOTIFICATIONS_PUBLIC_URL = '/public/jtnews/notifications/';
    this.PHOTO_OF_THE_DAY_BY_ID_URL = '/public/jtnews/photo_of_the_day/';
    this.VIDEO_OF_THE_DAY_BY_ID_URL = '/public/jtnews/video_of_the_day/';
    this.RECORD_PAGE_COMMENTS = '/public/jtnews/services/record_page_comments/';
    this.COMMENTS_REPORT = '/public/jtnews/services/comment_complaint';
    this.MY_NEWS_URL = '/public/jtnews/services/my_news/';
    this.RECORD_FEEDBACK = '/public/jtnews/services/mail_record_feedback/';
    this.MY_OPINION_URL = '/public/jtnews/services/mail_opinion/';
    this.REPORT_MISTAKE_PUBLIC_URL = '/public/jtnews/services/mistakes/';
    this.REPORT_FEEDBACK_PUBLIC_URL = '/public/jtnews/services/report/';
    this.DEFAULT_SEARCH_PARAMS = {
      pageSize: 40,
      currentPage: 1,
      searchText: ''
    };
    // урлы для форумов NN
    this.HOST_NN = `${protocol}://www.nn.ru`;
    this.SP_WIDGET_NOTIFY_NN_URL = '/jtnews/fedsp/notify/';

    this._topRecordsBlockUrl = '/public/jtnews/services/top_news/';
    this._recommendationsBlockUrl = '/public/jtnews/services/recommendation_block/';
    this._autoBlockUrl = '/public/jtnews/services/auto/';
    this._avitoAutoBlockUrl = '/public/jtnews/services/auto/avito';
    this._opinionsBlockUrl = '/public/jtnews/services/opinions/';
    this._doBlockUrl = '/public/jtnews/services/doska/';
    this._loveBlockUrl = '/public/jtnews/services/love/';
    this._everlastingNewsBlockUrl = '/public/jtnews/services/everlasting_news/';
    this._forumBlockUrl = '/public/jtnews/services/forum/';
    this._photosOfTheDayBlockUrl = '/public/jtnews/services/new_photos_of_the_day/';
    this._videosOfTheDayBlockUrl = '/public/jtnews/services/videos_of_the_day/';
    this._editorialOfficeBlockUrl = '/public/jtnews/services/editorial_office/';
    this._footerDataUrl = '/public/jtnews/footer/';
    this._recordLastNewsUrl = '/public/jtnews/services/last_news';
    this._recordImageViewer = '/public/jtnews/services/record/';
    this._authorRecordsUrl = '/public/jtnews/authors/';
    this._forumSearchUrl = '/public/forums/';
  }

  async getStaticPageData(regionId) {
    const url = `${this.HOST}${this.VERSION}${this.STATIC_PAGE_URL}`;
    let response;

    try {
      response = await axios(url, {
        withCredentials: true,
        params: {
          regionId
        }
      });
    } catch (error) {
      throw error.response;
    }

    this._checkResponseData(response.data, url);

    return response.data.result;
  }

  async reportComment(data, regionId) {
    const url = `${this.HOST}${this.VERSION}${this.COMMENTS_REPORT}`;
    const options = {
      withCredentials: true,
      method: 'POST',
      url,
      data,
      headers: {
        'content-type': 'application/json',
        accept: 'vnd.news.v1.jtnews+json'
      },
      params: {
        regionId
      }
    };

    const response = await axios(options);

    return response.data;
  }

  async recordVote(recordId, data) {
    const url = `${this.HOST}${this.VERSION}${this.RECORD_VOTE}${recordId}/vote`;
    const options = {
      withCredentials: true,
      method: 'PUT',
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      url,
      data
    };

    const response = await axios(options);

    return response.data;
  }

  async getActiveRecordVote(recordId, regionId) {
    const url = `${this.HOST}${this.VERSION}${this.RECORD_VOTE}${recordId}/vote`;
    const options = {
      withCredentials: true,
      method: 'GET',
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      url,
      params: {
        regionId
      }
    };

    const response = await axios(options);

    return response.data;
  }

  async getSubscriptionPageData(regionId) {
    const url = `${this.HOST}${this.VERSION}${this.SUBSCRIPTION_PAGE_URL}`;
    let response;

    try {
      response = await axios(url, {
        withCredentials: true,
        params: {
          regionId
        }
      });
    } catch (error) {
      throw error.response;
    }

    this._checkResponseData(response.data, url);

    return response.data.result;
  }

  async getSubscriptionsList(regionId, token) {
    const url = `${this.HOST}${this.VERSION}${this.SUBSCRIPTION_PUBLIC_URL}${token}`;

    const options = {
      withCredentials: true,
      method: 'get',
      url,
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      params: {
        regionId
      }
    };

    let response;

    try {
      response = await axios(options);
    } catch (error) {
      const err = error.response;

      if (err.status === 404) {
        throw error;
      }
    }

    return response.data;
  }

  async createSubscription(subscribeParams) {
    const url = `${this.HOST}${this.VERSION}${this.SUBSCRIPTION_PUBLIC_URL}`;
    const options = {
      withCredentials: true,
      method: 'post',
      url,
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      data: subscribeParams
    };
    let response;

    try {
      response = await axios(options);
    } catch (error) {
      throw error.response;
    }

    return response.data;
  }

  async activateSubscription(token, subscribeParams) {
    const url = `${this.HOST}${this.VERSION}${this.SUBSCRIPTION_PUBLIC_URL}${token}/activate`;

    const options = {
      method: 'put',
      url,
      withCredentials: true,
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      data: subscribeParams
    };
    let response;

    try {
      response = await axios(options);
    } catch (error) {
      throw error.response;
    }

    return response.data;
  }

  async editSubscription(token, subscribeParams) {
    const url = `${this.HOST}${this.VERSION}${this.SUBSCRIPTION_PUBLIC_URL}${token}/edit`;

    const options = {
      method: 'put',
      url,
      withCredentials: true,
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      data: subscribeParams
    };
    let response;

    try {
      response = await axios(options);
    } catch (error) {
      throw error.response;
    }

    return response.data;
  }

  async getPhotoOfTheDayById(regionId, recordId) {
    const url = `${this.HOST}${this.VERSION}${this.PHOTO_OF_THE_DAY_BY_ID_URL}`;

    const response = await axios(url, {
      withCredentials: true,
      params: {
        regionId,
        recordId
      }
    });

    const data = response.data.photo_of_the_day;

    if (data && data.data && !data.data.length) {
      return data.data;
    }

    return {};
  }

  async getVideoOfTheDayById(regionId, recordId) {
    const url = `${this.HOST}${this.VERSION}${this.VIDEO_OF_THE_DAY_BY_ID_URL}`;

    const response = await axios(url, {
      withCredentials: true,
      params: {
        regionId,
        recordId
      }
    });

    const data = response.data.video_of_the_day;

    if (data && data.data && !data.data.length) {
      return data.data;
    }

    return {};
  }

  async getNotificationsRecords(regionId, params) {
    const url = `${this.HOST}${this.VERSION}${this.NOTIFICATIONS_PUBLIC_URL}`;

    const response = await axios(url, {
      withCredentials: true,
      params: {
        ...params,
        regionId
      }
    });

    return response.data;
  }

  async getUserUid(regionId) {
    const url = `${this.HOST}${this.VERSION}${this.USER_UID}`;

    const options = {
      withCredentials: true,
      method: 'GET',
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      url,
      params: {
        regionId
      }
    };

    let response = null;

    try {
      response = await axios(options);
    } catch (err) {
      return null;
    }

    return response.data;
  }

  async checkAuthorization(regionId) {
    const url = `${this.HOST}${this.VERSION}${this.AUTHORIZATION_URL}`;

    const options = {
      withCredentials: true,
      method: 'GET',
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      url,
      params: {
        regionId
      }
    };

    let response = null;

    try {
      response = await axios(options);
    } catch (err) {
      return null;
    }

    return response.data;
  }

  async authorization(username, password, regionId) {
    const url = `${this.HOST}${this.VERSION}${this.AUTHORIZATION_URL}`;

    const options = {
      withCredentials: true,
      method: 'POST',
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      auth: {
        username,
        password
      },
      url,
      params: {
        regionId
      }
    };

    const response = await axios(options);

    return response.data;
  }

  async logout(regionId) {
    const url = `${this.HOST}${this.VERSION}${this.LOGOUT_URL}`;

    const options = {
      withCredentials: true,
      method: 'POST',
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      url,
      params: {
        regionId
      }
    };

    const response = await axios(options);

    return response.data;
  }

  async getSpWidgetNotifyNN() {
    const url = `${this.HOST_NN}${this.SP_WIDGET_NOTIFY_NN_URL}`;

    const options = {
      withCredentials: true,
      method: 'GET',
      url
    };

    let response = null;

    try {
      response = await axios(options);
    } catch (error) {
      throw error.response;
    }

    return response.data.hasNotify;
  }

  async sendFeedbackReport(regionId, data) {
    const url = `${this.HOST}${this.VERSION}${this.REPORT_FEEDBACK_PUBLIC_URL}`;
    const options = {
      withCredentials: true,
      method: 'POST',
      params: {
        regionId
      },
      url,
      data
    };
    let response = null;

    try {
      response = await axios(options);
    } catch (error) {
      throw error.response;
    }

    return response.data.result;
  }

  async sendMyNews(regionId, data) {
    const url = `${this.HOST}${this.VERSION}${this.MY_NEWS_URL}`;
    const options = {
      withCredentials: true,
      method: 'POST',
      params: {
        regionId
      },
      url,
      data
    };
    let response = null;

    try {
      response = await axios(options);
    } catch (error) {
      throw error.response;
    }

    return response.data.result;
  }

  async sendRecordFeedback(regionId, data) {
    const url = `${this.HOST}${this.VERSION}${this.RECORD_FEEDBACK}`;
    const options = {
      withCredentials: true,
      method: 'POST',
      params: {
        regionId
      },
      url,
      data
    };
    let response = null;

    try {
      response = await axios(options);
    } catch (error) {
      throw error.response;
    }

    return response.data.result;
  }

  async sendMyOpinion(regionId, data) {
    const url = `${this.HOST}${this.VERSION}${this.MY_OPINION_URL}`;
    const options = {
      withCredentials: true,
      method: 'POST',
      params: {
        regionId
      },
      url,
      data
    };
    let response = null;

    try {
      response = await axios(options);
    } catch (error) {
      throw error.response;
    }

    return response.data.result;
  }

  async sendMistakesReport(regionId, data) {
    const url = `${this.HOST}${this.VERSION}${this.REPORT_MISTAKE_PUBLIC_URL}`;
    const options = {
      withCredentials: true,
      method: 'POST',
      params: {
        regionId
      },
      url,
      data
    };

    let response = null;

    try {
      response = await axios(options);
    } catch (error) {
      throw error.response;
    }

    return response.data.result;
  }

  _checkResponseData(responseData, url) {
    // @TODO: добавить везде адекватную обработку ошибок от API
    if (responseData.status !== 200) {
      throw new Error(
        `${this.ERROR_PREFIX} Статус ответа от API не равен 200: 'data.status' = ${responseData.status}. URL: ${url}`
      );
    }

    if (!responseData.result) {
      throw new Error(
        `${this.ERROR_PREFIX} Невалидный результат от API: 'data.result' = ${responseData.result}. URL: ${url}`
      );
    }
  }

  _processSearchParams(regionId, params) {
    if (params && Object.values(params).length) {
      return {
        ...params,
        regionId,
        text: params.text || this.DEFAULT_SEARCH_PARAMS.searchText,
        page: params.page || this.DEFAULT_SEARCH_PARAMS.currentPage,
        pagesize: params.pagesize || this.DEFAULT_SEARCH_PARAMS.pageSize
      };
    }

    return {
      regionId,
      text: this.DEFAULT_SEARCH_PARAMS.searchText,
      page: this.DEFAULT_SEARCH_PARAMS.currentPage,
      pagesize: this.DEFAULT_SEARCH_PARAMS.pageSize
    };
  }

  _queryStringify(obj, prefix) {
    // функция для того чтобы axios правильно обрабатывал обьекты в параметрах
    const pairs = [];
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      const enkey = encodeURIComponent(key);
      const keyWithPrefix = prefix ? `${prefix}[${enkey}]` : enkey;
      if (typeof value === 'object') {
        pairs.push(this._queryStringify(value, keyWithPrefix));
      } else {
        pairs.push(`${keyWithPrefix}=${encodeURIComponent(value)}`);
      }
    });
    return pairs.join('&');
  }

  async getRecordNewPosts(regionId, recordId, timestamp) {
    const url = `${this.HOST}${this.VERSION}/public/jtnews/translations/${recordId}/posts`;

    const options = {
      withCredentials: true,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        accept: 'vnd.news.v1.jtnews+json'
      },
      params: {
        regionId,
        lastTime: timestamp
      },
      url
    };

    const response = await axios(options);

    return response.data;
  }

  //@TODO перенести в user-http-client
  async getNewNotices(profileId, regionId) {
    const url = `${this.HOST}${this.VERSION}${this.USERS_PROFILE_PUBLIC_URL}${profileId}/notices/new/count/`;

    const options = {
      withCredentials: true,
      method: 'GET',
      params: {
        regionId
      },
      url
    };

    const response = await axios(options);

    return response.data.data.total;
  }

  async getRecordComments(regionId, recordId, pagesize) {
    const url = `${this.HOST}${this.VERSION}${this.RECORD_PAGE_COMMENTS}`;
    const params = {
      recordId,
      regionId,
      pagesize
    };

    const options = {
      withCredentials: true,
      method: 'GET',
      params,
      url
    };

    const response = await axios(options);

    return response.data;
  }

  async getTopRecordsBlock(params) {
    const { regionId, headers } = params;
    const url = `${this.HOST}${this.VERSION}${this._topRecordsBlockUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId
      }
    });

    return {
      data: response.data
    };
  }

  async getRecommendationsBlock(params) {
    const { regionId, headers } = params;
    const url = `${this.HOST}${this.VERSION}${this._recommendationsBlockUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId
      }
    });

    return {
      data: response.data
    };
  }

  async getAutoBlock(params) {
    const { regionId, headers, isAvito } = params;
    const url = `${this.HOST}${this.VERSION}${
      isAvito ? this._avitoAutoBlockUrl : this._autoBlockUrl
    }`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId
      }
    });

    return {
      data: response.data
    };
  }

  async getOpinionsBlock(params) {
    const { regionId, headers } = params;
    const url = `${this.HOST}${this.VERSION}${this._opinionsBlockUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId
      }
    });

    return {
      data: response.data
    };
  }

  async getDoBlock(params) {
    const { regionId, headers } = params;
    const url = `${this.HOST}${this.VERSION}${this._doBlockUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId
      }
    });

    return {
      data: response.data
    };
  }

  async getLoveBlock(params) {
    const { regionId, headers, pagesize } = params;
    const url = `${this.HOST}${this.VERSION}${this._loveBlockUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId,
        pagesize
      }
    });

    return {
      data: response.data
    };
  }

  async getEverlastingNewsBlock(params) {
    const { regionId, headers } = params;
    const url = `${this.HOST}${this.VERSION}${this._everlastingNewsBlockUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId
      }
    });

    return {
      data: response.data
    };
  }

  async getForumBlock(params) {
    const { regionId, headers } = params;
    const url = `${this.HOST}${this.VERSION}${this._forumBlockUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId
      }
    });

    return {
      data: response.data
    };
  }

  async getPhotosOfTheDayBlock(params) {
    const DEFAULT_PAGE = 1;
    const DEFAULT_PAGE_SIZE = 10;
    const {
      regionId,
      headers,
      page = DEFAULT_PAGE,
      pagesize = DEFAULT_PAGE_SIZE
    } = params;
    const url = `${this.HOST}${this.VERSION}${this._photosOfTheDayBlockUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId,
        page,
        pagesize
      }
    });

    return {
      data: response.data
    };
  }

  async getVideosOfTheDayBlock(params) {
    const DEFAULT_PAGE = 1;
    const DEFAULT_PAGE_SIZE = 10;
    const {
      regionId,
      headers,
      page = DEFAULT_PAGE,
      pagesize = DEFAULT_PAGE_SIZE
    } = params;
    const url = `${this.HOST}${this.VERSION}${this._videosOfTheDayBlockUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId,
        page,
        pagesize
      }
    });

    return {
      data: response.data
    };
  }

  async getEditorialOfficeBlock(params) {
    const { regionId, headers } = params;
    const url = `${this.HOST}${this.VERSION}${this._editorialOfficeBlockUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId
      }
    });

    return {
      data: response.data
    };
  }

  async getRecordImages(params) {
    const { regionId, recordId } = params;
    const url = `${this.HOST}${this.VERSION}${this._recordImageViewer}${recordId}/images`;

    const response = await axios.get(url, {
      withCredentials: true,
      params: {
        regionId
      }
    });

    return {
      data: response.data
    };
  }

  async getFooterData(params) {
    const { regionId, headers } = params;
    const url = `${this.HOST}${this.VERSION}${this._footerDataUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId
      }
    });

    return {
      data: response.data
    };
  }

  async getRecordLastNews({ regionId, excludeIds, datesFrom, headers }) {
    const url = `${this.HOST}${this.VERSION}${this._recordLastNewsUrl}`;

    const response = await axios.get(url, {
      headers,
      withCredentials: true,
      params: {
        regionId,
        excludeIds: excludeIds.join(','),
        datesFrom
      },
      paramsSerializer: params => this._queryStringify(params)
    });

    return {
      data: response.data
    };
  }

  async getAuthorRecords({
    regionId,
    authorId,
    page,
    pagesize,
    dateTo,
    dateFrom,
    rubric,
    text
  }) {
    const url = `${this.HOST}${this.VERSION}${this._authorRecordsUrl}${authorId}/records`;

    const response = await axios(url, {
      withCredentials: true,
      params: {
        regionId,
        authorId,
        page,
        pagesize,
        dateTo,
        dateFrom,
        rubric,
        text
      }
    });

    return {
      ...response.data
    };
  }

  async searchForumsData(regionId, text) {
    const url = `${this.HOST}${this.VERSION}${this._forumSearchUrl}`;
    const options = {
      withCredentials: true,
      method: 'GET',
      url,
      params: {
        regionId,
        text
      }
    };

    const response = await axios(options);

    return {
      ...response.data
    };
  }
}

export default NewsClient;
