import { Observable, Subject } from 'rxjs';

export abstract class WebSocketService<T> {
  private _socketClient: WebSocket | null = null;

  private readonly _message$: Subject<T> = new Subject();

  public readonly host = 'wss://msg.portal.ngs.ru';

  public get message$(): Observable<T> {
    return this._message$.asObservable();
  }

  public sendMessage(state: T): void {
    this._message$.next(state);
  }

  public createWS(wsUrl: string): void {
    if (typeof WebSocket !== 'undefined') {
      try {
        if (this._socketClient === null) {
          this._socketClient = new WebSocket(wsUrl);
        }

        this._socketClient.onopen = () => {
          this.subscribeOnWS();
        };

        this._socketClient.onerror = err => {
          console.error(err);
        };
      } catch (err) {
        console.error(err);
      }
    } else {
      console.error(new Error('Нет поддержки WebSocket'));
    }
  }

  public subscribeOnWS(): void {
    if (this._socketClient !== null) {
      this._socketClient.onmessage = event => {
        try {
          const data = JSON.parse(event.data) as T;
          this.sendMessage(data);
        } catch (err) {
          console.error(err);
        }
      };
    }
  }

  public closeWS(): void {
    if (this._socketClient) {
      this._socketClient.close();
      this._socketClient = null;
    }
  }
}
