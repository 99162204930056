import { guardUnspecified, guardZeroNumber } from '@portal/utils/util-guards';
import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '../../mixins/inject-styles';

import styles from './jtn-ui-avatar.styles.scss?module';

type Gender = 'male' | 'female' | 'unknown';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'JtnUiAvatar',
  props: {
    photo: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    sex: {
      type: String,
      default: ''
    },
    size: {
      type: Number,
      default: 36
    },
    type: {
      type: String as () => 'ny' | 'base' | 'author',
      default: 'base'
    },
    isSquare: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconGender(): Gender {
      if (['male', 'female', 'unknown'].includes(this.sex)) {
        return this.sex as Gender;
      }
      return 'unknown';
    },
    iconType(): string {
      return `${this.type !== 'base' ? this.type + '-' : ''}${this.iconGender}`;
    },
    imageSize(): number | undefined {
      return guardZeroNumber(this.size) ? this.size : undefined;
    }
  },
  methods: {
    getIcon() {
      switch (this.iconType) {
        case 'author-female':
          return (
            <svg class={styles.icon} width={this.imageSize} height={this.imageSize}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-avatars.b19447e839d699679981359f78a677f5.svg#jtn-avatars-author-female"></use>
            </svg>
          );

        case 'author-male':
          return (
            <svg class={styles.icon} width={this.imageSize} height={this.imageSize}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-avatars.b19447e839d699679981359f78a677f5.svg#jtn-avatars-author-male"></use>
            </svg>
          );

        case 'author-unknown':
          return (
            <svg class={styles.icon} width={this.imageSize} height={this.imageSize}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-avatars.b19447e839d699679981359f78a677f5.svg#jtn-avatars-author-unknown"></use>
            </svg>
          );

        case 'female':
          return (
            <svg class={styles.icon} width={this.imageSize} height={this.imageSize}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-avatars.b19447e839d699679981359f78a677f5.svg#jtn-avatars-female"></use>
            </svg>
          );

        case 'male':
          return (
            <svg class={styles.icon} width={this.imageSize} height={this.imageSize}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-avatars.b19447e839d699679981359f78a677f5.svg#jtn-avatars-male"></use>
            </svg>
          );

        case 'ny-female':
          return (
            <svg class={styles.icon} width={this.imageSize} height={this.imageSize}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-avatars.b19447e839d699679981359f78a677f5.svg#jtn-avatars-ny-female"></use>
            </svg>
          );

        case 'ny-male':
          return (
            <svg class={styles.icon} width={this.imageSize} height={this.imageSize}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-avatars.b19447e839d699679981359f78a677f5.svg#jtn-avatars-ny-male"></use>
            </svg>
          );

        case 'ny-unknown':
          return (
            <svg class={styles.icon} width={this.imageSize} height={this.imageSize}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-avatars.b19447e839d699679981359f78a677f5.svg#jtn-avatars-ny-unknown"></use>
            </svg>
          );

        case 'unknown':
          return (
            <svg class={styles.icon} width={this.imageSize} height={this.imageSize}>
              <use xlinkHref="/dist/legacy/svg-sprites/jtn-avatars.b19447e839d699679981359f78a677f5.svg#jtn-avatars-unknown"></use>
            </svg>
          );

        default:
          break;
      }
    }
  },
  render() {
    const COMPONENT = this.link !== '' ? 'a' : 'div';
    const attributes = {
      attrs: { ...this.$attrs },
      on: this.$listeners
    };

    let style = '';

    if (guardZeroNumber(this.size)) {
      style = `width: ${this.size}px; height: ${this.size}px`;
    }

    if (this.link.length > 0) {
      attributes.attrs.href = this.link;
    }

    return (
      <COMPONENT
        class={[styles.avatar, styles[this.type], this.isSquare ? styles.square : '']}
        {...attributes}
        style={style}
      >
        {guardUnspecified(this.$slots.photo) ? (
          this.$slots.photo
        ) : this.photo?.length > 0 ? (
          <img
            class={styles.img}
            src={this.photo}
            width={this.imageSize}
            height={this.imageSize}
            alt="Фото пользователя"
          />
        ) : (
          this.getIcon()
        )}
      </COMPONENT>
    );
  }
});
