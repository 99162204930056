export type RecordVotesNameType = 'like' | 'happy' | 'surprised' | 'angry' | 'sad';

export type RecordVotesDataType = {
  count: number;
  name: RecordVotesNameType;
  title: string;
};

export interface RecordVotesInfo {
  active: RecordVotesNameType;
  data: RecordVotesDataType[];
}

export class RecordVotes implements RecordVotesInfo {
  public readonly active: RecordVotesNameType;

  public readonly data: RecordVotesDataType[];

  constructor(info: RecordVotesInfo) {
    this.active = info.active;
    this.data = info.data;
  }
}
