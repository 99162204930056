import { componentFactory } from 'vue-tsx-support';

const WIDTH = 44;
const HEIGHT = 6;

export default componentFactory.create({
  name: 'AdvLabel',

  render() {
    return (
      <div>
        <svg width={WIDTH} height={HEIGHT}>
          <use xlinkHref="/dist/legacy/svg-sprites/jtn-common.f7875ac1d2b1e964bfa6c21ef5757c8a.svg#jtn-common-adv-label"></use>
        </svg>
      </div>
    );
  }
});
