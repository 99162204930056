import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { getCookie } from 'tiny-cookie';
import { Md5 } from 'ts-md5';

import {
  ExternalScriptsService,
  externalScriptsService
} from '../external-scripts.service';

// eslint-disable-next-line
export interface CaptchaInterface {
  execute: (action?: string) => PromiseLike<string>;
}

export type CaptchaSettings = {
  scriptSrc: string;
  publicKey: string;
  timeout?: number;
};

export class CaptchaService {
  static readonly defaultTimeout = 2000;

  // eslint-disable-next-line
  private _captchaService: CaptchaInterface;
  // eslint-disable-next-line
  private _captchaKey: string;
  // eslint-disable-next-line
  private _externalScriptsService: ExternalScriptsService = externalScriptsService;

  constructor(captcha: CaptchaInterface) {
    this._captchaService = captcha;
  }

  async prepare(settings: CaptchaSettings): Promise<void> {
    return new Promise((res, rej) => {
      const timeoutId = setTimeout(
        rej,
        settings.timeout ?? CaptchaService.defaultTimeout
      );

      this._externalScriptsService
        .injectScriptToHead({
          src: settings.scriptSrc
        })
        .then(res)
        .then(() => {
          clearTimeout(timeoutId);
          this._captchaKey = settings.publicKey;
        })
        .catch(rej);
    });
  }

  async execute(action?: string, hasTimeout?: boolean): Promise<string> {
    return new Promise((res, rej) => {
      if (!guardUnspecified(this._captchaKey) || !guardEmptyString(this._captchaKey)) {
        return rej();
      }

      const timeoutId = hasTimeout ? setTimeout(rej, CaptchaService.defaultTimeout) : 0;

      void this._captchaService
        .execute(action)
        .then(res, rej)
        .then(() => {
          if (hasTimeout && timeoutId != 0) {
            clearTimeout(timeoutId);
          }
        });
    });
  }

  static getFallbackToken(): string {
    const userCookie = getCookie('ngs_uid') || '';
    const { userAgent } = window.navigator;

    return Md5.hashStr(`${userAgent}${userCookie}`);
  }
}
