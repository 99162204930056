<template>
  <div :class="$style['app-widgets']">
    <WidgetAdvHeaderLogo v-if="this.advHeaderLogo" :advData="this.advHeaderLogo" />
    <widget-weather @sendNewReachGoal="$emit('sendNewReachGoal', 'Погода')" />
    <widget-traffic @sendNewReachGoal="$emit('sendNewReachGoal', 'Пробки')" />
    <widget-currency @sendNewReachGoal="$emit('sendNewReachGoal', 'Курс валют')" />
  </div>
</template>
<script>
import WidgetWeather from '../../../shared/widgets/widget-weather';
import WidgetTraffic from '../../../shared/widgets/widget-traffic';
import WidgetCurrency from '../../../shared/widgets/widget-currency';
import { WidgetAdvHeaderLogo } from '../../../shared/widgets/widget-adv-header-logo';

export default {
  name: 'AppWidgets',
  components: {
    WidgetWeather,
    WidgetTraffic,
    WidgetCurrency,
    WidgetAdvHeaderLogo
  },
  props: {
    advHeaderLogo: {
      type: Object,
      default: null
    }
  }
};
</script>
<style lang="scss" module>
@import 'styles';

.app-widgets {
  display: flex;
  justify-content: space-between;
  min-width: 430px;

  :global .current-date {
    display: none;
  }

  @include on-res($tablet) {
    display: none;
  }

  @include on-min-resize($laptop) {
      justify-content: center;
      flex-grow: 1;

      & > *:not(:last-child) {
        margin-right: 40px;
      }
  }

  :global(.global-wrapper:not(._branding)) & {
    @include on-min-resize($desktop) {
      justify-content: center;
      min-width: 650px;
      padding: 0 4px;
      flex-grow: 1;

      :global .current-date {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: auto;
        height: $header-height;
        padding-right: 0;
        box-sizing: border-box;
      }

      & > *:not(:last-child) {
        margin-right: 60px;
      }
    }
  }
}
</style>
