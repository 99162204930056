import { Regions } from '@smh/projects/regions';

import { Image } from '@jtnews/shared/news';
import type { NewsApiClientError } from '@jtnews/shared/newsapi/error';
import type { BaseUserResponseDTO } from '@jtnews/shared/newsapi/user';
import { UserHttpClient } from '@jtnews/shared/newsapi/user';

type SaveRecordInHistoryParams = {
  regionId: Regions;
  profileId: number;
  type: string;
  recordId: number;
};

type AddBookmarkParams = {
  regionId: number;
  profileId: number;
  recordId: number;
  type: string;
};

type DeleteBookmarkParams = {
  regionId: number;
  profileId: number;
  bookmarkId: string;
};

type Format = {
  id: number;
  name: string;
  url: string;
};

type Theme = {
  id: number;
  name: string;
  path: string;
  url: string;
};

type Rubric = {
  id: string;
  name: string;
  path: string;
};

type BookmarkRecord = {
  id: number;
  header: string;
  mainPhoto: Image | null;
  publishAt: string;
  formats?: Format[];
  themes?: Theme[];
  rubrics: Rubric[];
  subheader?: string;
  urls: {
    url: string;
    urlComments: string;
    urlCanonical: string;
  };
  viewsCount?: number;
  commentsCount?: number;
  isCommentsAllowed: boolean;
  isCommercial: boolean;
};

type Bookmark = {
  id: string;
  bookmark: BookmarkRecord;
  type: string;
};

export class UserDataMapper {
  private readonly _userHttpClient: UserHttpClient;

  constructor(envType: string) {
    this._userHttpClient = new UserHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async confirmNotifyEmail(
    regionId: Regions,
    profileId: number,
    data: FormData
  ): Promise<BaseUserResponseDTO> {
    try {
      return await this._userHttpClient.confirmNotifyEmail(regionId, profileId, data);
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      throw (err as NewsApiClientError)?.response?.data;
    }
  }

  public async saveRecordInBrowsingHistory({
    regionId,
    profileId,
    recordId,
    type
  }: SaveRecordInHistoryParams): Promise<BaseUserResponseDTO> {
    try {
      return await this._userHttpClient.saveRecordInBrowsingHistory({
        regionId,
        profileId,
        recordId,
        type
      });
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      throw (err as NewsApiClientError)?.response?.data;
    }
  }

  public async addBookmark(params: AddBookmarkParams): Promise<Bookmark> {
    try {
      return await this._userHttpClient.addBookmark(params);
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      throw (err as NewsApiClientError)?.response?.data;
    }
  }

  public async deleteBookmark(params: DeleteBookmarkParams): Promise<void> {
    try {
      return await this._userHttpClient.deleteBookmark(params);
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      throw (err as NewsApiClientError)?.response?.data;
    }
  }
}
