import { componentFactory } from 'vue-tsx-support';

import { LazyHydrate } from '@jtnews/shared';

import { tsStoreMixin } from '../../../mixins';
import { AdvCreative } from '../adv-creative';

type ComponentData = {
  isClosed: boolean;
};

export type AdvData = {
  data: string;
  path: string;
  settings: {
    place: string;
    placeId: number;
    sticky: boolean;
    subPlace: string;
    types: {
      desktop: boolean;
      laptop: boolean;
      mobile: boolean;
      tablet: boolean;
    };
  };
  type: string;
} | null;

export default componentFactory.mixin(tsStoreMixin).create({
  name: 'AdvMobileStickyRsyaBody',
  props: {
    adv: {
      type: Object as () => AdvData,
      default: null
    }
  },
  data(): ComponentData {
    return {
      isClosed: false
    };
  },
  computed: {
    isScroogeTest() {
      return this.store.layoutModule.isScroogeTest;
    },
    advData(): string {
      return this.adv?.data || '';
    }
  },
  mounted() {
    if (!this.isScroogeTest) {
      this.injectAdvScript();

      document.addEventListener('creativeClosed', this.onClose);
    }
  },
  beforeDestroy() {
    document.removeEventListener('creativeClosed', this.onClose);
  },
  methods: {
    injectAdvScript(): void {
      const parsedAdv = this.store.advModule.advService.processAdvContent(this.advData);
      void this.store.commonModule.externalScriptsService.injectScriptToHead(
        parsedAdv.script
      );
    },
    onClose() {
      if (!(window as any).isStickyRefreshProcessing) {
        this.isClosed = true;
      }
    }
  },
  render() {
    return (
      <div
        style={this.isClosed ? 'display: none' : ''}
        data-cms-place={this.adv?.settings?.placeId}
      >
        {this.isScroogeTest ? (
          <AdvCreative adv={this.adv} clientRender={true} />
        ) : (
          <LazyHydrate ssrOnly={true}>
            <div domPropsInnerHTML={this.advData} />
          </LazyHydrate>
        )}
      </div>
    );
  }
});
