import { RouteConfig } from 'vue-router';

import { RUBRICS_REGEX_IDS, RUBRICS_REGEX_SECTION_IDS } from '@jtnews/shared/data';

type ArchiveModuleType = typeof import('./pages/archive.vue');
type ArchiveByDateModuleType = typeof import('./pages/archive-by-date.vue');
type NotificationsModuleType = typeof import('./pages/notifications.vue');
type TagModuleType = typeof import('./pages/tag.vue');
type FormatModuleType = typeof import('./pages/format.vue');
type ThemeModuleType = typeof import('./pages/theme.vue');
type LongreadsModuleType = typeof import('./pages/longreads.vue');

export const ARCHIVE_ROUTING: RouteConfig[] = [
  // страница архива (Все новости)
  {
    path: '/text/',
    component: (): Promise<ArchiveModuleType> =>
      import(/* webpackChunkName: "archive-page" */ './pages/archive.vue')
  },
  // страница архива по дате
  {
    path: `/text/:rubricId${RUBRICS_REGEX_IDS}/:year(\\d{4})/:month(\\d{2})?/:day(\\d{2})?`,
    component: (): Promise<ArchiveByDateModuleType> =>
      import(/* webpackChunkName: "archive-by-date" */ './pages/archive-by-date.vue')
  },
  {
    path: '/text/:year(\\d{4})/:month(\\d{2})?/:day(\\d{2})?',
    component: (): Promise<ArchiveByDateModuleType> =>
      import(/* webpackChunkName: "archive-by-date" */ './pages/archive-by-date.vue')
  },
  {
    path: '/text/:relativePeriod(today|yesterday|week)?',
    component: (): Promise<ArchiveByDateModuleType> =>
      import(/* webpackChunkName: "archive-by-date" */ './pages/archive-by-date.vue')
  },
  // страница уведомлений
  {
    path: '/text/notifications/',
    component: (): Promise<NotificationsModuleType> =>
      import(/* webpackChunkName: "notifications-page" */ './pages/notifications.vue')
  },
  // страница архива (Новости по тегу)
  {
    path: '/text/tags/:tag',
    component: (): Promise<TagModuleType> =>
      import(/* webpackChunkName: "tag-page" */ './pages/tag.vue')
  },
  // страница архива (Новости по формату)
  {
    path: '/text/format/:format',
    component: (): Promise<FormatModuleType> =>
      import(/* webpackChunkName: "format-page" */ './pages/format.vue')
  },
  // страница архива (Новости по теме)
  {
    path: '/text/theme/:themeId(\\d+)',
    component: (): Promise<ThemeModuleType> =>
      import(/* webpackChunkName: "theme-page" */ './pages/theme.vue')
  },
  // страница архива сетевых материалов
  {
    path: '/text/weekend/',
    component: (): Promise<NotificationsModuleType> =>
      import(/* webpackChunkName: "networks-page" */ './pages/networks.vue')
  }
];

export const ARCHIVE_LONGREAD_ROUTING = [
  // страница архива (Лонгриды)
  {
    path: '/text/longreads/',
    component: (): Promise<LongreadsModuleType> =>
      import(/* webpackChunkName: "longreads-page" */ './pages/longreads.vue')
  }
];
