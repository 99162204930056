/* eslint-disable no-useless-escape */
export const COOKIES_ADBLOCK = 'jtnews_a_template';
export const AB_YANDEX_ADBLOCK_COOKIE = 'jtnews_ab_proxy_e1_v3';
export const COOKIES_COMMENTS_SORT_NAME = 'jtnews_comments_sort';

export const LOCAL_STORAGE_STORIES_VOTE_KEY = 'jtnews_stories_votes';
export const LOCAL_STORAGE_RECORD_PAGE_COMMENT_SENT_KEY =
  'jtnews_send_comment_from_record';

export const OPINION_RULE_LINK = '/opinion/';

export const DEFAULT_DATE_TIME_FORMAT = 'D MMMM YYYY, HH:mm';
export const DEFAULT_DATE_FORMAT = 'D MMMM YYYY';
export const PHOTO_AND_VIDEO_PAGE_SIZES = 17;
export const PHOTO_WIDGET_VISIBLE_PAGES_COUNT = 3;
export const VIDEO_WIDGET_VISIBLE_PAGES_COUNT = 2;

export const HTML_TAGS_REGEX = /<(|\/|[^\/>][^>]+|\/[^>][^>]+)>|(\">)/i;
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
export const EMAIL_SGP_DOMAIN_REGEX = /^[^\s@]+@(ngs|eml|mosk|msk1|samara24|nn|prm)\.ru$/;
export const PHONE_REGEX =
  /^[\+]?[0-9]?\(?[0-9]{3}\)?[-\s\.]?[0-9]{3}([-\s\.]?[0-9]{2}){2}$/;
export const REGEX_COMMENTS_TEXT_FOR_COMMENTS_PAGE =
  /^(?!(\<br\>|\<br\s\/\>|\<a ((href=\".+\"|rel=\"ugc,nofollow\"|target=\"_blank\")\s?){3}\>|\<\/a\>))<\/?[^>]+>$/g;
export const REGEX_COMMENTS_TEXT = /^(?!(\<br\>|\<br\s\/\>))<\/?[^>]+>$/g;
export const PASSWORD_ALLOW_SYMBOLS_REG_EX =
  /^[0-9a-zA-Z`!@#\$%\^&\*\(\)\-_=\+\[\]{};:"\\\|,\.<>\/\?]+$/;
export const SYMBOLS_REG_EX = /[`!@#\$%\^&\*\(\)\-_=\+\[\]{};:"\\\|,\.<>\/\?]+/;
export const LETTERS_REG_EX = /[a-zA-Z]+/;
export const NUMBERS_REG_EX = /\d+/;

export const TABLET_MIN_WIDTH = 600;
export const LAPTOP_MIN_WIDTH = 900;
export const LAPTOP_DESKTOP_MIN_WIDTH = 1024;
export const DESKTOP_MIN_WIDTH = 1280;
