<template>
  <div class="sp-modal-container">
    <no-ssr>
      <modal
        :adaptive="true"
        :width="350"
        :min-height="360"
        :scrollable="true"
        height="auto"
        name="sp-modal"
      >
        <sp-widget
          width="100%"
          height="400"
          @clickCloseBtn="closeModal"
        />
      </modal>
    </no-ssr>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr';
import { SpWidget} from '../../../components/sp-widget';

export default {
  name: 'SpModal',
  components: {
    'no-ssr': NoSSR,
    SpWidget
  },
  methods: {
    closeModal() {
      this.$modal.hide('sp-modal');
    }
  }
};
</script>
