export type RecordKeypointsParamsType = {
  title: string;
};

export type RecordKeypointsDataType = {
  date: string;
  id: number;
  keylabel: string;
};

export interface RecordKeypointsInfo {
  data: RecordKeypointsDataType[];
  params: RecordKeypointsParamsType;
}

export class RecordKeypoints implements RecordKeypointsInfo {
  public readonly data: RecordKeypointsDataType[];

  public readonly params: RecordKeypointsParamsType;

  constructor(info: RecordKeypointsInfo) {
    this.data = info.data;
    this.params = info.params;
  }
}
