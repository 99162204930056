import { BehaviorSubject } from 'rxjs';

export type UserForumsState = {
  value: 'close' | 'open';
  name?: string;
};

export class UserForumsService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  static DEFAULT_PARAMS_STATE: UserForumsState = {
    value: 'close',
    name: 'userForums'
  };

  // eslint-disable-next-line
  private _state: BehaviorSubject<UserForumsState> = new BehaviorSubject(
    UserForumsService.DEFAULT_PARAMS_STATE
  );

  // eslint-disable-next-line
  private _name = 'userForums';

  public get state(): BehaviorSubject<UserForumsState> {
    return this._state;
  }

  public changeState(state: UserForumsState): void {
    const name = this._name;
    this._state.next({ ...state, name });
  }
}

export const userForumsService = new UserForumsService();
