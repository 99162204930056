import { Regions } from '@smh/projects/regions';
import { Observable, Subject } from 'rxjs';

import { HotNews } from '../domain';

type HotNewsData = {
  id?: string;
  recordId?: string;
  text?: string;
  url?: string;
  type?: 'urgent' | 'topic';
};

export class HotNewsService {
  private _ws: WebSocket;

  private readonly _state$: Subject<HotNews> = new Subject();

  private readonly _envType: string;

  private readonly _regionId: Regions;

  constructor(envType: string, regionId: Regions) {
    this._envType = envType;
    this._regionId = regionId;
  }

  public get state$(): Observable<HotNews> {
    return this._state$.asObservable();
  }

  public mutationState(state: HotNews): void {
    this._state$.next(state);
  }

  private get _hotNewsWSUrl() {
    return `wss://msg.portal.ngs.ru${this._envType}/wss/hot-news/subscribe?regionId=${this._regionId}`;
  }

  public createWS(): void {
    if (typeof WebSocket !== 'undefined') {
      try {
        this._ws = new WebSocket(this._hotNewsWSUrl);

        this._ws.onopen = () => {
          this.subscribeOnWS();
        };

        this._ws.onerror = err => {
          console.error(err);
        };
      } catch (err) {
        console.error(err);
      }
    } else {
      console.error(new Error('Нет поддержки WebSocket'));
    }
  }

  public subscribeOnWS(): void {
    this._ws.onmessage = event => {
      try {
        const data = JSON.parse(event.data) as HotNewsData;
        const hotNews = {
          data: {
            id: data.id ? data.id.toString() : '0',
            recordId: data.recordId || '',
            text: data.text || '',
            url: data.url || '',
            type: data.type || 'topic'
          },
          settings: {
            types: {
              mobile: true,
              tablet: true,
              laptop: true,
              desktop: true
            }
          }
        } as HotNews;
        this.mutationState(hotNews);
      } catch (err) {
        console.error(err);
      }
    };
  }

  public closeWS(): void {
    if (this._ws) {
      this._ws.close();
    }
  }
}
