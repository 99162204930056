/* eslint-disable */

export class ExternalScriptsService {
  // @TODO:
  _injectedScripts /* : Set<string> */ = new Set();

  injectScriptToHead(
    script /* : {
    src?: string,
    content?: string,
    raw: string,
    required: boolean
  } */
  ) {
    // eslint-disable-next-line
    return new Promise(resolve => {
      const includeScript = document.createElement('script');

      if (script.src) {
        if (this._injectedScripts.has(script.src) && !script.required) {
          return resolve(true);
        }

        includeScript.src = script.src;
      }

      if (script.content) {
        includeScript.innerHTML = script.content;
      }

      includeScript.type = 'text/javascript';

      includeScript.addEventListener('load', () => {
        if (script.src) {
          this._injectedScripts.add(script.src);
        }

        return resolve(false);
      });

      document.getElementsByTagName('head')[0].appendChild(includeScript);
    });
  }

  // eslint-disable-next-line
  injectStyleToHead(style /* : { raw: string, content: string } */) {
    const includeStyle = document.createElement('style');
    includeStyle.type = 'text/css';
    includeStyle.appendChild(document.createTextNode(style.content));
    document.getElementsByTagName('head')[0].appendChild(includeStyle);
  }

  // eslint-disable-next-line
  getSocialTypeByContent(content /* : string */) {
    const instagramRegEx = /<blockquote(.*)class="(instagram-media)".*/g;
    const jigsawRegEx = /<iframe(.*)(jigsawplanet\.com)(.*)<\/iframe>/g;
    const yandexMusicRegEx = /<iframe(.*)(music\.yandex\.ru\/iframe)(.*)<\/iframe>/g;
    const twitterRegEx = /<blockquote(.*)(twitter\.com)(.*)<\/blockquote>/g;
    const tiktokRegEx = /<blockquote(.*)(tiktok\.com)(.*)<\/blockquote>/g;
    const playbuzzClass = 'playbuzz';
    const telegramClass = 'telegram-post';

    let res = instagramRegEx.exec(content);
    if (res !== null && res[2]) {
      return 'instagram';
    }

    res = jigsawRegEx.exec(content);
    if (res !== null && res[2]) {
      return 'jigsaw';
    }

    res = yandexMusicRegEx.exec(content);
    if (res !== null && res[2]) {
      return 'yandex-music';
    }

    res = twitterRegEx.exec(content);
    if (res !== null && res[2]) {
      return 'twitter';
    }

    res = tiktokRegEx.exec(content);
    if (res !== null && res[2]) {
      return 'tiktok';
    }

    if (content && content.indexOf(playbuzzClass) !== -1) {
      return 'playbuzz';
    }

    if (content && content.indexOf(telegramClass) !== -1) {
      return 'telegram';
    }

    return null;
  }
}

export const externalScriptsService = new ExternalScriptsService();
