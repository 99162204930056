<template>
  <app-base />
</template>

<script>
import { Regions } from '@jtnews/shared/seedwork/frontend/domain';
import {
  Logger,
  ReachGoalAnalytics,
  getYandexCountersIds
} from '@jtnews/shared/seedwork/frontend/infrastructure';
import { useStore } from 'vuex-simple';
import AppBase from './layout/containers/app-base';

const logger = () => {
  // TODO: сменить на прокси-урл
  const SENTRY_DSN = 'http://ffe756ced6be49f4b87f30b70d20294d@sentry.k8s.portal.s/13';

  const isProd = process.env.NODE_ENV === 'production';

  return isProd
    ? new Logger({
        dsn: SENTRY_DSN,
        extra: ['reason', 'regionId']
      })
    : {
        capture: input => {
          console.error(input);
        }
      };
};
export default {
  name: 'App',
  provide() {
    return { logger: logger(), analyticsService: this.analytics };
  },
  components: {
    AppBase
  },
  data() {
    return {
      store: useStore(this.$store)
    };
  },
  created() {
    this.store.analyticsModule.init(this.store.domain, this.store.envType);
  },
  computed: {
    analytics() {
      return new ReachGoalAnalytics({
        yandex: {
          counters: getYandexCountersIds({ regionId: this.store.regionId })
        },
        domain: this.domain,
        experiments: this.experiments,
        sendOnlyByProps: this.store.regionId === Regions.Sochi
      });
    },
    domain() {
      return `https://analytics.${this.store.domain}${this.store.envType}/v1/`;
    },
    experiments() {
      return this.store.experimentNames;
    }
  },
  beforeMount() {
    this.store.commonModule.init();
  }
};
</script>
<style lang="scss">
@import 'jtn-ui/reset';
@import 'jtn-ui/css-vars';
@import 'ui/css-vars';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.jtn-ui--theme-orange {
  // theme colors
  --jtnews-color-primary: #ff5200;
  --jtnews-color-secondary: #ff5200;
  --jtnews-color-button: #ff5200;
  --jtnews-color-link-highlight: #ffe3d6;
  // text colors
  --jtnews-color-text: #37404d;
  --jtnews-color-caption: #77808c;
  --jtnews-color-link-primary: #ff5200;
  --jtnews-color-link-primary-hover: #ff520099;
  --jtnews-color-link-secondary: #37404d;
  --jtnews-color-link-secondary-hover: #ff5200;
  --jtnews-color-link-tertiary: #77808c;
  --jtnews-color-link-tertiary-hover: #ff5200;
}
</style>
