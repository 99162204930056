/* eslint-disable */
import { BehaviorSubject, Observable } from 'rxjs';
import { Action, Getter, State } from 'vuex-simple';

import type { Supercover } from '../../features/main/domain/supercover';
import { Record } from '../../shared/models/record';
import type { RecordPost, RecordCards } from '../../shared/models/record';
import {
  RecordKeypoints,
  RecordKeypointsInfo
} from '../../shared/models/record-keypoints';
import { ImageViewerService, imageViewerService } from '../services/image-viewer.service';

import { Media, media } from '../services/media.service';
import {
  RecordNavigation,
  recordNavigation
} from '../services/record-navigation.service';
import type { CurrentPoint as CurrentPointAlias } from '../services/record-navigation.service';

import { generateImageUrl } from '@jtnews/shared';
import { RootModule } from './store';
import { Record as RecordData } from '@jtnews/shared/news';
import { guardEmptyArray, guardUnspecified } from '@portal/utils/util-guards';
import { isAbsoluteUrl } from '@portal/utils/util-url';
import { WindowMapper } from '../services/mappers/window-mapper.service';
import { getResponsiveImageData, ImageAspectRatio } from '@jtnews/shared/images';

const IMAGES_TYPES = {
  images: 'images',
  gallery: 'gallery',
  doublePhoto: 'doublePhoto'
};

type AuthorRecordsData = {
  params: {
    title?: string;
  };
  data: RecordData[];
};
type CurrentPoint = CurrentPointAlias;

const DEFAULT_AUTHOR_RECORDS_DATA: AuthorRecordsData = {
  params: {},
  data: []
};

type Author = {
  id: string;
  url?: string;
  name: string;
  targetUrl?: string;
  jobPosition: string;
  image?: {
    fileName: string;
    width: number;
    height: number;
  };
};

const prepareAuthors = (
  authors: Author[],
  isMobile: boolean,
  isOpinion: boolean
): Author[] => {
  if (guardUnspecified(authors) && guardEmptyArray(authors)) {
    const mainImageSize = isOpinion ? (isMobile ? 64 : 90) : authors.length > 1 ? 32 : 48;

    return authors.map((author, index) => {
      const imageSize = index === 0 ? mainImageSize : 32;

      const authorAvatar = getResponsiveImageData({
        url: author.image?.fileName ?? '',
        width: imageSize,
        aspectRatio: ImageAspectRatio.Square,
        isLazy: false,
        isPreloadImage: false,
        values: [
          {
            breakpoint: 0,
            width: imageSize,
            noMedia: true
          }
        ]
      });

      return {
        ...author,
        image: {
          fileName: author.image
            ? generateImageUrl({
                url: author.image.fileName ?? '',
                width: imageSize,
                height: imageSize
              })
            : '',
          width: imageSize,
          height: imageSize,
          ...authorAvatar?.data
        },
        targetUrl: isAbsoluteUrl(author.url || '') ? '_blank' : '_self'
      };
    });
  }

  return [];
};

const squashTextLinks = (link: any, leftColumn: any[]) => {
  const lastItemInColumn = leftColumn.at(-1);
  if (lastItemInColumn.type === 'text_link_advert' && lastItemInColumn?.data?.push) {
    lastItemInColumn.data.push(link);
    return;
  }

  leftColumn.push({
    type: 'text_link_advert',
    data: [link]
  });
};

export class LayoutModule {
  constructor(private _root: RootModule) {}

  private _position = 0;

  private _setImagesPosition(widgets: any[]) {
    widgets.forEach(({ type, value }) => {
      if (IMAGES_TYPES[type] && guardUnspecified(value)) {
        this._position++;
        value.position = this._position;
      }
    });
  }

  private _getBlockDataWithImagesPosition(blockData: any): any {
    const data = Object.assign({}, blockData);

    if (data.text?.length) {
      this._setImagesPosition(data.text);
    }

    if (data.cards?.length) {
      data.cards.forEach(({ data }: RecordCards) => this._setImagesPosition(data));
    }

    if (data.posts?.length) {
      data.posts.forEach(({ data }: RecordPost) => this._setImagesPosition(data));
    }

    if (data.authors) {
      data.authors = prepareAuthors(data.authors as Author[], true, true);
    }

    this._position = 0;
    return data;
  }

  private _getBlockData(dataPath: string, blocks: any): any {
    const blockContent = blocks[dataPath];
    return blockContent !== undefined ? blockContent.data : undefined;
  }

  private _getBlockFromByConfig(blockConfig: any, blocks: any): any {
    const dataPath = blockConfig.path;
    const blockData = this._getBlockData(dataPath, blocks);

    return {
      params: blockConfig.params,
      type: blockConfig.type,
      settings: blockConfig.settings,
      data: blockData
    };
  }

  private _isAdvForWindow(blockType: any, blockSettings: any): any {
    return (
      blockSettings.subPlace === 'window' &&
      (blockType === 'interscroller_advert' ||
        blockType === 'adv' ||
        blockType === 'avito_auto' ||
        blockType === 'window_advert' ||
        blockType === 'mobile_record_inread_advert')
    );
  }

  public recordNavigationService: RecordNavigation = recordNavigation;

  public imageViewerService: ImageViewerService = imageViewerService;

  @State()
  public authorRecordsData: AuthorRecordsData = DEFAULT_AUTHOR_RECORDS_DATA;

  @Action()
  public setCurrentPointId(params: CurrentPoint): void {
    this.recordNavigationService.setCurrentPointId(params);
  }

  @Action()
  public setRecordHeight(height: number): void {
    this.recordNavigationService.setRecordHeight(height);
  }

  @Getter()
  public get currentPointId$(): BehaviorSubject<CurrentPoint> {
    return this.recordNavigationService.currentPointId;
  }

  @Getter()
  public get recordHeight$(): BehaviorSubject<number> {
    return this.recordNavigationService.recordHeight;
  }
  @Getter()
  public get isNewRecordDesign(): boolean {
    const isMobile = this._root.deviceInfo.isMobile && !this._root.deviceInfo.isTablet;
    const isNewRecordDesign = this._root.settings.window_redesign ?? false;
    return isMobile && isNewRecordDesign;
  }

  @Getter()
  // TODO: REGIONNEWS-33610
  public get columnsData(): any {
    const config = this._root.config.content;
    const { blocks } = this._root;

    const columnsData: any = {
      rightColumn: [],
      centralColumn: [],
      leftColumn: [],
      windowBlock: {
        data: [],
        button: undefined,
        params: undefined
      },
      record: {},
      // TODO: переименовать в 'record' и использовать
      // данные для записи везде через эту модель
      recordModel: {},
      recordAuthor: {},
      recordForum: null,
      supercover: {}
    };

    // сохранять количество новостей в ленте для вычисления первого места в списке
    let prevNewsFeedsLength = 1;
    let hasWindowMainItem = false;

    for (let i = 0; i < config.length; i++) {
      const blockConfig = config[i];
      const blockSettings = blockConfig.settings;
      let blockData;
      let block;

      switch (blockSettings?.place) {
        case 'left':
          block = this._getBlockFromByConfig(blockConfig, blocks);
          if (block.type === 'news') {
            block.firstNewsPlace = prevNewsFeedsLength;
            prevNewsFeedsLength += block.data.length;
          } else if (
            block.type === 'broadcast_lead_authors' &&
            guardUnspecified(block.data)
          ) {
            const avatarVM = block.data.map(author => {
              const authorAvatar = getResponsiveImageData({
                url: author.image?.url || '',
                width: 48,
                aspectRatio: ImageAspectRatio.Square,
                isLazy: false,
                isPreloadImage: true,
                values: [
                  {
                    breakpoint: 0,
                    width: 90,
                    noMedia: true
                  }
                ]
              });

              return {
                ...author,
                image: guardUnspecified(author.image)
                  ? { ...author.image, ...authorAvatar }
                  : null
              };
            });
            block.data = avatarVM;
          }

          if (block.type === 'text_link_advert' && block?.data?.placement !== 'vb-left-1') {
            squashTextLinks(block, columnsData.leftColumn);
          } else {
            columnsData.leftColumn.push(block);
          }
          break;

        case 'right':
          block = this._getBlockFromByConfig(blockConfig, blocks);
          columnsData.rightColumn.push(block);
          break;

        case 'central':
          // eslint-disable-next-line
          const blockType = blockConfig.type;
          // eslint-disable-next-line
          const isAdvForWindow = this._isAdvForWindow(blockType, blockSettings);

          // формируем windowBlock
          if (blockType === 'centralNews' || isAdvForWindow) {
            if (blockType === 'centralNews') {
              if (
                columnsData.windowBlock.button === undefined &&
                blockConfig.params !== undefined &&
                blockConfig.params.link !== undefined
              ) {
                columnsData.windowBlock.button = {
                  link: blockConfig.params.link,
                  buttonText: blockConfig.params.button_text
                };
              }

              if (
                columnsData.windowBlock.params === undefined &&
                blockConfig.params !== undefined
              ) {
                columnsData.windowBlock.params = blockConfig.params;
              }

              blockData = this._getBlockData(blockConfig.path, blocks);
              if (blockData !== undefined && Array.isArray(blockData)) {
                blockData = WindowMapper.getWindowItemVM(
                  blockData,
                  this._root.pageType,
                  !hasWindowMainItem,
                  this.isNewRecordDesign
                );
                hasWindowMainItem = true;
                columnsData.windowBlock.data =
                  columnsData.windowBlock.data.concat(blockData);
              }
            }

            if (isAdvForWindow) {
              block = this._getBlockFromByConfig(blockConfig, blocks);
              columnsData.windowBlock.data.push(block);
            }
          } else if (
            (blockType === 'forum' || blockType === 'forum_search') &&
            blockSettings.subPlace === 'window'
          ) {
            block = this._getBlockFromByConfig(blockConfig, blocks);
            columnsData.windowBlock.data.push(block);
          } else if (blockType === 'article') {
            block = this._getBlockFromByConfig(blockConfig, blocks);
            columnsData.record = {
              ...block,
              data: this._getBlockDataWithImagesPosition(block.data)
            };
            columnsData.recordModel = new Record(block.data);
            columnsData.centralColumn.push(blockConfig);
          } else if (blockType === 'authors') {
            block = this._getBlockFromByConfig(blockConfig, blocks);
            columnsData.recordAuthor = block;
            columnsData.centralColumn.push(blockConfig);
          } else if (blockType === 'record_forum') {
            columnsData.recordForum = blockConfig.params;
            columnsData.centralColumn.push(blockConfig);
          } else if (blockType === 'supercover') {
            block = this._getBlockFromByConfig(blockConfig, blocks);
            columnsData.supercover = block;
            columnsData.centralColumn.push(blockConfig);
          } else if (blockType === 'covid19_statistics') {
            block = this._getBlockFromByConfig(blockConfig, blocks);
            columnsData.covid = block;
            columnsData.centralColumn.push(blockConfig);
          } else if (blockType === 'subscription_block') {
            block = this._getBlockFromByConfig(blockConfig, blocks);
            if (block.data) {
              columnsData.centralColumn.push(block);
            }
          } else if (blockType === 'authors_records') {
            block = this._getBlockFromByConfig(blockConfig, blocks);

            const preparedBlock = {
              type: 'authors_records',
              params: {
                title: block.params?.title ?? ''
              },
              data: block.data ?? [],
              settings: block.settings
            };

            if (preparedBlock.data.length) {
              columnsData.centralColumn.push(preparedBlock);
            }
          } else if (blockType === 'record_footer_author') {
            block = this._getBlockFromByConfig(blockConfig, blocks);
            const isOpinion = columnsData.record?.data.template === 'opinions';
            const isMobile = this._root.deviceInfo.isMobile;

            if (block.data) {
              const data = prepareAuthors(block.data, isMobile, isOpinion);

              columnsData.centralColumn.push({ ...block, isOpinion, data });
            } else if (!block.data && isOpinion) {
              const data = [
                {
                  id: 'anonimous',
                  name: 'Анонимное мнение',
                  jobPosition: '',
                  image: {
                    width: isMobile ? 64 : 90,
                    img: {
                      src: ''
                    }
                  }
                }
              ];
              columnsData.centralColumn.push({ ...block, isOpinion, data });
            }
          } else {
            block = this._getBlockFromByConfig(blockConfig, blocks);
            columnsData.centralColumn.push(block);
          }
          break;

        default:
          break;
      }
    }

    return columnsData;
  }

  @Getter()
  public get leftColumn() {
    return this.columnsData.leftColumn;
  }

  @Getter()
  public get rightColumn() {
    return this.columnsData.rightColumn;
  }

  @Getter()
  public get centralColumn() {
    return this.columnsData.centralColumn;
  }

  // TODO: вынести получание всех данных для материала в отдельный модуль
  @Getter()
  public get windowBlock() {
    return this.columnsData.windowBlock;
  }

  @Getter()
  public get record() {
    return this.columnsData.record;
  }

  @Getter()
  public get recordId() {
    const recordData = this.columnsData.record;
    return recordData && recordData.data ? recordData.data.id : '';
  }

  @Getter()
  public get recordModel() {
    return this.columnsData.recordModel;
  }

  @Getter()
  public get recordAuthor() {
    return this.columnsData.recordAuthor;
  }

  @Getter()
  public get recordForum() {
    return this.columnsData.recordForum;
  }

  @Getter()
  public get supercover(): Supercover | null {
    const supercover = this.columnsData.supercover ?? null;

    if (guardUnspecified(supercover?.data)) {
      const data = supercover.data ?? null;
      const recordParams = supercover.params?.record ?? null;
      const themeParams = supercover.params?.theme ?? null;

      if (!guardUnspecified(data?.image.width)) {
        return { data, recordParams, themeParams };
      }

      const info = getResponsiveImageData({
        url: data.image.url,
        width: data.image.width,
        hasCommercialLabel: data.image.hasCommercialLabel,
        isLazy: false,
        isPreloadImage: true,
        values: [
          {
            breakpoint: 375,
            width: 375
          },
          {
            breakpoint: 600,
            width: 600
          },
          {
            breakpoint: 0,
            width: 907,
            noMedia: true
          }
        ]
      });

      data.image = {
        src: info.data.src,
        sources: info.data.sources,
        decoding: info.data.decoding,
        loading: info.data.loading
      };

      return { data, recordParams, themeParams };
    }

    return null;
  }

  @Getter()
  public get keypoints(): RecordKeypointsInfo | null {
    const keypointData: RecordKeypointsInfo = new RecordKeypoints(
      <RecordKeypointsInfo>{}
    );
    const keypointsConfigs = this.leftColumn.find(
      (block: any) => block.type === 'broadcast_keypoints'
    );

    if (keypointsConfigs) {
      keypointData.params = keypointsConfigs.params;

      const posts = this.recordModel ? this.recordModel.posts : [];

      keypointData.data = posts
        .filter((post: RecordPost) => post.isKeypoint)
        .map((post: RecordPost) => {
          const { id, keylabel, date } = post;
          return { id, keylabel, date };
        });

      return keypointData;
    }

    return null;
  }

  @Getter()
  public get isHoldoutTest(): boolean {
    const { settings } = this._root.data;

    return settings.holdout_test ?? false;
  }

  @Getter()
  public get isScroogeTest(): boolean {
    const settings = this._root.data.settings;

    return settings?.is_scrooge_test ?? false;
  }

  // Media service
  public mediaService: Media = media;

  @Action()
  public setPlayingMediaUrl(url: string): void {
    return this.mediaService.setPlayingMediaUrl(url);
  }

  @Getter()
  public get playingMediaUrl$(): Observable<string> {
    return this.mediaService.playingMediaUrl;
  }
}
