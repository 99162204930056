import { RecordImageInfo, RecordImage } from '../record-image';
import { SocialItem } from '../socials/socials';

export interface RecordAuthorInfo {
  id: number;
  name: string;
  url?: string;
  description?: string;
  email?: string;
  image?: RecordImageInfo;
  jobPosition?: string;
  socialLinks?: SocialItem[];
}

export class RecordAuthor implements RecordAuthorInfo {
  public readonly id: number;

  public readonly name: string;

  public readonly url?: string;

  public readonly description?: string;

  public readonly email?: string;

  public readonly image?: RecordImage;

  public readonly jobPosition?: string;

  public readonly socialLinks?: SocialItem[];

  constructor(authorInfo: RecordAuthorInfo) {
    this.id = authorInfo.id;
    this.name = authorInfo.name;
    this.url = authorInfo.url;
    this.description = authorInfo.description;
    this.email = authorInfo.email;

    if (authorInfo.image) {
      this.image = new RecordImage(authorInfo.image);
    }

    this.jobPosition = authorInfo.jobPosition;
    this.socialLinks = authorInfo.socialLinks;
  }
}
