import { RouteConfig } from 'vue-router';

type PhotosOfTheDayModule = typeof import('./pages/photos-of-the-day');
type VideosOfTheDayModule = typeof import('./pages/videos-of-the-day');

export const PHOTO_AND_VIDEOS_ROUTING: RouteConfig[] = [
  // страница фото дня
  {
    path: '/photos/',
    component: (): Promise<PhotosOfTheDayModule> =>
      import(
        /* webpackChunkName: "photos-of-the-day-page" */ './pages/photos-of-the-day'
      ),
    alias: ['/text/photos/']
  },
  // страница видео дня
  {
    path: '/videos/:videoId(\\d+)?',
    component: (): Promise<VideosOfTheDayModule> =>
      import(
        /* webpackChunkName: "videos-of-the-day-page" */ './pages/videos-of-the-day'
      ),
    props: true,
    alias: ['/text/videos/']
  }
];
