<template>
  <li v-if="plot.title !== '' || getImage" :class="$style.plot">
    <JtnUiTypography
      :class="[
        $style.link,
        $style[theme],
        isFullTemplate && $style['full'],
        (isInfoPlot || isAdvPlot) && $style['minWidthLink']
      ]"
      :href="getUrl"
      :title="plot.title"
      :target="getTargetLink"
      type="font-header-themes"
      :link-type="getLinkType"
      tag="a"
      @click="clickLink(getUrl)"
    >
      <img v-if="isFullTemplate && getImage" :src="getImage" height="27" />
      <span v-else>{{ plot.title }}</span>
    </JtnUiTypography>
    <div
      v-if="isAdvPlot || isInfoPlot"
      :class="[$style.advMarkerWrap, isInfoPlot ? $style['information'] : '']"
      data-click-area=""
      @click="clickAdvMarker()"
    />
  </li>
</template>

<script>
import { isAbsoluteUrl } from '@portal/utils/util-url';
import { JtnUiTypography } from '@jtnews/jtn-ui';
import { getImageUrl } from '@jtnews/shared/images';

export default {
  name: 'Plot',
  components: {
    JtnUiTypography
  },
  props: {
    plot: {
      type: Object,
      required: {}
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    theme() {
      if (this.isFullTemplate) {
        return 'defaultTheme';
      }

      if (this.plot.type === 'URGENT') {
        return 'urgentTheme';
      }

      if (this.plot.type === 'MOURNING') {
        return 'mourningTheme';
      }

      return 'defaultTheme';
    },
    getLinkType() {
      return ['REGULAR', 'ADVERTISING', 'INFORMATION'].includes(this.plot.type)
        ? 'link-dark-type-2'
        : 'link-white-type-2';
    },
    getUrl() {
      const cover = this.isMobile
        ? this.plot.covers?.mobileCover
        : this.plot.covers?.desktopCover;

      return cover?.link || this.plot.covers.mobileMainCover?.link || this.plot.url || '';
    },
    getTargetLink() {
      try {
        if (this.plot.type === 'ADVERTISING' || this.plot.type === 'INFORMATION') {
          return '_blank';
        }

        const currentHost = window.location.host;
        const urlHost = new URL(this.getUrl).host;
        const isCurrentHost = currentHost === urlHost;

        if (isCurrentHost) {
          return '_self';
        }

        return isAbsoluteUrl(this.getUrl) ? '_blank' : '_self';
      } catch (error) {
        return '_blank';
      }
    },
    getImage() {
      const cover = this.isMobile
        ? this.plot.covers?.mobileCover
        : this.plot.covers?.desktopCover;

      if (cover === null) {
        return false;
      }

      const imageUrl = cover.url;

      if (imageUrl.endsWith('.svg')) {
        return imageUrl;
      }

      const extension = imageUrl.split('##').pop();
      let newUrl = imageUrl.split('_');
      newUrl.pop();
      newUrl = `${newUrl.join('_')}.${extension}`;

      return newUrl;
    },
    isFullTemplate() {
      return this.plot.template === 'FULL';
    },
    isAdvPlot() {
      return this.plot.type === 'ADVERTISING';
    },
    isInfoPlot() {
      return this.plot.type === 'INFORMATION';
    }
  },
  methods: {
    clickLink(link) {
      this.$emit('linkClicked', link);
      this.$emit('click-link');
    },
    clickAdvMarker() {
      this.$emit('clickAdvMarker');
    }
  }
};
</script>

<style lang="scss" module>
@import 'styles';

.link {
  display: flex;
  align-items: center;

  height: auto;

  &:not(.full) {
    padding: 6px 8px;
    background-color: $color-surface-100;
  }

  &.defaultTheme {
    @include on-min-resize($laptop) {
      background: none;
      color: $color-surface-600;
    }
  }

  &.urgentTheme {
    background-color: $color-error;
    color: $color-surface;
  }

  &.mourningTheme {
    background-color: $color-surface-600;
    color: $color-surface;
  }

  &.minWidthLink {
    min-width: 60px;
    justify-content: center;
  }
}

.plot {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  padding: 0 0 13px;

  border-radius: 3px;
  overflow: hidden;

  white-space: nowrap;

  & + * {
    margin-left: 6px;
  }

  @include on-min-resize($laptop) {
    padding: 14px 4px 13px;
    flex: 0 0 auto;
  }

  .link {
    @include on-res($mobile) {
      font-size: 13px;
      line-height: 15px;
    }

    @include on-min-resize($laptop) {
      height: 20px;

      &.full {
        height: 27px;
      }

      &:not(.full) {
        padding: 4px 8px;
      }
    }
  }

  img {
    display: block;
  }
}

.advMarkerWrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10px;

  &:after {
    content: 'Реклама';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    width: 40px;
    margin: 0 auto;

    color: #8e8e8e;

    font-size: 6px;
    font-family: Roboto, sans-serif, Arial;
    letter-spacing: 2.28px;
    text-transform: uppercase;
  }

  &.information {
    &:after {
      content: 'Информация';
      width: 60px;
    }
  }
}
</style>
