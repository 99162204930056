import { guardUnspecified } from '@portal/utils/util-guards';
import { isAbsoluteUrl } from '@portal/utils/util-url';
import { Regions } from '@smh/projects/regions';
import { componentFactoryOf } from 'vue-tsx-support';

import { JtnUiTypography, JtnUiDropdown } from '@jtnews/jtn-ui';
import { injectStylesMixin, tsStoreMixin } from '@jtnews/shared';
import { AdvLogoInThemes, ADV_LOGO_IN_THEMES } from '@jtnews/shared/data';

import { WidgetAdvLogoInThemes } from '../../../widgets/widget-adv-logo-in-themes';

import styles from './text-list-with-dropdown.styles.scss?module';

interface IEvents {
  onClickedSublistBtn: void;
  onClickedLink: ThemeItem;
  onClick: Event;
}

type ThemeItem = {
  path: string;
  title: string;
  position?: number;
  status?: string;
  hasAutoIcon?: boolean;
  type: 'theme';
  advCompanyName: string;
  advToken: string;
};

type ComponentData = {
  isLoaded: boolean;
  hiddenItems: ThemeItem[];
  isShowAdvPopup: boolean;
  currentAdvCompanyName: string;
  currentAdvToken: string;
  advPopupLeft: number;
  advPopupArrowLeft: number;
};

const MORE_BTN_WRAP = 36;
const MORE_BTN_SIZE = 24;
const CONTENT_PADDING = 20;

export default componentFactoryOf<IEvents>()
  .mixin(injectStylesMixin(styles))
  .mixin(tsStoreMixin)
  .create({
    name: 'TextListWithDropdown',
    props: {
      type: {
        type: String as () => 'projects' | 'themes' | 'supercover',
        default: 'projects'
      },
      items: {
        type: Array as () => ThemeItem[],
        default: []
      },
      isForceSelfTarget: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        isLoaded: false,
        hiddenItems: [],
        isShowAdvPopup: false,
        currentAdvCompanyName: '',
        currentAdvToken: '',
        advPopupLeft: 0,
        advPopupArrowLeft: 0
      };
    },
    computed: {
      fontType(): string {
        let type = '';

        switch (this.type) {
          case 'themes':
          case 'supercover':
            type = 'font-header-themes';
            break;
          case 'project':
          default:
            type = 'font-header';
        }
        return type;
      },
      hasSublist(): boolean {
        return this.hiddenItems.length > 0;
      },
      regionId(): Regions {
        return this.store.regionId;
      },
      advLogoInThemes(): AdvLogoInThemes[] | null {
        return ADV_LOGO_IN_THEMES[this.regionId] || null;
      },
      advPositions(): number[] | undefined {
        if (this.advLogoInThemes) {
          return this.advLogoInThemes.map(item => item.position);
        }
      },
      themeOrders(): number[] {
        const orders = [];

        if (this.advPositions) {
          for (let order = 1, index = 0; index < this.items.length; order++) {
            if (!this.advPositions.includes(order)) {
              orders[index] = order;
              index++;
            }
          }
        }

        return orders;
      },
      advPopupStyle() {
        return `left: ${this.advPopupLeft}px;`;
      },
      advPopupArrowStyle() {
        return `left: ${this.advPopupArrowLeft}px;`;
      }
    },
    mounted() {
      this.updateHiddenList();
    },
    watch: {
      items() {
        this.updateHiddenList();
      }
    },
    methods: {
      updateHiddenList() {
        this.hiddenItems = [];
        const component = this.$el as HTMLElement;
        const elementChildren = (this.$refs.mainList as HTMLElement).children;
        const componentWidth = component.offsetWidth - MORE_BTN_WRAP;

        let itemsWidth = 0;

        for (let i = 0; i < this.items.length; i++) {
          const itemWidth = (elementChildren[i] as HTMLElement).offsetWidth;
          itemsWidth += itemWidth;

          if (itemsWidth > componentWidth) {
            this.hiddenItems.push(this.items[i]);
            this.hideItem(elementChildren[i]);
          } else {
            this.showItem(elementChildren[i]);
          }
        }
        this.isLoaded = true;
      },
      showItem(item: Element) {
        item.removeAttribute('hidden');
      },
      hideItem(item: Element) {
        item.setAttribute('hidden', 'hidden');
      },
      toggleSublistVisibility(isShowDropdown: boolean) {
        if (isShowDropdown) {
          this.$emit('clickedSublistBtn');
        }
      },
      clickLink(item: ThemeItem) {
        this.$emit('clickedLink', item);
      },
      clickOutside(event: Event) {
        this.$emit('click', event);
      },
      clickAdvMarker(event: Event, item: ThemeItem) {
        this.isShowAdvPopup = true;
        this.currentAdvCompanyName = item.advCompanyName;
        this.currentAdvToken = `Erid:&nbsp;${item.advToken}`;

        const targetInfo = (event.target as Element).getBoundingClientRect();
        const wrapperInfo = (this.$refs.wrapper as Element).getBoundingClientRect();
        this.$nextTick(() => {
          const popupInfo = this.$refs.advPopup?.$el.getBoundingClientRect();
          this.advPopupArrowLeft =
            targetInfo?.left + targetInfo?.width / 2 - wrapperInfo?.left;

          this.advPopupLeft =
            targetInfo.left + (targetInfo.width - popupInfo.width) / 2 - wrapperInfo.left;

          if (this.advPopupLeft < 0) {
            this.advPopupLeft = 0;
          }

          if (this.advPopupLeft + popupInfo.width > window.innerWidth) {
            this.advPopupLeft = window.innerWidth - popupInfo.width - CONTENT_PADDING;
          }
        });
      },
      getLinkType(itemStatus = 'default', place = 'mainlist') {
        switch (this.type) {
          case 'themes':
            return itemStatus === 'default' || itemStatus === 'advert'
              ? 'link-dark-type-2'
              : 'link-white-type-2';
          case 'supercover':
            return place === 'sublist' && itemStatus === 'default'
              ? 'link-dark-type-2'
              : 'link-white-type-2';
          case 'project':
          default:
            return 'link-white';
        }
      },
      getTargetLink(path: string) {
        return this.isForceSelfTarget || !isAbsoluteUrl(path) ? '_self' : '_blank';
      }
    },
    render() {
      const attributes = {
        on: this.$listeners
      };

      return (
        <div
          class={[
            styles.textListWithDropdownNew,
            styles[this.type],
            this.isLoaded ? styles.loaded : ''
          ]}
          ref={'wrapper'}
          data-test="text-list-with-dropdown"
          {...attributes}
        >
          <ul
            ref="mainList"
            class={[styles.list, this.hasSublist ? '' : styles.noButton]}
            data-test="list-visible"
          >
            {this.items.map((item: ThemeItem, index: number) => (
              <li
                key={item.path}
                class={[styles.item, item.status === 'advert' ? styles.advMarker : '']}
                style={{ order: this.themeOrders[index] || 0 }}
              >
                <JtnUiTypography
                  class={[
                    styles.link,
                    styles[this.type],
                    guardUnspecified(item.status) ? styles[item.status] : ''
                  ]}
                  href={item.path}
                  title={item.title}
                  target={this.getTargetLink(item.path)}
                  type={this.fontType}
                  linkType={this.getLinkType(item.status, 'mainlist')}
                  tag="a"
                  data-test="list-visible-link"
                  onClick={(event: Event) => {
                    this.clickLink(item);
                  }}
                >
                  {item.title}
                </JtnUiTypography>
                {item.status === 'advert' && (
                  <div
                    class={styles.advMarkerWrap}
                    onClick={(event: Event) => {
                      this.clickAdvMarker(event, item);
                    }}
                  />
                )}
              </li>
            ))}
            {this.advLogoInThemes &&
              this.type === 'themes' &&
              this.advLogoInThemes.map((advLogo: AdvLogoInThemes, index: number) => (
                <WidgetAdvLogoInThemes
                  key={index}
                  class={styles.item}
                  style={{ order: advLogo.position }}
                  advData={advLogo}
                />
              ))}
          </ul>

          {this.isShowAdvPopup && (
            <div>
              <div class={styles.advPopupArrow} style={this.advPopupArrowStyle} />
              <JtnUiTypography
                ref={'advPopup'}
                class={styles.advPopup}
                style={this.advPopupStyle}
                type={'font-photoday-type-1'}
                onClick={() => {
                  this.isShowAdvPopup = false;
                }}
              >
                <div domPropsInnerHTML={this.currentAdvCompanyName} />
                <div domPropsInnerHTML={this.currentAdvToken} />
                <svg width="10" height="10" class={styles.closeIcon}>
                  <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-close"></use>
                </svg>
              </JtnUiTypography>
            </div>
          )}

          {!this.isLoaded && <div class={styles.overlay} />}

          {this.hasSublist && (
            <div class={styles.moreBtnWrap}>
              <JtnUiDropdown
                onToggle={(isShowDropdown: boolean) =>
                  this.toggleSublistVisibility(isShowDropdown)
                }
                onCLickedOutside={(event: Event) => this.clickOutside(event)}
              >
                <template slot="iconOpen">
                  <svg
                    style={{ color: this.type === 'themes' ? '#37404D' : '#fff' }}
                    width={MORE_BTN_SIZE}
                    height={MORE_BTN_SIZE}
                  >
                    <use xlinkHref="/dist/legacy/svg-sprites/jtn-critical.42b7545660e4f467e75d4b37a02533e6.svg#jtn-critical-kebab"></use>
                  </svg>
                </template>
                <ul class={styles.sublist} data-test="list-dropdown">
                  {this.hiddenItems.map((item: ThemeItem, index: number) => (
                    <li
                      key={guardUnspecified(item.position) ? item.position : index}
                      class={styles.item}
                    >
                      <JtnUiTypography
                        class={[
                          styles.link,
                          guardUnspecified(item.status) ? styles[item.status] : ''
                        ]}
                        href={item.path}
                        title={item.title}
                        target={this.getTargetLink(item.path)}
                        type={this.fontType}
                        linkType={this.getLinkType(item.status, 'sublist')}
                        tag="a"
                        data-test="list-dropdown-link"
                        onClick={() => {
                          this.clickLink(item);
                        }}
                      >
                        {item.title}
                      </JtnUiTypography>
                    </li>
                  ))}
                </ul>
              </JtnUiDropdown>
            </div>
          )}
        </div>
      );
    }
  });
